import TableCrud from '../components/TableCrud'
import useDocumentTitle from '../hooks/useDocumentTitle'
import FileUploadButtonSimple from '../components/FileUploadButtonSimple'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const baseURL = process.env.REACT_APP_API_URL;
const TablaFCIs = () => {
  useDocumentTitle('Tabla FCIs')
 
   // const columns = [{title: "Referencia", dataIndex: 'identifier'}, {title: "Size", dataIndex: 'size', inputType: "number"}]
//  { "codCaja": "14018", "monedaLiq": "ARS", "codCAFCI": "1055", "plazoLiq": 2, "id": "qY8NgbXya8" }
   const columns = [
     { title: "Cod Caja", dataIndex: 'codCaja' },
     { title: "Moneda Liq.", dataIndex: 'monedaLiq' },
    { title: "Cod CAFCI", dataIndex: 'codCAFCI' },
    { title: "Plazo Liq.", dataIndex: 'plazoLiq' },
  ]
  function cargaDatos() {
    window.location.reload()
  }
  
  return (
    <div className="container">
      <div className="row">
       <div className="col-12">
        <div className="card">
          <div className="card-header">
          <span className="ml-1">
            <b>Tabla FCIs</b>
          </span>
        <FileUploadButtonSimple callBack={cargaDatos} endPoint={`${baseURL}upload/cargaMasivaTablaFCIs`} className={"float-right mr-1"} icon={<FontAwesomeIcon className="ml-2" icon={faUpload} />} btnText="Carga Masiva" btnClassName="btn btn-warning"  />

          </div>
          <div className="card-body">
          <TableCrud tableName={'fcis'} columns={columns} />
          </div>
        </div>

       </div>
 
      </div>

    </div>
  )
}

export default TablaFCIs