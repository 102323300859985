
const baseURL = process.env.REACT_APP_API_URL;

const getSaldosProyectadosAUNEAN = async (options, alycsP = false, sumaPendientes = false) => {
  let json =  []
  console.log({sumaPendientes})
  try {
    const res = await fetch(`${baseURL}AUNE/saldosProyectadosAN?alycsP=${alycsP == true ? 'true' : 'false' }&sumaPendientes=${sumaPendientes == true ? 'true' : 'false'}`, options);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export default getSaldosProyectadosAUNEAN;
