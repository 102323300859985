import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import '../css/customTable.css'

const ALYC_INTEGRAL = process.env.REACT_APP_ALYC_INTEGRAL  === "true";

const Plazotable = ({ titulo, datos, moneda, initBodyVisb = false, realoadBtn = false, isLoading = false, showDhs = false, showFooter = false, ALYC_INTEGRAL = process.env.REACT_APP_ALYC_INTEGRAL  === "true" }) => {
 
  const [filtro, setFiltro] = useState("");
  const [filtroEspecie, setfiltroEspecie] = useState("");
  let tabla = [];
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
 

  //console.log("datos", datos);

  if (datos[moneda] !== undefined) {
    tabla = datos[moneda];
  }

  const formatNumero = (numero, maximumFractionDigits = 2 ) => {
    return numero.toLocaleString('de', { maximumFractionDigits});
  };
  const formatMonto = (numero, maximumFractionDigits = 2, minimumFractionDigits = 2 ) => {
    if(numero == 0) return ""
    return numero.toLocaleString('de', { maximumFractionDigits, minimumFractionDigits});
  };
  const sumaMontoNeto = (datos) => {
    let suma = 0;
    datos.map((item) => {
      suma += item.nm;
    });
    return formatNumero(suma);
  };

  const sumaDhs = (datos) => {
    let suma = 0;
    datos.map((item) => {
      suma += item.dhMdoCalculado || 0;
    });
    return formatMonto(-suma);
  };
  const showhideBody = () => {
    setBodyVisible(!bodyVisible)
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
  const leyBtn = bodyVisible ? "-" : "+";

  useEffect(() => {}, []);

  const sumaCol = (tabla = [], idxCol) => {
     let suma = 0;
     tabla.forEach( row => {
        if( typeof row[idxCol] == "number" ) { suma+= row[idxCol] }
     } ) 

     return suma;
     
  }

  const tablaFiltrada = tabla.filter(item => item.cuenta.includes(filtro) && item.codespecie?.toLowerCase().includes(filtroEspecie?.toLowerCase())  )

  return (
    <div className="card">
      <div className="card-header ">
        <div className="row">
          <div className="col-8">
            <span>
              <b>Operaciones {titulo}</b> - Monto: $ {sumaMontoNeto(tablaFiltrada)}{" "}
              {showDhs && (
                <>
                  {"| Dhs. Mdo. $ "} {sumaDhs(tablaFiltrada)}{" "}
                </>
              )}
            </span>
          </div>
          <div className="col-4">
       
            <span className="input-group" style={{justifyContent: "right"}}>
              {realoadBtn && (
                <button
                  type="button"
                  onClick={() => realoadBtn()}
                  name=""
                  className={isLoading ? "btn btn-secondary float-right btn-sm ml-1 disabled" : "btn btn-secondary float-right btn-sm ml-1"}
                >
                  <FontAwesomeIcon icon={faSync} />
                </button>
              )}
       
              <input
                placeholder="Especie"
                onChange={(e) => {
                  setfiltroEspecie(e.target.value);
                }}
                className="form-control-sm form-control  float-right ml-1"
                type="text"
                style={{ maxWidth: "80px" }}
              />
               <input
                placeholder="Cuenta"
                onChange={(e) => {
                  setFiltro(e.target.value);
                }}
                className="form-control-sm form-control  float-right ml-1"
                type="text"
                style={{ maxWidth: "80px" }}
              />

                    <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
                <b>{leyBtn}</b>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className={cardBodyClass}>
        <div style={{ maxHeight: "60vh", minHeight: "10vh" }} className=" tableFixHead">
          <table className="table table-sm table-bordered table-hover">
            <thead>
              <tr>
                {ALYC_INTEGRAL && <th scope="col">Agente</th>}
                <th scope="col">Cuenta</th>
                <th scope="col">Especie</th>
                <th title="Monto Compra" scope="col">
                  M.Com
                </th>
                <th title="Cantidad Compra" scope="col">
                  C.Com
                </th>
                <th title="Monto Venta" scope="col">
                  M.Ven
                </th>
                <th title="Cantidad Venta" scope="col">
                  C.Ven
                </th>
                <th title="Neto Cantidad" scope="col">
                  Neto C.
                </th>
                <th title="Neto Monto" scope="col">
                  Neto M.
                </th>
                {showDhs && (
                  <th title="Derechos de Mercado Calculados" scope="col">
                    Dh. Mdo.
                  </th>
                )}
                <th scope="col">PPP</th>
              </tr>
            </thead>
            <tbody>
              {tablaFiltrada.map((item, index) => (
                <tr key={index}>
                   {ALYC_INTEGRAL && <th scope="col">{item.agente}</th>}
                  <th scope="col">{item.cuenta}</th>
                  <td>{item.codespecie}</td>
                  <td className="text-right">{formatMonto(item.mc)}</td>
                  <td className="text-right">{formatNumero(item.cc)}</td>
                  <td className="text-right">{formatMonto(item.mv)}</td>
                  <td className="text-right">{formatNumero(item.cv)}</td>
                  <td className="text-right">{formatNumero(item.nc)}</td>
                  <td className="text-right">{formatMonto(item.nm)}</td>
                  {showDhs && <td className="text-right">{formatMonto(-item.dhMdoCalculado)}</td>}
                  <td className="text-right">{formatNumero(item.ppp, 3)}</td>
                </tr>
              ))}
            </tbody>
            {showFooter && (
              <tfoot>
                <tr>
                  <th scope="col">Totales</th>
                  {ALYC_INTEGRAL && <th></th>}
                  <th></th>
                  <th className="text-right">{formatMonto(sumaCol(tablaFiltrada, "mc"))}</th>
                  <th className="text-right">{formatNumero(sumaCol(tablaFiltrada, "cc"))}</th>
                  <th className="text-right">{formatMonto(sumaCol(tablaFiltrada, "mv"))}</th>
                  <th className="text-right">{formatNumero(sumaCol(tablaFiltrada, "cv"))}</th>
                  <th className="text-right">{formatNumero(sumaCol(tablaFiltrada, "nc"))}</th>
                  <th className="text-right">{formatMonto(sumaCol(tablaFiltrada, "nm"))}</th>
                  {showDhs && <th className="text-right">{formatNumero(sumaCol(tablaFiltrada, "dhMdoCalculado"))}</th>}
                  <th></th>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Plazotable;
