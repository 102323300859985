import React, {useState} from "react";

const TotalesPorEspecieWidget = ({moneda, infoCI, info48hs, initBodyVisb = false}) => {

const [filtroEspecie, setfiltroEspecie] = useState('')
const [filtroCuenta, setFiltroCuenta] = useState('')
const [dias48hs, setdias48hs] = useState(2)
const [bodyVisible, setBodyVisible] = useState(initBodyVisb);

const diccCodMon = {'P': 'Pesos', 'D': 'Dólar', 'C': 'Dólar Transferencia'}

const formatNumero = (numero) => {
  return numero.toLocaleString('es', {minimumFractionDigits: 2, maximumFractionDigits: 2});
};
const formatCantidad = (numero) => {
  return numero.toLocaleString('es', {minimumFractionDigits: 0, maximumFractionDigits: 0});
};
let infoCIMoneda = infoCI[moneda] || []; 
let info48hsMoneda = info48hs[moneda] || []; 

//console.log({infoCIMoneda, info48hsMoneda})
const borderStyle = {"border-bottom-color": "black"}
const getDato = (cuenta, especie, datos = [], col) => {
 // console.log({cuenta, especie, datos})
   const found = datos.find( item => item.cuenta == cuenta && item.codespecie.toUpperCase() == especie.toUpperCase());
   //console.log(found)
   if(found) {return col == "ppp" ? found[col] : found[col] || 0 }
   else { return 0}
}
const nc48hs = getDato(filtroCuenta, filtroEspecie, info48hsMoneda, "nc");
const ncCI = getDato(filtroCuenta, filtroEspecie, infoCIMoneda, "nc");
const ppp48hs = getDato(filtroCuenta, filtroEspecie, info48hsMoneda, "ppp");
const pppCI = getDato(filtroCuenta, filtroEspecie, infoCIMoneda, "ppp");
const nm48hs = getDato(filtroCuenta, filtroEspecie, info48hsMoneda, "nm");
const nmCI = getDato(filtroCuenta, filtroEspecie, infoCIMoneda, "nm");

const sumCantidad = nc48hs + ncCI;
let sumNM = nm48hs + nmCI;
let pppTot = sumNM / sumCantidad;
pppTot = isFinite(pppTot) ? pppTot : "";
const spread = ppp48hs - pppCI;
const tasa = ((spread / pppCI) / dias48hs) * 365 *100;


const calculaTotalesOperados = (cuenta, especie, datos = []) => {
 let totNmoninales = 0, totMonto = 0, totDH = 0;
 datos.forEach(item => {
   if(item.cuenta == cuenta && item.codespecie.toUpperCase() == especie.toUpperCase()) {
     totNmoninales += item.cc +  item.cc;
     totMonto += item.mc +  item.mv;

   }
 })
 
 totDH = totMonto * (0.0025/100)
 

 return {totNmoninales, totMonto, totDH}

}

const {totMonto, totNmoninales, totDH} = calculaTotalesOperados(filtroCuenta, filtroEspecie, [...infoCIMoneda, ...info48hsMoneda])

//console.log({totMonto, totNmoninales, totDH})

const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
const leyBtn = bodyVisible ? "-" : "+";

const showhideBody = () => {
  setBodyVisible(!bodyVisible)
};

  return (
    <div className="col-lg-6 col-sm-12 col-md-12">
      <div className="card">
        <div className="card-header ">
          <span>
            <b>Totales por Especie</b> - {diccCodMon[moneda]}
          </span>{" "}
          <span ></span>{" "}
          <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              <b>{leyBtn}</b>
            </button>
          <input value={filtroEspecie} onChange= { (e) =>  setfiltroEspecie(e.target.value) } placeholder="especie" type="text" style={{"width": "60px",  "text-transform":"uppercase",  "float": "right"}} className={"form-control-sm form-control mr-1"} />
          <input value={filtroCuenta}   onChange= { (e) =>  setFiltroCuenta(e.target.value) } placeholder="cuenta" type="text" style={{ width: "60px", float: "right" }} className={"form-control-sm form-control mr-1"}  />
          <input value={dias48hs}  onChange= { (e) =>  setdias48hs(e.target.value) } type="number" style={{ width: "45px", float: "right" }} className={"form-control-sm form-control mr-1"}  />
        </div>
        <div className={cardBodyClass}>
          <table  className="table table-sm table-bordered table-hover texto-small">
            <thead>
              <tr>
                <th scope="col">Plazo</th>
                <th scope="col">Neto Cantidad</th>
                <th scope="col">PPP</th>
                <th scope="col">Neto Monto</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">48hs</th>
                <td className="text-right">{formatCantidad(nc48hs)}</td>
                <td className="text-right">{formatNumero(ppp48hs)}</td>
                <td className="text-right">{formatNumero(nm48hs)}</td>
              </tr>
              <tr>
                <th style={borderStyle} scope="row">
                  CI
                </th>{" "}
                <td className="text-right" style={borderStyle} >{formatCantidad(ncCI)}</td>
                <td  className="text-right" style={borderStyle}>{formatNumero(pppCI)}</td>
                <td className="text-right" style={borderStyle}>{formatNumero(nmCI)}</td>
              </tr>
              <tr>
                <th scope="row">TOTAL</th> <td className="text-right">{formatCantidad(sumCantidad)} </td> <td className="text-right">{formatNumero(pppTot)}</td> <td className="text-right">{formatNumero(sumNM)}</td>{" "}
              </tr>
              <tr>
                <th scope="row"></th> <td className="text-right" >Spread:</td> <td>{formatNumero(spread)}</td> <td>{formatNumero(tasa)}%</td>{" "}
              </tr>
              <tr>
                <td colSpan="4">Tot. Nominales: {formatCantidad(totNmoninales)} | Tot. Monto: {formatCantidad(totMonto)} | Dh: {formatNumero(totDH)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TotalesPorEspecieWidget;
