import React, { useState, useEffect } from "react";
import Paneldheader from "../components/PanelHeader";
import Plazotable from "../components/Plazotable";
import SaldosPanel from "../components/SaldosPanel";
import CaucionTable from "../components/CaucionTable";
import OpcionesTable from "../components/OpcionesTable";
//import getResumenOperdiaContado from "../services/getResumenOperdiaContado";
//import getResumenOperdiaCaucion from "../services/getResumenOperdiaCaucion";
//import getResumenOperdiaOpciones from "../services/getResumenOperdiaOpciones";
//import getSaldosBYMA from "../services/getSaldosBYMA";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
import SaldosPanelMAV from "../components/SaldosPanelMAV";
const hasDerivaciones = process.env.REACT_APP_BYMA_DERIVACIONES  === "true";
const hasSENEBI = process.env.REACT_APP_BYMA_SENEBI  === "true";


const ResumenSaldosMdos = () => {
  useDocumentTitle('Panel de Saldos')
  const [infoCI, setInfoCI] = useState([]);
  const [info24hs, setInfo24hs] = useState([]);
  const [info48hs, setInfo48hs] = useState([]);
  const [infoCISB, setInfoCISB] = useState([]);
  const [info24hsSB, setInfo24hsSB] = useState([]);
  const [info48hsSB, setInfo48hsSB] = useState([]);
  const [moneda, setMoneda] = useState("P");
  const [cuentaOper, setCuentaOper] = useState(0);
  const [showCI, setShowCI] = useState(true);
  const [show24hs, setShow24hs] = useState(false);
  const [show48hs, setShow48hs] = useState(true);
  const [saldosBYMA, setSaldosBYMA] = useState({});
  const [saldosCI, setSaldosCI] = useState({});
  const [saldosCISB, setSaldosCISB] = useState({});
  const [saldosCaucion, setSaldosCaucion] = useState({});
  const [saldosDerivacionesESCO, setSaldosDerivacionesESCO] = useState([])
  // const [tablaCaucion, setTablaCaucion] = useState({});
  // const [tablaOpciones, setTablaOpciones] = useState({});
  // const [hayEjercicios, setHayEjercicios] = useState(false);
  // const [hayVC, setHayVC] = useState(false)
  const [saldosDerivaciones, setSaldosDerivaciones] = useState(false)
  const [showPanelSENEBI, setShowPanelSENEBI] = useState(false);
  const [isLoading, setIsLoading]  = useState(false);

  const [isLoadingMAV, setIsLoadingMAV]  = useState(false);
  const [saldosDerivacionesMAV, setSaldosDerivacionesMAV] = useState({})
  const [saldosMAV, setSaldosMAV] = useState({})
  const [MAVConected, setMAVConected]  = useState(false);
  const [BYMAConected, setBYMAConected]  = useState(false);
  //const [info_procesada, setInfoprocesada] = useState({})

  let info_procesada;

  const {getResumenOperdiaContado, getSaldosBYMA, getResumenOperdiaCaucion, getDerivacionesPPTLiqDia, getResumenOperdiaContadoSB, getSaldosMAV, getSaldosDerivaMAVDiaAnt, saldosDerivaMAVHoy, getMarketSesions, derivSaldosESCOporDepMAV} = useServices();

  useEffect(() => {
    initPanel();
  }, []);


   async function updateSesionsStatus ()  {
    const res = await getMarketSesions();
    if (res.error) return;

    if(res.loginGARA) {
      cargaBYMA();
    }
    if(res.loginMAV) {
     cargaMAV();
    }
   

  };

  async function cargaBYMA () {

    setIsLoading(true)

    setBYMAConected(true)
    // poner aca carga panel byma
    await carga_oper_gara();
    await cargaResumenCaucGara();
   // await cargaResumenOpc();
    await cargaSaldosBYMA();
    if(hasDerivaciones) { await cargaDerivacionesDiaAnt()}
    if(hasSENEBI) { await carga_oper_gara_SB() }
    setIsLoading(false)

  }

  async function cargaMAV () {

    setMAVConected(true)
    setIsLoadingMAV(true)
    // poner aca carga panel mav
    await cargaSaldosMAV();
    await cargaSaldosDerivMAV();
    setIsLoadingMAV(false)

  }

  async function carga_oper_gara() {
    let operdia = await getResumenOperdiaContado();
    if(operdia.error) return

    info_procesada = operdia;

    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCI(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hs(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hs(info_procesada.tabla["48hs"]);
      }
    }
    if (info_procesada.saldos) {
      setSaldosCI(info_procesada.saldos["Inmediato"]);
    }
    return true;
  }

  async function carga_oper_gara_SB() {
    let operdia = await getResumenOperdiaContadoSB();
    if (operdia.error) return;

    const info_procesada = operdia;

    //setCuentaOper(prev => prev + info_procesada.cantOper);
    //setCuentaOper(info_procesada.cantOper);
    //console.log({ info_procesada });
    
    if(info_procesada.cantOper > 0) { setShowPanelSENEBI(true) }
    
    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCISB(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hsSB(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hsSB(info_procesada.tabla["48hs"]);
      }
      if (info_procesada.saldosALiq) {
        setSaldosCISB(info_procesada.saldosALiq["Inmediato"]);
      }
    }
    
    return true;
  }


  async function initPanel() {
    updateSesionsStatus();
 
  }



  async function cargaDerivacionesDiaAnt () {
    const res = await getDerivacionesPPTLiqDia();
    if(res.error) return;
    setSaldosDerivaciones(res)

  }


  async function cargaResumenCaucGara() {
    const resumenCaucion = await getResumenOperdiaCaucion();
    if(resumenCaucion.error) return
    //console.log({ resumenCaucion });
    if (resumenCaucion.saldos) {
      setSaldosCaucion(resumenCaucion.saldos);
    }
    if (resumenCaucion.tabla) {
     // setTablaCaucion(resumenCaucion.tabla);
    }
    return true;
  }

  async function cargaSaldosDerivMAV () {

    const saldosDerivESCO = await  derivSaldosESCOporDepMAV();
    if(saldosDerivESCO.error) return
    //console.log({saldosDerivESCO})
    if(saldosDerivESCO.saldosDerivESCOporDep) {
      setSaldosDerivacionesESCO(saldosDerivESCO.saldosDerivESCOporDep)
    } else {
    const dAnt = await getSaldosDerivaMAVDiaAnt();
    if(dAnt.error) return
    const hoy = await saldosDerivaMAVHoy();
    if(hoy.error) return
    

    
    setSaldosDerivacionesMAV({hoy, dAnt})
   }
    

  }


  const cargaSaldosBYMA = async () => {
    const saldos = await getSaldosBYMA();
    if(saldos.error) return

    setSaldosBYMA(saldos);
  };



  async function cargaSaldosMAV () {
    const res = await getSaldosMAV();
    if(res.error) return
    setSaldosMAV(res)
  }


  return (
    <div className="container-fluid">
      <div className="row no-gutters">
        <div className="col">
        <div className="card">
          <div className="card-header">
          <strong>BYMA {!BYMAConected && <> - <span class="badge badge-pill badge-danger">No conectado</span> </> } </strong>
          </div>
          <div className="card-body">
          <div className="row no-gutters">
          <div className="col"></div>
        <SaldosPanel reloadPanelSaldos={cargaBYMA} saldosCaucion={saldosCaucion} moneda="P" saldosCI={saldosCI} saldosCISB={saldosCISB} saldos={saldosBYMA} saldosDerivaciones={saldosDerivaciones} isLoading={isLoading} />

        <SaldosPanel reloadPanelSaldos={cargaBYMA}  saldosCaucion={saldosCaucion} moneda="D" saldosCI={saldosCI} saldosCISB={saldosCISB} saldos={saldosBYMA} saldosDerivaciones={saldosDerivaciones} isLoading={isLoading} />
        <SaldosPanel reloadPanelSaldos={cargaBYMA}  saldosCaucion={saldosCaucion} moneda="C" saldosCI={saldosCI} saldosCISB={saldosCISB} saldos={saldosBYMA} saldosDerivaciones={saldosDerivaciones} isLoading={isLoading} />
        <div className="col"></div>
        </div>
          </div>
        </div>

        </div>
      </div>

      <div className="row no-gutters">
        <div className="col">
        <div className="card">
          <div className="card-header">
          <strong>MAV  {!MAVConected && <> - <span class="badge badge-pill badge-danger">No conectado</span> </> } </strong>
          </div>
          <div className="card-body">
          <div className="row no-gutters">
          <div className="col"></div>
          <SaldosPanelMAV moneda="P" saldos={saldosMAV} isLoading={isLoadingMAV} reloadFunc={cargaMAV} saldosDerivaciones={saldosDerivacionesMAV} saldosDerivacionesESCO={saldosDerivacionesESCO} />
        <SaldosPanelMAV moneda="D" saldos={saldosMAV}  isLoading={isLoadingMAV} reloadFunc={cargaMAV}  saldosDerivaciones={saldosDerivacionesMAV} saldosDerivacionesESCO={saldosDerivacionesESCO}/>
       
         
          <div className="col"></div>
        </div>
          </div>
        </div>

        </div>
      </div>




    </div>
  );
};

export default ResumenSaldosMdos;
