const baseURL = process.env.REACT_APP_API_URL;

export const saveSaldosPersistencia = async (options, {id, saldos}) => {
    let json =  []
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...options.headers },
        body: JSON.stringify({id, saldos})
    };
    try {
      const res = await fetch(`${baseURL}saldosConsolidados/saldosPersistencia`, requestOptions);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };