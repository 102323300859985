import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faSync, faDownload } from "@fortawesome/free-solid-svg-icons";
//import getRG624 from "../services/getRG624";
import postRG624 from "../services/postRG624"
import { Link } from "react-router-dom";
import useServices from "../hooks/useServices";

const TablaRG624 = () => {
  const [cssBarra, setCSSBarra] = useState({ width: "0%" });
  const [datos, setDatos] = useState([]);
  const [ctasnoEncontradas, setCtasnoEncontradas] = useState([])
  const {getRG624} = useServices();

  function selectElementContents(el) {
    var body = document.body, range, sel;
    if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
            range.selectNodeContents(el);
            sel.addRange(range);
        } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
        }
        document.execCommand("copy");

    } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
        range.execCommand("Copy");
    }
}

  const cargaDatos = async () => {
    setCSSBarra({ width: "0%" });
    setDatos([])
    setCtasnoEncontradas([])
   
    const contado = await getRG624.Contado();
    if(contado.error) return
    setCSSBarra({ width: "33%" });
    const cuacion = await getRG624.Cauciones();
    if(cuacion.error) return
    setCSSBarra({ width: "66%" });
    const opciones = await getRG624.Opciones();
    if(opciones.error) return
    setCSSBarra({ width: "100%" });
    const dats = [...contado, ...cuacion, ...opciones];
    setDatos(dats);

    const noEncontrados = dats.filter(item => item.razonSocial == null).map( item => item.comitente)
    const arrnoEncontrados = [...new Set(noEncontrados)]
    //console.log({arrnoEncontrados})
    setCtasnoEncontradas( prevState => arrnoEncontrados)
  };

  async function generaExcel () {
    const post = await postRG624(datos)
  }


  useEffect(() => {
      cargaDatos()
  }, []);

  return (
    <div className="card">
      <div className="card-header ">
        <span>
          <b>Operaciones RG624</b>
        </span>{" "}
        <span className="badge badge-secondary"> {datos.length} </span>


        <button
          type="button"
          name=""
          data-toggle="tooltip"
          data-placement="bottom"
          title="Copiar"
          onClick={() =>  cargaDatos() }
   
          className="btn btn-secondary  ml-2"
        >
      
          <FontAwesomeIcon icon={faSync} />
        </button>

        {ctasnoEncontradas.length != 0 && <><span className="ml-2 alert-warning">No se encontraron datos para las cuentas: {ctasnoEncontradas.join(",")}</span> <Link to="/comitentes">Agregar comitentes</Link> </> }

        <button
          type="button"
          name=""
          data-toggle="tooltip"
          data-placement="bottom"
          title="Copiar"
          onClick={() =>  selectElementContents( document.getElementById('tb_rg624')) }
   
          className="btn btn-secondary float-right ml-2"
        >
      
          <FontAwesomeIcon icon={faCopy} />
        </button>
        <button
          type="button"
          name=""
          data-toggle="tooltip"
          data-placement="bottom"
          title="Descargar Excel"
          className="btn btn-success float-right"
          onClick={() => {generaExcel()}}
        >
      
          <FontAwesomeIcon icon={faDownload} />
        </button>
      </div>
      <div className="progress" style={{ height: "5px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={cssBarra}
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="dropdown-divider"></div>
      <div className="card-body">
        <table id="tb_rg624" class="table table-sm table-bordered table-hover texto-small">
          <thead>
            <tr>
              <th scope="col">Nombre y Apellido</th>
              <th scope="col">Nº CUIL/CUIT</th>
              <th scope="col">Instrumento</th>
              <th scope="col">Plazo</th>
              <th scope="col">Moneda</th>
              <th scope="col">Cantidad Comprada</th>
              <th scope="col">Precio Promedio Cpra.</th>
              <th scope="col">Monto Comprado</th>
              <th scope="col">Cantidad Vendida</th>
              <th scope="col">Precio Promedio Vta.</th>
              <th scope="col">Monto Vendido</th>
            </tr>
          </thead>
          <tbody>
            {datos.map((item) => (
              <tr> 
              <td className={item.razonSocial == null ? 'alert-warning' : '' }>{item.razonSocial != null ? item.razonSocial : `NO ENCONTRADO COMIT. ${item.comitente}` }</td>
              <td>{item.cuit}</td>
              <td>{item.instrumento}</td>
              <td>{item.plazo}</td>
              <td>{item.moneda}</td>
              <td>{item.cantidad_cpra}</td>
              <td>{item.precio_cpra}</td>
              <td>{item.monto_cpra}</td>
              <td>{item.cantidad_vta}</td>
              <td>{item.precio_vta}</td>
              <td>{item.monto_vta}</td>
             </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablaRG624;
