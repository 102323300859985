import React, { useState, useRef } from "react";
import useServices from "../hooks/useServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";


const OperacionesTrader = () => {
  const [data, setData] = useState([]);
  const [filtraAplicadas, setFiltraAplicadas] = useState(false);
  const [filtraEjercicios, setFiltraEjercicios] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [contador, setContador] = useState({ejercicio: 0, aplicacion: 0, vc: 0});
  const { getOperacionesContadoGaraTrader } = useServices();
  const [localeFormat, setLocaleFormat] = useState("de");
  const tablaRef = useRef(null);
  async function cargaOperaciones() {
    setisLoading(true);
    const res = await getOperacionesContadoGaraTrader();
    setisLoading(false);
    if (res.error) return;
    const cont = cuentaEstados(res)
    setData(res);
    setContador(cont)
  }
  function formatNumero (valor) {
    if(valor) {
     
        return valor.toLocaleString(localeFormat)
    }

  }





  function cuentaEstados (datos) {
      let contador = {ejercicio: 0, aplicacion: 0, vc: 0}
      datos.forEach( item => {
          if(item.esAplicacion) {contador.aplicacion++}
          if(item.esEjercicio === "Si") {contador.ejercicio++}
          if(item.esVC === "Si") {contador.vc++}
      } )
     // console.log(contador)
      return contador;
  }

  function exportTableToExcel(tableSelect, filename = "") {
    
    const dataType = "application/vnd.ms-excel;charset=utf-8";
    //var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

    // Specify file name
    //const fechaStr = new Date().toISOString().split("T")[0];
    const fechaStr =  new Date().toISOString().split("T")[0]
    //fechaStr = fechaStr.replace(/\//g, '-');
    filename = filename ? filename + ".xls" : `trading_${fechaStr}.xls`;

    // Create download link element
    const downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = "data:" + dataType + ", " + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }

  function handleDownloadExcel () {
    exportTableToExcel(tablaRef.current)
  }
  function handleSetSeparadorDecimales(locale) {
     setLocaleFormat(locale)
  }


  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-8">
            {" "}
            <b>Operaciones</b> -{" "}
            <select className="" style={{ maxWidth: "180px" }}>
              {" "}
              <option value="contado"> Contado Garantizado </option>{" "}
            </select>{" "}
          </div>
          <div className="col-4">
          
          <select title="Separador decimal" value={localeFormat} style={{width: "40px"}} onChange={(e) => {handleSetSeparadorDecimales(e.target.value)} } class="form-control-sm float-right ml-1" >
              <option value="de">,</option> 
              <option value="en">.</option>
            </select>
            <button className="btn btn-sm btn-success ml-1  float-right" onClick={() => handleDownloadExcel()}>
              <FontAwesomeIcon icon={faFileExcel} />
            </button>
            <span className="btn btn-sm  float-right ml-1 "> {data.length} </span>

            <button
              type="button"
              disabled={isLoading}
              onClick={() => cargaOperaciones()}
              className="btn btn-secondary btn-sm float-right ml-1"
            >
              {" "}
              {isLoading ? "Cargando..." : "Cargar"}
            </button>{" "}
            {contador.aplicacion > 0 && (
              <div className="btn-group float-right">
                <button
                  onClick={() => setFiltraAplicadas(!filtraAplicadas)}
                  type="button"
                  class="btn btn-sm btn-danger  mr-1"
                >
                  Aplicaciones | <span class="badge badge-light">{contador.aplicacion}</span>
                </button>
                {filtraAplicadas && (
                  <button onClick={() => setFiltraAplicadas(false)} type="button" class="btn btn-sm btn-danger">
                    X
                  </button>
                )}
              </div>
            )}
            {contador.ejercicio > 0 && (
              <div className="btn-group float-right">
                <button
                  onClick={() => setFiltraEjercicios(!filtraEjercicios)}
                  type="button"
                  class="btn btn-sm btn-warning float-right mr-1"
                >
                  Ejercicios | <span class="badge badge-light">{contador.ejercicio}</span>
                </button>
                {filtraEjercicios && (
                  <button onClick={() => setFiltraEjercicios(false)} type="button" class="btn btn-sm btn-warning">
                    X
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div style={{ maxHeight: "60vh", minHeight: "10vh" }} className=" tableFixHead">
          <table ref={tablaRef} className="table table-sm table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col">Hora</th>
                <th scope="col">Numero Oper.</th>
                <th scope="col">Comitente</th>
                <th scope="col">Titulo</th>
                <th scope="col">Especie</th>
                <th scope="col">Plazo</th>
                <th scope="col">Lado</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Precio</th>
                <th scope="col">Monto</th>
                <th scope="col">Estado</th>
                <th scope="col">Ejercicio</th>
                <th scope="col">VC</th>
                <th scope="col">Aplicacion</th>
              </tr>
            </thead>
            <tbody>
              {data
                .filter((item) => {
                  if (filtraAplicadas) {
                    return item.esAplicacion;
                  }
                  if (filtraEjercicios) {
                    return item.esEjercicio == "Si";
                  }
                  return item;
                })
                .map((item) => (
                  <tr>
                    <td>{item.hora}</td>
                    <td>{item.numeroOper}</td>
                    <td>{item.comitente}</td>
                    <td>{item.titulo}</td>
                    <td>{item.codEspecie}</td>
                    <td>{item.plazo}</td>
                    <td>{item.lado}</td>
                    <td className="text-right">{formatNumero(item.cantidad)}</td>
                    <td className="text-right">{formatNumero(item.precio)}</td>
                    <td className="text-right">{formatNumero(item.monto)}</td>
                    <td>{item.estado}</td>
                    <td className={item.esEjercicio == "Si" ? "alert-warning" : ""}>
                      {item.esEjercicio == "Si" ? "Si" : "No"}
                    </td>
                    <td>{item.esVC}</td>
                    <td className={item.esAplicacion ? "alert-danger" : ""}>{item.esAplicacion ? "Si" : "No"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OperacionesTrader;
