import React, { useState, useEffect } from "react";
import shortid from "shortid";
import dayjs from "dayjs";
const REACT_APP_CAJVAL_DEPOSITANTE = process.env.REACT_APP_CAJVAL_DEPOSITANTE;
const REACT_APP_TRANF_OMITE_CONTADO = process.env.REACT_APP_TRANF_OMITE_CONTADO === "true";

const TranfTSA = ({ datos = [], fecha, transfWidgets = [], delTransfWidgets, btnCdo = true }) => {
  const [stateDatos, setDatos] = useState([]);
  const [stateTransfWidgets, seTstateTransfWidgets] = useState([]);

  useEffect(() => {
    if (!REACT_APP_TRANF_OMITE_CONTADO) {
      setDatos(datos);
    }
  }, [datos]);

  useEffect(() => {
    seTstateTransfWidgets([...transfWidgets]);
  }, [transfWidgets]);

  //console.log("render  de TranfTSA--->")
  //console.log({stateDatos})
  function handleChange(e, index, col) {
    //console.log(e.target.value)
    let newArr = [...stateDatos];
    newArr[index][col] = e.target.value;
    setDatos(newArr);
  }
  function handleClickDelete(index) {
    let newArr = [...stateDatos];
    newArr.splice(index, 1);

    setDatos(newArr);
  }

  function handleClickDeleteWidgets(index) {
    delTransfWidgets(index);
  }

  function addItem() {
    let item = { tipo: "", subcuenta: "", contraparte: "", cantidad: "", nroEspecie: "", match: "N" };
    let newArr = [...stateDatos, item];
    setDatos(newArr);
  }

  function genTSA(tobj) {
    //const fecha_sm = fecha.split("/")[2].substr(-2, 2) + fecha.split("/")[1] + fecha.split("/")[0];
    const fecha_sm = dayjs().format('YYMMDD');
   //const fecha_lg = fecha.split("/")[2] + fecha.split("/")[1] + fecha.split("/")[0];
    const fecha_lg = dayjs().format('YYYYMMDD');

    let cant_reg = (tobj.length + 1).toString();
    tobj.sort((a, b) => a.tipo.localeCompare(b.tipo));

    //let hora = new Date().toLocaleTimeString();
    //hora = hora.split(":")[0] + hora.split(":")[1] + hora.split(":")[2];
    const hora =  dayjs().format('HHmmss');

    cant_reg = "0".repeat(9 - cant_reg.length) + cant_reg;

    const file_head = `00Aftfaot    ${fecha_lg}${hora}${cant_reg}\r\n`;
    const file_end = `99Aftfaot    ${fecha_lg}${hora}${cant_reg}\r\n`;

    const reg_head = `0${fecha_sm}FTFAOT${"0".repeat(4 - REACT_APP_CAJVAL_DEPOSITANTE.length)}${REACT_APP_CAJVAL_DEPOSITANTE}\r\n`;
    let body = "";

    for (var i = 0; i < tobj.length; i++) {
      if (tobj[i] != undefined) {
        let ejec = tobj[i].tipo.split("/")[0].split("(")[1];
        let lado = tobj[i].tipo.split("/")[1].split(")")[0];
        let orig_dep = tobj[i].subcuenta.split("/")[0].toString();
        orig_dep = "0".repeat(4 - orig_dep.length) + orig_dep;

        let orig_sub = tobj[i].subcuenta.split("/")[1].toString();
        orig_sub = "0".repeat(9 - orig_sub.length) + orig_sub;

        let esp = tobj[i].nroEspecie;
        esp = "0".repeat(5 - esp.length) + esp;
        let cant = (tobj[i].cantidad * 1).toFixed(2) + "00000";
        cant = "0".repeat(19 - cant.length) + cant;

        let cont_dep = tobj[i].contraparte.split("/")[0].toString();
        cont_dep = "0".repeat(4 - cont_dep.length) + cont_dep;

        let cont_sub = tobj[i].contraparte.split("/")[1].toString();
        cont_sub = "0".repeat(9 - cont_sub.length) + cont_sub;

        let match = tobj[i].match;

        let prioridades = { I: "01", D: "00" };
        let prioridad = prioridades[ejec];
        if (ejec === "I" && lado === "R") {
          prioridad = "00";
        }

        let linea = `1'${ejec}'${lado}'${orig_dep}'${orig_sub}'${esp}       '${cant}'${cont_dep}'${cont_sub}'N'${prioridad}'0000'0000'${match}\r\n`;
        body += linea;
      }
    }

    let filetxt = file_head + reg_head + body + file_end;
    //console.log(filetxt)
    downloadTXT(filetxt);
    return filetxt;
  }
  function convertirNumero(numero) {
    const formatoNumero = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 3
    });
  
    // Formatea el número con un decimal y agrega ".0" al final
    return formatoNumero.format(numero).replace(/,/g, "")
  }

  function createID(index = "") {
    const indexStr = index.toString();
    const seg = (Date.now() / 1000).toFixed(0);
    return `EA${seg}${"0".repeat(14-seg.length-indexStr.length)}${indexStr}`
  }

  function genNASDAQ(tobj = []) {
    console.log(tobj);
    const date = new Date().toISOString().split("T")[0].replace(/-/g, "");

    const headers = [
      "InstructingParty",
      "SettlementParty",
      "SecuritiesAccount",
      "Instrument",
      "InstrumentIdentifierType",
      "CSDOfCounterparty",
      "SettlementCounterparty",
      "SecuritiesAccountOfCounterparty",
      "InstructionReference",
      "Instrument(MovementOfSecurities)",
      "Quantity",
      "QuantityType",
      "TransactionType",
      "SettlementMethod",
      "TradeDate",
      "IntendedSettlementDate",
      "PaymentType",
    ];

    const base = {
      InstructingParty: REACT_APP_CAJVAL_DEPOSITANTE,
      SettlementParty: REACT_APP_CAJVAL_DEPOSITANTE,
      SecuritiesAccount: "",
      Instrument: "",
      InstrumentIdentifierType: "LOCAL_CODE",
      CSDOfCounterparty: "CVSA",
      SettlementCounterparty: REACT_APP_CAJVAL_DEPOSITANTE,
      SecuritiesAccountOfCounterparty: "",
      InstructionReference: "Dist29",
      "Instrument(MovementOfSecurities)": "",
      Quantity: "",
      QuantityType: "",
      TransactionType: "TRAD",
      SettlementMethod: "RTGS",
      TradeDate: date,
      IntendedSettlementDate: date,
      PaymentType: "NOTHING",
    };

    const transfers = tobj.map((item, index) => {
      const { cantidad, tipo, contraparte, nroEspecie, subcuenta: origen } = item;
     // const InstructingParty = origen.split("/")[0];
     // const SettlementParty = InstructingParty;
      const SecuritiesAccount = origen;
      const Instrument = nroEspecie;
     // const SettlementCounterparty = contraparte.split("/")[0];
      const SecuritiesAccountOfCounterparty = contraparte;
      const InstructionReference = createID(index);
      const movement = tipo.split("/")[1] == "E)" ? "DELIVER" : "RECEIVE";
      return {
        ...base,
        SecuritiesAccount,
        Instrument,
        SecuritiesAccountOfCounterparty,
        InstructionReference,
        "Instrument(MovementOfSecurities)": movement,
        Quantity: cantidad.toString(),
      };
    });

    let csv = headers.join(";");
    transfers.forEach((transf) => {
      let linea = [];
      headers.forEach((head) => {
        linea.push(transf[head] || "");
      });
      linea =  "\n" + linea.join(";") ;
      csv += linea;
    });
    csv+= "\n";
    console.log(csv);
    downloadTXT(csv, "NASDAQ", ".si2");
  }
  function downloadTXT(txt, sufijo = "TSA", tipo = ".txt") {
    let data, filename, link;
    let csv = txt;

    if (csv == null) return;
    const fecha_lg = dayjs().format('YYYYMMDD');
    filename = sufijo + fecha_lg + tipo;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/txt;charset=utf-8," + csv;
    }
    data = encodeURI(csv);

    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <div className="card">
      <div className="card-header grey">
        <b>Generar Transferencias TSA</b>

        <button
          onClick={() => {
            addItem();
          }}
          className="btn btn-sm btn-primary float-right mr-1"
        >
          +
        </button>
        {REACT_APP_TRANF_OMITE_CONTADO ? (
          btnCdo &&
          (stateDatos.length == 0 ? (
            <button
              onClick={() => {
                setDatos(datos);
              }}
              className="btn btn-sm btn-warning float-right mr-1"
            >
              Cargar Cdo.
            </button>
          ) : (
            <button
              onClick={() => {
                setDatos([]);
              }}
              className="btn btn-sm btn-danger float-right mr-1"
            >
              Limpiar Cdo.
            </button>
          ))
        ) : (
          <button
            onClick={() => {
              setDatos([]);
            }}
            className="btn btn-sm btn-danger float-right mr-1"
          >
            Limpiar Cdo.
          </button>
        )}
        <button
          onClick={() => {
            genTSA([...stateDatos, ...stateTransfWidgets]);
          }}
          className="btn btn-sm btn-success float-right mr-1"
        >
          TSA
        </button>
        <button
          onClick={() => {
            genNASDAQ([...stateDatos, ...stateTransfWidgets]);
          }}
          className="btn btn-sm btn-success float-right mr-1"
        >
          NASDAQ
        </button>
      </div>
      <div className="card-body">
        <table className="table table-sm table-bordered table-hover">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Origen</th>
              <th>Especie</th>
              <th>Cantidad</th>
              <th>Contraparte</th>
              <th>Match</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {stateDatos.map((item, index) => (
              <tr key={`${item.tipo}-${item.subcuenta}-${item.nroEspecie}-${item.contraparte}`}>
                <td>
                  {" "}
                  <input
                    // //style={{ width: "40px" }}
                    value={item.tipo}
                    onChange={(e) => {
                      handleChange(e, index, "tipo");
                    }}
                    type="text"
                    className="form-control-sm form-control"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <input
                    //style={{ width: "100px" }}
                    value={item.subcuenta}
                    onChange={(e) => {
                      handleChange(e, index, "subcuenta");
                    }}
                    type="text"
                    className="form-control-sm form-control"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <input
                    //style={{ width: "70px" }}
                    value={item.nroEspecie}
                    onChange={(e) => {
                      handleChange(e, index, "nroEspecie");
                    }}
                    type="text"
                    className="form-control-sm form-control"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <input
                    //style={{ maxWidth: "100px" }}
                    value={item.cantidad}
                    onChange={(e) => {
                      handleChange(e, index, "cantidad");
                    }}
                    type="text"
                    className="form-control-sm form-control"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <input
                    //style={{ maxWidth: "80px" }}
                    value={item.contraparte}
                    onChange={(e) => {
                      handleChange(e, index, "contraparte");
                    }}
                    type="text"
                    className="form-control-sm form-control"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <input
                    //style={{ width: "30px" }}
                    value={item.match}
                    onChange={(e) => {
                      handleChange(e, index, "match");
                    }}
                    type="text"
                    className="form-control-sm form-control"
                  />{" "}
                </td>
                <td>
                  <button
                    onClick={() => {
                      handleClickDelete(index);
                    }}
                    className="btn btn-sm btn-danger"
                  >
                    -
                  </button>
                </td>
              </tr>
            ))}
            {stateTransfWidgets.map((item, index) => (
              <tr key={item.id} className={item.classString}>
                <td>{item.tipo}</td>
                <td>{item.subcuenta}</td>
                <td>{item.nroEspecie}</td>
                <td>{item.cantidad}</td>
                <td>{item.contraparte}</td>
                <td>{item.match}</td>
                <td>
                  <button
                    onClick={() => {
                      handleClickDeleteWidgets(index);
                    }}
                    className="btn btn-sm btn-danger"
                  >
                    -
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TranfTSA;
