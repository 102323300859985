import React, { useState, useEffect } from "react";

import Plazotable from "../components/Plazotable";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
import PanelHeaderTrader from "../components/PanelHeaderTrader";


const PanelSBAN = () => {
  useDocumentTitle("Panel SENEBI");
  const [infoCI, setInfoCI] = useState([]);
  const [info24hs, setInfo24hs] = useState([]);

  const [moneda, setMoneda] = useState("P");

  const [showCI, setShowCI] = useState(true);
  const [show24hs, setShow24hs] = useState(true);

  const [isLoadingContado, setisLoadingContado] = useState(false);
  
  const [totalOpers, setTotalOpers] = useState(0);

  const [hayVC, setHayVC] = useState(false);
  //const [info_procesada, setInfoprocesada] = useState({})

  let info_procesada;

  const { getResumenOperdiaContadoSBAN } = useServices();


  useEffect(() => {
    initPanel();
  }, []);

async function reloadPanel () {
  //setCuentaOper(0);

  await carga_oper_gara();


}

  async function carga_oper_gara() {
    setisLoadingContado(true)
    let operdia = await getResumenOperdiaContadoSBAN ();
    setisLoadingContado(false);
    if (operdia.error) return;

    info_procesada = operdia;

    setTotalOpers(prev => info_procesada.cantOper);
    //console.log({ info_procesada });
   // setHayEjercicios(info_procesada.hayEjercicios);
    setHayVC(info_procesada.hayVC);

    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCI(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hs(info_procesada.tabla["24hs"]);
      }

    }

    return true;
  }

 


  async function initPanel() {
    await carga_oper_gara();


  }





  const showhidePanel = (idpanel) => {
    switch (idpanel) {
      case "CI":
        setShowCI(!showCI);
        break;
      case "24hs":
        setShow24hs(!show24hs);
        break;

    }
  };

  return (
    <div className="container-fluid">
      <PanelHeaderTrader
        showhidePanel={showhidePanel}
        setMoneda={setMoneda}
        moneda={moneda}
        cuentaOper={totalOpers}
        cargaContado={reloadPanel}
        showPanelesNoContado = {false}
        isLoadingContado={isLoadingContado}

        hayEjercicios={false}
        hayVC={hayVC}
        panelShow={{ showCI, show24hs }}
      />

      <div className="row no-gutters">
        {showCI && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="CI - SENEBI" datos={infoCI} ALYC_INTEGRAL={false} />
          </div>
        )}
        {show24hs && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="24hs - SENEBI" datos={info24hs} ALYC_INTEGRAL={false} />
          </div>
        )}

      </div>
      


    </div>
  );
};

export default PanelSBAN;
