import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons";

import {getFeriados, addFeriado, delFeriado} from "../services/listaFeriados"

const TablaFeriados = () => {
    const [datos, setDatos] = useState([]);
    const [fecha, setFecha] = useState('')
    const [descripcion, setDescripcion] = useState('') 

    async function cargaDatos() {
        const listaFeriados = await getFeriados();
        //console.log({ listaFeriados });
        setDatos(listaFeriados);
      }

      useEffect(() => {
        cargaDatos();
      }, []);

      function selectElementContents(el) {
        var body = document.body, range, sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents(el);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(el);
                sel.addRange(range);
            }
            document.execCommand("copy");
    
        } else if (body.createTextRange) {
            range = body.createTextRange();
            range.moveToElementText(el);
            range.select();
            range.execCommand("Copy");
        }
    }
     async function handleDelFeriado (id) {
     
      //console.log(id)
      const respuesta = await delFeriado(id);
    
      if(!respuesta.error) { setDatos(respuesta) }
    
     }
    
     async function handleAddFerido () {
     
      const respuesta = await addFeriado({fecha, descripcion});
    
      if(!respuesta.error) { setDatos(respuesta) }
    
     }

    return (
        <div className="card">
        <div className="card-header ">
          <span className="ml-1">
            <b>Feriados</b>
          </span>{" "}
          <span className="badge badge-secondary"></span>
          <button
            type="button"
            name=""
            data-toggle="tooltip"
            data-placement="bottom"
            title="Copiar"
            onClick={() => {selectElementContents(document.getElementById('tb_feriados') )}}
            className="btn btn-secondary float-right ml-1"
          >
              <FontAwesomeIcon icon={faCopy} />
          </button>

        </div>
  
        <div style={{ maxHeight: "95vh", overflowY: "auto" }} className="card-body">
          <table id={"tb_feriados"} className="table table-sm table-bordered table-hover ">
            <thead>
              <tr>
                <th scope="col">Feriado</th>
                <th scope="col">Descripcion</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  <input type="date" onChange={(e) => setFecha(e.target.value) } className="form-control"  />
                </td>
                <td>
                  {" "}
                  <input onChange={(e) => setDescripcion(e.target.value) } className="form-control" type="text" />
                </td>

                <td>
                  {" "}
                  <button onClick={() => handleAddFerido() } className="btn btn-success btn-sm">
                    <b>+</b>
                  </button>
                </td>
              </tr>
  
              {datos.map((linea) => (
                <tr  key={linea.id}>
                  <td> {new Date(linea.fecha).toLocaleDateString(undefined, {timeZone: 'UTC'})} </td>
                  <td> {linea.descripcion} </td>
      
                  <td>
  
                    <button onClick={() => {handleDelFeriado(linea.id)}} className="btn btn-danger btn-sm">
                      
                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    )
}

export default TablaFeriados
