
const baseURL = process.env.REACT_APP_API_URL;

const postRG624 = async (operaciones) => {
  let blob = '';
  let body = JSON.stringify(operaciones);

  try {
    const res = await fetch(`${baseURL}rg624/Excel`, {
      method: "POST",
      headers: {
   
        "Content-Type": "application/json",
      },

      body: body,
    });

    const filename = res.headers.get('content-disposition');
    blob = await res.blob();
    var url = window.URL.createObjectURL(blob);
    //console.log(url)
    var a = document.createElement('a');
    a.download =  filename.split('filename="')[1].split('"')[0];
    a.href = url;
    document.body.appendChild(a); 
    a.click();    
    a.remove();  
  } catch (e) {
    return { error: e };
  }

  return blob;
};

export default postRG624;
