import React from 'react'
import TablaFondos from '../components/TablaFondos';



import useDocumentTitle from "../hooks/useDocumentTitle";

const Fondos = () => {
    useDocumentTitle('Gestión de Fondos')
    return (
        <div className="container-fluid">
        <div className="row">
            <div className="col">       
            <TablaFondos />
             </div>

        </div>
        </div>
    )
}

export default Fondos
