import React, { useEffect, useState } from "react";
import FileUploadButton from "../components/FileUploadButton";
import useServices from "../hooks/useServices";
import ConciliaCGVBTable from "../components/ConciliaCGVBTable";
import useDocumentTitle from "../hooks/useDocumentTitle";
import ConciliaCaucionesVBTable from "../components/ConciliaCaucionesVBTable";
const baseURL = process.env.REACT_APP_API_URL;
const SYS_BO = process.env.REACT_APP_SYS_BO;


const ConciliaOpersVB = () => {
  useDocumentTitle("Concilia Operaciones VB")
  const [tableDataCG, setTableDataCG] = useState([]);
  const [tableDataCauciones, setTableDataCauciones] = useState([]);
  const [cargoAchivoMinutasCaucion, setCargoAchivoMinutasCaucion] = useState(true)
  const [cargoAchivoMinutasCG, setCargoAchivoMinutasCG] = useState(true)
  const [soloCI, setSoloCI] = useState(false)

  const [isLoadingCG, setIsLoadingCG] = useState(false)
  const [isLoadingCauciones, setIsLoadingCauciones] = useState(false)

  const { getConciliaOpersCG, getConciliaOpersCaucion } = useServices();
  
  async function cargaDatosCG() {
    //alert("archivo cargado");
    setIsLoadingCG(true)
    const res = await getConciliaOpersCG(soloCI);
    if (res.error) return;
    if(res.noCargoArchivo) {setCargoAchivoMinutasCG(false); setIsLoadingCG(false); return;}
    setCargoAchivoMinutasCG(true);
    setTableDataCG(res.data);
    setIsLoadingCG(false);
  }

  async function cargaDatosCauciones() {
    //alert("archivo cargado");
     setIsLoadingCauciones(true)
     const res = await getConciliaOpersCaucion(soloCI);
     if (res.error) return;
     if(res.noCargoArchivo) {setCargoAchivoMinutasCaucion(false);      setIsLoadingCauciones(false); return;}
     setTableDataCauciones(res.data);
     setCargoAchivoMinutasCaucion(true);
     setIsLoadingCauciones(false);
  }


  useEffect(async () => {
  await cargaDatosCG();
  await cargaDatosCauciones();
  
  }, [soloCI]);



  return (
    <div className="container-fluid">
      <div className="card-header">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col">
              <FileUploadButton
              endPoint={`${baseURL}VB/uploadMinutasCG`}
              fileName={"contado.xlsx"}
              label={"Cargar Minutas VB Contado Gdo."}
              callback={cargaDatosCG}
            />
              </div>
              { !cargoAchivoMinutasCG && 
              <div className="col-3">
              <div class="btn  btn-outline-danger " >
          Archivo no cargado
          </div>
              </div> }
            </div> 
  
        
          </div>
       
          <div className="col-6">

          <div className="row">
            <div className="col">
            { SYS_BO == "VISUAL_BOLSA" &&  <FileUploadButton
              endPoint={`${baseURL}VB/uploadMinutasCaucion`}
              fileName={"cauciones.xlsx"}
              label={"Cargar Minutas VB Cauciones"}
              callback={cargaDatosCauciones}
            />}
            </div>
            { !cargoAchivoMinutasCaucion && 
            <div className="col-3">
            <div class="btn  btn-outline-danger " >
          Archivo no cargado
          </div>
            </div> }
          </div>
      
          
     
          </div>
   
         


        
       
        </div>
      </div>

      <div className="row">
        <div className="col-12">
        <ConciliaCGVBTable  soloCI={soloCI} setSoloCI={setSoloCI} isLoading={isLoadingCG} tableData={tableDataCG} reloadDatos={cargaDatosCG} setTableData={setTableDataCG} />
        </div>
      </div> 
      <div className="row">
        <div className="col-12">
        <ConciliaCaucionesVBTable  isLoading={isLoadingCauciones} tableData={tableDataCauciones} reloadDatos={cargaDatosCauciones} />
        </div>
      </div> 
    </div>
  );
};

export default ConciliaOpersVB;
