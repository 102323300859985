import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import "../css/customTable.css";
import FileUploadButton from "./FileUploadButton";
import useServices from "../hooks/useServices";
import { Link } from "react-router-dom";
const baseURL = process.env.REACT_APP_API_URL;
const REACT_APP_SYS_BO = process.env.REACT_APP_SYS_BO;

const TomadorasComitente = ({tomadoras = [], comitente = "", setDistribuible = ()  => {}, setAllDistribuible = () => {}, distribuirByComitente = () =>  {}, aforosNoEncontradosPorComitente = {}, isSending = false}) => {

  
  const formatNumero = (numero) => {
    if (isNaN(numero)) return "";
    if (numero == undefined) return numero;
    if (numero == " ") return numero;
    return numero.toLocaleString("es");
  };

  const aforosNoEncontrados = aforosNoEncontradosPorComitente[comitente] || [];

return (
  <div className="row no-gutters">
    <div className="col">
      <div className="card">
        <div className="card-header">
          <div className="row no-gutters">
            <div className="col">
         
                <span className="badge badge-secondary">{comitente}</span>
              {aforosNoEncontrados.length > 0 &&  <span className=" btn btn-sm alert-danger ml-1">Especies sin aforo: {aforosNoEncontrados.join(", ")}</span>}
          
            </div>
            <div className="col">
              <div className="row ">
       
            <div className="col">
              <div className="row float-right">
              <span className=" mr-1 input-group-text ">
                  Seleccionar todas{" "}
                  <input
                    type="checkbox"
                    className="ml-1 "
                    onClick={(e) => {

                      setAllDistribuible(e.target, comitente);
                    }}
                  />{" "}
                </span>
                <button disabled={isSending} onClick={() => distribuirByComitente(comitente) } className="btn btn-sm ml-1 mr-2 btn-secondary">Distribuir seleccionadas</button>
                </div>
            </div>
              

              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          {tomadoras.map((tomadora) => (
            <div className="row no-gutters">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered  ">
                    <thead>
                      <tr>
                        <th scope="col">Concertación</th>
                        <th scope="col">Liquidación</th> <th scope="col">Monto Tomadora</th>
                        <th scope="col">Monto Futuro</th>
                        <th scope="col">Tasa</th>
                        <th scope="col">Moneda</th>
                        <th scope="col">Titulos a Distribuir</th>
                        <th scope="col">Resta distribuir</th>
                        <th scope="col">Distribuir</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={tomadora.id}>
                        <td>{tomadora.fechaConcertacion}</td>
                        <td>{tomadora.fechaLiquidacion}</td>
                        <td>{formatNumero(tomadora.montoTomadora)}</td>
                        <td>{formatNumero(tomadora.montoFuturo)}</td>
                        <td>{formatNumero(tomadora.tasa)}</td>
                        <td>{tomadora.moneda}</td>
                        <td>
                          <div className="row">
                            <div className="col-12">
                              <table className="table  table-sm  table-bordered  table-hover">
                                <thead>
                                  <th scope="col">codCajVal</th>
                                  <th scope="col">especie</th>
                                  <th scope="col">cantidad</th>
                                  <th scope="col">origen</th>
                                  <th scope="col">precio</th>
                                  <th scope="col">montoAforadoPesos</th>
                                  <th scope="col">aforoTabla</th>
                                  <th scope="col">aforoUsado</th>
                                  <th scope="col">LamMin</th>
                                </thead>
                                <tbody>
                                  {tomadora.titulosaDistribuir.map((titulo) => (
                                    <tr key={titulo.codCajVal+titulo.origen}>
                                      <td>{titulo.codCajVal}</td>
                                      <td>{titulo?.especie}</td>
                                      <td>{formatNumero(titulo.cantidad)}</td>
                                      <td>{titulo.origen}</td>
                                      <td>{formatNumero(titulo.precio)}</td>
                                      <td>{formatNumero(titulo.montoAforado)} {tomadora.moneda == "Dólar" ? ` (TC: ${titulo?.tipoDeCambioBYMA})` : "" } </td>
                                      <td>{titulo.aforo}</td>
                                      <td>{titulo.aforoMod != undefined ? titulo.aforoMod : titulo.aforo }</td>
                                      <td>{titulo.lamMin}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                        <td>{formatNumero(tomadora.montoRestaDistribuir)}</td>
                        <td>
                    {  !tomadora.success ? <>  <input
                            disabled={tomadora.montoRestaDistribuir != 0}
                            checked={tomadora.distribuir}
                            onClick={() => setDistribuible(tomadora.id)}
                            type="checkbox"
                          ></input>{" "}
                          {tomadora.distribuir ? "SI" : "NO"} </>  : <span className="badge alert-success">Distribuida</span> } 
                          {tomadora.error && <span className=" alert-danger">{tomadora.errorMsg}</span>}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

}




const DistribucionesCaucionTable = ({ titulo, initBodyVisb = false, realoadBtn = true }) => {
  const [filtro, setFiltro] = useState("");
 
  const [isLoading, setisLoading] = useState(false);
  //let tabla = [];
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
  const tablaRef = useRef(null);
  const { getDistribucionesCaucionesBYMA, distribuirCaucionComitente } = useServices();

  const [tablas, setTablas] = useState({});
  const [aforosNoEncontradosPorComitente, setAforosNoEncontradosPorComitente] = useState({});
  const [tomadoraCierre, setTomadoraCiere] = useState(true);  
  const [garantiaGara, setGarantiaGara] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const [leyAforos, setLeyAforos] = useState("");
  useEffect(() => {
   
    cargaDatos()

  }, [tomadoraCierre, garantiaGara]);

  function handleUploadResult() {}

  //console.log("datos", datos);

  //setTabla(datos)
  //tabla = datos;
  async function cargaDatos() {
    setisLoading(true)
    const { saldosTomadoras, aforosNoEncontrados, aforosNoEncontradosPorComitente } = await getDistribucionesCaucionesBYMA(tomadoraCierre, garantiaGara);
    if (saldosTomadoras) {
      
      let tablas = {};
      saldosTomadoras.forEach(element => {
        if(tablas[element.comitente] == undefined ) { tablas[element.comitente] = [] }
        tablas[element.comitente].push(element);
      });
      
      setTablas(tablas)
    }
    setisLoading(false)
    setAforosNoEncontradosPorComitente(aforosNoEncontradosPorComitente)
    if(aforosNoEncontrados.length >0) {setLeyAforos("No se encontraron aforos para las especies:\n"+aforosNoEncontrados.join("\n"))}
  }


  const showhideBody = () => {
    setBodyVisible(!bodyVisible);
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none";
  const leyBtn = bodyVisible ? "-" : "+";

  function exportTableToExcel(tableSelect, filename = "") {
    const dataType = "application/vnd.ms-excel;charset=utf-8";
    //var tableSelect = document.getElementById(tableID);
    let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");
    tableHTML = tableHTML.replace(/<input.*?type="checkbox">/gm, "");

    // Specify file name
    //const fechaStr = new Date().toISOString().split("T")[0];
    const fechaStr = new Date().toISOString().split("T")[0];
    //fechaStr = fechaStr.replace(/\//g, '-');
    filename = filename ? filename + ".xls" : `distribuciones_${fechaStr}.xls`;

    // Create download link element
    const downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = "data:" + dataType + ", " + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }
  function handleDownloadExcel() {
    exportTableToExcel(tablaRef.current);
  }

  function handleSetDistribuible (id) {
   console.log(id)
   let newTablas = {...tablas};
   for (const comitente in newTablas) {
   let found = newTablas[comitente].find( item => item.id == id)
   if(found) {found.distribuir = !found.distribuir}
   }
   setTablas(newTablas)

  }
  function handleSetDistribuibleByComitente (element, comitente) {
     console.log(comitente)
     console.log(element.checked)
    let newTablas = {...tablas};

    let found = newTablas[comitente].forEach(item => {if (item.montoRestaDistribuir == 0) {item.distribuir = element.checked} })

    setTablas(newTablas)
   }

  async function distribuirByComitente (comitente) {
       setIsSending(true);
       const caucionesADistribuir = [...tablas[comitente].filter(item => item.distribuir && !item.success)]
       for(const i in caucionesADistribuir) {
        const rdoDistribuir = await distribuirCaucionComitente(caucionesADistribuir[i])
        setIsSending(false);
        if(rdoDistribuir.error) return
        if(rdoDistribuir.hayError) { handleSetErrorById(rdoDistribuir.comitente,rdoDistribuir.id, rdoDistribuir.errorMsg )} else {
          handleSetSuccessById(rdoDistribuir.comitente,rdoDistribuir.id)
        }
        console.log(rdoDistribuir)
       }
       console.log({caucionesADistribuir})
  }

  function handleSetErrorById (comitente, id, errorMsg) {

   let newTablas = {...tablas};

   let found = newTablas[comitente].find(item => item.id == id);
   found.error = true;
   found.errorMsg = errorMsg;
   setTablas(newTablas)
  }

  function handleSetSuccessById (comitente, id) {

    let newTablas = {...tablas};
 
    let found = newTablas[comitente].find(item => item.id == id);
    found.error = false;
    found.success = true;
    setTablas(newTablas)
   }

  const handleTomadoraCierre = () => {
    setTomadoraCiere(prev => !prev)
  }
  const handleGarantiaGara = () => {
    setGarantiaGara(prev => !prev)
  }

  return (
    <>
      <div className="card">
        <div className="card-header ">
          <div className="row">
            <div className="col">
              <span>
                <b>Distribución de Cauciones - BYMA</b>
              </span>

           { leyAforos != "" && <button className="btn btn-sm ml-3 btn-warning" renderAs="button" onClick={() => {alert(leyAforos)}}>
                  <span>Aforos no encontrados</span>
                </button>}
            </div>
           
        
       
            <div className="col align-items-center">

    { REACT_APP_SYS_BO  == 'AUNE' &&       
              <FileUploadButton
                endPoint={`${baseURL}upload/uploadTenVal`}
                fileName={"tenvalores.xlsx"}
                label={"Cargar Tenencia AUNE T-1:"}
                callback={cargaDatos}
              />}
   { REACT_APP_SYS_BO  == 'VISUAL_BOLSA' && 
              <FileUploadButton
                endPoint={`${baseURL}upload/uploadPosicionTitulosVB`}
                fileName={"tenvalvb-1.xlsx"}
                label={"Cargar Posición (Visual Bolsa) T-1"}
                callback={cargaDatos}
              /> 
              }
            </div>

            <div className="col-5">
            <input
                placeholder="Cuenta"
                onChange={(e) => {
                  setFiltro(e.target.value);
                }}
                className="form-control-sm form-control  float-right ml-1"
                type="text"
                style={{ maxWidth: "80px" }}
              />
            <span className="float-right ml-2 input-group-text form-control-sm">Garantia GARA <input onChange={handleGarantiaGara} checked={garantiaGara} className="ml-1" type="checkbox"  /> </span>
            <span className="float-right ml-2 input-group-text form-control-sm">Tomadora Cierre <input onChange={handleTomadoraCierre} checked={tomadoraCierre} className="ml-1" type="checkbox"  /> </span>
     
          
              <Link target={"_blank"} open to="/aforosBYMA">
                <button className="btn btn-sm ml-1 btn-secondary float-right" renderAs="button">
                  <span>Tabla Aforos</span>
                </button>
              </Link>
        
         
            </div>
          </div>
        </div>
        <div className={cardBodyClass}>
          {isLoading && (
            <div className="row">
              {" "}
              <div className="col-12 text-center">
                <h3>Cargando...</h3>{" "}
              </div>
            </div>
          )}

          {Object.entries(tablas).filter(([comitente, infoComitente]) => comitente.includes(filtro) ).map(([comitente, infoComitente]) => (
            <TomadorasComitente
              comitente={comitente}
              tomadoras={infoComitente}
              setDistribuible={handleSetDistribuible}
              setAllDistribuible={handleSetDistribuibleByComitente}
              distribuirByComitente={distribuirByComitente}
              aforosNoEncontradosPorComitente={aforosNoEncontradosPorComitente}
              isSending={isSending}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default DistribucionesCaucionTable;
