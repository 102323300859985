import React, { useState, useEffect } from "react";
import DayTradingTable from "../components/DayTradingTable";

import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";




const Daytrading = () => {
  useDocumentTitle("DayTrading");
  const moneda = "P";
  const [info48hs, setInfo48hs] = useState({});
  const [infoCI, setInfoCI] = useState({});
  const [infoDT, setInfoDT] = useState([]);
  const [fechaLiq, setFechaLiq] = useState("");
  const [isLoading, setisLoading] = useState(false)
  const [isLoadingTitulos, setisLoadingTitulos] = useState(false)
  const { getOpersdiaContadoDayTrading, postDayTrading, renewBaseTitulos } = useServices();

  useEffect(() => {
    cargaOperdia();

  }, []);

  async function handleReloadBaseTitulos () {
    setisLoadingTitulos(true)
    const res = await renewBaseTitulos();
    if(res.error) return
    setisLoadingTitulos(false)
    if(res.status == "OK") {

    }
  }

  async function cargaOperdia() {
    setisLoading(true)
    let operContado = await getOpersdiaContadoDayTrading();
    setisLoading(false)
    if (operContado.error) return;
    setInfoDT(operContado)



  }
  const descargaArchivo = async () => {
    

  };

  
  



  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
        <div className="row">
            <div className="col">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                  <button disabled={isLoadingTitulos} className="btn btn-sm btn-secondary float-right " onClick={() => handleReloadBaseTitulos()}>
                  Actualizar Base Titulos
                </button>
                  </div>
                </div>
      
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
          <DayTradingTable moneda={moneda} titulo={`DayTrading`} datos={infoDT} initBodyVisb={true} realoadBtn={cargaOperdia} isLoading={isLoading} />
          </div>
          </div>

        </div>
{/*         <div className="col">

          <div className="row">
            <div className="col">
              <MailTemplate />
            </div>
          </div>
    
        </div> */}
      </div>
    </div>
  );
};

export default Daytrading;
