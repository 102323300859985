import React, { useEffect, useState } from "react";

const ControlNominalesBOP = ({ tenenciaBOP = [], garantiaDispBOP = [], nominalesGARA = [], cargaDatos }) => {
  const [tablaState, setTablaState] = useState([]);
  const [fechasArr, setFechas] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [showConliq, setShowConliq] = useState(false);
  const tdBorderedCSS =  {
    borderTopWidth: '3px',
    borderBottomWidth: '3px',
    borderLeftWidth: '3px',
    borderRightWidth: '3px',
    borderTopColor: 'black',
    borderBottomColor: 'black',
    borderLeftColor: 'black',
    borderRightColor: 'black'
  }
  const formatNumero = (numero) => {
    if (!numero) return "";
    return numero.toLocaleString();
  };
  //let tabla = tenenciaBOP.filter((item) => item.estado == "Disponible" && item.nroCajaValores != "");
  //console.log({ tabla });

  const findByCtaEsp = (nrocuenta, nro_especie, lista) => {
    var found = lista.find(
      (linea) => linea.nroComitente * 1 == nrocuenta * 1 && linea.nroCajaValores * 1 == nro_especie * 1
    );
    return found;
  };

  const extrae_nro_especie = (texto) => {
    if (texto.indexOf("(") != -1 && texto.indexOf(")") != -1) {
      var nro_especie = texto.split("(")[1].split(")")[0];
      return nro_especie;
    }
  };

  function procesaGtiaDisp(gtiadisp, tabla) {
    gtiadisp.pop();
    for (var i = 0; i < gtiadisp.length; i++) {
      if (i > 1) {
        var nrocuenta = gtiadisp[i][0];
        var instrumento = gtiadisp[i][1];
        var cantdisp = gtiadisp[i][3];
        var nro_especie = extrae_nro_especie(instrumento);

        if (nro_especie !== undefined) {
          var founded = findByCtaEsp(nrocuenta, nro_especie, tabla);

          if (founded !== undefined) {
           // console.log(founded);
            founded.dispGARA = cantdisp * 1;
          }
        }
      }
    }

    return tabla;
  }

  function procesaNominales(posinominales, tabla, fecha, col) {
    for (var i = 0; i < posinominales.length; i++) {
      if (i > 1) {
        var fechapos = posinominales[i][2];
        if (fechapos == fecha) {
          //console.log(fechapos);
          var instrumento = posinominales[i][1];
          var nro_especie = extrae_nro_especie(instrumento);
          var nrocuenta = posinominales[i][4];

          var dic = { Vendedor: -1, Cerrada: 1, Comprador: 1 };

          var posinom = posinominales[i][8] * dic[posinominales[i][7]];
      

          if (nro_especie !== undefined) {
            var founded = findByCtaEsp(nrocuenta, nro_especie, tabla);

            if (founded !== undefined) {
              if (founded[col] == undefined) {
                founded[col] = 0;
              }
              founded[col] += posinom;
            } else {
              var linea = {
                descripcionTitVal: instrumento,
                estado: "Por liquid",
                desComitente: nrocuenta,
                nroComitente: nrocuenta,
                cantidad: 0,
                dispGARA: 0,
                posinom: 0,
                posinomt1: 0,
                posinomt2: 0,
                nroCajaValores: nro_especie,
              };
              linea[col] = posinom;
              if (linea[col] != 0) {
                tabla.push(linea);
              }
            }
          }
        }
      }
    }
    return tabla;
  }

  function extraeFechasPosinom(posinominales) {
    const formatoFecha = (dato) => {
      if (dato.getDate().toString().length == 1) {
        var dia = "0" + dato.getDate().toString();
      } else {
        var dia = dato.getDate().toString();
      }

      if ((dato.getMonth() + 1).toString().length == 1) {
        var mes = "0" + (dato.getMonth() + 1).toString();
      } else {
        var mes = (dato.getMonth() + 1).toString();
      }

      if (isNaN(dia)) {
        return null;
      }
      var fechat = dia + "/" + mes + "/" + dato.getFullYear().toString();

      return fechat;
    };
    var fechas = {};
    for (var i = 0; i < posinominales.length; i++) {
      if (i > 1) {
        var fecha = posinominales[i][2];
        fechas[fecha] = "";
      }
    }
    var lista = Object.keys(fechas);
    var listanum = [];
    var listatxt = [];
    lista.forEach((fecha) => {
      var fechs = fecha.split("/");
      var timest = new Date(fechs[2] * 1, fechs[1] * 1 - 1, fechs[0]).getTime();
      listanum.push(timest);
    });
    listanum.sort().forEach((fecha) => {
      listatxt.push(formatoFecha(new Date(fecha)));
    });
    return listatxt;
  }

  useEffect(() => {
    const fechas = extraeFechasPosinom(nominalesGARA);
    setFechas(fechas);
    let tabla = tenenciaBOP.filter((item) => item.estado == "Disponible" && item.nroCajaValores != "");
    if (fechas[0] != undefined && fechas[0] != null) {
       procesaNominales(nominalesGARA, tabla, fechas[0], "posinom");
    }
    if (fechas[1] != undefined && fechas[1] != null) {
     procesaNominales(nominalesGARA, tabla, fechas[1], "posinomt1");
    }
    if (fechas[2] != undefined && fechas[2] != null) {
       procesaNominales(nominalesGARA, tabla, fechas[2], "posinomt2");
    }
     procesaGtiaDisp(garantiaDispBOP, tabla);
    setTablaState([...tabla]);
  }, [ nominalesGARA]);

  /*  useEffect(() => {
    
    tabla = procesaGtiaDisp(garantiaDispBOP, tabla);
    setTablaState(tabla)
  
  }, [garantiaDispBOP])

  */

  const sumaLista = (arr = []) => {
    let suma = 0;
    arr.forEach((item) => {
      if (item) {
        suma += item;
      }
    });
    return suma;
  };

 const showConLiq = (show) => {

  setShowConliq(show)
/*   if(show) {
    const newState = tabla.filter(item => item.posinom != undefined);
    setTablaState(newState)
  } else {
    setTablaState(tabla)
  } */

 }

  return (
    <div className="card">
      <div className="card-header grey">
        <span>
          <b>NOMINALES</b> - Posicion por Comitente{" "}
        </span>
        <button type="button" onClick={() => {cargaDatos()} } name="bt_fecha" className="btn btn-secondary btn-sm float-right ml-1">
          Recargar
        </button>
        <button type="button" name="bt_fecha" className="btn btn-secondary btn-sm float-right ml-1">
          {new Date().toLocaleDateString()}
        </button>

        <div data-toggle="buttons" className="btn-group btn-group-toggle float-right">
          <label  onClick={()=> {showConLiq(false)}} className="btn  btn-sm btn-secondary active">
            <input type="radio" name="options" id="option1" checked="checked" /> Todas
          </label>{" "}
          <label  onClick={()=> {showConLiq(true)}} className="btn  btn-sm btn-secondary">
            <input  type="radio" name="options" id="option2" /> Con liquid.
          </label>
        </div>
        <input placeholder="Filtra por comitente" type="text" value={filtro} onChange={ (e) => {setFiltro(e.target.value)} } className="form-control-sm float-right mr-1" />
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <table className="table table-sm table-bordered table-hover">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Producto</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Nro CAJVAL</th>
                  <th scope="col">Comitente</th>
                  <th scope="col">Nro Especie</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col">Disp GARA</th>
                  <th scope="col">{fechasArr[0] ? `Liq ${fechasArr[0]}` : "T0"}</th>
                  <th scope="col">Neto</th>
                  <th scope="col">{fechasArr[1] ? `Liq ${fechasArr[1]}` : "T1"}</th>
                  <th scope="col">{fechasArr[2] ? `Liq ${fechasArr[2]}` : "T2"}</th>
                  <th scope="col">Concert.</th>
                </tr>
              </thead>
              <tbody>
                {!showConliq && tablaState.filter(item => item.desComitente.toLowerCase().indexOf(filtro.toLowerCase()) > -1 ).map((item) => (
                  <tr>
                    <td>{item.descripcionTitVal}</td>
                    <td>{item.estado}</td>
                    <td>{item.nroComitente}</td>
                    <td>{item.desComitente}</td>
                    <td>{item.nroCajaValores}</td>
                    <td className="text-right">{formatNumero(item.cantidad)}</td>
                    <td className="text-right">{formatNumero(item.dispGARA)}</td>
                    <td className="text-right">{formatNumero(item.posinom)}</td>
                    <td className={sumaLista([item.cantidad, item.dispGARA, item.posinom]) < 0 ? 'alert-danger' : null} style={tdBorderedCSS}> {formatNumero(sumaLista([item.cantidad, item.dispGARA, item.posinom]))} </td>
                    <td className="text-right">{formatNumero(item.posinomt1)}</td>
                    <td className="text-right">{formatNumero(item.posinomt2)}</td>
                    <td style={tdBorderedCSS}> {formatNumero(sumaLista([item.cantidad, item.dispGARA, item.posinom, item.posinomt1, item.posinomt2]))} </td>
                  </tr>
                ))}
                {showConliq && tablaState.filter(item => item.desComitente.toLowerCase().indexOf(filtro.toLowerCase()) > -1 &&  item.posinom != undefined &&  item.posinom != 0).map((item) => (
                  <tr>
                    <td>{item.descripcionTitVal}</td>
                    <td>{item.estado}</td>
                    <td>{item.nroComitente}</td>
                    <td>{item.desComitente}</td>
                    <td>{item.nroCajaValores}</td>
                    <td className="text-right">{formatNumero(item.cantidad)}</td>
                    <td className="text-right">{formatNumero(item.dispGARA)}</td>
                    <td className="text-right">{formatNumero(item.posinom)}</td>
                    <td className={sumaLista([item.cantidad, item.dispGARA, item.posinom]) < 0 ? 'alert-danger' : null} style={tdBorderedCSS}> {formatNumero(sumaLista([item.cantidad, item.dispGARA, item.posinom]))} </td>
                    <td className="text-right">{formatNumero(item.posinomt1)}</td>
                    <td className="text-right">{formatNumero(item.posinomt2)}</td>
                    <td style={tdBorderedCSS}> {formatNumero(sumaLista([item.cantidad, item.dispGARA, item.posinom, item.posinomt1, item.posinomt2]))} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlNominalesBOP;
