import React, { useContext, useState } from "react";
import logo from "../img/logo.svg";
import logoRosval from "../img/logo-rosval.webp";
import { Link, useLocation } from "react-router-dom";
import { menuList } from "../vars/menuList";
import useSession from "../hooks/useSession";
import useDocumentTitle from "../hooks/useDocumentTitle";
import SessionContext from "../context/SessionContext";
const REACT_APP_INSTANCIA_AN = process.env.REACT_APP_INSTANCIA_AN === "true";

function Menu() {
  useDocumentTitle("Menu");
  //console.log({ menuList });
  const { logout } = useSession();
  const context = useContext(SessionContext);
  const { userCategory } = context;
  //console.log({ userCategory });
  const menuShow = menuList[userCategory] || [];
  const location = useLocation();

  const [errorDetail, setErrorDetail] = useState(location.state?.detail || {});
  const menusBYMA = menuShow.filter((item) => item.mercado === "BYMA");
  const menusMAV = menuShow.filter((item) => item.mercado === "MAV");
  const menusMTR = menuShow.filter((item) => item.mercado === "MTR");
  const menusMULTI = menuShow.filter((item) => item.mercado === "MULTI");
  const menusOtros = menuShow.filter((item) => !item.mercado);
  console.log({errorDetail})
  
  

  return (
    <div className="container text-center mt-5">
      <div className="row justify-content-center">
     
          <div className="col-4 ">
           { errorDetail.loginSDIB === false &&     <div className="alert alert-danger">SDIB no login</div> }
             { errorDetail.loginSLYC === false &&     <div className="alert alert-danger">SLYC no login</div> }
          </div>
    

        
      </div>
      <div className="row">
        <div className="col-sm-0 col-lg-4"></div>
        <div className="col-lg-4 col-sm-12">
          <img className="mb-4" src={REACT_APP_INSTANCIA_AN ? logoRosval : logo} alt="" width="150" height="150"></img>
          <div className="list-group">
            <button
              type="button"
              className="list-group-item list-group-item-action active"
              style={{ "background-color": "black" }}
            >
              <span className="font-weight-bold">MENÚ</span>
            </button>

            <div className="accordion" id="accordionMenu">

              <div className="card">
                <div className="card-header" id="headingBYMA">
                  <button
                    className="btn btn-sm btn-link btn-block text-center"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseBYMA"
                    aria-expanded="true"
                    aria-controls="collapseBYMA"
                  >
                    BYMA
                  </button>
                </div>
                
                <div id="collapseBYMA" className="collapse " aria-labelledby="headingBYMA" data-parent="#accordionMenu">
                  <div style={{'maxHeight': '35vh', 'overflow-y': 'auto', "padding": "8px"}} className="card-body tableFixHead">
                    <div   className="list-group">
                      {menusBYMA.map((item) => (
                        <Link key={item.link} to={item.link}>
          
                          <button type="button" class="btn btn-sm list-group-item list-group-item-action text-left">
                            {item.title}
                          </button>
           
                    
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>

              </div>
{ menusMAV.length > 0 &&    <div className="card">
                <div className="card-header" id="headingMAV">
                  <button
                    className="btn  btn-sm btn-link btn-block text-center"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseMAV"
                    aria-expanded="true"
                    aria-controls="collapseMAV"
                  >
                    MAV
                  </button>
                </div>
                <div id="collapseMAV" className="collapse " aria-labelledby="headingMAV" data-parent="#accordionMenu">
                <div style={{'maxHeight': '35vh', 'overflow-y': 'auto', "padding": "8px"}} className="card-body tableFixHead">
                    <div className="list-group">
                      {menusMAV.map((item) => (
                        <Link key={item.link} to={item.link}>
                          <button type="button" class="btn btn-sm list-group-item list-group-item-action text-left">
                            {item.title}
                          </button>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>}
              { menusMTR.length > 0 &&    <div className="card">
                <div className="card-header" id="headingMTR">
                  <button
                    className="btn  btn-sm btn-link btn-block text-center"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseMTR"
                    aria-expanded="true"
                    aria-controls="collapseMTR"
                  >
                    MTR
                  </button>
                </div>
                <div id="collapseMTR" className="collapse " aria-labelledby="headingMTR" data-parent="#accordionMenu">
                <div style={{'maxHeight': '35vh', 'overflow-y': 'auto', "padding": "8px"}} className="card-body tableFixHead">
                    <div className="list-group">
                      {menusMTR.map((item) => (
                        <Link key={item.link} to={item.link}>
                          <button type="button" class="btn btn-sm list-group-item list-group-item-action text-left">
                            {item.title}
                          </button>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div> }

              { menusMULTI.length > 0 &&    <div className="card">
                <div className="card-header" id="headingMAV">
                  <button
                    className="btn  btn-sm btn-link btn-block text-center"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseMULTI"
                    aria-expanded="true"
                    aria-controls="collapseMULTI"
                  >
                    MULTIMERCADO
                  </button>
                </div>
                <div id="collapseMULTI" className="collapse " aria-labelledby="headingMAV" data-parent="#accordionMenu">
                <div style={{'maxHeight': '35vh', 'overflow-y': 'auto', "padding": "8px"}} className="card-body tableFixHead">
                    <div className="list-group">
                      {menusMULTI.map((item) => (
                        <Link key={item.link} to={item.link}>
                          <button type="button" class="btn btn-sm list-group-item list-group-item-action text-left">
                            {item.title}
                          </button>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>}



            </div>
            {menusOtros.map((item) => (
              <Link to={item.link}>
                <button type="button" className="list-group-item list-group-item-action">
                  {item.title}
                </button>
              </Link>
            ))}

            <button
              type="button"
              className="btn list-group-item list-group-item-action "
              style={{ backgroundColor: "lightgray" }}
              onClick={() => {
                logout();
              }}
            >
              <span className="font-weight-ligh">Logout</span>
            </button>
          </div>
        </div>
        <div className="col-sm-0 col-lg-4"></div>
      </div>
    </div>
  );
}

export default Menu;
