import React from 'react'

const TableCanceladasMTR = ({data = [], isLoading}) => {

 
    function formatDate(clearingBusinessDate ) {
       const yer = clearingBusinessDate.substring(0, 4);
       const mes = clearingBusinessDate.substring(4, 6);
       const dia = clearingBusinessDate.substring(6, 8);
       return `${dia}/${mes}/${yer}`;
    }
    function formatNumber(num) {
      return num?.toLocaleString("de");
    }


  return (
    <div className="card">
      <div className="card-body">
      <div className="tableFixHead" style={{"max-height": "85vh", "min-height": "10vh"}}>
        <table className="table table-bordered table-hover">
          <thead>

            <tr>
              <th scope="col">PosMaintRptID</th>
              <th scope="col">Fecha</th>
              <th scope="col">Cuenta</th>
              <th scope="col">SettlSessID</th>
              <th scope="col">Instrumento</th>
              <th scope="col">SecurityType</th>
              <th scope="col">TipoPos</th>
              <th scope="col">Moneda</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Precio Compra</th>
              <th scope="col">Precio Venta</th>
              <th scope="col">Resultado</th>
              <th scope="col">Market</th>
              <th scope="col">TC Dolar MTR</th>
            </tr>

           </thead>
           <tbody>
            {isLoading && <tr><td colSpan="14" className="text-center"><span >Cargando...</span></td></tr>}
           {!isLoading && data.map((item, index) => (
            <tr key={item.PosMaintRptID}>
              <td>{item.PosMaintRptID}</td>
              <td>{ formatDate(item.ClearingBusinessDate) }</td>
              <td>{item.Account}</td>
              <td>{item.SettlSessID}</td>
              <td>{item.Instrument[0].Symbol}</td>
              <td>{item.Instrument[0].SecurityType}</td>
              <td>{item.PositionQty[0].PosType}</td>
              <td>{item.Currency}</td>
              <td className="text-right">{ formatNumber(item.TradeVariationAmount) }</td>
                <td className="text-right">{ formatNumber(item?.RelatedTradeGrp?.find(item => item.RelatedTradeSide == 1).RelatedTradePrice || 0) }</td>
                <td className="text-right">{  formatNumber(item?.RelatedTradeGrp?.find(item => item.RelatedTradeSide == 2).RelatedTradePrice || 0) }</td>
              <td className="text-right">{ formatNumber(item.PositionAmountData) }</td>
              <td>{item.MarketID}</td>
              <td className="text-right">{ formatNumber(item.tipoDeCambioMTR) }</td>
            </tr>
          ))}
          </tbody>
            </table>
            </div>
      </div>
    </div>
  )
}

export default TableCanceladasMTR