import React, { useState, useEffect } from "react";

import Plazotable from "../components/Plazotable";
import CaucionTable from "../components/CaucionTable";
import OpcionesTable from "../components/OpcionesTable";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
import PanelHeaderTrader from "../components/PanelHeaderTrader";
import SaldosTrader from "../components/SaldosTrader";
import TotalesPorEspecieWidget from "../components/TotalesPorEspecieWidget";
import OperacionesTrader from "../components/OperacionesTrader";
import WidgetResumenEspMon from "../components/WidgetResumenEspMon";

const hasSaldosBOP = process.env.REACT_APP_MENU_SALDOS_BOP === "true";
const REACT_APP_SYS_BO = process.env.REACT_APP_SYS_BO;

const Panel = () => {
  useDocumentTitle("Panel de Operaciones");
  const [infoCI, setInfoCI] = useState({});
  const [info24hs, setInfo24hs] = useState({});
  const [info48hs, setInfo48hs] = useState({});
  const [moneda, setMoneda] = useState("P");
  const [cuentaOper, setCuentaOper] = useState(0);
  const [showCI, setShowCI] = useState(true);
  const [show24hs, setShow24hs] = useState(true);
  const [show48hs, setShow48hs] = useState(true);
  const [showCauc, setShowCauc] = useState(true);
  const [showOpc, setShowOpc] = useState(true);
  const [showTotPorEsp, setShowTotPorEsp] = useState(false);
  const [saldosCI, setSaldosCI] = useState({});
  const [saldosCaucion, setSaldosCaucion] = useState({});
  const [tablaCaucion, setTablaCaucion] = useState({});
  const [tablaOpciones, setTablaOpciones] = useState({});
  const [hayEjercicios, setHayEjercicios] = useState(false);
  const [hayVC, setHayVC] = useState(false);
  const [saldosBOP, setSaldosBOP] = useState([]);
  const [horaUltimaOperacion, setHoraUltimaOperacion] = useState("");
  const [saldosPanelShow, setSaldosPanelShow] = useState({ P: true, D: true, C: true });
  const [showDhs, setShowDhs] = useState(true);
  const [infoCISB, setInfoCISB] = useState([]);
  const [info24hsSB, setInfo24hsSB] = useState([]);
  const [info48hsSB, setInfo48hsSB] = useState([]);
  const [saldosCISB, setSaldosCISB] = useState({});
  const [showPanelSENEBI, setShowPanelSENEBI] = useState(true);
  const [isLoadingContado, setisLoadingContado] = useState(false);
  const [isLoadingCauciones, setisLoadingCauciones] = useState(false);
  const [isLoadingOpciones, setisLoadingOpciones] = useState(false);
  const [isLoadingContadoSB, setisLoadingContadoSB] = useState(false);
  const [widgetResumenEspMonData, setWidgetResumenEspMonData] = useState({});
  const [widgetResumenEspMonDataSB, setWidgetResumenEspMonDataSB] = useState({});
  const [userConfig, setUserConfigState] = useState({})
  const [origin, setOrigin] = useState("N/D")
  //const [info_procesada, setInfoprocesada] = useState({})


  let info_procesada;

  const {
    getResumenOperdiaContadoTrader,
    getResumenOperdiaCaucionTrader,
    getResumenOperdiaOpcionesTrader,
    getSaldosBOPTrader,
    getSaldosVBCCTrader,
    getResumenOperdiaContadoSBTrader,
    getUserConfig,
    setUserConfig
  } = useServices();

  useEffect(() => {
    initPanel();
  
  }, []);

  async function cargaSaldosBOP() {
    let res = [];
    if (REACT_APP_SYS_BO == "AUNE") {
      res = await getSaldosBOPTrader();
    }
    if (REACT_APP_SYS_BO == "VISUAL_BOLSA") {
      res = await getSaldosVBCCTrader();
    }
    if (res.error) return;
    setSaldosBOP(res);
  }

  async function carga_oper_gara() {
    setisLoadingContado(true)
    let operdia = await getResumenOperdiaContadoTrader();
    if (operdia.error) {setisLoadingContado(false); return;}

    info_procesada = operdia;

    setCuentaOper(info_procesada.cantOper);
    //console.log({ info_procesada });
    setHayEjercicios(info_procesada.hayEjercicios);
    setHayVC(info_procesada.hayVC);
    setHoraUltimaOperacion(info_procesada.horaUltimaOperacion);

    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCI(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hs(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hs(info_procesada.tabla["48hs"]);
      }
      if(info_procesada.origen) {
        setOrigin(info_procesada.origen)
      }
     
      //const dataWidget = generaListaTabla(info_procesada.tabla)
      //mergeContadoySB({infoCI, info24hs, infoCISB, info24hsSB})

      setWidgetResumenEspMonData({...info_procesada.tabla})
    
    }
    if (info_procesada.saldos) {
      setSaldosCI(info_procesada.saldos["Inmediato"]);
    }
    setisLoadingContado(false);
    return true;
  }
  async function handleGetUserConfig () {
    const res = await getUserConfig();
    if(res.error) return;
    setUserConfigState(res)
  }

  async function initPanel() {
    handleGetUserConfig();
    cargaSaldosBOP();
    await carga_oper_gara();
    await cargaResumenCaucGara();
    await cargaResumenOpc();
    await carga_oper_gara_SB();
  }
  async function reloadPanel() {
    await carga_oper_gara();
    await cargaResumenCaucGara();
    await cargaResumenOpc();
    await carga_oper_gara_SB();
  }

  async function cargaResumenCaucGara() {
    setisLoadingCauciones(true);
    const resumenCaucion = await getResumenOperdiaCaucionTrader();
    if (resumenCaucion.error) { setisLoadingCauciones(false); return;}
    //console.log({ resumenCaucion });
    if (resumenCaucion.saldos) {
      setSaldosCaucion(resumenCaucion.saldos);
    }
    if (resumenCaucion.tabla) {
      setTablaCaucion(resumenCaucion.tabla);
    }
    setisLoadingCauciones(false);
    return true;
  }

  async function cargaResumenOpc() {
    setisLoadingOpciones(true);
    const resumenOpciones = await getResumenOperdiaOpcionesTrader();
    if (resumenOpciones.error){ setisLoadingOpciones(false); return;}
    //console.log(resumenOpciones)

    if (resumenOpciones.tabla) {
      setTablaOpciones(resumenOpciones.tabla);
    }
    setisLoadingOpciones(false); 
    return true;
  }

  const showhidePanel = (idpanel) => {
    switch (idpanel) {
      case "CI":
        setShowCI(!showCI);
        break;
      case "24hs":
        setShow24hs(!show24hs);
        break;
      case "48hs":
        setShow48hs(!show48hs);
        break;
      case "caucion":
        setShowCauc(!showCauc);
        break;
      case "opciones":
        setShowOpc(!showOpc);
      case "SENEBI":
        setShowPanelSENEBI(!showPanelSENEBI);
        break;
      case "panelTotPorEsp":
        setShowTotPorEsp(!showTotPorEsp);
          break;
    }
  };
  let ajustes = { P: {}, D: {}, C: {} };
  if (window.localStorage.getItem("ajustes")) {
    ajustes = JSON.parse(window.localStorage.getItem("ajustes"));
  } else {
    window.localStorage.setItem("ajustes", JSON.stringify(ajustes));
  }

  function handleShowHideSaldos(moneda) {
    let newSaldosPanelShow = { ...saldosPanelShow };
    newSaldosPanelShow[moneda] = !newSaldosPanelShow[moneda];
    setSaldosPanelShow(newSaldosPanelShow);
  }



  async function carga_oper_gara_SB() {
    setisLoadingContadoSB(true);
    let operdia = await getResumenOperdiaContadoSBTrader();
    if (operdia.error) { setisLoadingContadoSB(false);  return;}

    const info_procesada = operdia;

    //setCuentaOper(prev => prev + info_procesada.cantOper);
    //setCuentaOper(info_procesada.cantOper);
    //console.log({ info_procesada });

    if (info_procesada.cantOper > 0) {
      setShowPanelSENEBI(true);
    }

    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCISB(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hsSB(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hsSB(info_procesada.tabla["48hs"]);
      }
      if (info_procesada.saldosALiq) {
        setSaldosCISB(info_procesada.saldosALiq["Inmediato"]);
      }
      setWidgetResumenEspMonDataSB({...info_procesada.tabla})
    }
   
    setisLoadingContadoSB(false); 
    return true;
  }


  

  return (
    <div className="container-fluid">
      <div className="row no-gutters">
        <div className="col-12">
          <div className="card-header ">
            <div className="row">
              <div className="col-12">
                <b>Panel Saldos</b>
                <div role="group" className="btn-group  btn-group-toggle float-right">
                  <button
                    onClick={() => {
                      handleShowHideSaldos("P");
                    }}
                    type="checkbox"
                    aria-pressed="false"
                    autocomplete="off"
                    className={
                      saldosPanelShow.P ? "btn btn-secondary btn-sm active ml-1" : "btn btn-secondary btn-sm ml-1"
                    }
                  >
                    Pesos
                  </button>

                  <button
                    onClick={() => {
                      handleShowHideSaldos("D");
                    }}
                    type="checkbox"
                    aria-pressed="false"
                    autocomplete="off"
                    className={saldosPanelShow.D ? "btn btn-secondary btn-sm active" : "btn btn-secondary btn-sm"}
                  >
                    Dólar
                  </button>
                  <button
                    onClick={() => {
                      handleShowHideSaldos("C");
                    }}
                    type="checkbox"
                    aria-pressed="true"
                    autocomplete="off"
                    className={saldosPanelShow.C ? "btn btn-secondary btn-sm active" : "btn btn-secondary btn-sm"}
                  >
                    Cable
                  </button>

                  <button
                    onClick={() => {
                      setShowDhs(!showDhs);
                    }}
                    type="checkbox"
                    aria-pressed="true"
                    autocomplete="off"
                    className={showDhs ? "btn btn-secondary btn-sm active ml-2" : "btn btn-secondary btn-sm ml-2"}
                  >
                    Calcula Derechos
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {saldosPanelShow.P && (
          <SaldosTrader
            reloadPanel={reloadPanel}
            saldosBOP={saldosBOP}
            saldosContadoBYMA={infoCI}
            saldosContadoBYMASB={infoCISB}
            saldosCaucionBYMA={tablaCaucion}
            moneda={"P"}
            hasColAjuste={true}
            initialAjustes={ajustes["P"]}
            showDhs={showDhs}
            showSB={true}
          />
        )}
        {saldosPanelShow.D && (
          <SaldosTrader
            reloadPanel={reloadPanel}
            saldosBOP={saldosBOP}
            saldosContadoBYMA={infoCI}
            saldosContadoBYMASB={infoCISB}
            saldosCaucionBYMA={tablaCaucion}
            moneda={"D"}
            hasColAjuste={true}
            initialAjustes={ajustes["D"]}
            showDhs={showDhs}
            showSB={true}
          />
        )}
        {saldosPanelShow.C && (
          <SaldosTrader
            reloadPanel={reloadPanel}
            saldosBOP={saldosBOP}
            saldosContadoBYMA={infoCI}
            saldosContadoBYMASB={infoCISB}
            saldosCaucionBYMA={tablaCaucion}
            moneda={"C"}
            hasColAjuste={true}
            initialAjustes={ajustes["C"]}
            showDhs={showDhs}
            showSB={true}
          />
        )}
      </div>
      <PanelHeaderTrader
        showhidePanel={showhidePanel}
        setMoneda={setMoneda}
        moneda={moneda}
        cuentaOper={cuentaOper}
        cargaContado={carga_oper_gara}
        cargaCauciones={cargaResumenCaucGara}
        cargaOpciones={cargaResumenOpc}
        cargaContadoSB={carga_oper_gara_SB}
        hayEjercicios={hayEjercicios}
        hayVC={hayVC}
        panelShow={{ showCI, show24hs, show48hs, showCauc, showOpc, showPanelSENEBI, showTotPorEsp }}
        horaUltimaOperacion={horaUltimaOperacion}
        isLoadingContado={isLoadingContado}
        isLoadingCauciones={isLoadingCauciones}
        isLoadingOpciones={isLoadingOpciones}
        isLoadingContadoSB={isLoadingContadoSB}
        opersOrigin={origin}
      />

      <div className="row no-gutters">
        {showCI && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="CI" datos={infoCI} showDhs={showDhs} showFooter={true} />
          </div>
        )}
        {show24hs && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="24hs" datos={info24hs} showDhs={showDhs} showFooter={true} />
          </div>
        )}
  
      </div>
      {showPanelSENEBI && (
        <div className="row no-gutters">
          {showCI && (
            <div className="col-lg col-sm-12 col-md-12">
              <Plazotable moneda={moneda} titulo="CI - SENEBI" datos={infoCISB} showFooter={true} />
            </div>
          )}
          {show24hs && (
            <div className="col-lg col-sm-12 col-md-12">
              <Plazotable moneda={moneda} titulo="24hs - SENEBI" datos={info24hsSB} showFooter={true} />
            </div>
          )}

        
        
        </div>
      )}

      <div className="row no-gutters">
        {showCauc && <CaucionTable moneda={moneda} datos={tablaCaucion} />}
        {showOpc && <OpcionesTable datos={tablaOpciones} />}
        </div>
      <div className="row no-gutters">
        {showTotPorEsp && <>
        <TotalesPorEspecieWidget moneda={"P"} infoCI={infoCI} info48hs={info48hs} />
        <TotalesPorEspecieWidget moneda={"D"} infoCI={infoCI} info48hs={info48hs} />
        </>
        }
        <div className="col-lg col-sm-12 col-md-12">
      <WidgetResumenEspMon data={widgetResumenEspMonData} dataSB={widgetResumenEspMonDataSB} setUserConfig={setUserConfig} userConfig={userConfig} /> 
      </div>
     
      </div>

  


      <div className="row no-gutters">
        <div className="col">
          <OperacionesTrader  />
        </div>
      </div>
    </div>
  );
};

export default Panel;
