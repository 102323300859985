import React, { useEffect, useState } from "react";
import PosCaucComit from "../components/PosCaucComit";
import useDocumentTitle from "../hooks/useDocumentTitle";

//import getResumenPosiCauc from "../services/getResumenPosiCauc";
import useServices from "../hooks/useServices";


const VencCaucTrader = () => {
  useDocumentTitle('Vencimientos Cauciones')
  const [tablaPosiciones, setTablaPosiciones] = useState({ P: [], D: [] });
  const [listaFechas, setListaFechas] = useState({ P: [], D: [] });
  const {getResumenPosiCaucionesTrader} = useServices();

  async function cargaDatos() {
    const datos = await getResumenPosiCaucionesTrader();
    if(datos.error) return
    setTablaPosiciones(datos.tablasPosiciones);
    setListaFechas(datos.listaFechas);
  }

  useEffect(() => {
    cargaDatos();
  }, []);

  return (
    <div className="container-fluid">
    <div className="row">
      <div className="col-sm">
        <PosCaucComit monedaLabel="PESOS" tablaPosiciones={tablaPosiciones.P} listaFechas={listaFechas.P} trader={true} showLeyenda={true} />
      </div>
      <div className="col-sm"> 
       <PosCaucComit monedaLabel="DÓLAR" tablaPosiciones={tablaPosiciones.D} listaFechas={listaFechas.D}  trader={true} showLeyenda={true} />
      </div>
    </div>
    </div>
  );
};

export default VencCaucTrader;
