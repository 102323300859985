import React, { useState, useRef, useEffect } from "react";

import "../css/customTable.css";
import FileUploadButton from "./FileUploadButton";
import useServices from "../hooks/useServices";
import PosConsolDerivMAV from "./PosConsolDerivMAV";
const baseURL = process.env.REACT_APP_API_URL;


const DistribucionesTableMAV = ({ titulo, initBodyVisb = false, realoadBtn = true }) => {

  const [data, setData] = useState([]);

  const [MAVConected, setMAVConected]  = useState(false);
  //let tabla = [];
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
   const {getSaldosDerivESCO, getMarketSesions} = useServices()

   async function cargaAnterior () {
    const res = await getSaldosDerivESCO();
    if(res.error) return
    if(res.consolPosMon) {
    setData(res.consolPosMon)

    if(res?.noEncontrados?.length > 0) {
      alert("No se encontro el comitente en la tabla de fondos:\n" + res.noEncontrados.join("\n"))
    }
  }
   }

   async function updateSesionsStatus ()  {
    const res = await getMarketSesions();
    if (res.error) return;

 
    if(res.loginMAV) {
      setMAVConected(true)
    }
   

  };


  useEffect(() => {
   cargaAnterior()
   updateSesionsStatus()
  }, []);



  //console.log("datos", datos);

  //setTabla(datos)
  //tabla = datos;



  const showhideBody = () => {
    setBodyVisible(!bodyVisible);
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none";
  const leyBtn = bodyVisible ? "-" : "+";

  
 async function handleUploadResult (res) {
   
   if(res.error) return;
  // setData([...res.consolPosMon])

   if(res?.noEncontrados?.length > 0) {
       alert("No se encontro el comitente en la tabla de fondos:\n" + res.noEncontrados.join("\n"))
       window.location.reload();
   } else {
    window.location.reload();
   }


 }

  return (
    <div className="card">
      <div className="card-header ">
        <div className="row">
          <div className="col">
            <span>
              <b>Derivaciones - {titulo}</b>
             
            </span>
          </div>
          <div className="col">
            <FileUploadButton
              endPoint={`${baseURL}upload/uploadBoletosVBMAV`}
              fileName={"boletosvbmav.xlsx"}
              label={"Cargar Boletos"}
              captionCargando={"Generando archivos..."}
              callback={handleUploadResult}
            />
             </div>
        <div className="col">

        <strong className="float-right">MAV - {!MAVConected ?  <span class="badge badge-pill badge-danger">No conectado</span> : <span class="badge badge-pill badge-success">Conectado</span>} </strong>

           
          </div>
        </div>
      </div>
      <div className={cardBodyClass}>
       { data.map( item =>    (<PosConsolDerivMAV key={item.aliasFondo+item.fechaText}  data={item} /> ) )}
   
      </div>
    </div>
  );
};

export default DistribucionesTableMAV;
