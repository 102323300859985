import React, {  useState } from "react";

import TranfTSA from "../components/TranfTSA";


//import getPosiNominales from "../services/getPosiNominales";
import {formatoFecha, procesaPosiNom} from "../lib/utils"
import useDocumentTitle from "../hooks/useDocumentTitle";
import MovGtiasCaucMAV from "../components/MovGtiasCaucMAV";
import PNGVencimientosTit from "../components/PNGVencimientosTit";
const REACT_APP_CAJVAL_DEPOSITANTE = process.env.REACT_APP_CAJVAL_DEPOSITANTE;
const REACT_APP_CAJVAL_PUENTE = process.env.REACT_APP_CAJVAL_PUENTE;
const REACT_APP_CAJVAL_GTIAS = process.env.REACT_APP_CAJVAL_GTIAS;


//console.log({REACT_APP_CAJVAL_PUENTE})

//const { procesaPosiNom } = require("../lib/utils");

const NominalesMAV = () => {
  useDocumentTitle('Nominales MAV')

  const [tablaTranf, setTablaTranf] = useState([]);

  const [fecha, setFecha] = useState(formatoFecha(new Date()));
  const [transfWidgets, setTransfWidgets] = useState([]);
  





  const addTransfWidgets =  (transfers) => {
    
    const newTranfWidgets = [ ...transfWidgets, ...transfers ];
    setTransfWidgets(newTranfWidgets)

  }

  const delTransfWidgets =  (index) => {
    
    let newArr = [...transfWidgets];
    newArr.splice(index, 1);

    setTransfWidgets(newArr)

  }



  return (
    <div className="container-fluid">



 

  
      <div className="row no-gutters">
        <div className="col"></div>
        <div className="col-lg-4 col-sm-12 col-md-12">
          <MovGtiasCaucMAV addTransfWidgets={addTransfWidgets} />
          <PNGVencimientosTit  addTransfWidgets={addTransfWidgets} />
        </div>
        <div className="col-lg-4 col-sm-12 col-md-12">
          <TranfTSA delTransfWidgets={delTransfWidgets} transfWidgets={transfWidgets} fecha={fecha} datos={tablaTranf} btnCdo={false} />
        </div>
        <div className="col"></div>
      </div>
   
    </div>
  );
};

export default NominalesMAV;
