import React, { useState, useEffect } from "react";
import DistribucionesTableMAV from "../components/DistribucionesTableMAV";



import useDocumentTitle from "../hooks/useDocumentTitle";





const DistribucionesMAV = () => {
  useDocumentTitle("Distribuciones MAV");

  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
        <div className="row">
            <div className="col">
              <div className="card-header">
                <div className="row">
                  <div className="col">

                  </div>
                </div>
      
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
          <DistribucionesTableMAV  titulo={`Distribuciones`} initBodyVisb={true} />
          </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default DistribucionesMAV;
