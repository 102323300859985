
const baseURL = process.env.REACT_APP_API_URL;

export const delComitente = async (options, id) => {
  let json =  []
  try {
    const res = await fetch(`${baseURL}listaComitentes/${id}`, { method: 'DELETE', headers: options.headers });
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export const addComitente = async (options, newComitente) => {
    let json =  []
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...options.headers },
        body: JSON.stringify(newComitente)
    };
    try {
      const res = await fetch(`${baseURL}listaComitentes`, requestOptions);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };

  export const setComitente = async (options, comitente) => {
    let json =  []
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...options.headers },
        body: JSON.stringify(comitente)
    };
    try {
      const res = await fetch(`${baseURL}listaComitentes`, requestOptions);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };


 export const getComitentes = async (options) => {
    let json =  []
    try {
      const res = await fetch(`${baseURL}listaComitentes`, options);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };
  

