import React, {useState} from "react";
import CardTemplate from "./CardTemplate";

const RdosEjercicios = ({ tabla = [], descripcion = '' }) => {

  const [filtroCuenta, setfiltroCuenta] = useState('')
  const [filtroSerie, setfiltroSerie] = useState('')


  return (
    <CardTemplate title={"Resultado Ejercicios"} description={descripcion}>
      <table className="table table-sm table-bordered table-hover texto-small">
        <thead>
        <tr>
                <th> <div class="input-group input-group-sm"> <input type="text" value={filtroCuenta} onChange= {(e) => setfiltroCuenta(e.target.value) } placeholder="Cuenta" className="form-control" /> </div> </th>
                <th> <div class="input-group input-group-sm"> <input type="text" value={filtroSerie} onChange= {(e) => setfiltroSerie(e.target.value) } placeholder="Serie" className="form-control" />  </div> </th>
                <th>  </th>
                <th></th>
                <th></th>
              </tr>
          <tr>
            <th scope="col">Cuenta</th>
            <th scope="col">Serie</th>
            <th scope="col">Precio Ejercicio</th> 
            <th scope="col">Tipo</th>   
            <th scope="col">Cantidad Lotes</th>
          </tr>
        </thead>
        <tbody>
          { tabla.filter(item => item.comitente.includes(filtroCuenta) && item.serie.includes(filtroSerie) ).map( item => (
              <tr key={item.comitente+item.serie+item.tipo}>
             <td>{item.comitente}</td>
             <td>{item.serie}</td>
             <td>{item.precioEjercicio}</td>
             <td>{item.tipo}</td>
             <td>{item.sumCantLotes}</td>
             </tr>
          ))
          
          }
        </tbody>
      </table>
    </CardTemplate>
  );
};

export default RdosEjercicios;
