import React, { useEffect, useState } from "react";
import NomPorComit from "../components/NomPorComit";
import TransfPorComit from "../components/TransfPorComit";
import NominalesHeader from "../components/NominalesHeader";
import NomPorEspecie from "../components/NomPorEspecie";
import TranfTSA from "../components/TranfTSA";
import ReqNocub from "../components/ReqNocub";
import GarantiaDisp from "../components/GarantiaDisp"

//import getPosiNominales from "../services/getPosiNominales";
import {formatoFecha, procesaPosiNom} from "../lib/utils"
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
const REACT_APP_CAJVAL_DEPOSITANTE = process.env.REACT_APP_CAJVAL_DEPOSITANTE;
const REACT_APP_CAJVAL_PUENTE = process.env.REACT_APP_CAJVAL_PUENTE;
const REACT_APP_CAJVAL_GTIAS = process.env.REACT_APP_CAJVAL_GTIAS;


//console.log({REACT_APP_CAJVAL_PUENTE})

//const { procesaPosiNom } = require("../lib/utils");

const Nominales = () => {
  useDocumentTitle('Nominales BYMA')
  const [tablaPosiConLiqHoy, setTablaPosiConLiqHoy] = useState([]);
  const [tablaTranf, setTablaTranf] = useState([]);
  const [tablaEspecies, setTablaEspecies] = useState([]);
  const [listaFechas, setListaFechas] = useState([]);
  const [fecha, setFecha] = useState(formatoFecha(new Date()));
  const [transfWidgets, setTransfWidgets] = useState([]);
  const [isLoadingContado, setIsloadingContado] = useState(false); 
  const [orgigenPosiCdo, setOrigenPosiCdo] = useState("")
  
  const {getPosiNominalesProcesado} = useServices();

  let posiNominales = [];
  let posiNominalesProces = {};

  async function init() {
    setIsloadingContado(true)
    posiNominales = await getPosiNominalesProcesado();
    setIsloadingContado(false)
    if(posiNominales.error) return
    const {listFechas, tablaPosiConLiqHoy, tablaTranf, tablaEspecies, origen} = posiNominales;
    setOrigenPosiCdo(origen);
    setListaFechas(listFechas);
    setFecha(listFechas?.[0]?.vencimiento);
    setTablaPosiConLiqHoy(tablaPosiConLiqHoy);
    setTablaTranf(tablaTranf);
    setTablaEspecies(tablaEspecies);
  }



  const addTransfWidgets =  (transfers) => {
    
    const newTranfWidgets = [ ...transfWidgets, ...transfers ];
    setTransfWidgets(newTranfWidgets)

  }

  const delTransfWidgets =  (index) => {
    
    let newArr = [...transfWidgets];
    newArr.splice(index, 1);

    setTransfWidgets(newArr)

  }


  useEffect(() => {
    init();
  }, []);

  return (
    <div className="container-fluid">
            <div className="row no-gutters">
              <div className="col-12">

              <div className="card-header"><h4> {isLoadingContado ? "Cargando Contado..." : `Contado Cargado | Origen: ${orgigenPosiCdo}`} </h4></div>
              </div>
            </div>
      
      <div className="row no-gutters">
        <div className="col-lg-6 col-sm-12 col-md-12">
          <NomPorComit fecha={fecha} datos={tablaPosiConLiqHoy} />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12">
          {" "}
          <TransfPorComit fecha={fecha} datos={tablaTranf} />
        </div>
      </div>

 
      <div className="row no-gutters ">
      <div className="col-sm-12 col-md-12 col-lg-6">
          <NomPorEspecie fecha={fecha} datos={tablaEspecies} />
        </div>
        </div>
  
      <div className="row no-gutters">
        <div className="col-lg-4 col-sm-12 col-md-12">
          <ReqNocub addTransfWidgets={addTransfWidgets} />
        </div>
        <div className="col-lg-4 col-sm-12 col-md-12">
          <TranfTSA delTransfWidgets={delTransfWidgets} transfWidgets={transfWidgets} fecha={fecha} datos={tablaTranf} />
        </div>
        <div className="col-lg-4 col-sm-12 col-md-12">
          <GarantiaDisp  addTransfWidgets={addTransfWidgets}  />
        </div>
      </div>
   
    </div>
  );
};

export default Nominales;
