import React, { useState, useEffect } from "react";



import useDocumentTitle from "../hooks/useDocumentTitle";
import DistribucionesCaucionTable from "../components/DistribucionesCaucionTable";




const DistribucionesCaucionBYMA = () => {
  useDocumentTitle("Distribución de Cauciones - BYMA");
  
  

  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
        <div className="row">
            <div className="col">
              <div className="card-header">
                <div className="row">
                  <div className="col">

                  </div>
                </div>
      
              </div>
            </div>
          </div>
       
          <div className="row">
            <div className="col-12">
          <DistribucionesCaucionTable  titulo={``} initBodyVisb={true} />
          </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default DistribucionesCaucionBYMA;
