import React, { useState, useEffect } from "react";


import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";


import SaldosControl from "../components/SaldosControl";



const hasSaldosBOP = process.env.REACT_APP_MENU_SALDOS_BOP === 'true';

const ControlSaldosAUNE = () => {
  useDocumentTitle("Control de saldos - AUNE");
  const [infoCI, setInfoCI] = useState([]);
  const [info24hs, setInfo24hs] = useState([]);
  const [info48hs, setInfo48hs] = useState([]);
  const [moneda, setMoneda] = useState("P");
  const [cuentaOper, setCuentaOper] = useState(0);

  const [saldosCI, setSaldosCI] = useState({});
  const [saldosCaucion, setSaldosCaucion] = useState({});
  const [tablaCaucion, setTablaCaucion] = useState({});
  const [saldosBOP, setSaldosBOP] = useState([]);
  //const [info_procesada, setInfoprocesada] = useState({})

  let info_procesada;

  const { getResumenOperdiaContado, getResumenOperdiaCaucion, getSaldosBOPCC } =
    useServices();

  useEffect(() => {
    initPanel();
  }, []);

  async function cargaSaldosBOP () {
     const res = await getSaldosBOPCC();
     if (res.error) return
     setSaldosBOP(res)
  }

  async function carga_oper_gara() {
    let operdia = await getResumenOperdiaContado();
    if (operdia.error) return;

    info_procesada = operdia;

    setCuentaOper(info_procesada.cantOper);


    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCI(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hs(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hs(info_procesada.tabla["48hs"]);
      }
    }
    if (info_procesada.saldos) {
      setSaldosCI(info_procesada.saldos["Inmediato"]);
    }
    return true;
  }

  async function initPanel() {
     if(hasSaldosBOP) {cargaSaldosBOP()};
    await carga_oper_gara();
    await cargaResumenCaucGara();
    
  }
  async function reloadPanel() {

   await carga_oper_gara();
   await cargaResumenCaucGara();

   
 }

  async function cargaResumenCaucGara() {
    const resumenCaucion = await getResumenOperdiaCaucion();
    if (resumenCaucion.error) return;
    //console.log({ resumenCaucion });
    if (resumenCaucion.saldos) {
      setSaldosCaucion(resumenCaucion.saldos);
    }
    if (resumenCaucion.tabla) {
      setTablaCaucion(resumenCaucion.tabla);
    }
    return true;
  }





  return (
    <div className="container-fluid">


<div className="row no-gutters"> 
 <SaldosControl reloadPanel={reloadPanel} initBodyVisb={true} saldosBOP={saldosBOP} saldosContadoBYMA={infoCI} saldosCaucionBYMA={tablaCaucion} moneda={'P'} />
 <SaldosControl reloadPanel={reloadPanel} initBodyVisb={true} saldosBOP={saldosBOP} saldosContadoBYMA={infoCI} saldosCaucionBYMA={tablaCaucion} moneda={'D'} />
 <SaldosControl reloadPanel={reloadPanel} initBodyVisb={true} saldosBOP={saldosBOP} saldosContadoBYMA={infoCI} saldosCaucionBYMA={tablaCaucion} moneda={'C'} />

</div> 





    </div>
  );
};

export default ControlSaldosAUNE;
