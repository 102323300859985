import React, { useEffect, useState } from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useServices from "../hooks/useServices";
import TablePosicionesMTR from "../components/TablePosicionesMTR";


const PosicionesMTR = () => {
  useDocumentTitle("Posiciones - MTR");
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const {getPositionReportT0MTR} = useServices();

  
 
  async function cargaData() {
    setIsLoading(true);
    const res = await getPositionReportT0MTR();
    if(res.error) return;
    //console.log(res)
    setTableData(res)

    setIsLoading(false);

  }


  useEffect(() => {
    cargaData()
  }, []);

  

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
         <TablePosicionesMTR isLoading={isLoading} cargaData={cargaData} tableData={tableData} />
        </div>
      </div>

    </div>
  );
};

export default PosicionesMTR;
