import React, { useEffect, useState } from "react";
import FileUploadButton from "../components/FileUploadButton";
import useServices from "../hooks/useServices";
const baseURL = process.env.REACT_APP_API_URL;
const SYS_BO = process.env.REACT_APP_SYS_BO;

const ConciliaTenenciaCV = () => {
  const [tableData, setTableData] = useState([]);
  const [filtroCuenta, setFiltroCuenta] = useState("");
  const [filtroCodInstr, setFiltroCodInstr] = useState("");
  const [filtraConDiferencia, setFiltraConDiferencia] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const { getConciliaTenenciaCV } = useServices();
  async function cargaDatos() {
    //alert("archivo cargado");
    setIsLoading(true)
    const res = await getConciliaTenenciaCV();
    if (res.error) return;
    setIsLoading(false)
    setTableData(res);
  }

  const formatNumero = (num) => {
    return num?.toLocaleString("de");
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  const checkFiltroDiferencia = (diferencia) => {
    if (filtraConDiferencia) {
      return diferencia != 0;
    } else {
      return true;
    }
  };

  return (
    <div className="container-fluid">
      <div className="card-header">
        <div className="row">
          <div className="col-4">
            <FileUploadButton
              endPoint={`${baseURL}CAJVAL/uploadTenenciaCV`}
              fileName={"tenvalores.csv"}
              label={"Cargar archivo de tenencia NASDAQ"}
              callback={cargaDatos}
            />
          </div>

          <div className="col-4">
         { SYS_BO == "AUNE" &&  <FileUploadButton
              endPoint={`${baseURL}CAJVAL/uploadTenenciaAUNE`}
              fileName={"aune.xlsx"}
              label={"Cargar archivo de tenencia AUNE"}
              callback={cargaDatos}
            />}

        { SYS_BO == "VISUAL_BOLSA" &&  <FileUploadButton
              endPoint={`${baseURL}CAJVAL/uploadTenenciaVB`}
              fileName={"vb.xlsx"}
              label={"Cargar archivo de tenencia Visual Bolsa"}
              callback={cargaDatos}
            />}
          </div>

          <div className="col-4">
            <div data-toggle="buttons" className="btn-group btn-group-toggle float-right">
              <label
                onClick={() => {
                  setFiltraConDiferencia(false);
                }}
                className="btn  btn-sm btn-secondary active"
              >
                <input type="radio" name="options" id="option1" checked="checked" /> Todas
              </label>{" "}
              <label
                onClick={() => {
                  setFiltraConDiferencia(true);
                }}
                className="btn  btn-sm btn-secondary"
              >
                <input type="radio" name="options" id="option2" /> Con Diferencias
              </label>
            </div>
            <input
              placeholder="Codigo CV"
              value={filtroCodInstr}
              onChange={(e) => setFiltroCodInstr(e.target.value)}
              className="form-control-sm form-control  float-right mr-1"
              type="text"
              style={{ "max-width": "130px" }}
            />
            <input
              placeholder="Cuenta"
              value={filtroCuenta}
              onChange={(e) => setFiltroCuenta(e.target.value)}
              className="form-control-sm form-control  float-right mr-1"
              type="text"
              style={{ "max-width": "70px" }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header"></div>
            <div className="card-body">
              {isLoading ? <h3 className="text-center">Cargando...</h3>  :  <div style={{ maxHeight: "85vh", minHeight: "10vh" }}  className="table-responsive tableFixHead">
                <table className="table table-sm table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>Origen</th>
                      <th>Código Comitente CV</th>
                      <th>Código CV</th>
                      <th>Descripción Instrumento</th>
                      <th>Saldo Disponible CV</th>
                      <th>Saldo {SYS_BO == "AUNE" && "AUNE"} {SYS_BO == "VISUAL_BOLSA" && "Visual Bolsa"}</th>
                      <th>Diferencia</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData
                      .filter(
                        (item) =>
                          item.codigoComitenteCV.includes(filtroCuenta) &&
                          item.codigoCV.includes(filtroCodInstr) &&
                          checkFiltroDiferencia(item.diferencia)
                      )
                      .map((item) => (
                        <tr key={`${item.origen}-${item.codigoComitenteCV}-${item.codigoCV}`}>
                          <td>{item.origen}</td>
                          <td>{item.codigoComitenteCV}</td>
                          <td>{item.codigoCV}</td>
                          <td>{item.desInstrumento}</td>
                          <td className="text-right">{formatNumero(item.saldoDisponibleCV)}</td>
                          <td className="text-right">{formatNumero(item.saldoAUNE)}</td>
                          <td className="text-right">{formatNumero(item.diferencia)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConciliaTenenciaCV;
