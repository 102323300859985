import React, { useState, useContext } from "react";
import SessionContext from "../context/SessionContext";
import { useHistory, useLocation, Link } from "react-router-dom";

import logo from "../img/logo.svg";
import logoRosval from "../img/logo-rosval.webp";
import logon from "../services/login";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { PasswordInput } from "../components/PasswordInput";
const appVer = process.env.REACT_APP_VERSION;
//const LDAP_AUTH = process.env.REACT_APP_LDAP_AUTH === "true";
const REACT_APP_INSTANCIA_AN = process.env.REACT_APP_INSTANCIA_AN === "true";


const Login = () => {
  useDocumentTitle("Login");
  const [user, setUser] = useState("");
  const [password, setPass] = useState("");
  const [loginBYMA, setLoginBYMA] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const { setTkn, setUserCategory } = useContext(SessionContext);
  let history = useHistory();
  const location = useLocation();

  const [errorDetail, setErrorDetail] = useState(location.state?.detail);

  //console.log({ errorDetail });

  const loguear = async (event) => {
    event.preventDefault();
    //console.log(user, password);
    if(!user || !password) return;
    setIsLogin(true);
    const res = await logon({ user: user.trim(), password: password.trim(), loginBYMA });

    const { partialLogin, login, tkn, status, userCategory } = res;
    if (login) {
      localStorage.setItem("tkn", tkn);
      localStorage.setItem("userCategory", userCategory);
      setUserCategory(userCategory);
      setTkn(tkn);
      if (partialLogin) {
        history.push("/menu", {detail: partialLogin});
      } else {
        history.push("/menu");
      }
    } else {
      setErrorDetail(status);
      setIsLogin(false);
    }
  };

  return (
    <div   className="container text-center  mt-3">
      <div className="row justify-content-center">
        {errorDetail && (
          <div className="col-8 center">
            <div className="alert alert-danger">{errorDetail}</div>
          </div>
        )}
      </div>
      <div style={{"height": "70vh" }} className="row d-flex justify-content-center align-items-center">
        <div className="col-lg-4 col-md-4 "></div>
        <div className="col">
          <form onSubmit={loguear} className="form-signin" action="" method="post">
            <img className="mb-4" src={REACT_APP_INSTANCIA_AN ? logoRosval : logo} alt="" width="200" height="200" ></img>

            <label htmlFor="inputUser" className="sr-only">
              Usuario
            </label>
            <input
              name="user"
              type="text"
              id="inputUser"
              className="form-control"
              placeholder="Usuario"
              onChange={(e) => {
                setUser(e.target.value);
              }}
              required
            ></input>
            <label htmlFor="inputPassword" className="sr-only">
              Password
            </label>

            <PasswordInput 
             name="pass"
             id="inputPassword"
             className="form-control"
       
             onChange={(e) => {
               setPass(e.target.value);
             }}
         
      
            />

            <button disabled={isLogin} style={REACT_APP_INSTANCIA_AN ? {"backgroundColor": "#6fa185", "borderColor": "#6fa185"} : {}} className="btn btn btn-primary btn-block mt-3" type="submit">
              {!isLogin ? "Login" : "Logueando..."}
            </button>
         {!REACT_APP_INSTANCIA_AN && <>
            <input
              type="checkbox"
              className="form-check-input "
              onChange={(e) => {
                setLoginBYMA(!loginBYMA);
              }}
              id="exampleCheck1"
            />
            <label className="form-check-label ml-1 " htmlFor="exampleCheck1">
              Loguea a BYMA
              
            </label>
            </>
            }
     

          </form>
  
            {
             loginBYMA &&
            <div className="alert alert-sm alert-warning mt-3">Recuerde ingresar la contraseña del usuario de BYMA asociado a <b>{user}</b></div>
           }
          <Link to={ REACT_APP_INSTANCIA_AN ? "/login" : "/about"}>    <p className="mt-5 mb-3 text-muted">&copy; {new Date().getFullYear()} - v{appVer} </p> </Link>
        </div>
        <div className="col-lg-4 col-md-4 ">
    
        </div>
      </div>
    </div>
  );
};

export default Login;
