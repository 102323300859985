import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faEdit } from "@fortawesome/free-solid-svg-icons";


const Li = ({ type = "strong", leyenda, valor = 0 }) => {
  const formatNumero = (numero) => {
    if(numero == 0)  return numero
    if (!numero) return "";
    return (numero * 1).toLocaleString();
  };

  return (
    <>
      {type == "strong" && (
        <li className="list-group-item d-flex justify-content-between bg-light">
          <div>
            <h6 className="my-0">{leyenda}</h6>
          </div>
          <strong>{formatNumero(valor)}</strong>
        </li>
      )}
      {type == "light" && (
        <li className="list-group-item d-flex justify-content-between lh-condensed">
          <div>
            <small className="text-muted">{leyenda}</small>
          </div>
          <span id="sdos_inicio" className="text-muted ">
            {formatNumero(valor)}
          </span>
        </li>
      )}
    </>
  );
};

const SaldosPanelMAV = ({ moneda, saldos = {},  isLoading = false, reloadFunc = false, saldosDerivaciones, saldosDerivacionesESCO = [] }) => {
  const [showDatallado, setShoDetallado] = useState(false)
  const formatNumero = (numero) => {
    if (!numero) return "";
    return (numero * 1).toLocaleString();
  };
 //console.log({saldosDerivacionesESCO})
  const mon_dicc = { P: "Pesos", D: "Dólares", C: "Dólar Transferencia" };
  const diccCodMon = {'P': '$', 'D': 'U$S', 'C': 'Dólar Transferencia'}
  const diccCodMonDerivESCO = {'P': 'Pesos', 'D': 'Dólar'}
  const monendaESCO = diccCodMonDerivESCO[moneda];
  
  let saldoDerivaHoy = 0;
  let saldoDerivaDant = 0;

  const listadoSaldosDerivDepESCO = saldosDerivacionesESCO.filter( item => item.moneda == monendaESCO)
  if(listadoSaldosDerivDepESCO.length > 0) {
    listadoSaldosDerivDepESCO.forEach( item => saldoDerivaHoy+= item.sumaMontoNetoDerivable*-1)
  } else {
  if(saldosDerivaciones.hoy) {
    if(saldosDerivaciones.hoy[diccCodMon[moneda]]) {
      Object.entries(saldosDerivaciones.hoy[diccCodMon[moneda]]).forEach( ([operat, saldo]) => { saldoDerivaHoy+= saldo*-1; } )
    }
  }
  if(saldosDerivaciones.dAnt) {
    if(saldosDerivaciones.dAnt[diccCodMon[moneda]]) {
      Object.entries(saldosDerivaciones.dAnt[diccCodMon[moneda]]).forEach( ([operat, saldo]) => { saldoDerivaDant+= saldo.importeNetoTotal*-1; } )
    }
  }
  }
  
 
  const leyBtn = showDatallado ? "-" : "+";
  const handleReload = async () => {
  if(reloadFunc) {
    reloadFunc();
  }
   
  
  }
  let saldoInicio = 0;
  let movDiasAnt = [];
  let movHoy = [];
  let saldoFinal = 0;
  let saldoAntsCI = 0;

  if(saldos[moneda]) {
    const saldoMoneda = saldos[moneda];
    saldoInicio = saldoMoneda.saldoInicio;
    saldoAntsCI = saldoMoneda.saldoAntsCI;
    movDiasAnt = saldoMoneda.movDiasAnt;
    movHoy = saldoMoneda.movHoy;
    saldoFinal = saldoMoneda.saldoFinal;

  }



  
  //console.log({saldosAntesCI , saldosCIMoneda ,movBancos, saldoCaucion,movGtias})
  return (
    <div className="col-lg-4 col-sm-6 col-md-6">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <strong>Saldos en {mon_dicc[moneda]} </strong> - {new Date().toLocaleDateString()}
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 d-sm-none d-md-block ">

              <button
                type="button"
                onClick={() => setShoDetallado(!showDatallado)}
                className="btn btn-secondary btn-sm float-right ml-1"
              >
                <b>{leyBtn}</b>
              </button>
              <button type="button" onClick={() => handleReload() } name="" disabled={isLoading} className={!isLoading ? "btn btn-secondary float-right btn-sm mr-1" : "btn btn-secondary float-right btn-sm mr-1 disabled"}>
                <FontAwesomeIcon icon={faSync} /> 
              </button>
   

            </div>
          </div>
        </div>
        <div className="card-body">
          {showDatallado && (
            <ul className="list-group ">
              <Li type="strong" leyenda={"Saldos al inicio"} />
              <Li type="light" leyenda={"CCyL MAV Inicio"} valor={saldoInicio} />
              <Li type="strong" leyenda={"Dias anterior liquida hoy"} valor={null} />
              {movDiasAnt.map(item =>    <Li type="light" leyenda={item.concepto} valor={item.saldo} /> )}
              <Li type="strong" leyenda={"Saldo antes de CI"} valor={saldoAntsCI} />
              <Li type="strong" leyenda={"Movimientos hoy"} valor={null} />
              {movHoy.map(item =>    <Li type="light" leyenda={item.concepto} valor={item.saldo} /> )}

              <Li
                type="strong"
                leyenda={"Final"}
                valor={saldoFinal}
              />

          <Li type="strong" leyenda={"Derivaciones"} valor={null} />
             { listadoSaldosDerivDepESCO.length > 0 ? listadoSaldosDerivDepESCO.map( item =>  <Li type="light" leyenda={item.denomSocDepositaria} valor={item.sumaMontoNetoDerivable*-1} /> )
              : 
              <>
              <Li type="light" leyenda={"Dias Ant."} valor={saldoDerivaDant} />
              <Li type="light" leyenda={"Hoy"} valor={saldoDerivaHoy} / >
              </> 
            }
      
                         <Li
                type="strong"
                leyenda={"Final con derivaciones"}
                valor={saldoFinal+saldoDerivaDant+saldoDerivaHoy}
              />
            </ul>
          )}

          {!showDatallado && (
            <ul className="list-group">
              <Li type="strong" leyenda={"Saldos al inicio"} valor={saldoInicio} />
              <Li type="strong" leyenda={"Saldo antes de CI"} valor={saldoAntsCI} />
              <Li
                type="strong"
                leyenda={"Final"}
                valor={saldoFinal}
              />
                            <Li
                type="strong"
                leyenda={"Saldo Derivable"}
                valor={saldoDerivaDant+saldoDerivaHoy}
              />
                                  <Li
                type="strong"
                leyenda={"Final con derivaciones"}
                valor={saldoFinal+saldoDerivaDant+saldoDerivaHoy}
              />
            </ul>
            
          )}
        </div>
      </div>
    </div>
  );
};

export default SaldosPanelMAV;
