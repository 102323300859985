
const baseURL = process.env.REACT_APP_API_URL;

const getNominalesProyectadoAUNEAN = async (options, sumaCOMAFI = true, alycsP = false) => {
  let json =  []
  try {
    const res = await fetch(`${baseURL}AUNE/nominalesProyectadoAN?sumaCOMAFI=${sumaCOMAFI == true ? 'true' : 'false'}&alycsP=${alycsP == true ? 'true' : 'false'}`, options);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export default getNominalesProyectadoAUNEAN;
