import React, { useState, useEffect } from "react";
import Paneldheader from "../components/PanelHeader";
import Plazotable from "../components/Plazotable";
import SaldosPanel from "../components/SaldosPanel";
import CaucionTable from "../components/CaucionTable";
import OpcionesTable from "../components/OpcionesTable";
//import getResumenOperdiaContado from "../services/getResumenOperdiaContado";
//import getResumenOperdiaCaucion from "../services/getResumenOperdiaCaucion";
//import getResumenOperdiaOpciones from "../services/getResumenOperdiaOpciones";
//import getSaldosBYMA from "../services/getSaldosBYMA";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";

const hasSENEBI = process.env.REACT_APP_BYMA_SENEBI  === "true";


const PanelOperaciionesAN = () => {
  useDocumentTitle('Panel de Operaciones BYMA')
  const [infoCI, setInfoCI] = useState([]);
  const [info24hs, setInfo24hs] = useState([]);
  const [info48hs, setInfo48hs] = useState([]);
  const [infoCISB, setInfoCISB] = useState([]);
  const [info24hsSB, setInfo24hsSB] = useState([]);
  const [info48hsSB, setInfo48hsSB] = useState([]);
  const [moneda, setMoneda] = useState("P");
  const [cuentaOper, setCuentaOper] = useState(0);
  const [showCI, setShowCI] = useState(true);
  const [show24hs, setShow24hs] = useState(true);
  const [show48hs, setShow48hs] = useState(true);
  const [tablaCaucion, setTablaCaucion] = useState({});
  const [tablaOpciones, setTablaOpciones] = useState({});
  const [hayEjercicios, setHayEjercicios] = useState(false);
  const [hayVC, setHayVC] = useState(false)
  const [showPanelSENEBI, setShowPanelSENEBI] = useState(false);
  const [isLoading, setIsLoading]  = useState(false);
  const [horaUltimaOperacion, setHoraUltimaOperacion] = useState("");
  const [horaUltimaOperacionCI, setHoraUltimaOperacionCI] = useState("");
  const [origin, setOrigin] = useState("N/D")
  const [cantidadOpersCI, setCantidadOpersCI] = useState(0);
  const [cantidadOperTotal, setCantidadOperTotal] = useState(0);
  const [cantidadOpersCaucion, setCantidadOpersCaucion] = useState(0);
  const [horaUltimaOperacionCaucion, setHoraUltimaOperacionCaucion] = useState("");
  const [isLoadingContado, setisLoadingContado] = useState(false);
  const [isLoadingCauciones, setisLoadingCauciones] = useState(false);
  const [isLoadingOpciones, setisLoadingOpciones] = useState(false);
  

  let info_procesada;

  const {getResumenOperdiaContadoAN, getResumenOperdiaCaucionAN, getResumenOperdiaOpcionesAN, getResumenOperdiaContadoSBAN} = useServices();

  useEffect(() => {
    initPanel();
  }, []);

  async function carga_oper_gara() {
    setisLoadingContado(true);
    let operdia = await getResumenOperdiaContadoAN();
    if(operdia.error) return

    setisLoadingContado(false);

    info_procesada = operdia;

    setCuentaOper(info_procesada.cantOper);
    //console.log({ info_procesada });
    setHayEjercicios(info_procesada.hayEjercicios)
    setHayVC(info_procesada.hayVC)
    
    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCI(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hs(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hs(info_procesada.tabla["48hs"]);
      }
    }
    if(info_procesada.horaUltimaOperacionCI) {
      setHoraUltimaOperacionCI(info_procesada.horaUltimaOperacionCI)
    }
    if(info_procesada.horaUltimaOperacion) {
      setHoraUltimaOperacion(info_procesada.horaUltimaOperacion)
    }
    if(info_procesada.origen) {
      setOrigin(info_procesada.origen)
    }
    if(info_procesada.cantidadOpersCI) {
      setCantidadOpersCI(info_procesada.cantidadOpersCI)
    }
    if(info_procesada.cantidadOperTotal) {
      setCantidadOperTotal(info_procesada.cantidadOperTotal)
    }

    return true;
  }

  async function carga_oper_gara_SB() {
    let operdia = await getResumenOperdiaContadoSBAN();
    if (operdia.error) return;

    const info_procesada = operdia;

    //setCuentaOper(prev => prev + info_procesada.cantOper);
    //setCuentaOper(info_procesada.cantOper);
    //console.log({ info_procesada });
    
    if(info_procesada.cantOper > 0) { setShowPanelSENEBI(true) }
    
    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCISB(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hsSB(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hsSB(info_procesada.tabla["48hs"]);
      }

    }
    
    return true;
  }


  async function initPanel() {

    await carga_oper_gara();
    await cargaResumenCaucGara();
    await cargaResumenOpc();

    if(hasSENEBI) { await carga_oper_gara_SB() }

  }

  async function reloadContado () {
    setIsLoading(true)
    await carga_oper_gara();
    if(hasSENEBI) { await carga_oper_gara_SB() }
    setIsLoading(false)
  }





  async function cargaResumenCaucGara() {
    setisLoadingCauciones(true);
    const resumenCaucion = await getResumenOperdiaCaucionAN();
    if(resumenCaucion.error) return
    setisLoadingCauciones(false);
    //console.log({ resumenCaucion });

    if (resumenCaucion.tabla) {
      setTablaCaucion(resumenCaucion.tabla);
    }
    if(resumenCaucion.cantidadOpers) {
      setCantidadOpersCaucion(resumenCaucion.cantidadOpers)
    }
    if(resumenCaucion.horaUltimaOperacion) {
      setHoraUltimaOperacionCaucion(resumenCaucion.horaUltimaOperacion)
    }
    return true;
  }

  async function cargaResumenOpc() {
    setisLoadingOpciones(true);
    const resumenOpciones = await getResumenOperdiaOpcionesAN();
    if(resumenOpciones.error) return
    setisLoadingOpciones(false);
    ////console.log(resumenOpciones)

    if (resumenOpciones.tabla) {
      setTablaOpciones(resumenOpciones.tabla);
    }
    return true;
  }

  const showhidePanel = (idpanel) => {
    switch (idpanel) {
      case "CI":
        setShowCI(!showCI);
        break;
      case "24hs":
        setShow24hs(!show24hs);
        break;
      case "48hs":
        setShow48hs(!show48hs);
        break;
      case "SENEBI":
        setShowPanelSENEBI(!showPanelSENEBI);
        break;
    }
  };


  return (
    <div className="container-fluid">

      <Paneldheader
        showhidePanel={showhidePanel}
        setMoneda={setMoneda}
        moneda={moneda}
        cuentaOper={cuentaOper}
        cargaContado={reloadContado}
        cargaCauciones={cargaResumenCaucGara}
        cargaOpciones={cargaResumenOpc}
        hayEjercicios={hayEjercicios}
        hayVC={hayVC}
        panelShow = {{showCI, show24hs, show48hs, showPanelSENEBI}}
        horaUltimaOperacion={horaUltimaOperacion}
        horaUltimaOperacionCI={horaUltimaOperacionCI}
        opersOrigin={origin}
        cantidadOpersCI={cantidadOpersCI}
        cantidadOperTotal={cantidadOperTotal}
        cantidadOpersCaucion={cantidadOpersCaucion}
        horaUltimaOperacionCaucion={horaUltimaOperacionCaucion}
        isLoadingContado={isLoadingContado}
        isLoadingCauciones={isLoadingCauciones}
        isLoadingOpciones={isLoadingOpciones}
      
      />

      <div className="row no-gutters">
      {showCI && <div className="col-lg col-sm-12 col-md-12">
            <Plazotable showFooter={true} moneda={moneda} titulo="CI" datos={infoCI} ALYC_INTEGRAL={false} />
        </div>}
        {show24hs && <div className="col-lg col-sm-12 col-md-12">
         <Plazotable showFooter={true} moneda={moneda} titulo="24hs" datos={info24hs} ALYC_INTEGRAL={false} />
        </div>}

      </div>

      <div className="row no-gutters">
        <CaucionTable moneda={moneda} datos={tablaCaucion} /> <OpcionesTable datos={tablaOpciones} />
      </div>
      {  (hasSENEBI && showPanelSENEBI) &&    <div className="row no-gutters">
        {showCI && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable  showFooter={true} moneda={moneda} titulo="CI - SENEBI" datos={infoCISB} ALYC_INTEGRAL={false} />
          </div>
        )}
        {show24hs && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable  showFooter={true} moneda={moneda} titulo="24hs - SENEBI" datos={info24hsSB} ALYC_INTEGRAL={false} />
          </div>
        )}
 
      </div>}
    </div>
  );
};

export default PanelOperaciionesAN;
