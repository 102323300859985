import React, { useState, useEffect } from "react";

import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
import SaldosControlAN from "../components/SaldosControlAN";

const baseURL = process.env.REACT_APP_API_URL;
const ALYC_INTEGRAL = process.env.REACT_APP_ALYC_INTEGRAL === 'true';

const ControlSaldosPerfilAN = () => {
  useDocumentTitle("Control de saldos - AN");
  const [dataSaldos, setDataSaldos] = useState({});
  const {getSaldosProyectadosAUNEAN, getUploadedStatusSaldosAUNE} = useServices();
  const [isLoading, setIsLoading] = useState(false);
  const [alycsP, setAlycsP] = useState(false);
  const [sumaPendientes, setSumaPendientes] = useState(true);
  const [uploaded, setUploaded] = useState(false);
  
  async function reloadPanel() {

   
    setIsLoading(true)
    console.log("reloadPanel:", sumaPendientes)
    const res = await getSaldosProyectadosAUNEAN(alycsP, sumaPendientes);
    setIsLoading(false)
    if(res.error) return;
    setDataSaldos(res)
    
  }



   function handleSumaPendientes () {
    setSumaPendientes(prev => !prev)
   } 

  useEffect(() => {

  reloadPanel()
  //
  }, [alycsP, sumaPendientes])
  

  return (
    <div className="container-fluid">
      <div className="row no-gutters">
        <div className="col-12">

            <div className="card-header">
              <div className="row">
                <div className="col-5" style={{display: "flex", gap: "10px"}}>




             
           
          
                </div>
         
              <div className="col">

              <span className="float-right input-group-text form-control-sm ml-1"> <b>{dataSaldos["ultimaOperacionInmediato"] ? `Últ. Op. CI: ${dataSaldos["ultimaOperacionInmediato"]}` : `Últ. Op. CI: No disponible`}</b></span>
              { ALYC_INTEGRAL && <>
                <span className="float-right input-group-text form-control-sm ml-1">Suma Sdos. Pendientes <input onChange={handleSumaPendientes} checked={sumaPendientes} className="ml-1" type="checkbox"  /> </span>

               
                </> }
                </div>

              </div>
          </div>
        </div>
      </div>
      <div className="row no-gutters">
        <SaldosControlAN  reloadPanel={reloadPanel} isLoading={isLoading} initBodyVisb={true} data={dataSaldos["Pesos"] || [] } moneda={"Pesos"} />
        <SaldosControlAN reloadPanel={reloadPanel} isLoading={isLoading} initBodyVisb={true} data={dataSaldos["Dólar"] || [] } moneda={"Dólar"} />
        <SaldosControlAN reloadPanel={reloadPanel} isLoading={isLoading} initBodyVisb={true} data={dataSaldos["Dólar Transferencia"] || [] } moneda={"Dólar Transferencia"} />
      </div>
    </div>
  );
};

export default ControlSaldosPerfilAN;
