import React, { useEffect, useState } from "react";
import SaldosCCBOP from "../components/SaldosCCBOP";
//import getSaldosBOP from "../services/getSaldosBOP";
import FileUploadButton from "../components/FileUploadButton"
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
const baseURL = process.env.REACT_APP_API_URL;
const ALYC_DENOMINACION = process.env.REACT_APP_ALYC_DENOMINACION;


const CtasProp = [
  11030200002, 11030200009, 11030200012, 11030200020, 11030200026, 11030300002, 11030300005, 11030300029, 11030300032,
  11030300040, 11030400001, 11030800006, 11030800010, 11030200008, 11030200014, 11030200015, 11030300004, 11030300006,
  11030300009, 11030400002, 11030800005, 11030200070, 11030300058, 11030200074,
];

const ctasCorrientesOmitidas = [11030100005, 11030200013, 11030200071]

const SaldosBOP = () => {
  useDocumentTitle('Saldos AUNE')
  const [fechaT0, setFechaT0] = useState("");
  const [fechaT1, setFechaT1] = useState("");
  const [tablaCCARS, setTablaCCARS] = useState([]);
  const [tablaCCUSD, setTablaCCUSD] = useState([]);
  const [tablaCCUSDMTR, setTablaCCUSDMTR] = useState([]);
  const [tablaCCARSTerceros, setTablaCCARSTerceros] = useState([]);
  const {getSaldosBOP} = useServices();

  const extreCuentasCorrientes = (saldos, moneda) => {
    const cuentasOmitidas = ["[MACRO $ EXENTA] Banco MACRO $ Cuenta EXENTA", "[BYMA] Bolsas y Mercados Argentinos S.A.", "[BIND PESOS EXENTA] Banco BIND PESOS Cuenta EXENTA", "[ROFX1360] MATBA ROFEX S.A. - CCL (Operaciones de terceros)", "[MACRO USD] Banco MACRO Cuenta USD" ]
    return saldos.filter((item) => item.unidad == moneda && item.uso == "GRAL" && item.cuenta && !cuentasOmitidas.includes(item.cuenta) );
  };
  const findCuentaByCuenta = (saldos, cuenta) => {
    return saldos.find((item) => item.cuenta == cuenta);
  };

  const dividePropiasTerceros = (tabla) => {
    let tablaTerceros = [];
    let tablaPropias = [];

    tabla.forEach((item) => {
      if (CtasProp.indexOf(item.codigo * 1) == -1) {
        tablaTerceros.push(item);
      } else {
        tablaPropias.push(item);
      }
    });

    return { tablaTerceros, tablaPropias };
  };

  const matchPlazos = (saldosT0, saldosT1) => {
    let tabla = [];
    saldosT0.forEach((element) => {
      const {codCuenta: codigo, cuenta, total:saldoMonOrig, unidad:moneda} = element;
      let saldoMonOrigT1 = findCuentaByCuenta(saldosT1, cuenta) || 0;
      if (saldoMonOrigT1 != 0) {
        saldoMonOrigT1 = saldoMonOrigT1.total;
      }
      tabla.push({
        codigo,
        cuenta,
        saldoMonOrig,
        moneda,
        saldoMonOrigT1,
        diff: saldoMonOrigT1 - saldoMonOrig,
      });
    });

    return tabla;
  };

  async function cargaDatos() {
    const saldosBOP = await getSaldosBOP();
    if(saldosBOP.error) return

    //console.log({ saldosBOP });
    const cuentasCorrientesARSt0 = extreCuentasCorrientes(saldosBOP.t0.saldos, "ARS");
    const cuentasCorrientesARSt1 = extreCuentasCorrientes(saldosBOP.t1.saldos, "ARS");
    const tablaCCARS = matchPlazos(cuentasCorrientesARSt0, cuentasCorrientesARSt1);
    //console.log({ tablaCCARS });
    tablaCCARS.sort((a, b) => b.saldoMonOrig - a.saldoMonOrig);
    setTablaCCARS(tablaCCARS);

    const cuentasCorrientesUSDt0 = extreCuentasCorrientes(saldosBOP.t0.saldos, "USD");
    const cuentasCorrientesUSDt1 = extreCuentasCorrientes(saldosBOP.t1.saldos, "USD");
    const tablaCCUSD = matchPlazos(cuentasCorrientesUSDt0, cuentasCorrientesUSDt1);
    //console.log({ tablaCCUSD });
    tablaCCUSD.sort((a, b) => b.saldoMonOrig - a.saldoMonOrig);
    setTablaCCUSD(tablaCCUSD);

    const cuentasCorrientesUSDMTRt0 = extreCuentasCorrientes(saldosBOP.t0.saldos, "USDL");
    const cuentasCorrientesUSDMTRt1 = extreCuentasCorrientes(saldosBOP.t1.saldos, "USDL");
    const tablaCCUSDMTR = matchPlazos(cuentasCorrientesUSDMTRt0, cuentasCorrientesUSDMTRt1);
    //console.log({ tablaCCUSDMTR });
    tablaCCUSDMTR.sort((a, b) => b.saldoMonOrig - a.saldoMonOrig);
    setTablaCCUSDMTR(tablaCCUSDMTR);






  }




  useEffect(() => {
    cargaDatos();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {" "}
          <div className="card-header">
            {" "}
            <div className="row">
              <div className="col-3">
            <b>Saldos T+0:</b> {fechaT0} | <b>Saldos T+1:</b> {fechaT1}  
            </div> 
             <div className="col-9">
               <div className="row">
                 <div className="col-6 align-items-center ">
            <span> <FileUploadButton endPoint={`${baseURL}upload/uploadSaldos`} fileName={'sdp1.xlsx'} label={'Cargar archivo T+0'} callback={cargaDatos} /></span>
            </div>
            <div className="col-6 align-items-center" >
            <span><FileUploadButton endPoint={`${baseURL}upload/uploadSaldos`} fileName={'sdp2.xlsx'} label={'Cargar archivo T+1'} callback={cargaDatos} /></span>
            </div>
            </div>
            </div>
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col">
        <div className="row">
            <div className="col-12">
              <SaldosCCBOP
                title="Cuentas Corrientes - ARS"
                datos={tablaCCARS}
                initFiltro={ALYC_DENOMINACION}
                initBodyVisb={true}
              />
            </div>
            <div className="col-12">
        
            </div>
          </div>
         
        </div>
        <div className="col">
        <SaldosCCBOP
                title="Cuentas Corrientes - USD"
                datos={tablaCCUSD}
                initFiltro={ALYC_DENOMINACION}
                initBodyVisb={true}
              />
        </div>
      
      </div>
      <div className="row no-gutters">
        <div className="col">
          <SaldosCCBOP title="Cuentas Corrientes - ARS" datos={tablaCCARS} initBodyVisb={false} />
        </div>
        <div className="col">
          <SaldosCCBOP title="Cuentas Corrientes - USD" datos={tablaCCUSD} initBodyVisb={false} />
        </div>
      
      </div>
      <div className="row no-gutters">
        <div className="col">
     
        </div>
        <div className="col">
          <SaldosCCBOP title="Cuentas Corrientes - USD MTR" datos={tablaCCUSDMTR} initBodyVisb={false} />
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col">

        </div>
        <div className="col">
{/*         <div className="row">
            <div className="col-12">
              <SaldosCCBOP
                title="Cuentas Corrientes - ARS"
                datos={tablaCCARS}
                initFiltro={"BOGGIONE, Fernando"}
                initBodyVisb={false}
              />
            </div>
            <div className="col-12">
              <SaldosCCBOP
                title="Cuentas Corrientes - USD"
                datos={tablaCCUSD}
                initFiltro={"BOGGIONE, Fernando"}
                initBodyVisb={false}
              />
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
};

export default SaldosBOP;
