
const baseURL = process.env.REACT_APP_API_URL;

const getRG624 = async (options, tipo) => {
    let json = [];
    try {
      const res = await fetch(`${baseURL}rg624/${tipo}`, options);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }

    return json;
  }

/* const getRG624 = {
  Contado: (options) => templateReq("Contado", options),
  Opciones: (options) => templateReq("Opciones", options),
  Cauciones: (options) => templateReq("Cauciones", options),
}; */

export default getRG624;
