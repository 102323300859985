import React, { useState, useEffect } from "react";

import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";

import SaldosTrader from "../components/SaldosTrader";
import FileUploadButton from "../components/FileUploadButton";
const baseURL = process.env.REACT_APP_API_URL;

const ControlSaldosVB = () => {
  useDocumentTitle("Control de saldos BYMA - VB");
  const [infoCI, setInfoCI] = useState([]);
  const [info24hs, setInfo24hs] = useState([]);
  const [info48hs, setInfo48hs] = useState([]);
  const [moneda, setMoneda] = useState("P");
  const [cuentaOper, setCuentaOper] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [saldosCI, setSaldosCI] = useState({});
  const [saldosCaucion, setSaldosCaucion] = useState({});
  const [tablaCaucion, setTablaCaucion] = useState({});
  const [saldosBOP, setSaldosBOP] = useState([]);
  //const [info_procesada, setInfoprocesada] = useState({})

  let info_procesada;

  const { getResumenOperdiaContado, getResumenOperdiaCaucion, getSaldosVBCC } = useServices();

  useEffect(() => {
    initPanel();
  }, []);

  async function cargaSaldosVB() {
    const res = await getSaldosVBCC();
    if (res.error) return;
    setSaldosBOP(res);
  }

  async function carga_oper_gara() {
    let operdia = await getResumenOperdiaContado();
    if (operdia.error) return;

    info_procesada = operdia;

    setCuentaOper(info_procesada.cantOper);

    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCI(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hs(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hs(info_procesada.tabla["48hs"]);
      }
    }
    if (info_procesada.saldos) {
      setSaldosCI(info_procesada.saldos["Inmediato"]);
    }
    return true;
  }

  async function initPanel() {
    setIsLoading(true)
    cargaSaldosVB();
    await carga_oper_gara();
    await cargaResumenCaucGara();
    setIsLoading(false)
  }
  async function reloadPanel() {
    setIsLoading(true)
    await carga_oper_gara();
    await cargaResumenCaucGara();
    setIsLoading(false)
  }

  async function cargaResumenCaucGara() {
    const resumenCaucion = await getResumenOperdiaCaucion();
    if (resumenCaucion.error) return;
    //console.log({ resumenCaucion });
    if (resumenCaucion.saldos) {
      setSaldosCaucion(resumenCaucion.saldos);
    }
    if (resumenCaucion.tabla) {
      setTablaCaucion(resumenCaucion.tabla);
    }
    return true;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header ">
              <div className="row">
                <div className="col">  {isLoading && <span className="btn btn-sm btn-success">Cargando...</span>} </div>
                <div className="col">
                <FileUploadButton
                  endPoint={`${baseURL}upload/uploadSaldosVB`}
                  fileName={"saldosvb.xlsx"}
                  label={"Cargar archivo de Saldos (Visual Bolsa) T0"}
                  callback={cargaSaldosVB}
                />
                </div>
                <div className="col"></div>
  
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <SaldosTrader
          reloadPanel={reloadPanel}
          initBodyVisb={true}
          saldosBOP={saldosBOP}
          saldosContadoBYMA={infoCI}
          saldosCaucionBYMA={tablaCaucion}
          moneda={"P"}
        
        />
        <SaldosTrader
          reloadPanel={reloadPanel}
          initBodyVisb={true}
          saldosBOP={saldosBOP}
          saldosContadoBYMA={infoCI}
          saldosCaucionBYMA={tablaCaucion}
          moneda={"D"}
        
        />
        <SaldosTrader
          reloadPanel={reloadPanel}
          initBodyVisb={true}
          saldosBOP={saldosBOP}
          saldosContadoBYMA={infoCI}
          saldosCaucionBYMA={tablaCaucion}
          moneda={"C"}
        
        />
      </div>
    </div>
  );
};

export default ControlSaldosVB;
