import React, { useMemo, useState } from "react";
import Table from "./Table";


const TransfPorComit = ({ datos = [], fecha, initBodyVisb = false}) => {
  const formatDigit = (number) => {
    return number.toLocaleString();
  };
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
  const showhideBody = () => {
    setBodyVisible(!bodyVisible)
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
  const leyBtn = bodyVisible ? "-" : "+";


  const columns = useMemo(
    () => [
      {
        Header: "Tipo",
        accessor: "tipo",
        Cell: (props) => <span style={{ fontWeight: "bold" }}>{String(props.value)}</span>,
      },
      {
        Header: "Subcuenta",
        accessor: "subcuenta",
      },
      {
        Header: "Especie.",
        accessor: "nroEspecie",
      },
      {
        Header: "Cantidad",
        accessor: "cantidad",
        Cell: (props) => formatDigit(props.value),
      },
      {
        Header: "Contraparte.",
        accessor: "contraparte",
      },
    ],
    []
  );

  return (
    <div className="card">
      <div className="card-header grey">
        <b>Transferencias por comitente</b> - Contado Garantizado
        <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              <b>{leyBtn}</b>
            </button>
        <button type="button" name="bt_fecha" className="btn btn-secondary btn-sm float-right">
          {fecha}
        </button>
      </div>
      <div  className={cardBodyClass}>
      <div className="tableFixHead" style={{"max-height": "50vh", "min-height": "10vh"}}>
        <Table data={datos} columns={columns} />
        </div>
      </div>
    </div>
  );
};

export default TransfPorComit;
