import React from "react";

const CardTemplate = ({title, description, fecha = new Date().toLocaleDateString(), children, customBtn = null}) => {
  return (
    <div className="card">
      <div className="card-header grey">
        <span>
          <b>{title}</b> - {description}
        </span>
        <button type="button" className="btn btn-secondary btn-sm float-right">
          {fecha}
        </button>
      {  customBtn}
      </div>
      <div className="card-body">
     {children}
      </div>
    </div>
  );
};

export default CardTemplate;
