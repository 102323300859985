import React, { useEffect, useState } from "react";
import TableGarantiasyCoberturasBYMA from "../components/TableGarantiasyCoberturasBYMA";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useServices from "../hooks/useServices";




const GarantiasYCoberturasBYMATrader = () => {
  useDocumentTitle("Garantias y Coberturas - BYMA");
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const {getGaratiasyCoberturasGARATrader} = useServices();



  async function cargaData() {
    setIsLoading(true);
    const res = await getGaratiasyCoberturasGARATrader();
    if(res.error) return;
    //console.log(res)
    setTableData(res)

    setIsLoading(false);

  }


  useEffect(() => {
   cargaData()
  }, []);

  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
      <TableGarantiasyCoberturasBYMA cargaData={cargaData} objData={tableData} isLoading={isLoading} />
        </div>
      </div>

    </div>
  );
};

export default GarantiasYCoberturasBYMATrader;
