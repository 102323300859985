import React, { useRef, useState } from 'react'
import useServices from '../hooks/useServices';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faFolder } from "@fortawesome/free-solid-svg-icons";


const PosConsolDerivMAV = ({data = {}}) => {
  const tablaRef = useRef(null);
  const {distribFilesMAV} = useServices();
  const [tabla, setTabla] = useState([...data?.registros] || [])

 // console.log({tabla})


 // const tabla = data?.registros || [];
  const {aliasFondo, monedaPosicion, posicionGlobal, fechaText} = data;

  const formatNumero = (numero) => {
    if (isNaN(numero)) return "";
    if (numero == undefined) return numero;
    if (numero == " ") return numero;
    return numero.toLocaleString("es");
  };
 async function downloadZip(aliasFondo, fechaText) {
  const folderName = `${aliasFondo}_${fechaText}`
  const res = await distribFilesMAV({folderName})
  }
 
  function handleSetDetalle(id) {
    let newTabla = [...tabla]
    let found = newTabla.find(item => item.id == id);
    found.detalle = !found.detalle;

    setTabla([...newTabla])

  }
  

  return (
    <div className="card">
    <div className="card-header ">
      <div className="row">
       <div className="col-12">
       <b>{aliasFondo} - {monedaPosicion}</b>
       <button title="Descargar archivos" className="btn btn-sm btn-success ml-1  float-right" onClick={() => downloadZip(aliasFondo, fechaText)}>
           <span className='mr-2'>   Descargar archivos</span>
             <FontAwesomeIcon icon={faFolder} />
            </button>
       </div>
    
      </div>
      </div>
      <div className="card-body">
    <div style={{ maxHeight: "90vh", minHeight: "10vh" }} className=" tableFixHead">
    
          <table ref={tablaRef} className="table table-sm table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col">Fecha liquidación</th>
                <th scope="col">Comitente ESCO</th>
                <th scope="col">Fondo</th>
                <th scope="col">MAV Paga</th>
                <th scope="col">MAV Cobra</th>
                <th scope="col">Operaciones origen saldo</th>
                <th scope="col">Detalle</th>
              </tr>
            </thead>
            <tbody>
              {tabla
                .map((item, index) => (
                  <>
                  <tr key={item.id}>
                    <td>{item.fechaLiquidacion}</td>
                    <td>{item.nroComitenteESCO}</td>
                    <td>{item.denominacion}</td>
                    <td className="text-right">{formatNumero(item.mavPaga)}</td>
                    <td className="text-right">{formatNumero(item.mavCobra)}</td>
                    <td>{item.leyendaOps}</td>
                    <td><input type="button" onClick={() => handleSetDetalle(item.id)} className='btn btn-sm btn-success' value={item.detalle ? "-" : "+"} /></td>
              
                  </tr>
                  {item.detalle && Object.entries(item.montoPorTipoOp).map( ([concepto, valor]) =>  { return <tr className='bg-light'>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-right"> { valor > 0 ? formatNumero(Math.abs(valor)) : "" }</td>
                    <td className="text-right"> { valor < 0 ? formatNumero(Math.abs(valor)) : "" }</td>
                    <td>{concepto}</td>
                  </tr>} ) }
                  </>
                
                ))}
             
                  <tr><td className="text-right"><b>Posición Global:</b></td><td className="text-right">{formatNumero(posicionGlobal)}</td></tr>
            </tbody>
          </table>
        </div>
        </div>
        </div>
   
  
  )
}

export default PosConsolDerivMAV