
const baseURL = process.env.REACT_APP_API_URL;

export const delAforo = async (options, id) => {
  let json =  []
  try {
    const res = await fetch(`${baseURL}distribuciones/BYMA/listaAforos/${id}`, { method: 'DELETE', headers: options.headers });
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export const addAforo = async (options, newAforo) => {
    let json =  []
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...options.headers },
        body: JSON.stringify(newAforo)
    };
    try {
      const res = await fetch(`${baseURL}distribuciones/BYMA/listaAforos`, requestOptions);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };

  export const setAforo = async (options, Aforo) => {
    let json =  []
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...options.headers },
        body: JSON.stringify(Aforo)
    };
    try {
      const res = await fetch(`${baseURL}distribuciones/BYMA/listaAforos`, requestOptions);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };


 export const getAforos = async (options) => {
    let json =  []
    try {
      const res = await fetch(`${baseURL}distribuciones/BYMA/listaAforos`, options);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };
  

