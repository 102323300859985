import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const PanelHeader = ({ cargaContado, cuentaOper = 0, setMoneda, showhidePanel, hayEjercicios = false,  hayVC = false, panelShow, cargaCauciones, cargaOpciones, moneda,  horaUltimaOperacion = "", horaUltimaOperacionCI = "", opersOrigin = "N/D", cantidadOpersCI = 0, cantidadOperTotal = 0, cantidadOpersCaucion = 0, horaUltimaOperacionCaucion = "N/D", showContadores = true, isLoadingCauciones = false, isLoadingOpciones = false, isLoadingContado = false }) => {
  //console.log({panelShow})
  //console.log({moneda})
 // const [isLoadingContado, setisLoadingContado] = useState(false);
 // const [isLoadingCauciones, setisLoadingCauciones] = useState(false);
 // const [isLoadingOpciones, setisLoadingOpciones] = useState(false);
  const handleCargaContado = async () => {

     await cargaContado();

  }
  const handleCargaCauciones = async () => {
  
     await cargaCauciones();

  }
  const handleCargaOpciones = async () => {

     await cargaOpciones();

  }
  return (
    <div className="row">
      <div className="col-sm">
        <div className="card-header ">
          <button
            disabled={moneda == 'P'}
            type="button"
            onClick={() => {
              setMoneda("P");
            }}
            className="btn btn-secondary btn-sm"
          >
            Pesos
          </button>{" "}
          <button
           disabled={moneda == 'D'}
            type="button"
            onClick={() => {
              setMoneda("D");
            }}
            className="btn btn-secondary btn-sm ml-1"
          >
            Dolares
          </button>{" "}
          <button
           disabled={moneda == 'C'}
            type="button"
            onClick={() => {
              setMoneda("C");
            }}
            className="btn btn-secondary btn-sm ml-1"
          >
            Cable
          </button>
          <span> <b>|</b> </span>
          <button disabled={isLoadingContado} type="button" onClick={handleCargaContado} className="btn btn-secondary btn-sm ">
            Contado
            <FontAwesomeIcon className={isLoadingContado ? "loading-icon ml-1" : "ml-1" }  icon={faSync} />
          </button>
       
          <button disabled={isLoadingCauciones} onClick={handleCargaCauciones} type="button" name="" className="btn btn-secondary ml-1 btn-sm">
            Cauciones <FontAwesomeIcon className={isLoadingCauciones ? "loading-icon" : "" } icon={faSync} />
          </button>
          <span> </span>
        {cargaOpciones &&  <button disabled={isLoadingOpciones} onClick={handleCargaOpciones} type="button" name="" className="btn btn-secondary btn-sm ">
            Opciones <FontAwesomeIcon className={isLoadingOpciones ? "loading-icon" : "" } icon={faSync} /> 
          </button> }
          <span id="cant_oper_opc"> </span>
        
          <div role="group" className="btn-group  btn-group-toggle float-right">
         
         
            <button
              onClick={() => {
                showhidePanel("CI");
              }}
              type="checkbox"
              aria-pressed="false"
              autocomplete="off"
              className={panelShow.showCI ? "btn btn-secondary btn-sm active" : "btn btn-secondary btn-sm"}
              
            >
              CI
            </button>
      
            <button
              onClick={() => {
                showhidePanel("24hs");
              }}
              type="checkbox"
              aria-pressed="false"
              autocomplete="off"
              className={panelShow.show24hs ? "btn btn-secondary btn-sm active" : "btn btn-secondary btn-sm"}
            >
              24hs
            </button>
     
            <button
              onClick={() => {
                showhidePanel("SENEBI");
              }}
              type="checkbox"
              aria-pressed="true"
              autocomplete="off"
              className={panelShow.showPanelSENEBI ? "btn btn-secondary btn-sm active ml-1" : "btn btn-secondary btn-sm ml-1"}
            >
              SENEBI
            </button>
            
          </div>
          { hayEjercicios &&
          <Link to="/ejercicios">
          <button className="btn btn-sm btn-warning float-right mr-1">Hay ejercicios</button>
          </Link>
          }
                    { hayVC &&
          <Link to="/vc">
          <button className="btn btn-sm btn-danger float-right mr-1">Hay venta en corto</button>
          </Link>
          }
          
    
         {showContadores && <>        
          <span title="Hora Ultima Operación Cauciones" className="float-right ml-1 input-group-text form-control-sm mr-2"> <b>Últ. Op. Cauc.: {horaUltimaOperacionCaucion ? horaUltimaOperacionCaucion : "N/D"} {`(${cantidadOpersCaucion})`}</b></span>
          <span title="Hora Ultima Operación" className="float-right ml-1 input-group-text form-control-sm"> <b>Últ. Op.: {horaUltimaOperacion ? horaUltimaOperacion : "N/D"} {`(${cantidadOperTotal})`}</b></span>
          <span title="Hora Ultima Operación Contado Inmediato" className="float-right ml-1 input-group-text form-control-sm"> <b>Últ. Op. CI: {horaUltimaOperacionCI ? horaUltimaOperacionCI : "N/D"} {`(${cantidadOpersCI})`}</b></span>
          <span title="Fuente Operaciones" className="float-right ml-1 input-group-text form-control-sm"> <b>Origen: {opersOrigin == "SDIB" ? "SDIB+GARA" : opersOrigin}</b></span>
          </> 
  
          }
        </div>
      </div>
    </div>
  );
};

export default PanelHeader;
