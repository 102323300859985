import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faEdit } from "@fortawesome/free-solid-svg-icons";
const REACT_APP_SYS_BO = process.env.REACT_APP_SYS_BO;

const SaldosTrader = ({
  moneda = "P",
  saldosBOP = [],
  saldosContadoBYMA = {},
  saldosContadoBYMASB = {},
  saldosCaucionBYMA = {},
  reloadPanel,
  initBodyVisb = false,
  hasColAjuste = false,
  initialAjustes = {},
  showSB = false,
  showDhs = false
}) => {
  // console.log({ saldosContadoBYMA });
  const [isLoading, setisLoading] = useState(false);
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
  const [filtro, setFiltro] = useState("");
  const [ajustes, setAjustes] = useState(initialAjustes)
  const [editAjustes, setEditAjustes] = useState(false)
 // const [showDhs, setShowDhs] = useState(initialShowDhs)
 

  const mon_dicc = { P: "Pesos", D: "Dólar", C: "Cable" };
  let mon_bop_dicc = {};
  if (REACT_APP_SYS_BO == "AUNE") {
    mon_bop_dicc = { P: "ARS", D: "USD", C: "USDC" };
  }
  if (REACT_APP_SYS_BO == "VISUAL_BOLSA") {
    mon_bop_dicc = { P: "$", C: "USD", D: "U$S" };
  }

  const formatNumero = (numero) => {
    if (!numero) return "";
    return (numero * 1).toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handleReload = async () => {
    setisLoading(true);
    await reloadPanel();
    setisLoading(false);
  };
  const borderStyle = { borderTopWidth: "2px", borderTopColor: "black" };

  let saldosCaucionBYMAMoneda = [];

  if (saldosCaucionBYMA[mon_dicc[moneda]]) {
    saldosCaucionBYMAMoneda = saldosCaucionBYMA[mon_dicc[moneda]];
  }

  //console.log({ saldosCaucionBYMAMoneda });

  let saldosBOPMoneda = [];
  saldosBOPMoneda = saldosBOP.filter((item) => item.moneda == mon_bop_dicc[moneda]);
  let saldosContadoBYMAMoneda = [];
  if (saldosContadoBYMA[moneda] != undefined) {
    saldosContadoBYMAMoneda = saldosContadoBYMA[moneda];
  }
  if (saldosContadoBYMASB[moneda] != undefined) {
    saldosContadoBYMAMoneda = [...saldosContadoBYMAMoneda, ...saldosContadoBYMASB[moneda] ];
  }


 // console.log({saldosContadoBYMASB})
  const getSdoCdo = (saldosContadoBYMAMoneda, cuenta, colKey = "nm") => {
    let sumSaldo = 0;
    const saldosCuenta = saldosContadoBYMAMoneda.filter((item) => {
      return item.cuenta == cuenta;
    });

    saldosCuenta.forEach((item) => {
      sumSaldo += item[colKey] || 0;
    });
    return sumSaldo;
  };



  const getSdocaucion = (saldosCaucionBYMAMoneda, cuenta) => {
    let sumSaldo = 0;
    const saldosCuenta = saldosCaucionBYMAMoneda.filter((item) => {
      return item.cuenta == cuenta;
    });

    saldosCuenta.forEach((item) => {
      sumSaldo += item.mc;
    });
    return sumSaldo;
  };

  saldosBOPMoneda = saldosBOPMoneda.map((item) => {
    item.cdoGdo = getSdoCdo(saldosContadoBYMAMoneda, item.cuentaAcociada);
    item.sdoCaucion = getSdocaucion(saldosCaucionBYMAMoneda, item.cuentaAcociada);
    item.dhMdoGdo = showDhs ? -getSdoCdo(saldosContadoBYMAMoneda, item.cuentaAcociada, "dhMdoCalculado") : 0;
    
    item.cierre = item.cantidad + item.cdoGdo +  item.sdoCaucion + item.dhMdoGdo;
    return item;
  });
  let cuentasUnicos = new Set([
    ...saldosContadoBYMAMoneda.map((i) => i.cuenta),
    ...saldosCaucionBYMAMoneda.map((i) => i.cuenta),
  ]);
  [...cuentasUnicos].forEach((cuenta) => {
    const found = saldosBOPMoneda.find((item) => item.cuentaAcociada == cuenta);
    if (!found) {
      let linea = {};
      linea.cuentaAcociada = cuenta;
      linea.denomCuenta = "";
      linea.cdoGdo = getSdoCdo(saldosContadoBYMAMoneda, cuenta);
      linea.sdoCaucion = getSdocaucion(saldosCaucionBYMAMoneda, cuenta);
      linea.dhMdoGdo = showDhs ? -getSdoCdo(saldosContadoBYMAMoneda, cuenta, "dhMdoCalculado") : 0;
      linea.cierre = linea.cdoGdo + linea.sdoCaucion + linea.dhMdoGdo;
      saldosBOPMoneda.push(linea);
    }
  });

  // console.log({saldosContadoBYMAMoneda})
  // let sumaContado = {};
  // saldosContadoBYMAMoneda.forEach( item => { if(sumaContado[item.cuenta] == undefined ) {sumaContado[item.cuenta] = 0} sumaContado[item.cuenta] += item.nm  } )
  // console.log({sumaContado})
  // let sumCauciones = {}
  // saldosCaucionBYMAMoneda.forEach( item => { if(sumCauciones[item.cuenta] == undefined ) {sumCauciones[item.cuenta] = 0} sumCauciones[item.cuenta] += item.mc  } )
  // console.log({sumCauciones})
  // Object.entries(sumaContado).forEach(([cuenta, valor]) => { const found = saldosBOPMoneda.find( i => i.cuentaAcociada == valor ); if(!found) {}  } )

  const sumColumn = (table, col) => {
    let sum = 0;
    table.forEach((item) => {
      if (item[col]) {
        sum += item[col];
      }
    });
    return sum;
  };

  const cardBodyClass = bodyVisible ? "card-body " : "card-body d-none";
  const leyBtn = bodyVisible ? "-" : "+";

  const showhideBody = () => {
    setBodyVisible(!bodyVisible);
  };

 if(hasColAjuste) {
 
  saldosBOPMoneda.forEach(item => {
    if(ajustes[item.cuentaAcociada] == undefined) { item.ajuste = 0} else {
     // console.log("encontre ajuste, cuenta:" ,item.cuentaAcociada)
      item.ajuste = ajustes[item.cuentaAcociada]
     // console.log("cierre:", item.cierre)
      item.cierre += ajustes[item.cuentaAcociada]
     // console.log("cierre2:", item.cierre)
    }

  })
 }

function handleUpdateAjuste (cuentaAcociada, valor) {
//  console.log(cuentaAcociada, valor)

  let newAjustes = {...ajustes};
  newAjustes[cuentaAcociada] = Number(valor);
  if( window.localStorage.getItem("ajustes")) {
    let newAjustesStorage = JSON.parse(window.localStorage.getItem("ajustes"))
    newAjustesStorage[moneda] = newAjustes;
    window.localStorage.setItem("ajustes", JSON.stringify(newAjustesStorage))
  } 
   setAjustes(newAjustes)
}
  return (
    <div className="col">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <strong>Saldos {mon_dicc[moneda]} </strong> - {new Date().toLocaleDateString()}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
            <span className="input-group" style={{justifyContent: "right"}}>
            
            <input
                placeholder="Filtro"
                onChange={(e) => {
                  setFiltro(e.target.value);
                }}
                className="form-control-sm form-control  float-right mr-2"
                type="text"
                style={{ maxWidth: "90px", minWidth: "70px" }}
              />

 
{   hasColAjuste &&  <button
                type="button"
                onClick={() => setEditAjustes( !editAjustes )}
                name=""
                disabled={isLoading}
                className={
                   "btn btn-secondary float-right btn-sm mr-1"
                }
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>}
              <button
                type="button"
                onClick={() => handleReload()}
                name=""
                disabled={isLoading}
                className={
                  !isLoading
                    ? "btn btn-secondary float-right btn-sm mr-1"
                    : "btn btn-secondary float-right btn-sm mr-1 disabled"
                }
              >
                <FontAwesomeIcon className={isLoading ? "loading-icon" : "" } icon={faSync} />
              </button>
 
             <button
                type="button"
                onClick={() => showhideBody()}
                className="btn btn-secondary btn-sm float-right ml-1"
              >
                <b>{leyBtn}</b>
              </button>
              </span>
            </div>
          </div>
        </div>
        <div className={cardBodyClass}>
          <div style={{ maxHeight: "82vh", minHeight: "10vh" }} className="tableFixHead">
            <table className="table table-sm table-bordered table-hover ">
              <thead>
                <tr>
                  <th scope="col">Cuenta</th>
                  <th scope="col">Denom.</th>
                  <th scope="col" class="text-right">
                    Inicio
                  </th>
                  <th title={ showSB ? "Contado Garantizado y SENEBI" : "Contado Garantizado"} scope="col" class="text-right">
                   { showSB ? "Cdo. Gdo. / SB" : "Cdo. Gdo." }
                  </th>
                  <th scope="col" class="text-right">
                    Caución
                  </th>
                  {showDhs && (
                    <th title="Derechos de Mercado Calculados" scope="col" class="text-right">
                      Dhs. Mdo.
                    </th>
                  )}
                  {hasColAjuste && (
                    <th scope="col" class="text-right">
                      Ajuste
                    </th>
                  )}
 

                  <th scope="col" class="text-right">
                    Cierre
                  </th>
                </tr>
              </thead>
              <tbody>
                {saldosBOPMoneda
                  .filter((item) =>
                    `${item.cuentaAcociada} ${item.denomCuenta}`.toLowerCase().includes(filtro.toLowerCase())
                  )
                  .map((item) => (
                    <tr key={item.cuentaAcociada}>
                      <td>{item.cuentaAcociada}</td>
                      <td>{item.denomCuenta}</td>
                      <td className="text-right"> {formatNumero(item.cantidad)} </td>
                      <td className="text-right"> {formatNumero(item.cdoGdo)} </td>
                      <td className="text-right"> {formatNumero(item.sdoCaucion)} </td>
                     { showDhs && <td className="text-right"> {formatNumero(item.dhMdoGdo)} </td>}
                      {hasColAjuste && <td style={{maxWidth: "120px"}}>
                        {editAjustes ? <input className="form-control-sm form-control " value={item.ajuste} onChange={(e) => handleUpdateAjuste(item.cuentaAcociada, e.target.value)} type="number" /> : formatNumero(item.ajuste)  }
                      </td> }
                      <td className={item.cierre < 0 ? "text-right alert-danger" : "text-right"}>
                        {" "}
                        {formatNumero(item.cierre)}{" "}
                      </td>
                    </tr>
                  ))}

                <tr>
                  <td style={borderStyle}></td>
                  <td style={borderStyle} className="text-right">
                    <b>Totales</b>
                  </td>{" "}
                  <td style={borderStyle} className="text-right">
                    {" "}
                    {formatNumero(
                      sumColumn(
                        saldosBOPMoneda.filter((item) => item.denomCuenta.toLowerCase().includes(filtro.toLowerCase())),
                        "cantidad"
                      )
                    )}{" "}
                  </td>
                  <td style={borderStyle} className="text-right">
                    {" "}
                    {formatNumero(
                      sumColumn(
                        saldosBOPMoneda.filter((item) => item.denomCuenta.toLowerCase().includes(filtro.toLowerCase())),
                        "cdoGdo"
                      )
                    )}{" "}
                  </td>
                  <td style={borderStyle} className="text-right">
                    {" "}
                    {formatNumero(
                      sumColumn(
                        saldosBOPMoneda.filter((item) => item.denomCuenta.toLowerCase().includes(filtro.toLowerCase())),
                        "sdoCaucion"
                      )
                    )}{" "}
                  </td>
{  showDhs &&
                  <td style={borderStyle} className="text-right">
                    {" "}
                    {formatNumero(
                      sumColumn(
                        saldosBOPMoneda.filter((item) => item.denomCuenta.toLowerCase().includes(filtro.toLowerCase())),
                        "dhMdoGdo"
                      )
                    )}
                  </td>}

                  
                  {hasColAjuste && <td  style={borderStyle} className="text-right">
               
                  </td> }
                  <td style={borderStyle} className="text-right">
                    {" "}
                    {formatNumero(
                      sumColumn(
                        saldosBOPMoneda.filter((item) => item.denomCuenta.toLowerCase().includes(filtro.toLowerCase())),
                        "cierre"
                      )
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaldosTrader;
