import React from 'react'
import TablaAforosBYMA from '../components/TablaAforosBYMA';




import useDocumentTitle from "../hooks/useDocumentTitle";

const AforosBYMA = () => {
    useDocumentTitle('Aforos BYMA')
    return (
        <div className="container-fluid">
        <div className="row">
            <div className="col">       
            <TablaAforosBYMA />
             </div>

        </div>
        </div>
    )
}

export default AforosBYMA
