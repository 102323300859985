import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";




const SaldosDerivacionesMAV = ({
  moneda = "$",
  dataCauciones = [],
  dataOpersGral = [],
  datosCPD = [],
  saldosDiaAnt = {},
  isLoading = false,
  fechaHoy = "", 
  reloadFunc 
}) => {
  const formatNumero = (numero) => {
    if (!numero) return "";
    return (numero * 1).toLocaleString();
  };

  let saldosAgrupados = {};

  let saldoCauciones = 0;
  dataCauciones.forEach( item => {
    if(item.moneda == moneda) {saldoCauciones+= item.mContado}
  } )

  let saldoOperaciones = 0;
  dataOpersGral.forEach( item => {
    if(item.moneda == moneda && item.fechLiq == fechaHoy ) {
      saldoOperaciones+= item.nm;
      if(saldosAgrupados[item.operatoria] == undefined ) { saldosAgrupados[item.operatoria] = {hoy: 0, dAnt: 0} }
      saldosAgrupados[item.operatoria].hoy += item.nm*-1;


    }
  } )
  let saldoCPD = 0;

  datosCPD.forEach( item => {
    if(item.moneda == moneda && item.fechaLiq == fechaHoy ) {saldoCPD+= item.montoNetoTotal}
  } )

  if(saldosAgrupados["Caucion"] == undefined ) { saldosAgrupados["Caucion"] = {hoy: 0, dAnt: 0} }
  saldosAgrupados["Caucion"].hoy = saldoCauciones*-1;

  if(saldosDiaAnt[moneda]) {
    Object.entries(saldosDiaAnt[moneda]).forEach( item => {
      const [operatoria, datos] = item;
      if(saldosAgrupados[operatoria] == undefined ) { saldosAgrupados[operatoria] = {hoy: 0, dAnt: 0} }
      saldosAgrupados[operatoria].dAnt += datos.importeNetoTotal*-1;

    })

  }

 const tabla = Object.entries(saldosAgrupados);


 let totales = {hoy: 0, dAnt: 0};
 tabla.forEach( ([operatoria, {hoy, dAnt}] ) => {
  totales.hoy += hoy;
  totales.dAnt += dAnt;
 } )
  

  const mon_dicc = { "$": "Pesos", "U$S": "Dólares", C: "Dólar Transferencia" };

 async function handleReload() {

  reloadFunc();

 }






  const borderStyle = { borderTopWidth: "2px", borderTopColor: "black" };
  //console.log({ saldosAntesCI, saldosCIMoneda, movBancos, movGtias });
  return (
    <div className="col-lg-4 col-sm-6 col-md-6">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12">
              <strong>Saldos Derivable en {mon_dicc[moneda]} </strong> - {fechaHoy}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 d-sm-none d-md-block ">
              <button
                type="button"
                onClick={() => handleReload()}
                name=""
                disabled={isLoading}
                className={
                  !isLoading
                    ? "btn btn-secondary float-right btn-sm mr-1"
                    : "btn btn-secondary float-right btn-sm mr-1 disabled"
                }
              >
                <FontAwesomeIcon icon={faSync} />
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-sm table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" class="text-right">
                  Saldo Hoy
                </th>
                <th scope="col" class="text-right">
                  Saldo Dias Ant.
                </th>
                <th scope="col" class="text-right">
                  Neto
                </th>
              </tr>
            </thead>
            <tbody>
              {tabla.map( ([operatoria, datos]) =>               <tr>
                <td>{operatoria}</td> <td class="text-right">{formatNumero(datos.hoy)}</td>
                <td class="text-right">{formatNumero(datos.dAnt)}</td>
                <td class="text-right">{formatNumero(datos.hoy+datos.dAnt)}</td>
              </tr> ) }

              <tr>

                
                {" "}
                <td style={borderStyle} class="text-right">
                  <b>Totales</b>
                </td>{" "}
                <td style={borderStyle} class="text-right">
                {formatNumero(totales.hoy)}
              
                </td>{" "}
                <td style={borderStyle} class="text-right">
                {formatNumero(totales.dAnt)}
            
                </td>
                <td class="text-right" style={borderStyle}>
                {formatNumero(totales.hoy+totales.dAnt)}
                  
                </td>{" "}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SaldosDerivacionesMAV;
