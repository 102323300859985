import React, { useEffect, useState } from "react";
import getTKN from "../services/getTKN";
import bsCustomFileInput from "bs-custom-file-input";

export default function FileUploadButton({ endPoint = "", fileName = null, label = "", callback = null, captionCargando = null, disabled = false }) {
  const [selectedFile, setSelectedFile] = useState();
  const [isSending, setIsSending] = useState(false);
  const leyCarga = captionCargando || "Cargando...";

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = () => {
    const formData = new FormData();
    setIsSending(true);
    if(!selectedFile) {setIsSending(false); return alert("No se cargo archivo") }
    formData.append("File", selectedFile, fileName);

    fetch(endPoint, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Bearer ${getTKN()}` },
    })
      .then((response) => response.json())
      .then((result) => {
        // console.log("Success:", result);
        setIsSending(false);
        if (callback) {
          callback(result);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error cargando el archivo");
        setIsSending(false);
      });
  };

  return (
    <>
      {/*         <label className="mr-1" for="avatar"> <b>{label}</b> </label>
      <input style={{"maxWidth" : "250px"}}  type="file" name="avatar" onChange={changeHandler}  accept=".xls,.xlsx" />{" "}
      <button disabled={isSending} className="btn btn-sm btn-secondary" onClick={handleSubmission}>
        {isSending ? leyCarga : 'Enviar'}
      </button> */}

      <div className="input-group">
        <div className="custom-file">
          <input
            type="file"
            onChange={changeHandler}
            className="custom-file-input"
            id="inputGroupFile04"
            aria-describedby="inputGroupFileAddon04"
            data-browse={"Cargar"} 
            disabled={disabled}
          />
          <label data-browse={"Cargar"} className="custom-file-label" for="inputGroupFile04">
            {label}
          </label>
        </div>
        <div className="input-group-append">
          <button
            disabled={isSending}
            className="btn btn-outline-secondary"
            type="button"
            id="inputGroupFileAddon04"
            onClick={handleSubmission}
          >
            {isSending ? "Enviando..." : "Enviar"}
          </button>
        </div>
      </div>
    </>
  );
}
