import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
const {REACT_APP_BYMA_NROAGENTE} = process.env;

const ConciliaCGVBTable = ({isLoading = false, tableData = [], soloCI = false, setSoloCI = () => {}, reloadDatos = () => {}, setTableData }) => {

    const formatNumero = (num) => {
        return num?.toLocaleString("de", {maximumFractionDigits: 99});
      };

//console.log({tableData})

function convertirFecha(fechaString) {
  // Dividir la cadena de fecha en día, mes y año
  const partes = fechaString.split('/');
  
  // Obtener el año, mes y día
  let anio = partes[2];
  let mes = partes[1];
  let dia = partes[0];
  
  // Asegurarse de que el mes y el día tengan dos dígitos
  if (mes.length === 1) {
    mes = '0' + mes;
  }
  
  if (dia.length === 1) {
    dia = '0' + dia;
  }
  
  // Concatenar el anio, mes y día en el formato YYMMDD
  const formatoYYMMDD = anio.substring(2) + mes + dia;
  
  return formatoYYMMDD;
}

function downloadTXT(txt, sufijo = "SINACNON", tipo = ".DAT") {
  let data, filename, link;
  let csv = txt;

  if (csv == null) return;
  let fecha_lg = new Date().toISOString().split("T")[0];
  filename = sufijo + "_" + fecha_lg + tipo;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = "data:text/txt;charset=utf-8," + csv;
  }
  data = encodeURI(csv);

  link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", filename);
  link.click();
}

function generaRUEDANON (diferencias = []) {
  let txt = '';
  const DICC_EQ_PLAZOS = {"Inmediato": "0", "24hs": "1", "48hs": "2"};
  const DICC_EQ_MONEDAS = {
    "Pesos": "0",
    "Dólar": "1",
    "Dólar Transferencia": "2"
  };

  diferencias.filter(item => item.origen == "GARA" && item.selected ).forEach(item => {
    // N"375056"V"000"AAPL "08445"0"0"2"24547.5"142242"N"P"N" "DMAX067A" "57517    "901243"240119"142242
    let {tipoDeOperacion, numeroBYMA, codComitente, codNeg, instrumentoCod, plazo, moneda, precio, cantidad, horaIngreso, concertacion} = item;

    codNeg = `${codNeg}${" ".repeat(5-codNeg.length)}`;
    instrumentoCod = `${"0".repeat(5-instrumentoCod.length)}${instrumentoCod}`;
    plazo = DICC_EQ_PLAZOS[plazo];
    moneda = DICC_EQ_MONEDAS[moneda];
    horaIngreso = horaIngreso.replace(/:/g, "");
    const codOperador = `DMAX${"0".repeat(3-REACT_APP_BYMA_NROAGENTE.length)}${REACT_APP_BYMA_NROAGENTE}A`
    codComitente = `${codComitente}${" ".repeat(9-codComitente.length)}`;
    const fechaOferta = convertirFecha(concertacion)

    const linea = `N"${numeroBYMA}"${tipoDeOperacion[0]}"000"${codNeg}"${instrumentoCod}"${plazo}"${moneda}"${cantidad}"${precio}"${horaIngreso}"N"P"N" "${codOperador}" "${codComitente}"${numeroBYMA}"${fechaOferta}"${horaIngreso}\r\n`;
   // console.log(linea)
    txt = txt.concat(linea);
  })
   console.log(txt)
   downloadTXT(txt, "RUEDANON")
}

function generaSINACNON (diferencias = []) {
  let txt = '';
  const DICC_EQ_PLAZOS = {"Inmediato": "0", "24hs": "1", "48hs": "2"};
  const DICC_EQ_MONEDAS = {
    "Pesos": "0",
    "Dólar": "1",
    "Dólar Transferencia": "2"
  };

  diferencias.filter(item => item.origen == "GARA" && item.selected).forEach(item => {
    // N"375056"V"000"AAPL "08445"0"0"2"24547.5"142242"N"P"N" "DMAX067A" "57517    "901243"240119"142242
    let {tipoDeOperacion, numeroBYMA, codComitente, codNeg, instrumentoCod, plazo, moneda, precio, cantidad, horaIngreso, concertacion} = item;

    codNeg = `${codNeg}${" ".repeat(5-codNeg.length)}`;
    instrumentoCod = `${"0".repeat(5-instrumentoCod.length)}${instrumentoCod}`;
    plazo = DICC_EQ_PLAZOS[plazo];
    moneda = DICC_EQ_MONEDAS[moneda];
    horaIngreso = horaIngreso.replace(/:/g, "");
    const codOperador = `DMAX${"0".repeat(3-REACT_APP_BYMA_NROAGENTE.length)}${REACT_APP_BYMA_NROAGENTE}A`
    codComitente = `${codComitente}${" ".repeat(9-codComitente.length)}`;
    const fechaOferta = convertirFecha(concertacion)

    const linea = `${numeroBYMA}"${tipoDeOperacion[0]}"000"${codNeg}"${instrumentoCod}"${plazo}"${moneda}"${cantidad}"${precio}"${horaIngreso}"N"P"N"${codOperador}" "${codComitente}"${numeroBYMA}"${fechaOferta}"${horaIngreso}\r\n`;
   // console.log(linea)
    txt = txt.concat(linea);
  })

   console.log(txt)

   downloadTXT(txt, "SINACNON");
}
function handleCheck (id) {
    let newData = [...tableData];
    let found = newData.find(item => item.id == id)
    found.selected = !found.selected;
    setTableData(newData)
}
  return (
    <div className="card">
    <div className="card-header">
        <div className="row">
            <div className="col-12"> <b>Diferencias</b> - Contado Garantizado

            <span class="badge badge-secondary ml-1">{tableData.length}</span>
            <button
                  type="button"
                  onClick={() => reloadDatos()}
                  name=""
                  className={isLoading ? "btn btn-secondary float-right btn-sm ml-2 disabled" : "btn btn-secondary float-right btn-sm ml-2"}
                >
                  <FontAwesomeIcon className={isLoading ? "loading-icon" : "" } icon={faSync} />
                </button>

            <span className="float-right ml-2 input-group-text form-control-sm">Solo CI <input onChange={ () => setSoloCI(prev => !prev)} checked={soloCI} className="ml-2" type="checkbox"  /> </span>
            
            <button className='mr-2 btn btn-sm float-right' onClick={() => generaRUEDANON(tableData.filter(item => item.plazo == "Inmediato"))}>Genera RUEDANON.DAT (CI) </button>
            <button className='mr-2 btn btn-sm float-right' onClick={() => generaSINACNON(tableData.filter(item => item.plazo != "Inmediato"))}>Genera SINACNON.DAT (24-48hs) </button>

            </div>
        </div>
    </div>
    <div className="card-body">
      {isLoading ? <h3 className="text-center">Cargando...</h3>  :  <div style={{ maxHeight: "35vh", minHeight: "10vh" }}  className="table-responsive tableFixHead">
        <table className="table table-sm table-hover table-bordered">
          <thead>
            <tr>
              <th>Origen</th>
              <th>Baja/Ejercicio en GARA</th>
              <th>Numero Operacion BYMA</th>
              <th>Código CV</th>
              <th>Instrumento Codigo</th>
              <th>Tipo Op.</th>
              <th>Código Comitente</th>
              <th>Plazo</th>
              <th>Moneda</th>
              <th>Cantidad</th>
              <th>Precio</th>
              <th>Archivo</th>

            </tr>
          </thead>
          <tbody>
            {tableData

              .map((item) => (
                <tr key={item.id}>
                  <td>{item.origen}</td>
                  <td>{item.esBajaEnGara ? "Baja" : " "} {item.ejercicio == "Si" ? "Ejercicio" : " "} </td>
                  <td>{item.numeroBYMA}</td>
                  <td>{item.instrumentoCod}</td>
                  <td>{item.instrumentoAbr}</td>
                  <td>{item.tipoDeOperacion}</td>
                  <td>{item.codComitente}</td>
                  <td>{item.plazo}</td>
                  <td>{item.moneda}</td>
                  <td className='text-right' >{formatNumero(item.cantidad)}</td>
                  <td className='text-right'>{formatNumero(item.precio)}</td>
                  <td>
                  {item.origen == "GARA" && <input onChange={ (e) => {handleCheck(item.id)} }  checked={item.selected} className="form-control-sm form-control" type="checkbox"  /> }

                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>}
    </div>
  </div>
  )
}

export default ConciliaCGVBTable