
const baseURL = process.env.REACT_APP_API_URL;

export const delFeriado = async (id) => {
  let json =  []
  try {
    const res = await fetch(`${baseURL}listaFeriados/${id}`, { method: 'DELETE' });
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export const addFeriado = async (newFeriado) => {
    let json =  []
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newFeriado)
    };
    try {
      const res = await fetch(`${baseURL}listaFeriados`, requestOptions);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };

 export const getFeriados = async () => {
    let json =  []
    try {
      const res = await fetch(`${baseURL}listaFeriados`);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };
  

