import React, { useState, useEffect } from "react";

import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
import SaldosPanelMAV from "../components/SaldosPanelMAV";
import CaucionTableMAV from "../components/CaucionTableMAV";
import OpersGralTableMAV from "../components/OpersGralTableMAV";
import PanelHeaderMAV from "../components/PanelHeaderMAV";
import CPDsTableMAV from "../components/CPDsTableMAV";
const REACT_APP_MENU_SALDOS_MAV = process.env.REACT_APP_MENU_SALDOS_MAV === "false" ? false : true;

const PanelMAV = () => {
  useDocumentTitle("Panel de Operaciones MAV");

  const [isLoading, setIsLoading] = useState(false);
  const [dataCauciones, setDataCauciones] = useState([]);
  const [saldosMAV, setSaldosMAV] = useState({})
  const [dataOpersGral, setDataOpersGral] = useState([])
  const [datosCPD, setDatosCPD] = useState([])
  const [moneda, setMoneda] = useState("$")
  const [saldosDerivaciones, setSaldosDerivaciones] = useState({})
  const [saldosDerivacionesESCO, setSaldosDerivacionesESCO] = useState([])
  const [marketSesions, setMarketSesions] = useState({})
  const { getInfCauciones, getSaldosMAV, getInfOpersGeneralMAV, getCPDConcertacionMAV, getSaldosDerivaMAVDiaAnt, saldosDerivaMAVHoy, derivSaldosESCOporDepMAV, getMarketSesions } = useServices();

  useEffect(() => {
    initPanel();
  }, []);

  async function cargaCauciones() {
    const res = await getInfCauciones();
    if(res.error) return
    setDataCauciones(res)
  }
  async function cargaSaldosMAV () {
    const res = await getSaldosMAV();
    if(res.error) return
    setSaldosMAV(res)
  }
  async function cargaCPDsMAV () {
    const res = await getCPDConcertacionMAV();
    if(res.error) return
    setDatosCPD(res)
  }

  async function cargaOpersGralMAV () {
    const res = await getInfOpersGeneralMAV();
    if(res.error) return
    setDataOpersGral(res)

  }

  async function cargaSaldosDerivMAV () {

    const saldosDerivESCO = await  derivSaldosESCOporDepMAV();
    if(saldosDerivESCO.error) return
    //console.log({saldosDerivESCO})
    if(saldosDerivESCO.saldosDerivESCOporDep) {
      setSaldosDerivacionesESCO(saldosDerivESCO.saldosDerivESCOporDep)
    } else {
    const dAnt = await getSaldosDerivaMAVDiaAnt();
    if(dAnt.error) return
    const hoy = await saldosDerivaMAVHoy();
    if(hoy.error) return

    
    setSaldosDerivaciones({hoy, dAnt})
    }
    

  }

  async function handleSetMarketSesions (){
    const res = await getMarketSesions();
    if(res.error) return
    setMarketSesions(res)
  }


  async function initPanel() {
    await handleSetMarketSesions()
    setIsLoading(true)
    if( REACT_APP_MENU_SALDOS_MAV) {

      await cargaSaldosMAV();
    }
    await cargaCauciones();
    await cargaOpersGralMAV();
    await cargaCPDsMAV();
   if(REACT_APP_MENU_SALDOS_MAV) {await cargaSaldosDerivMAV();}    
   setIsLoading(false)
  }
  async function reloadSaldos () {
    setIsLoading(true)
    await cargaSaldosMAV();
    await cargaSaldosDerivMAV();
    setIsLoading(false)

  }

  return (
    <div className="container-fluid">
<div className="row no-gutters">
  <div className="col-12">
    <div className="card-header d-flex justify-content-between">

      <div></div>
 
      <div className="float-right">
      <span className="mr-2">MAV (trading)</span> 
        {marketSesions.loginMAV ? <span className="badge badge-pill badge-success mr-2">Conectado</span> : <span className="badge badge-pill badge-danger mr-2">Desconectado</span>}
        <span className="mr-2">MAV Automatico</span> 
        {marketSesions.loginMAVAutomatico ? <span className="badge badge-pill badge-success">Cargado</span> : <span className="badge badge-pill badge-danger">Sin credenciales</span>}
      </div>
    </div>
  </div>
</div>
   { REACT_APP_MENU_SALDOS_MAV &&  <div className="row no-gutters">
        <div className="col"></div>
        <SaldosPanelMAV moneda="P" saldos={saldosMAV} isLoading={isLoading} reloadFunc={reloadSaldos} saldosDerivaciones={saldosDerivaciones} saldosDerivacionesESCO={saldosDerivacionesESCO} />
        <SaldosPanelMAV moneda="D" saldos={saldosMAV}  isLoading={isLoading} reloadFunc={reloadSaldos}  saldosDerivaciones={saldosDerivaciones} saldosDerivacionesESCO={saldosDerivacionesESCO}  />
        <div className="col"></div>
      </div>}
      {/*       <Paneldheader
        showhidePanel={showhidePanel}
        setMoneda={setMoneda}
        moneda={moneda}
        cuentaOper={cuentaOper}
        cargaContado={reloadContado}
        cargaCauciones={cargaResumenCaucGara}
        cargaOpciones={cargaResumenOpc}
        hayEjercicios={hayEjercicios}
        hayVC={hayVC}
        panelShow = {{showCI, show24hs, show48hs, showPanelSENEBI}}
      /> */}

  
      <PanelHeaderMAV isLoading={isLoading} setMoneda={setMoneda} moneda={moneda} cargaCauciones={cargaCauciones} cargaOpersGral={getInfOpersGeneralMAV} cargaCPDsMAV={cargaCPDsMAV}/>

 

      <div className="row no-gutters">    
      <CaucionTableMAV moneda={moneda} datos={dataCauciones}  />  
      <OpersGralTableMAV moneda={moneda} datos={dataOpersGral} />  
      <CPDsTableMAV  moneda={moneda} datos={datosCPD} />
      
      </div>
    </div>
  );
};

export default PanelMAV;
