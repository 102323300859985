import React, { useEffect, useState } from 'react'
import ControlNominalesBOP from "../components/ControlNominalesBOP"
import ControlMovCustodia from "../components/ControlMovCustodia"
//import getTenenciaValoresBOP from "../services/getTenenciaValoresBOP"
//import getGarantiaDisponible from "../services/getGarantiaDisponible";
//import getPosiNominales from "../services/getPosiNominales"
import FileUploadButton from "../components/FileUploadButton"
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
const baseURL = process.env.REACT_APP_API_URL;


const ControlNominales = () => {
    useDocumentTitle('Control Nominales')
   const [tenenciaBOP, setTenenciaBOP] = useState([])
   const [garantiaDisp, setGarantiaDisp] = useState([])
   const [posiNominales, setPosiNominales] = useState([])
   const {getGarantiaDisponible, getPosiNominales, getTenenciaValoresBOP}  = useServices();
    
 async function cargaDatos() {
   const tenenciaBOP = await getTenenciaValoresBOP();
   const garantiaDisp = await getGarantiaDisponible();
   const posiNominales = await getPosiNominales();
   if(garantiaDisp.error) return
   setTenenciaBOP(tenenciaBOP);
   setGarantiaDisp([...garantiaDisp]);
   setPosiNominales([...posiNominales]);
 }
 
    
    
    useEffect(() => {
 
        cargaDatos();

    }, [])
    return (
        <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                <div className="card-header">
                    <div className="row">
                        <div className="col-12"> <FileUploadButton endPoint={`${baseURL}upload/uploadTenVal`} fileName={'tenvalores.xlsx'} label={'Cargar archivo de tenencia AUNE T-1:'} callback={cargaDatos} />  </div>
                     

                    </div>
                  
             
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">

            <ControlNominalesBOP cargaDatos={cargaDatos} tenenciaBOP={tenenciaBOP} garantiaDispBOP={garantiaDisp} nominalesGARA={posiNominales} />

            </div>


            </div>
            <div className="row">
            <div className="col-6">

            <ControlMovCustodia tenenciaBOP={tenenciaBOP}  />

            </div>
            <div className="col-6">
          
            </div>


            </div>
            
            
        </div>
    )
}

export default ControlNominales
