import React, {useState} from 'react'

const CaucionTableMAV = ({ datos = [], moneda = '$'}) => {

    const [filtro, setFiltro] = useState("");
    let tabla = [];
    const [bodyVisible, setBodyVisible] = useState(false);
    const diccCodMon = {'$': 'Pesos', 'D': 'Dólar', 'C': 'Dólar Transferencia'}

    tabla = datos.filter(item => item.moneda == moneda)
    tabla.sort( (a, b) => a.dtv - b.dtv )


  const formatNumero = (numero) => {
    if(!numero) {return ""}
    return numero?.toLocaleString();
  };
  const sumaMontoNeto = (datos) => {
    let suma = 0;
    datos.map((item) => {
      suma += item.mContado;
    });
    return formatNumero(suma);
  };
  const showhideBody = () => {
    setBodyVisible(!bodyVisible)
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
  const leyBtn = bodyVisible ? "-" : "+";

    return (
     <div className="col col-sm-12 col-md-12">
      <div className="card">
        <div className="card-header ">
          <div className="row">
          <div className="col-8 ">
            <span>
              <b>Operaciones Caucion</b> {tabla.length > 0 && "-"} Monto: $ {sumaMontoNeto(tabla.filter((item) => item.comitente.includes(filtro)))}
             
            </span>
       
        
          </div>
          <div className="col-4">
            <span>
    <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              <b>{leyBtn}</b>
            </button>
            <input
              placeholder="Cuenta"
              onChange={(e) => {
                setFiltro(e.target.value);
              }}
              className="form-control-sm form-control   float-right ml-1"
              type="text"
              style={{ "maxWidth": "80px" }}
            />
             
            </span>
          </div>
          </div>
        </div>
        <div className={cardBodyClass}>
        <div style={{'maxHeight': '60vh', minHeight: '10vh'}} className="tableFixHead table-responsive-md">
          <table className="table table-sm table-bordered table-hover texto-small">
            <thead>
              <tr>
                <th scope="col">Cuenta</th>
                <th scope="col">Venc.</th>
                <th scope="col">DTV</th>
                <th scope="col">Monto Contado</th>
                <th scope="col">Monto Futuro</th>
                <th scope="col">Tasa</th>
              </tr>
            </thead>
            <tbody>
              {tabla
                .filter((item) => item.comitente.includes(filtro))
                .map((item, index) => (
                  <tr key={index}>
                    <th scope="col">{item.comitente}</th>
                    <td className='text-right'>{item.fechLiq}</td>
                    <td>{item.dtv}</td>
                    <td className='text-right'>{formatNumero(item.mContado)}</td>
                    <td className='text-right'>{formatNumero(item.mFuturo)}</td>
                    <td className='text-right'>{formatNumero(item.tasaProm)}</td>

                  </tr>
                ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    )
}

export default CaucionTableMAV
