import React, { useState, useEffect } from "react";

import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
import SaldosControlAN from "../components/SaldosControlAN";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
const baseURL = process.env.REACT_APP_API_URL;
const ALYC_INTEGRAL = process.env.REACT_APP_ALYC_INTEGRAL === 'true';

const ControlSaldosAUNEANPorComitente = () => {
  useDocumentTitle("Control de saldos (Comitente) - AUNE");
  const [dataSaldos, setDataSaldos] = useState({});
  const {getSaldosProyectadosAUNEComitente} = useServices();
  const [isLoading, setIsLoading] = useState(false);
  const [alycsP, setAlycsP] = useState(false);
  const [sumaPendientes, setSumaPendientes] = useState(true);
  const [uploaded, setUploaded] = useState(false);
  const [comitente, setComitente] = useState("");
  
  async function handleSearchComitente() {
    if (comitente.length < 5) {
      return;
    }
    setIsLoading(true);

    const res = await getSaldosProyectadosAUNEComitente({ sumaPendientes, comitente});
    setIsLoading(false);
    if (res.error) return;
    setDataSaldos(res);
    if (res.listaFciNoEncontrados && res.listaFciNoEncontrados?.length > 0) {
      alert(`Los siguientes cod. de FCI no estan en tabla:\n${res.listaFciNoEncontrados.join("\n")}`);
    }
  }



   function handleSumaPendientes () {
    setSumaPendientes(prev => !prev)
   } 

  useEffect(() => {

  handleSearchComitente()
  //
  }, [sumaPendientes])
 

  return (
    <div className="container-fluid">
      <div className="row no-gutters">
        <div className="col-12">

            <div className="card-header">
              <div className="row">
                <div className="col-6" style={{display: "flex"}}>
                <input style={{width: "150px"}} type="text" className="form-control" placeholder="Codigo Cuenta" onChange={ e => setComitente(e.target.value)} value={comitente} />
                <button disabled={isLoading} className="btn btn-primary ml-1" onClick={handleSearchComitente}>{ isLoading ?    <FontAwesomeIcon className={isLoading ? "loading-icon" : "" } icon={faSync} /> : "Buscar"}</button>


             
           
          
                </div>
         
              <div className="col">

              <span className="float-right input-group-text form-control-sm ml-1"> <b>{dataSaldos["ultimaOperacionInmediato"] ? `Últ. Op. CI: ${dataSaldos["ultimaOperacionInmediato"]}` : `Últ. Op. CI: No disponible`}</b></span>
              { ALYC_INTEGRAL && <>
                <span className="float-right input-group-text form-control-sm ml-1">Suma Sdos. Pendientes <input onChange={handleSumaPendientes} checked={sumaPendientes} className="ml-1" type="checkbox"  /> </span>

                </> }
                </div>

              </div>
          </div>
        </div>
      </div>
      <div className="row no-gutters">
        <SaldosControlAN  reloadPanel={handleSearchComitente} isLoading={isLoading} initBodyVisb={true} data={dataSaldos["Pesos"] || [] } moneda={"Pesos"} />
        <SaldosControlAN reloadPanel={handleSearchComitente} isLoading={isLoading} initBodyVisb={true} data={dataSaldos["Dólar"] || [] } moneda={"Dólar"} />
        <SaldosControlAN reloadPanel={handleSearchComitente} isLoading={isLoading} initBodyVisb={true} data={dataSaldos["Dólar Transferencia"] || [] } moneda={"Dólar Transferencia"} />
      </div>
    </div>
  );
};

export default ControlSaldosAUNEANPorComitente;
