import React, {useState} from 'react'

const OpcionesTable = ({ datos = {}, moneda = 'Pesos'}) => {

    const [filtro, setFiltro] = useState("");
    let tabla = [];
    const [bodyVisible, setBodyVisible] = useState(false);

    
  if (datos[moneda] !== undefined) {
    tabla = datos[moneda];
  }

  const formatNumero = (numero) => {
    if(!numero) return ''
    return numero.toLocaleString();
  };
  const sumaMontoNeto = (datos) => {
    let suma = 0;
    datos.map((item) => {
      suma += item.nm;
    });
    return formatNumero(suma);
  };
  const showhideBody = () => {
    setBodyVisible(!bodyVisible)
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
  const leyBtn = bodyVisible ? "-" : "+";

    return (
     <div className=" col">
      <div className="card">
        <div className="card-header ">
          <div className="row">
          <div className="col-8 ">
            <span>
              <b>Operaciones Opciones</b> - Monto: $ {sumaMontoNeto(tabla.filter((item) => item.cuenta.includes(filtro)))}
             
            </span>
       
        
          </div>
          <div className="col-4">
            <span>
    <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              <b>{leyBtn}</b>
            </button>
            <input
              placeholder="Cuenta"
              onChange={(e) => {
                setFiltro(e.target.value);
              }}
              className="form-control-sm  form-control  float-right ml-1"
              type="text"
              style={{ "maxWidth": "80px" }}
            />
             
            </span>
          </div>
          </div>
        </div>
        <div className={cardBodyClass}>
        <div style={{ maxHeight: "60vh", minHeight: "10vh" }} className="tableFixHead table-responsive-md">
  
          <table className="table table-sm table-bordered table-hover texto-small">
            <thead>
              <tr>
                <th scope="col">Cuenta</th>
                <th scope="col">Serie</th>
                <th scope="col">Monto Comp.</th>
                <th scope="col">Cantidad Comp.</th>
                <th scope="col">Monto Venta</th>
                <th scope="col">Cantidad Venta</th>
                <th scope="col">Cantidad Neta</th>
                <th scope="col">Monto Neto</th>
                <th scope="col">PPP</th>
              </tr>
            </thead>
            <tbody>
              {tabla
                .filter((item) => item.cuenta.includes(filtro))
                .map((item, index) => (
                  <tr key={index}>
                    <th scope="col">{item.cuenta}</th>
                    <td>{item.serie}</td>
                    <td>{formatNumero(item.mc)}</td>
                    <td>{item.cc}</td>
                    <td>{formatNumero(item.mv)}</td>
                    <td>{formatNumero(item.cv)}</td>
                    <td class="text-right">{item.nc}</td>
                    <td class="text-right">{formatNumero(item.nm)}</td>
                    <td class="text-right">{formatNumero(item.ppp)}</td>

                  </tr>
                ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    )
}

export default OpcionesTable
