import { useHistory } from "react-router-dom";
import { addCtasProp } from "../services/addCtasProp";
import AP5Reconnect from "../services/AP5Reconnect";
import { delCtasProp } from "../services/delCtasProp";
import { distribucionMasiva } from "../services/distribucionMasiva";
import getArchivoEjercicios from "../services/getArchivoEjercicios";
import getCPDConcertacionMAV from "../services/getCPDConcertacion";
import getCPDConcertacionDerivacionesMAV from "../services/getCPDConcertacionDerivacionesMAV";
import getCtasProp from "../services/getCtasProp";
import getDerivacionesPPTLiqDia from "../services/getDerivacionesPPTLiqDia";
import getDerivacionesPPTLiqDiaBoletos from "../services/getDerivacionesPPTLiqDiaBoletos";
import getDerivacionesSBLiqDia from "../services/getDerivacionesSBLiqDia";
import getGarantiaDisponible from '../services/getGarantiaDisponible';
import getInfCauciones from "../services/getInfCauciones";
import getInfCaucionesDerivacionesMAV from "../services/getInfCaucionesDerivacionesMAV";
import getInfOpersGeneralDerivacionesMAV from "../services/getInfOpersGeneralDerivacionesMAV";
import getInfOpersGeneralMAV from "../services/getInfOpersGeneralMAV";
import getMarketSesions from "../services/getMarketSesions";
import getNominalesCaucMAV from "../services/getNominalesCaucMAV";
import getOperacionesContadoGaraTrader from "../services/getOperacionesContadoGaraTrader";
import getOpersdiaContadoDayTrading from "../services/getOpersdiaContadoDayTrading";
import getPosiNominales from '../services/getPosiNominales';
import getPosiOpciones from "../services/getPosiOpciones";
import getPosiOpcionesTrader from "../services/getPosiOpcionesTrader";
import getReporteIdSdib from "../services/getReporteIdSdib";
import getReqNoCubiertos from '../services/getReqNoCubiertos';
import getResultadoEjercicioDia from "../services/getResultadoEjercicioDia";
import getResultadoEjercicioDiaTrader from "../services/getResultadoEjercicioDiaTrader";
import getResultadoEjercicioVencimiento from '../services/getResultadoEjercicioVencimiento';
import getResultadoEjercicioVencimientoTrader from "../services/getResultadoEjercicioVencimientoTrader";
import getResumenOperdiaAlquilerVC from "../services/getResumenOperdiaAlquilerVC";
import getResumenOperdiaAntContadoCLiq from "../services/getResumenOperdiaAntContadoCLiq";
import getResumenOperdiaCaucion from '../services/getResumenOperdiaCaucion';
import getResumenOperdiaCaucionDerivaciones from "../services/getResumenOperdiaCaucionDerivaciones";
import getResumenOperdiaCaucionTrader from "../services/getResumenOperdiaCaucionTrader";
import getResumenOperdiaContado from '../services/getResumenOperdiaContado';
import getResumenOperdiaContadoDerivaciones from "../services/getResumenOperdiaContadoDerivaciones";
import getResumenOperdiaContadoDerivacionesSB from "../services/getResumenOperdiaContadoDerivacionesSB";
import getResumenOperdiaContadoSB from "../services/getResumenOperdiaContadoSB";
import getResumenOperdiaContadoTotal from "../services/getResumenOperdiaContadoTotal";
import getResumenOperdiaContadoTrader from "../services/getResumenOperdiaContadoTrader";
import getResumenOperdiaEjercicios from '../services/getResumenOperdiaEjercicios';
import getResumenOperdiaEjerciciosTrader from "../services/getResumenOperdiaEjerciciosTrader";
import getResumenOperdiaOpciones from '../services/getResumenOperdiaOpciones';
import getResumenOperdiaOpcionesTrader from "../services/getResumenOperdiaOpcionesTrader";
import getResumenOperdiaVC from "../services/getResumenOperdiaVC";
import getResumenPosiCauc from "../services/getResumenPosiCauc";
import getRG624 from "../services/getRG624";
import getSaldosBOP from "../services/getSaldosBOP";
import getSaldosBOPCC from "../services/getSaldosBOPCC";
import getSaldosBOPTrader from "../services/getSaldosBOPTrader";
import getSaldosBYMA from '../services/getSaldosBYMA';
import getSaldosMAV from "../services/getSaldosMAV";
import getTenenciaValoresBOP from '../services/getTenenciaValoresBOP';
import getTKN from "../services/getTKN";
import getTradeCaptureAP5 from "../services/getTradeCaptureAP5";
import getTradeCaptureTraderAP5 from "../services/getTradeCaptureTraderAP5";
// getResumenOperdiaEjerciciosTrader, getResultadoEjercicioVencimientoTrader, getResultadoEjercicioDiaTrader
import { addComitente, delComitente, getComitentes, setComitente } from "../services/listaComitentes";
import { addUser, delUser, getUsers, setUser } from "../services/listaUsers";
import { addFondo, delFondo, getFondos, setFondo } from "../services/listaFondos";
import MAVConnect from "../services/MAVConnect";
import MAVDisconnect from "../services/MAVDisconnect";
import MAVForceDisconnect from "../services/MAVForceDisconnect";
import postDayTrading from "../services/postDayTrading";
import renewBaseTitulos from "../services/renewBaseTitulos";
import getSaldosDerivaMAVDiaAnt from "../services/saldosDerivaMAVDiaAnt";
import saldosDerivaMAVHoy from "../services/saldosDerivaMAVHoy";
import userStatus from "../services/userStatus";
import getSaldosDerivESCO from "../services/getSaldosDerivESCO";
import distribFilesMAV from "../services/distribFilesMAV";
import derivSaldosESCOporDepMAV from "../services/derivSaldosESCOporDepMAV";
import getPNGVencimientosTitulosMAV from "../services/getPNGVencimientosTitulosMAV";
import getDistribucionesCaucionesBYMA from "../services/getDistribucionesCaucionesBYMA";
import getSaldosVBCC from "../services/getSaldosVBCC ";



import { addAforo, delAforo, getAforos, setAforo } from "../services/listaAforos";
import { distribuirCaucionComitente } from "../services/distribuirCaucionComitente";
import getSaldosVBCCTrader from "../services/getSaldosVBCCTrader";
import getResumenPosiCaucionesTrader from "../services/getResumenPosiCaucTrader";
import getResumenOperdiaContadoSBTrader from "../services/getResumenOperdiaContadoSBTrader";
import getResumenOperdiaAlquilerVCTrader from "../services/getResumenOperdiaAlquilerVCTrader";
import getResumenOperdiaVCTrader from "../services/getResumenOperdiaVCTrader";
import getResumenNeteoCaucionesGARATrader from "../services/getResumenNeteoCaucionesGARATrader";
import getPositionReportT1MTR from "../services/getPositionReportT1MTR";

import getPositionReportT0MTR from "../services/getPositionReportT0MTR";
import getPositionReportT0MTRTrader from "../services/getPositionReportT0MTRTrader";
import getGarantiasYMargenes from "../services/getGarantiasYMargenes";
import getGarantiasYMargenesTrader from "../services/getGarantiasYMargenesTrader";
import { getTableCrud, addTableCrud, delTableCrud } from "../services/tableCrudService";
import getGaratiasyCoberturasGARA from "../services/getGaratiasyCoberturasGARA";
import getGaratiasyCoberturasGARATrader from "../services/getGaratiasyCoberturasGARATrader";
import getConciliaTenenciaCV from "../services/getConciliaTenenciaCV";
import getConciliaOpersCG from "../services/getConciliaOpersCG";
import getConciliaOpersCaucion from "../services/getConciliaOpersCaucion";
import getUserConfig from "../services/getUserConfig";
import { setUserConfig } from "../services/setUserConfig";
import getSaldosProyectadosAUNE from "../services/getSaldosProyectadosAUNE";
import getNominalesProyectadoAUNE from "../services/getNominalesProyectadoAUNE";
import reconectMAVAutomatico from "../services/reconectMAVAutomatico";
import switchOpersSource from "../services/switchOpersSource";
import getActiveOpersSource from "../services/getActiveOpersSource";
import reconectAPIAUNE from "../services/reconectAPIAUNE";
import getPosiNominalesProcesado from "../services/getPosiNominalesProcesado";
import getUploadedStatusSaldosAUNE from "../services/getUploadedStatusSaldosAUNE";
import getPositionMaintenanceReportBetweenDates from "../services/getPositionMaintenanceReportBetweenDates";
import getUploadedStatusTenValAUNE from "../services/getUploadedStatusTenValAUNE";
import getPositionMaintenanceReportBetweenDatesTrader from "../services/getPositionMaintenanceReportBetweenDatesTrader";
import getPositionMaintenanceReportBetweenDatesExcel from "../services/getPositionMaintenanceReportBetweenDatesExcel";
import getPositionMaintenanceReportBetweenDatesTraderExcel from "../services/getPositionMaintenanceReportBetweenDatesTraderExcel";
//import getTableCrud from "../services/tableCrudService";

import getResumenOperdiaContadoAN from "../services/getResumenOperdiaContadoAN";
import getResumenOperdiaCaucionAN from "../services/getResumenOperdiaCaucionAN";

import getResumenOperdiaOpcionesAN from "../services/getResumenOperdiaOpcionesAN";
import getResumenOperdiaContadoSBAN from "../services/getResumenOperdiaContadoSBAN";

import getResumenPosiCaucAN from "../services/getResumenPosiCaucAN";

import getPosiOpcionesAN from "../services/getPosiOpcionesAN";

import getInfCaucionesAN from "../services/getInfCaucionesAN";

import getCPDConcertacionMAVAN from "../services/getCPDConcertacionAN";

import getInfOpersGeneralMAVAN from "../services/getInfOpersGeneralMAVAN";

import getSaldosProyectadosAUNEAN from "../services/getSaldosProyectadosAUNEAN";
import getNominalesProyectadoAUNEAN from "../services/getNominalesProyectadoAUNEAN";
import getSaldosConsolidados from "../services/getSaldosConsolidados";
import { saveSaldosPersistencia } from "../services/saveSaldosPersistencia";
import reconnectEMS from "../services/reconnectEMS";
import getSaldosProyectadosAUNEComitente from "../services/getSaldosProyectadosAUNEComitente";




const useServices = () => {
    const history = useHistory();
    const options = {headers: { 'Authorization': `Bearer ${getTKN()}`} }


   async function envolveService (service, param = null, param2 = null)  {
       
       const json = await service(options, param, param2);
       //console.log({json})
        if(json.error) {
           
            window.localStorage.clear();
            //console.log(json.error.message)
            //console.log(json.error)
           if(history) {
            history.push("/login", {detail: json.error});
           } else {
           
           }
            
           
            return json;
        }
        return json

    }
 
    return {getResumenOperdiaContado: () => envolveService(getResumenOperdiaContado),
            getSaldosBYMA: () => envolveService(getSaldosBYMA),
            getResumenOperdiaCaucion: () => envolveService(getResumenOperdiaCaucion),
            getResumenOperdiaOpciones: () => envolveService(getResumenOperdiaOpciones),
            getReqNoCubiertos: () => envolveService(getReqNoCubiertos),
            getPosiNominales: () => envolveService(getPosiNominales),
            getGarantiaDisponible: () => envolveService(getGarantiaDisponible),
            getTenenciaValoresBOP: () => envolveService(getTenenciaValoresBOP),
            getSaldosBOP: () => envolveService(getSaldosBOP),
            getReporteIdSdib: (id) => envolveService(getReporteIdSdib, id),
            getResumenPosiCauc: () => envolveService(getResumenPosiCauc),
            getPosiOpciones: () => envolveService(getPosiOpciones),
            getResumenOperdiaEjercicios: () => envolveService(getResumenOperdiaEjercicios),
            getResultadoEjercicioVencimiento: () => envolveService(getResultadoEjercicioVencimiento),
            getResultadoEjercicioDia: () => envolveService(getResultadoEjercicioDia),
            getArchivoEjercicios: () => envolveService(getArchivoEjercicios),
            getResumenOperdiaVC: ()  => envolveService(getResumenOperdiaVC),
            postDayTrading: (tabla) => envolveService(postDayTrading, tabla),
            getRG624: {
                Contado: () => envolveService(getRG624, 'Contado'),
                Opciones: () => envolveService(getRG624, 'Opciones'),
                Cauciones: () => envolveService(getRG624, 'Cauciones')
            },
            delComitente: (id) => envolveService(delComitente, id),
            addComitente: (comitentes) => envolveService(addComitente, comitentes),
            getComitentes: () => envolveService(getComitentes),
            setComitente: (comitente) => envolveService(setComitente, comitente),
            userStatus: () => envolveService(userStatus),
            getResumenOperdiaContadoTrader:  () => envolveService(getResumenOperdiaContadoTrader),
            getResumenOperdiaOpcionesTrader:  () => envolveService(getResumenOperdiaOpcionesTrader),
            getResumenOperdiaCaucionTrader: () => envolveService(getResumenOperdiaCaucionTrader),
            getPosiOpcionesTrader: () => envolveService(getPosiOpcionesTrader),
            getResumenOperdiaEjerciciosTrader: () => envolveService(getResumenOperdiaEjerciciosTrader),
            getResultadoEjercicioVencimientoTrader: () => envolveService(getResultadoEjercicioVencimientoTrader),
            getResultadoEjercicioDiaTrader: () => envolveService(getResultadoEjercicioDiaTrader),
            getTradeCaptureAP5: () => envolveService(getTradeCaptureAP5),
            getTradeCaptureTraderAP5: () => envolveService(getTradeCaptureTraderAP5), 
            delUser: (id) => envolveService(delUser, id),
            addUser: (Users) => envolveService(addUser, Users),
            getUsers: () => envolveService(getUsers),
            setUser: (User) => envolveService(setUser, User),
            getCtasProp: () => envolveService(getCtasProp), 
            delCtasProp: (id) => envolveService(delCtasProp, id),
            addCtasProp: (cuenta) => envolveService(addCtasProp, cuenta),
            getMarketSesions:  () => envolveService(getMarketSesions),
            getResumenOperdiaContadoDerivaciones: () => envolveService(getResumenOperdiaContadoDerivaciones),
            getResumenOperdiaCaucionDerivaciones: () => envolveService(getResumenOperdiaCaucionDerivaciones),
            getResumenOperdiaAntContadoCLiq: () => envolveService(getResumenOperdiaAntContadoCLiq),
            AP5Reconnect: () => envolveService(AP5Reconnect),
            getResumenOperdiaContadoTotal: () => envolveService(getResumenOperdiaContadoTotal),
            getDerivacionesPPTLiqDia: () => envolveService(getDerivacionesPPTLiqDia),
            renewBaseTitulos: () => envolveService(renewBaseTitulos),
            getResumenOperdiaContadoSB: () => envolveService(getResumenOperdiaContadoSB),
            getResumenOperdiaContadoDerivacionesSB: () => envolveService(getResumenOperdiaContadoDerivacionesSB),
            getDerivacionesSBLiqDia: () => envolveService(getDerivacionesSBLiqDia),
            getSaldosBOPTrader: () =>  envolveService(getSaldosBOPTrader), 
            getResumenOperdiaAlquilerVC: () =>  envolveService(getResumenOperdiaAlquilerVC),
            getInfCauciones: () => envolveService(getInfCauciones),
            getSaldosMAV: () => envolveService(getSaldosMAV),
            getNominalesCaucMAV: () => envolveService(getNominalesCaucMAV),
            getInfOpersGeneralMAV: () => envolveService(getInfOpersGeneralMAV),
            getCPDConcertacionMAV: () => envolveService(getCPDConcertacionMAV),
            getInfCaucionesDerivacionesMAV: () => envolveService(getInfCaucionesDerivacionesMAV),
            getInfOpersGeneralDerivacionesMAV: () => envolveService(getInfOpersGeneralDerivacionesMAV),
            getCPDConcertacionDerivacionesMAV: () => envolveService(getCPDConcertacionDerivacionesMAV),
            MAVConnect: () => envolveService(MAVConnect),
            MAVDisconnect: () => envolveService(MAVDisconnect),
            MAVForceDisconnect: () => envolveService(MAVForceDisconnect),
            getSaldosDerivaMAVDiaAnt: () => envolveService(getSaldosDerivaMAVDiaAnt),
            saldosDerivaMAVHoy: () => envolveService(saldosDerivaMAVHoy),
            getOperacionesContadoGaraTrader: () => envolveService(getOperacionesContadoGaraTrader),
            getOpersdiaContadoDayTrading: () => envolveService(getOpersdiaContadoDayTrading),
            getSaldosBOPCC: () => envolveService(getSaldosBOPCC),
            getDerivacionesPPTLiqDiaBoletos: () => envolveService(getDerivacionesPPTLiqDiaBoletos),
            distribucionMasiva: (data) => envolveService(distribucionMasiva, data),
            delFondo: (id) => envolveService(delFondo, id),
            addFondo: (Fondos) => envolveService(addFondo, Fondos),
            getFondos: () => envolveService(getFondos),
            setFondo: (Fondo) => envolveService(setFondo, Fondo),
            getSaldosDerivESCO: () => envolveService(getSaldosDerivESCO),
            distribFilesMAV:  (data) => envolveService(distribFilesMAV, data),
            derivSaldosESCOporDepMAV: () => envolveService(derivSaldosESCOporDepMAV),
            getPNGVencimientosTitulosMAV: () => envolveService(getPNGVencimientosTitulosMAV),
            getDistribucionesCaucionesBYMA: (tomadoraCierre = true, garantiaGara = true)  => envolveService(getDistribucionesCaucionesBYMA, tomadoraCierre, garantiaGara),
            delAforo: (id) => envolveService(delAforo, id),
            addAforo: (Aforos) => envolveService(addAforo, Aforos),
            getAforos: () => envolveService(getAforos),
            setAforo: (Aforo) => envolveService(setAforo, Aforo),
            distribuirCaucionComitente: (data) => envolveService(distribuirCaucionComitente, data),
            getSaldosVBCC: () => envolveService(getSaldosVBCC),
            getSaldosVBCCTrader: () => envolveService(getSaldosVBCCTrader),
            getResumenPosiCaucionesTrader: () => envolveService(getResumenPosiCaucionesTrader),
            getResumenOperdiaContadoSBTrader: () => envolveService(getResumenOperdiaContadoSBTrader),
            getResumenOperdiaAlquilerVCTrader: () => envolveService(getResumenOperdiaAlquilerVCTrader),
            getResumenOperdiaVCTrader: () => envolveService(getResumenOperdiaVCTrader),
            getResumenNeteoCaucionesGARATrader: () => envolveService(getResumenNeteoCaucionesGARATrader),
            getPositionReportT1MTR: () => envolveService(getPositionReportT1MTR),
            getPositionReportT0MTR: () => envolveService(getPositionReportT0MTR),
            getPositionReportT0MTRTrader: () => envolveService(getPositionReportT0MTRTrader),
            getGarantiasYMargenes: () => envolveService(getGarantiasYMargenes),
            getGarantiasYMargenesTrader: () => envolveService(getGarantiasYMargenesTrader),
            getTableCrud: (tableName) => envolveService(getTableCrud, tableName),
            addTableCrud: ({tableName, row}) => envolveService(addTableCrud, {tableName, row}),
            delTableCrud: ({tableName, findParams}) => envolveService(delTableCrud, {tableName, findParams}),
            getGaratiasyCoberturasGARA: () => envolveService(getGaratiasyCoberturasGARA),
            getGaratiasyCoberturasGARATrader: () => envolveService(getGaratiasyCoberturasGARATrader),
            getConciliaTenenciaCV: () => envolveService(getConciliaTenenciaCV),
            getConciliaOpersCG: (soloCI = false) => envolveService( getConciliaOpersCG, soloCI),
            getConciliaOpersCaucion: () => envolveService(getConciliaOpersCaucion),
            getUserConfig: () => envolveService(getUserConfig),
            setUserConfig: (config) => envolveService(setUserConfig, config),
            getSaldosProyectadosAUNE: (alycsP = false, sumaPendientes) => envolveService(getSaldosProyectadosAUNE, alycsP = false, sumaPendientes ),
            getNominalesProyectadoAUNE: (sumaCOMAFI = true, alycsP = false) => envolveService(getNominalesProyectadoAUNE, sumaCOMAFI, alycsP),
            reconectMAVAutomatico: () => envolveService(reconectMAVAutomatico),
            switchOpersSource: (source = "") => envolveService(switchOpersSource, source),
            getActiveOpersSource: ( ) => envolveService(getActiveOpersSource),
            reconectAPIAUNE: () => envolveService(reconectAPIAUNE),
            getPosiNominalesProcesado: () => envolveService(getPosiNominalesProcesado),
            getUploadedStatusSaldosAUNE: () => envolveService(getUploadedStatusSaldosAUNE),
            getPositionMaintenanceReportBetweenDates: ({startDate, endDate, account = ''}) => envolveService(getPositionMaintenanceReportBetweenDates, {startDate, endDate, account}),
            getUploadedStatusTenValAUNE: () => envolveService(getUploadedStatusTenValAUNE),
            getPositionMaintenanceReportBetweenDatesTrader: ({startDate, endDate, account = ''}) => envolveService(getPositionMaintenanceReportBetweenDatesTrader, {startDate, endDate, account}),
            getPositionMaintenanceReportBetweenDatesExcel: ({startDate, endDate, account = ''}) => envolveService(getPositionMaintenanceReportBetweenDatesExcel, {startDate, endDate, account}),
            getPositionMaintenanceReportBetweenDatesTraderExcel: ({startDate, endDate, account = ''}) => envolveService(getPositionMaintenanceReportBetweenDatesTraderExcel, {startDate, endDate, account}),
            getResumenOperdiaContadoAN: () => envolveService(getResumenOperdiaContadoAN),
            getResumenOperdiaCaucionAN: () => envolveService(getResumenOperdiaCaucionAN),
            getResumenOperdiaOpcionesAN: () => envolveService(getResumenOperdiaOpcionesAN),
            getResumenOperdiaContadoSBAN: () => envolveService(getResumenOperdiaContadoSBAN),
            getResumenPosiCaucAN: () => envolveService(getResumenPosiCaucAN),
            getPosiOpcionesAN: () => envolveService(getPosiOpcionesAN),
            getInfCaucionesAN: () => envolveService(getInfCaucionesAN),
            getCPDConcertacionMAVAN: () => envolveService(getCPDConcertacionMAVAN),
            getInfOpersGeneralMAVAN: () => envolveService(getInfOpersGeneralMAVAN),
            getSaldosProyectadosAUNEAN: (alycsP = false, sumaPendientes) => envolveService(getSaldosProyectadosAUNEAN, alycsP = false, sumaPendientes ),
            getNominalesProyectadoAUNEAN: (sumaCOMAFI = true, alycsP = false) => envolveService(getNominalesProyectadoAUNEAN, sumaCOMAFI = true, alycsP = false),
            getSaldosConsolidados: (dropCopyFIX = false) => envolveService(getSaldosConsolidados, dropCopyFIX),
            saveSaldosPersistencia: (params) => envolveService(saveSaldosPersistencia, params ),
            reconnectEMS: () => envolveService(reconnectEMS),
            getSaldosProyectadosAUNEComitente: ({sumaPendientes = false, comitente}) => envolveService(getSaldosProyectadosAUNEComitente,  {sumaPendientes, comitente})
        }
          
}

export default useServices
