import React, {useMemo, useState} from "react";
import Table from './Table'
const NomPorComit = ({datos = [], fecha, initBodyVisb = false}) => {

  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);

  const formatDigit = number => {

    return number.toLocaleString();
  }
  const showhideBody = () => {
    setBodyVisible(!bodyVisible)
  };

  //class=" tableFixHead" style="max-height: 60vh; min-height: 10vh;"
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
  const leyBtn = bodyVisible ? "-" : "+";

  const columns = useMemo(
    () => [
      {
        Header: 'Cuenta',
        accessor: 'codComit', 
        Cell: (props) => (
          <span style={{fontWeight: 'bold'}}>{String(props.value)}</span>
        )
      },
      {
        Header: 'Nro. Esp',
        accessor: 'nroEspecie',
      },
      {
        Header: 'Denominac.',
        accessor: 'denom',
      },
      {
        Header: 'Cantidad Comp.',
        accessor: 'cantComp',
        Cell: (props) => formatDigit(props.value)
      },
      {
        Header: 'Cantiad Vent.',
        accessor: 'cantVent',
        Cell: (props) => formatDigit(props.value)
      },
      {
        Header: 'Neto',
        accessor: 'saldoNeto',
        Cell: (props) => formatDigit(props.value)
      },
    ],
    []
  )
 
  return (
    <div className="card">
      <div className="card-header grey">
        <b>Nominales por comitente</b> - Contado Garantizado
        <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              <b>{leyBtn}</b>
            </button>
        <button type="button" name="bt_fecha" className="btn btn-secondary btn-sm float-right" >
         {fecha}
        </button>
      </div>
      <div  className={cardBodyClass}>
          <div className="tableFixHead" style={{"max-height": "50vh", "min-height": "10vh"}}>
        <Table data={datos} columns={columns} />

          </div>
     
{/*         <table className="table table-sm table-bordered table-hover" >
          <thead>
            <tr>
              <th scope="col">Cuenta</th>
              <th scope="col">Nro. Esp</th>
              <th scope="col">Denom</th>
              <th scope="col">Cantidad Comp.</th>
              <th scope="col">Cantidad Vent.</th>
              <th scope="col">Neto</th>
            </tr>
          </thead>
          <tbody>
           { datos.map(item => (
           <tr key={item.nroEspecie+item.codComit}>
           <th scope="row">{item.codComit}</th>
           <td>{item.nroEspecie}</td>
           <td>{item.denom}</td>
           <td>{item.cantComp}</td>
           <td>{item.cantVent}</td>
           <th scope="row">{item.saldoNeto}</th>
           </tr>)) } 
        </tbody>
        </table> */}
      </div>
    </div>
  );
};

export default NomPorComit;
