const ENV_VARS = process.env;
const modulesHab = () => {
    
    let modulosHabilitados = {};
    Object.entries(ENV_VARS).forEach( ([key, valor] ) => {
        if(key.startsWith('REACT_APP_MENU_')) {
            const menu = key.split('REACT_APP_MENU_')[1];
            if(valor === 'true') {
            modulosHabilitados[menu] = true;
           }
        }
    } )
   return modulosHabilitados;
}
export  const MODULOS_HAB = modulesHab();

