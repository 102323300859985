import React, { useState } from "react";

const SaldosCCBOP = ({ title = "", datos = [], initBodyVisb = false, initFiltro = "", showSaldoT1 = true }) => {
  const [filtro, setFiltro] = useState(initFiltro);
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
  const [filterPositivesOn, setfilterPositivesOn] = useState(false);

  const formatNumero = (valor) => {
    if(!valor) return ""
    let str = valor.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    if (str == "0") {
      str = "";
    }
    if (str == "0.00") {
      str = "";
    }
    if (str == "0,00") {
      str = "";
    }
    return str;
  };
  const sumaCol = (datos, col) => {
    let suma = 0;
    datos.forEach((item) => (suma += item[col]));
    return suma;
  };
  const evalClassNeg = (valor) => {
    if (valor < 0) {
      return "text-right alert-danger";
    } else {
      return "text-right";
    }
  };

  const handleSetFiltro = (valor) => {
    setFiltro(valor);
  };

  const showhideBody = () => {
    setBodyVisible(!bodyVisible);
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none";
  const leyBtn = bodyVisible ? "-" : "+";
  const filterPositives = (valor) => {
    if (filterPositivesOn) {
      return valor <= 0;
    } else {
      return true;
    }
  };

  return (
    <div className="card">
      <div className="card-header grey">
        <span>
          <b>{title}</b> {filtro != "" && `- ${filtro}`} | <b>T+0:</b>{" "}
          {formatNumero(
            sumaCol(
              datos.filter((itemF) => itemF.cuenta.toLowerCase().indexOf(filtro.toLowerCase()) > -1),
              "saldoMonOrig"
            )
          )}
        </span>

        <div role="group" className="  btn-group-toggle float-right ml-1">
          <button
            onClick={() => {
              setfilterPositivesOn(!filterPositivesOn);
            }}
            type="checkbox"
            aria-pressed="false"
            autocomplete="off"
            className={filterPositivesOn ? "btn btn-secondary btn-sm active" : "btn btn-secondary  btn-sm"}
            title="Mostrar solo negativos"
          >
            (-)
          </button>
        </div>

        <button
          type="button"
          onClick={() => showhideBody()}
          className="btn btn-secondary btn-sm float-right ml-1 active"
        >
          <b>{leyBtn}</b>
        </button>

        <input
          placeholder="Cuenta"
          onChange={(e) => {
            handleSetFiltro(e.target.value);
          }}
          className="form-control-sm form-control  float-right ml-1"
          type="text"
          style={{ maxWidth: "150px" }}
          value={filtro}
        />
      </div>
      <div className={cardBodyClass} >
        <div style={{'maxHeight': '60vh', minHeight: '10vh'}} className="tableFixHead table-responsive-md">
        <table className="table table-sm table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">Cuenta</th>
              <th scope="col" className="text-right">
                Saldo T-0
              </th>
        {   showSaldoT1 &&  <> <th scope="col" className="text-right">
                Saldo T+1
              </th>
              <th scope="col" className="text-right">
                Diferencia
              </th>
              </>}
            </tr>
          </thead>
          <tbody>
            {datos
              .filter(
                (itemF) =>
                  itemF.cuenta.toLowerCase().indexOf(filtro.toLowerCase()) > -1 && filterPositives(itemF.saldoMonOrig)
              )
              .map(
                (item) =>
                  item.saldoMonOrig != 0 && (
                    <tr key={item.cuenta}>
                      <td>{item.cuenta}</td>
                      <td className={evalClassNeg(item.saldoMonOrig)}>{formatNumero(item.saldoMonOrig)}</td>
                      {   showSaldoT1 && <>
                      <td className={evalClassNeg(item.saldoMonOrigT1)}>{formatNumero(item.saldoMonOrigT1)}</td>
                      <td className={evalClassNeg(item.diff)}>{formatNumero(item.diff)}</td>
                      </>}
                    </tr>
                  )
              )}
                <tr>
              <td className="text-right" style={{ borderTopWidth: "2px", borderTopColor: "black" }}></td>
              <td className="text-right" style={{ borderTopWidth: "2px", borderTopColor: "black" }}>
                {formatNumero(
                  sumaCol(
                    datos.filter(
                      (itemF) =>
                        itemF.cuenta.toLowerCase().indexOf(filtro.toLowerCase()) > -1 &&
                        filterPositives(itemF.saldoMonOrig)
                    ),
                    "saldoMonOrig"
                  )
                )}
              </td>
            {   showSaldoT1 && <>  <td className="text-right" style={{ borderTopWidth: "2px", borderTopColor: "black" }}>
                {formatNumero(
                  sumaCol(
                    datos.filter(
                      (itemF) =>
                        itemF.cuenta.toLowerCase().indexOf(filtro.toLowerCase()) > -1 &&
                        filterPositives(itemF.saldoMonOrig)
                    ),
                    "saldoMonOrigT1"
                  )
                )}
              </td>
              <td className="text-right" style={{ borderTopWidth: "2px", borderTopColor: "black" }}>
                {formatNumero(
                  sumaCol(
                    datos.filter(
                      (itemF) =>
                        itemF.cuenta.toLowerCase().indexOf(filtro.toLowerCase()) > -1 &&
                        filterPositives(itemF.saldoMonOrig)
                    ),
                    "diff"
                  )
                )}
              </td> </>}
            </tr>
            
          </tbody>
         
        
    
        </table>
        </div>
      </div>
    </div>
  );
};

export default SaldosCCBOP;
