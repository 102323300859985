import React, { useEffect, useState } from 'react'
import TradeCaptureTable  from '../components/TradeCaptureTable'
import useDocumentTitle from "../hooks/useDocumentTitle";
import useServices from '../hooks/useServices';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

const PanelAP5Trader = () => {
    useDocumentTitle('Panel Operaciones - MTR')
    const {getTradeCaptureTraderAP5} = useServices();
    const [tradeCapturesPesos, settradeCapturesPesos] = useState([]);
    const [tradeCapturesDolarMTR, settradeCapturesDolarMTR] = useState([]);
    const [tradesCount, setTradesCount] = useState(0);
    const [isLoadingTrades, setisLoadingTrades] = useState(false)
    async function cargaTradeCaptures () {
        setisLoadingTrades(true)
        const {tablaPosiciones, tradesCount, error} = await getTradeCaptureTraderAP5();
        setisLoadingTrades(false)
        if(error) return;
      
        settradeCapturesPesos(tablaPosiciones.Pesos ?? [])
        settradeCapturesDolarMTR(tablaPosiciones['Dólar MtR'] ?? [])
        setTradesCount(tradesCount)
    }
    useEffect(() => {
     cargaTradeCaptures();
    }, [])
    

  return (
    <div className="container">
        <div className="row">
        <div class="col-12">
 
 <div class="card-header">
   <span><b>Operaciones</b>  </span>   <button disabled={isLoadingTrades} className="btn btn-secondary btn-sm " onClick={() => {cargaTradeCaptures()} }><FontAwesomeIcon icon={faSync} /></button>  {tradesCount}
 </div>


</div>
        </div>
    <div className="row">
       
    <div className="col">
    <TradeCaptureTable  SettlCurrency='Pesos' tableData={tradeCapturesPesos} />
    </div>
    
    </div>
    <div className="row">
    
    <div className="col">
    <TradeCaptureTable  SettlCurrency='Dólar MtR' tableData={tradeCapturesDolarMTR} />
    </div>
    
    </div>
    </div>
  )
}

export default PanelAP5Trader