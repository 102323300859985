import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const PanelHeaderMAV = ({ cargaContado, isLoading, setMoneda, showhidePanel, panelShow, cargaCauciones,  moneda, cargaOpersGral, cargaCPDsMAV}) => {
  //console.log({panelShow})
  //console.log({moneda})
  const [loadingInterno, setLoadingInterno] = useState({opersGral: false, cauciones: false, CPDs: false})
  const handleCargas = async (state, func) => {
    let newLoading = {...loadingInterno};
    newLoading[state] = true;
    setLoadingInterno(newLoading)
    await func();

    newLoading[state] = false;
    setLoadingInterno({...newLoading})
       
  }
 
  return (
    <div className="row">
      <div className="col-sm">
        <div className="card-header ">
   
          <button
            disabled={moneda == '$'}
            type="button"
            onClick={() => {
              setMoneda("$");
            }}
            className="btn btn-secondary btn-sm"
          >
            Pesos
          </button>{" "}
          <button
           disabled={moneda == 'U$S'}
            type="button"
            onClick={() => {
              setMoneda("U$S");
            }}
            className="btn btn-secondary btn-sm ml-1"
          >
            Dolares
          </button>
    
          <span> <b>|</b> </span>

          <button disabled={loadingInterno.opersGral || isLoading } type="button" onClick={() => {handleCargas("opersGral", cargaOpersGral)} } className="btn btn-secondary  btn-sm">
            Operaciones <FontAwesomeIcon icon={faSync} />
          </button>
        
          <button disabled={loadingInterno.cauciones || isLoading } type="button" onClick={() => {handleCargas("cauciones", cargaCauciones)} } name="" className="btn btn-secondary ml-2 btn-sm">
            Cauciones <FontAwesomeIcon icon={faSync} />
          </button>
          <button disabled={loadingInterno.CPDs || isLoading } type="button" onClick={() => {handleCargas("CPDs", cargaCPDsMAV)} } name="" className="btn btn-secondary ml-2 btn-sm">
            CPD <FontAwesomeIcon icon={faSync} />
          </button>
          <span> </span>
 
        
          <div role="group" className="btn-group  btn-group-toggle float-right">
         
         {/* 
            <button
              onClick={() => {
                showhidePanel("CI");
              }}
              type="checkbox"
              aria-pressed="false"
              autocomplete="off"
              className={panelShow.showCI ? "btn btn-secondary btn-sm active" : "btn btn-secondary btn-sm"}
              
            >
              CI
            </button>
      
            <button
              onClick={() => {
                showhidePanel("24hs");
              }}
              type="checkbox"
              aria-pressed="false"
              autocomplete="off"
              className={panelShow.show24hs ? "btn btn-secondary btn-sm active" : "btn btn-secondary btn-sm"}
            >
              24hs
            </button>
            <button
              onClick={() => {
                showhidePanel("48hs");
              }}
              type="checkbox"
              aria-pressed="true"
              autocomplete="off"
              className={panelShow.show48hs ? "btn btn-secondary btn-sm active" : "btn btn-secondary btn-sm"}
            >
              48hs
            </button>
            <button
              onClick={() => {
                showhidePanel("SENEBI");
              }}
              type="checkbox"
              aria-pressed="true"
              autocomplete="off"
              className={panelShow.showPanelSENEBI ? "btn btn-secondary btn-sm active ml-1" : "btn btn-secondary btn-sm ml-1"}
            >
              SENEBI
            </button> */}
            
          </div>
  


        </div>
      </div>
    </div>
  );
};

export default PanelHeaderMAV;
