import React, {useState} from 'react'

const OpersGralTableMAV = ({ datos = [], moneda = '$'}) => {

    const [filtro, setFiltro] = useState("");
    const [filtroFecha, setFiltroFecha] = useState("");
    let tabla = [];
    const [bodyVisible, setBodyVisible] = useState(false);
    const diccCodMon = {'$': 'Pesos', 'D': 'Dólar', 'C': 'Dólar Transferencia'}

    tabla = datos.filter(item => item.moneda == moneda)
    let montosNetos = {};
    tabla.filter((item) => item.comitente.includes(filtro)).forEach( ({nm, fechLiq}) => {
         if(montosNetos[fechLiq] == undefined) { montosNetos[fechLiq] = 0}
         montosNetos[fechLiq] += nm;
    } )
   // console.log({montosNetos})
   
   


  const formatNumero = (numero) => {
    if(!numero) return "-"
    return numero.toLocaleString();
  };

  const showhideBody = () => {
    setBodyVisible(!bodyVisible)
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
  const leyBtn = bodyVisible ? "-" : "+";

    return (
     <div className="col col-sm-12 col-md-12">
      <div className="card">
        <div className="card-header ">
          <div className="row">
          <div className="col-8 ">
            <span>
              <b>Operaciones</b> {tabla.length > 0 && "-"} {Object.entries(montosNetos).map( ([fecha, monto]) =><button onClick={() => setFiltroFecha(fecha) } className='btn btn-sm active'><b>{fecha}:</b> {formatNumero(monto)} </button>)} {filtroFecha != '' && <button onClick={() => setFiltroFecha('') } className='btn btn-sm btn-secondary'>quitar filtro</button> }
             
            </span>
       
        
          </div>
          <div className="col-4">
            <span>
    <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              <b>{leyBtn}</b>
            </button>
            <input
              placeholder="Cuenta"
              onChange={(e) => {
                setFiltro(e.target.value);
              }}
              className="form-control-sm form-control   float-right ml-1"
              type="text"
              style={{ "maxWidth": "80px" }}
            />
             
            </span>
          </div>
          </div>
        </div>
        <div className={cardBodyClass}>
        <div  style={{'maxHeight': '60vh', minHeight: '10vh'}} className="tableFixHead table-responsive-md" >
          <table className="table table-sm table-bordered table-hover texto-small">
            <thead>
              <tr>
               <th scope="col">Tipo Op.</th>
                <th scope="col">Cuenta</th>
                <th scope="col">Especie</th>
                <th scope="col">Fecha Liq.</th>
                <th scope="col">Cantidad Compra</th>
                <th scope="col">Monto Compra</th>
                <th scope="col">Cantidad Venta</th>
                <th scope="col">Monto Venta</th>
                <th scope="col">Neto Cantidad</th>
                <th scope="col">Neto Monto</th>
                <th scope="col">PPP</th>
                <th scope="col">Tasa</th>
              </tr>
            </thead>
            <tbody>
              {tabla
                .filter((item) => item.comitente.includes(filtro) && item.fechLiq.includes(filtroFecha)  )
                .map((item, index) => (
                  <tr key={index}>
                    <th scope="col">{item.operatoria}</th>
                    <th scope="col">{item.comitente}</th>
                    <td className='text-right'>{item.especie}</td>
                    <td>{item.fechLiq}</td>
                    <td className='text-right'>{formatNumero(item.cc)}</td>
                    <td className='text-right'>{formatNumero(item.mc)}</td>
                    <td className='text-right'>{formatNumero(item.cv)}</td>
                    <td className='text-right'>{formatNumero(item.mv)}</td>
                    <td className='text-right'>{formatNumero(item.nc)}</td>
                    <td className='text-right'>{formatNumero(item.nm)}</td>
                    <td className='text-right'>{formatNumero(item.ppp)}</td>
                    <td className='text-right'>{ item.nc != 0 ? formatNumero(Math.abs(item.tasa)) : "-" }</td>
           

                  </tr>
                ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    )
}

export default OpersGralTableMAV
