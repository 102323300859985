const baseURL = process.env.REACT_APP_API_URL;

export const delCtasProp = async (options, id) => {
    let json =  []
    try {
      const res = await fetch(`${baseURL}genDayTrading/CtasProp/${id}`, { method: 'DELETE', headers: options.headers });
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };