import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTrashAlt, faCopy, faSave, faEdit } from "@fortawesome/free-solid-svg-icons";
//import {  getComitentes } from "../services/listaComitentes";
import useServices from "../hooks/useServices";
import FileUploadButtonSimple from "./FileUploadButtonSimple";

const baseURL = process.env.REACT_APP_API_URL;
const TablaComitentes = () => {
  const [datos, setDatos] = useState([]);
  const [razonSocial, setRazonSocial] = useState("");
  const [cuit, setCuit] = useState("");
  const [nroByma, setNroByma] = useState("");
  const [nroMTR, setNroMTR] = useState("");
  const { delComitente, addComitente, getComitentes, setComitente } = useServices();

  async function cargaDatos() {
    const listaComitentes = await getComitentes();
    //console.log({ listaComitentes });
    setDatos(listaComitentes);
  }

  function selectElementContents(el) {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
  }
  async function handleDelComitente(id) {
    //console.log(id);
    const respuesta = await delComitente(id);

    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }

  async function handleAddComitente() {
    const respuesta = await addComitente({ razonSocial, nroByma, nroMTR, cuit });

    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }
  async function handleSetComitente (comitente) {
    comitente.editable = false;
    const respuesta = await setComitente(comitente);
    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }
  function setEditable(id) {
    datos.find(item => item.id == id).editable = true;
    setDatos([...datos])
  }

  useEffect(() => {
    cargaDatos();
  }, []);
  function editDato(valor, columna, id) {
    //   console.log(valor, columna, id)
       let newDatos = [...datos];
       newDatos.find(item => item.id == id)[columna] = valor;
       setDatos([...newDatos])
  } 

  return (
    <div className="card">
      <div className="card-header ">
        <span className="ml-1">
          <b>Comitentes</b>
        </span>{" "}
        <span className="badge badge-secondary"></span>
        <button
          type="button"
          name=""
          data-toggle="tooltip"
          data-placement="bottom"
          title="Copiar"
          onClick={() => {
            selectElementContents(document.getElementById("tb_comit"));
          }}
          className="btn btn-secondary float-right ml-1"
        >
          <FontAwesomeIcon icon={faCopy} />
        </button>
{/*         <button
          type="button"
          name=""
          data-toggle="tooltip"
          data-placement="bottom"
          title="Carga masiva"
          className="btn btn-warning float-right ml-1"
        >
          <FontAwesomeIcon icon={faUpload} />
        </button> */}
        <FileUploadButtonSimple callBack={cargaDatos} endPoint={`${baseURL}upload/cargaMasivaTablaComitentes`} className={"float-right mr-1"} icon={<FontAwesomeIcon className="ml-2" icon={faUpload} />} btnText="Carga Masiva" btnClassName="btn btn-warning"  />
      </div>

      <div style={{ maxHeight: "95vh", overflowY: "auto" }} className="card-body">
        <table id={"tb_comit"} className="table table-sm table-bordered table-hover ">
          <thead>
            <tr>
              <th scope="col">Razon Social</th>
              <th scope="col">CUIL/CUIT</th>
              <th scope="col">Nro. Cuenta BYMA</th>
              <th scope="col">Nro. Cuenta ROFEX</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {" "}
                <input onChange={(e) => setRazonSocial(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                {" "}
                <input onChange={(e) => setCuit(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                {" "}
                <input onChange={(e) => setNroByma(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                {" "}
                <input onChange={(e) => setNroMTR(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                {" "}
                <button onClick={() => handleAddComitente()} className="btn btn-success btn-sm">
                  <b>+</b>
                </button>
              </td>
            </tr>

            {datos.map((linea) => (
              <tr key={linea.id}>
                {!linea.editable && <>
                <td> {linea.razonSocial} </td>
                <td> {linea.cuit} </td>
                <td> {linea.nroByma} </td>
                <td> {linea.nroMTR} </td>
                </>
              } { linea.editable &&
                <>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'razonSocial', linea.id)} value = {linea.razonSocial} className="form-control" type="text" />  </td>
                 <td> <input onChange={(e) => editDato(e.target.value, 'cuit', linea.id)} value = {linea.cuit} className="form-control" type="text" />  </td>
                 <td><input onChange={(e) => editDato(e.target.value, 'nroByma', linea.id)} value = {linea.nroByma} className="form-control" type="text" />  </td>
                 <td><input onChange={(e) => editDato(e.target.value, 'nroMTR', linea.id)} value = {linea.nroMTR} className="form-control" type="text" />  </td>
                </>

              }
                <td>
                  <button
                    onClick={() => {
                      handleDelComitente(linea.id);
                    }}
                    className="btn btn-danger btn-sm"
                  >
                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => {
                      setEditable(linea.id);
                    }}
                    className="btn btn-warning btn-sm"
                  >
                     <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                  </button>
                </td>
                {linea.editable &&
                <td>
                  <button
                    onClick={() => {
                      handleSetComitente(linea);
                    }}
                    className="btn btn-success btn-sm"
                  >
                     <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                  </button>
                </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablaComitentes;
