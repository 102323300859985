import React, { useState, useEffect } from "react";
import Plazotable from "../components/Plazotable";
import RdosEjercicios from "../components/RdosEjercicios"
//import getResumenOperdiaEjercicios from "../services/getResumenOperdiaEjercicios";
//import getResultadoEjercicioVencimiento from "../services/getResultadoEjercicioVencimiento" 
//import getResultadoEjercicioDia from "../services/getResultadoEjercicioDia"
//import getArchivoEjercicios from "../services/getArchivoEjercicios"
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";

const EjerciciosTrader = () => {
  useDocumentTitle('Ejercicios BYMA')
  const moneda = "P";
  const [info48hs, setInfo48hs] = useState({});
  const [rdoEjerciciosVencimiento, setRdoEjerciciosVencimiento] = useState([]);
  const [rdoEjerciciosDia, setRdoEjerciciosDia] = useState([]);
  const {getResumenOperdiaEjerciciosTrader, getResultadoEjercicioVencimientoTrader, getResultadoEjercicioDiaTrader, getArchivoEjercicios} = useServices();

  useEffect(() => {
    cargaOperdia();
    cargaRdosEjercicios();
  }, []);

  async function cargaOperdia() {
    let operContado = await getResumenOperdiaEjerciciosTrader();
    if(operContado.error) return
    if (operContado.tabla["48hs"] != undefined) {
     
      const operContadoIntrad = operContado.tabla["48hs"][moneda];
      setInfo48hs({ P: operContadoIntrad });
    }
  }


async function cargaRdosEjercicios () {

  const rdoEjerciciosVencimiento = await getResultadoEjercicioVencimientoTrader();
  if(rdoEjerciciosVencimiento.error) return 
  //console.log({rdoEjerciciosVencimiento})
  if(rdoEjerciciosVencimiento.tablaRdosEjerc) {
  setRdoEjerciciosVencimiento(rdoEjerciciosVencimiento.tablaRdosEjerc)
  }
 const rdoEjerciciosDia = await getResultadoEjercicioDiaTrader();
 if(rdoEjerciciosDia.tablaRdosEjerc) {
  setRdoEjerciciosDia(rdoEjerciciosDia.tablaRdosEjerc)
  }


}



function downloadFile(file) {
  let filename, link;
  filename = file.fileName;
  let data = "data:text/txt;charset=utf-8," + file.data;
  data = encodeURI(data);
  link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", filename);
  link.click();
}

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-sm-12 col-md-12">
          <Plazotable moneda={moneda} titulo="48hs" datos={info48hs} initBodyVisb={true} />
          <div className="col12"> 
          <div className="card">
            <div className="card-header ">
              <div className="row justify-content-center">
          
          </div>
          </div>
          </div>
          
          </div>
         
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
           < RdosEjercicios  tabla={rdoEjerciciosVencimiento}  descripcion={'al vencimiento'} />
        
            </div>
          </div>
          <div className="row">
            <div className="col">
            < RdosEjercicios  tabla={rdoEjerciciosDia}  descripcion={'del día'}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EjerciciosTrader;
