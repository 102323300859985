import React from "react";
import TablaFeriados from "../components/TablaFeriados"
import useDocumentTitle from "../hooks/useDocumentTitle";

const Feriados = () => {
  useDocumentTitle('Tabla Feriados')
  return (
    <div className="container-fluid">
    <div className="row">
      <div className="col">
          <TablaFeriados />
  
      </div>
    </div>
    </div>
  );
};

export default Feriados;
