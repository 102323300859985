import React, { useState, useEffect } from "react";
import Paneldheader from "../components/PanelHeader";
import Plazotable from "../components/Plazotable";
import SaldosDerivaciones from "../components/SaldosDerivaciones";
import CaucionTable from "../components/CaucionTable";

//import getResumenOperdiaContadoDerivaciones from "../services/getResumenOperdiaContadoDerivaciones";
//import getResumenOperdiaCaucionDerivaciones from "../services/getResumenOperdiaCaucionDerivaciones";
//import getResumenOperdiaOpciones from "../services/getResumenOperdiaOpciones";
//import getSaldosBYMA from "../services/getSaldosBYMA";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
const hasSENEBI = process.env.REACT_APP_BYMA_SENEBI  === "true";
const PanelDerivaciones = () => {
  useDocumentTitle('Panel de Derivaciones BYMA')
  const [infoCI, setInfoCI] = useState([]);
  const [info24hs, setInfo24hs] = useState([]);
  const [info48hs, setInfo48hs] = useState([]);
  const [infoCISB, setInfoCISB] = useState([]);
  const [info24hsSB, setInfo24hsSB] = useState([]);
  const [info48hsSB, setInfo48hsSB] = useState([]);
  const [moneda, setMoneda] = useState("P");
  const [cuentaOper, setCuentaOper] = useState(0);
  const [showCI, setShowCI] = useState(true);
  const [saldosCISB, setSaldosCISB] = useState({});
  const [show24hs, setShow24hs] = useState(true);
  const [show48hs, setShow48hs] = useState(true);
  const [saldosBYMA, setSaldosBYMA] = useState({});
  const [saldosCI, setSaldosCI] = useState({});
  const [saldosCaucion, setSaldosCaucion] = useState({});
  const [tablaCaucion, setTablaCaucion] = useState({});
  const [hayEjercicios, setHayEjercicios] = useState(false);
  const [hayVC, setHayVC] = useState(false)
  const [saldosDerivaciones, setSaldosDerivaciones] = useState(false)
  const [saldosDerivacionesSB, setSaldosDerivacionesSB] = useState(false)
  const [showPanelSENEBI, setShowPanelSENEBI] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cantidadOpersCI, setCantidadOpersCI] = useState(0);
  const [horaUltimaOperacion, setHoraUltimaOperacion] = useState("");
  const [horaUltimaOperacionCI, setHoraUltimaOperacionCI] = useState("");
  const [origin, setOrigin] = useState("N/D");
  const [cantidadOpersCaucion, setCantidadOpersCaucion] = useState(0);
  const [horaUltimaOperacionCaucion, setHoraUltimaOperacionCaucion] = useState("");
  const [cantidadOperTotal, setCantidadOperTotal] = useState(0);

  const [isLoadingContado, setisLoadingContado] = useState(false);
  const [isLoadingCauciones, setisLoadingCauciones] = useState(false);
  
  //const [info_procesada, setInfoprocesada] = useState({})

  let info_procesada;

  const {getResumenOperdiaContadoDerivaciones, getResumenOperdiaContadoDerivacionesSB, getResumenOperdiaCaucionDerivaciones, getDerivacionesPPTLiqDia, getDerivacionesSBLiqDia} = useServices();

  useEffect(() => {
    initPanel();
  }, []);


  async function cargaDerivacionesDiaAnt () {
    const res = await getDerivacionesPPTLiqDia();
    if(res.error) return;
    setSaldosDerivaciones(res)
  }
  async function cargaDerivacionesDiaAntSB () {
    const res = await getDerivacionesSBLiqDia();
    if(res.error) return;
    setSaldosDerivacionesSB(res)
  }

  async function carga_oper_gara() {
    setisLoadingContado(true);
    let operdia = await getResumenOperdiaContadoDerivaciones();
    setisLoadingContado(false);
    if(operdia.error) return

    info_procesada = operdia;

    setCuentaOper( prev => prev + info_procesada.cantOper);
    //console.log({ info_procesada });
    setHayEjercicios(info_procesada.hayEjercicios)
    setHayVC(info_procesada.hayVC)
    
    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCI(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hs(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hs(info_procesada.tabla["48hs"]);
      }
    }
 /*    if (info_procesada.saldos) {
      setSaldosCI(info_procesada.saldos["Inmediato"]);
    } */
    if(info_procesada.cantidadOpersCI) {
      setCantidadOpersCI(info_procesada.cantidadOpersCI)
    }
    if(info_procesada.cantidadOperTotal) {
      setCantidadOperTotal(info_procesada.cantidadOperTotal)
    }
    if(info_procesada.horaUltimaOperacion) {
      setHoraUltimaOperacion(info_procesada.horaUltimaOperacion)
    }
    if(info_procesada.horaUltimaOperacionCI) {
      setHoraUltimaOperacionCI(info_procesada.horaUltimaOperacionCI)
    }
    if(info_procesada.origen) {
      setOrigin(info_procesada.origen)
    }

    return true;
  }

  async function carga_oper_gara_SBDerivaciones() {
    let operdia = await getResumenOperdiaContadoDerivacionesSB();
    if (operdia.error) return;

    const info_procesada = operdia;

    setCuentaOper(prev => prev + info_procesada.cantOper);
    //setCuentaOper(info_procesada.cantOper);
    //console.log({ info_procesada });
    
    if(info_procesada.cantOper > 0) { setShowPanelSENEBI(true) }
    
    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCISB(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hsSB(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hsSB(info_procesada.tabla["48hs"]);
      }
/*       if (info_procesada.saldos) {
        setSaldosCISB(info_procesada.saldos["Inmediato"]);
      } */
    }
    
    return true;
  }

  async function initPanel() {
    setIsLoading(true)
    // cargaSaldosBYMA();
    await carga_oper_gara();
    await cargaResumenCaucGara();
    //  await cargaResumenOpc();
    await cargaDerivacionesDiaAnt();
    if (hasSENEBI) {
      await carga_oper_gara_SBDerivaciones();
      await cargaDerivacionesDiaAntSB();
    }
    setIsLoading(false)
  }

  async function reloadPanelSaldos() {
    setIsLoading(true)
    //cargaSaldosBYMA();
    setCuentaOper(0)
    await carga_oper_gara();
    await cargaResumenCaucGara();
    await cargaDerivacionesDiaAnt()
    if(hasSENEBI) { 
      await carga_oper_gara_SBDerivaciones() 
      await cargaDerivacionesDiaAntSB()
    }
    setIsLoading(false)
  }

  async function reloadContado () {
    setCuentaOper(0)
    await carga_oper_gara();
    if(hasSENEBI) { 
      await carga_oper_gara_SBDerivaciones() 
      await cargaDerivacionesDiaAntSB()
         }
  }


  async function cargaResumenCaucGara() {
    setisLoadingCauciones(true);
    const resumenCaucion = await getResumenOperdiaCaucionDerivaciones();
    setisLoadingCauciones(false);
    if(resumenCaucion.error) return
    //console.log({ resumenCaucion });
    if (resumenCaucion.saldos) {
      setSaldosCaucion(resumenCaucion.saldos);
    }
    if (resumenCaucion.tabla) {
      setTablaCaucion(resumenCaucion.tabla);
    }
    if(resumenCaucion.cantidadOpers) {
      setCantidadOpersCaucion(resumenCaucion.cantidadOpers)
    }
    if(resumenCaucion.horaUltimaOperacion) {
      setHoraUltimaOperacionCaucion(resumenCaucion.horaUltimaOperacion)
    }
    return true;
  }


  const showhidePanel = (idpanel) => {
    switch (idpanel) {
      case "CI":
        setShowCI(!showCI);
        break;
      case "24hs":
        setShow24hs(!show24hs);
        break;
      case "48hs":
        setShow48hs(!show48hs);
        break;
      case "SENEBI":
        setShowPanelSENEBI(!showPanelSENEBI);
        break;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row no-gutters">
        <div className="col"></div>
        <SaldosDerivaciones reloadPanelSaldos={reloadPanelSaldos} saldosCaucion={saldosCaucion} moneda="P" saldosCI={saldosCI} saldos={saldosBYMA} saldosDerivaciones={saldosDerivaciones} saldosDerivacionesSB={saldosDerivacionesSB} isLoading={isLoading} />

        <SaldosDerivaciones reloadPanelSaldos={reloadPanelSaldos}  saldosCaucion={saldosCaucion} moneda="D" saldosCI={saldosCI} saldos={saldosBYMA} saldosDerivaciones={saldosDerivaciones}  saldosDerivacionesSB={saldosDerivacionesSB} isLoading={isLoading} />
        <SaldosDerivaciones reloadPanelSaldos={reloadPanelSaldos}  saldosCaucion={saldosCaucion} moneda="C" saldosCI={saldosCI} saldos={saldosBYMA} saldosDerivaciones={saldosDerivaciones} saldosDerivacionesSB={saldosDerivacionesSB} isLoading={isLoading} />
        <div className="col"></div>
      </div>
      <Paneldheader
        showhidePanel={showhidePanel}
        setMoneda={setMoneda}
        moneda={moneda}
        cuentaOper={cuentaOper}
        cargaContado={reloadContado}
        cargaCauciones={cargaResumenCaucGara}
        cargaOpciones={false}
        hayEjercicios={hayEjercicios}
        hayVC={hayVC}
        panelShow = {{showCI, show24hs, show48hs, showPanelSENEBI}}
        horaUltimaOperacion={horaUltimaOperacion}
        horaUltimaOperacionCI={horaUltimaOperacionCI}
        opersOrigin={origin}
        cantidadOpersCI={cantidadOpersCI}
        cantidadOperTotal={cantidadOperTotal}
        cantidadOpersCaucion={cantidadOpersCaucion}
        horaUltimaOperacionCaucion={horaUltimaOperacionCaucion}
        isLoadingContado={isLoadingContado}
        isLoadingCauciones={isLoadingCauciones}

      

      />

      <div className="row no-gutters">
      {showCI && <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="CI - Derivaciones" datos={infoCI} initBodyVisb={true} />
        </div>}
        {show24hs && <div className="col-lg col-sm-12 col-md-12">
         <Plazotable moneda={moneda} titulo="24hs - Derivaciones" datos={info24hs}  initBodyVisb={true} />
        </div>}

      </div>

      <div className="row no-gutters">
        <CaucionTable moneda={moneda} datos={tablaCaucion} /> {/* <OpcionesTable datos={tablaOpciones} /> */}
      </div>
      {  (hasSENEBI && showPanelSENEBI) &&    <div className="row no-gutters">
        {showCI && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="CI - Derivaciones - SENEBI" datos={infoCISB} />
          </div>
        )}
        {show24hs && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="24hs - Derivaciones - SENEBI" datos={info24hsSB} />
          </div>
        )}
    
      </div>}
    </div>
  );
};

export default PanelDerivaciones;
