import React, { useEffect, useState } from "react";
import CardTemplate from "../components/CardTemplate";

//import getPosiOpcionesTrader from "../services/getPosiOpcionesTrader";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";

const NeteoCaucTrader = () => {
  useDocumentTitle('Posiciones de cauciones BYMA')
  const [tablaCauc, setTablaCauc] = useState([]);
  const {getResumenNeteoCaucionesGARATrader} = useServices();
  const [filtroCuenta, setfiltroCuenta] = useState('')
  const [filtroMontoCierre, setfiltroMontoCierre] = useState(true)
  const [filtroVencimiento, setfiltroVencimiento] = useState('')

  async function cargaDatos() {
    
    const res = await getResumenNeteoCaucionesGARATrader();
    if(res.error) {return}
    setTablaCauc(res)

  }

  const formatNumero = (num = 0) => {
  return num.toLocaleString()
  }

  useEffect(() => {
    cargaDatos();
  }, []);

  return (
    <div className="container-fluid">
    <div className="row">
       <div className="col-sm-0 col-lg-3 col-md-2"></div>
      <div className="col-sm-12 col-lg-6 col-md-8">
        <CardTemplate title={"Cauciones BYMA"} description={"Posiciones"}>
        
          <table className="table table-sm table-bordered table-hover">
            <thead className="thead-light">
              <tr>
                <th> <input type="text" value={filtroCuenta} onChange= {(e) => setfiltroCuenta(e.target.value) } placeholder="Cuenta" className="form-control" /> </th>
                <th> <input type="text" value={filtroVencimiento} onChange= {(e) => setfiltroVencimiento(e.target.value) } placeholder="Vencimiento" className="form-control" /> </th>
                <th></th>
                <th></th>
                <th className="text-center">con cierre <input type="checkbox" checked={filtroMontoCierre} onClick={() => setfiltroMontoCierre(!filtroMontoCierre) } /> </th>
                <th></th>
              </tr>
              <tr>
                <th scope="col">Cuenta</th>
                <th scope="col">Vencimiento</th>
                <th scope="col">Tipo</th>
                <th scope="col">Monto Apertura</th>
                <th scope="col">Monto Cierre</th>
                <th scope="col">Neto</th>
              </tr>
            </thead>
            <tbody>
              {tablaCauc.filter(item => item.cuenta.includes(filtroCuenta) && item.fechaLiq.includes(filtroVencimiento) && (filtroMontoCierre ? item.montoCierre > 0 : true) ).map((item) => (
                <tr key={item.cuenta + item.fechaLiq + item.tipoOp}>
                  <td>{item.cuenta}</td>
                  <td>{item.fechaLiq}</td>
                  <td>{item.tipoOp}</td>
                  <td className="text-right">{formatNumero(item.montoApertura)}</td>
                  <td className="text-right">{formatNumero(item.montoCierre)}</td>
                  <td className="text-right">{formatNumero(item.montoApertura - item.montoCierre)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardTemplate>
      </div>
      <div className="col-sm-0 col-lg-6 col-md-2"></div>
    </div>
    </div>
  );
};

export default NeteoCaucTrader;
