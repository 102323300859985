
const baseURL = process.env.REACT_APP_API_URL;

//console.log({baseURL})

const getResumenOperdiaContadoTrader = async (options) => {
  let json =  []

 //const options = {headers: { 'Authorization': `Bearer ${getTKN()}`} }
  try {
    const res = await fetch(`${baseURL}hub/resumenOperacionesContadoGarantizadoTrader`,options);
    json = await res.json();
/*     if(json.error) {
      //console.log("entro erroooor en fetch cliente ------>")
      //console.log({json})
  
    } */
  } catch (e) {
    return { error: e };
  }

  return json;
};

export default getResumenOperdiaContadoTrader;
