import React, { useContext, useState } from "react";
import "../css/custom.css";
import { Link } from "react-router-dom";
import { menuList } from "../vars/menuList";
import { useLocation } from "react-router-dom";
import useSession from "../hooks/useSession";
import SessionContext from "../context/SessionContext";

const QuickMenu = () => {
  /*   const menuList = [
    { title: "Panel de Operaciones BYMA", link: "/panel" },
    { title: "Nominales BYMA", link: "/nominales" },
    { title: "Control Nominales", link: "/controlnominales" },
    { title: "Saldos BOP", link: "/saldosbop" },
    { title: "SDIB", link: "/sdib" },
    { title: "Vencimientos Caucion", link: "/posicauc" },
    { title: "Posición Opciones", link: "/posiopc" },
    { title: "RG624", link: "/rg624" },
    { title: "SDIB", link: "/sdib" },
    { title: "Tabla Comitentes", link: "/comitentes" },
    { title: "Tabla Feriados", link: "/feriados" },
  ]; */
 const [show, setShow] = useState(false)
  const { logout } = useSession();
  let location = useLocation();
  const context = useContext(SessionContext);
  //console.log({location})
  const notAllowLocations = ["/", "/menu", "/login", "/about"];

  if (notAllowLocations.indexOf(location.pathname) > -1) return null;
  const { userCategory } = context;
  //console.log({userCategory})
  const menuShow = menuList[userCategory] || [];
  const menusBYMA = menuShow.filter((item) => item.mercado === "BYMA");
  const menusMAV = menuShow.filter((item) => item.mercado === "MAV");
  const menusMTR = menuShow.filter((item) => item.mercado === "MTR");
  const menusMULTI = menuShow.filter((item) => item.mercado === "MULTI");
  const menusOtros = menuShow.filter((item) => !item.mercado);

const menuStyles = {
  position: "fixed",
  bottom: "5px",
  right:"5px",
  maxWidth: "250px",
  'maxHeight': '90vh',
  "zIndex": 99
}

  return (
    <div style={menuStyles}>

      <div className={show ? "" : "d-none" } >
      <div className="accordion"  id="accordionMenu">
        <div className="card ">
          <div className="card-header p-1" id="headingBYMA">
            <button
              className="btn btn-sm btn-link btn-block text-center"
              type="button"
              data-toggle="collapse"
              data-target="#collapseBYMA"
              aria-expanded="true"
              aria-controls="collapseBYMA"
            >
              BYMA
            </button>
          </div>
          <div id="collapseBYMA" className="collapse " aria-labelledby="headingBYMA" data-parent="#accordionMenu">
            <div style={{'maxHeight': '30vh', 'overflow-y': 'auto'}} className="card-body p-0 border border-primary tableFixHead">
              <div className="list-group">
                {menusBYMA.map((item) => (
                  <Link key={item.link} onClick={ () => setShow(false) } to={item.link}>
                    <button type="button" class="btn btn-sm list-group-item list-group-item-action p-1">
                      {item.title}
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
{ menusMAV.length > 0 &&      <div className="card">
          <div className="card-header p-1" id="headingMAV">
            <button
              className="btn  btn-sm btn-link btn-block text-center"
              type="button"
              data-toggle="collapse"
              data-target="#collapseMAV"
              aria-expanded="true"
              aria-controls="collapseMAV"
            >
              MAV
            </button>
          </div>
          <div id="collapseMAV" className="collapse " aria-labelledby="headingMAV" data-parent="#accordionMenu">
            <div style={{'maxHeight': '30vh', 'overflow-y': 'auto'}}  className="card-body p-0 border border-primary tableFixHead">
              <div className="list-group">
                {menusMAV.map((item) => (
                  <Link onClick={ () => setShow(false) }  to={item.link}>
                    <button type="button" class="btn btn-sm list-group-item list-group-item-action p-1">
                      {item.title}
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>}
      { menusMTR.length > 0 &&    <div className="card">
          <div className="card-header p-1" id="headingMTR">
            <button
              className="btn  btn-sm btn-link btn-block text-center"
              type="button"
              data-toggle="collapse"
              data-target="#collapseMTR"
              aria-expanded="true"
              aria-controls="collapseMTR"
            >
              MTR
            </button>
          </div>
          <div id="collapseMTR" className="collapse " aria-labelledby="headingMTR" data-parent="#accordionMenu">
            <div className="card-body p-0 border border-primary">
              <div className="list-group">
                {menusMTR.map((item) => (
                  <Link onClick={ () => setShow(false) }  to={item.link}>
                    <button type="button" class="btn btn-sm list-group-item list-group-item-action p-1">
                      {item.title}
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div> }
        { menusMAV.length > 0 &&      <div className="card">
          <div className="card-header p-1" id="headingMULTIMERCADO">
            <button
              className="btn  btn-sm btn-link btn-block text-center"
              type="button"
              data-toggle="collapse"
              data-target="#collapseMULTIMERCADO"
              aria-expanded="true"
              aria-controls="collapseMULTIMERCADO"
            >
              MULTIMERCADO
            </button>
          </div>
          <div id="collapseMULTIMERCADO" className="collapse " aria-labelledby="headingMULTIMERCADO" data-parent="#accordionMenu">
            <div style={{'maxHeight': '30vh', 'overflow-y': 'auto'}}  className="card-body p-0 border border-primary tableFixHead">
              <div className="list-group">
                {menusMULTI.map((item) => (
                  <Link onClick={ () => setShow(false) }  to={item.link}>
                    <button type="button" class="btn btn-sm list-group-item list-group-item-action p-1">
                      {item.title}
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>}
      </div>

      {menusOtros.map((item) => (
              <Link onClick={ () => setShow(false) }  to={item.link}>
                <button type="button" className="btn btn-sm list-group-item list-group-item-action p-1">
                  {item.title}
                </button>
              </Link>
            ))}

         <button
        
        className="btn btn-sm list-group-item list-group-item-action p-1"
        style={{ backgroundColor: "lightgray" }}
        onClick={() => {
          document.documentElement.classList.toggle("dark-mode");
          if(window.localStorage.getItem("darkmode")) { window.localStorage.removeItem("darkmode") } else {
            window.localStorage.setItem("darkmode", "true")
          }
        }}
      >
      <span > Darkmode</span> 
      </button>

                    <button
        
        className="btn btn-sm list-group-item list-group-item-action p-1"
        style={{ backgroundColor: "lightgray" }}
        onClick={() => {
          logout();
        }}
      >
      <span > Logout </span> 
      </button>


      </div>
      <button
        class="btn btn-sm btn-secondary dropdown-toggle float-right mt-1"
        type="button"
        onClick={() => setShow(!show) }
 
      >
        MENÚ
      </button>
    </div>
  );
};

export default QuickMenu;
