import React from "react";
import { useTable, useSortBy } from "react-table";

const Tablev2 = ({ data = [], columns = [], hasFooter = true }) => {
  const tableInstance = useTable({ columns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } = tableInstance;
  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  return (
    <table className="table table-sm table-bordered table-hover" {...getTableProps()}>
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {generateSortingIndicator(column)}
                </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => {
                    // Apply the cell props
                    return (
                      <td {...cell.getCellProps({ className: cell.column.className })}>
                  {/*    </td> <td {...cell.getCellProps()}> */}
                        {
                          // Render the cell contents
                          cell.render("Cell")
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
{  hasFooter && <tfoot>
              {
                footerGroups.map(footerGroup => (
                  <tr {...footerGroup.getFooterGroupProps()}>
                    {
                      footerGroup.headers.map(column => (
                        <td  {...column.getFooterProps} {...column.getFooterProps({ className: column.className })} > {column.render('Footer')} </td>
                      ))
                    }

                  </tr>
                ))
              }
       
       </tfoot>}
   
    </table>
  );
};

export default Tablev2;
