import React, { useEffect, useState } from "react";
import shortid from "shortid";
import { faSave, faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function combineAndSumObjects(obj1, obj2) {
  // Helper function to sum two objects
  const copyObj1 = structuredClone(obj1);
  const copyObj2 = structuredClone(obj2);
  function sumObjects(a, b) {
      for (let key in b) {
            if(b[key] === "") {b[key] = 0 }
            if(a[key] === "") {a[key] = 0 }
          if (b.hasOwnProperty(key)) {
              if (typeof b[key] === 'object' && !Array.isArray(b[key])) {
                  a[key] = sumObjects(a[key] || {}, b[key]);
              } else if (typeof b[key] === 'number') {
                  a[key] = (a[key] || 0) + b[key];
              } else {
                  a[key] = b[key];
              }
          }
      }
      return a;
  }

  // Helper function to combine two arrays of objects
  function combineArrays(arr1, arr2) {
      const combined = [...arr1];
      for (const item2 of arr2) {
          const index = combined.findIndex(item1 => item1.cuenta === item2.cuenta && item1.codespecie === item2.codespecie);
          if (index >= 0) {
              combined[index] = sumObjects(combined[index], item2);
          } else {
              combined.push(item2);
          }
      }
      return combined;
  }

  // Main function logic
  const result = {};
  for (let key in copyObj1) {
      if (copyObj1.hasOwnProperty(key)) {
          if (typeof copyObj1[key] === 'object' && !Array.isArray(copyObj1[key])) {
              result[key] = combineAndSumObjects(copyObj1[key], copyObj2[key] || {});
          } else if (Array.isArray(copyObj1[key])) {
              result[key] = combineArrays(copyObj1[key], copyObj2[key] || []);
          } else {
              result[key] = copyObj1[key];
          }
      }
  }

  for (let key in copyObj2) {
      if (copyObj2.hasOwnProperty(key) && !result.hasOwnProperty(key)) {
          result[key] = copyObj2[key];
      }
  }

  return result;
}

const WidgetResumenEspMon = ({ data = {}, dataSB = {}, setUserConfig = () => {}, userConfig = {}, initBodyVisb = false }) => {
  const [tableDate, setTableDate] = useState([]);
  const [diccEspeciesConfig, setDiccEspeciesConfig] = useState({});
  
  const { lista, especies, diccEspecies, cuentas } = generaListaTabla(data, dataSB, diccEspeciesConfig);
  const [selectedCuenta, setSelectedCuenta] = useState(cuentas[0]);
  const [comaValuesEnabled, setComaValuesEnabled] = useState(false);
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
  const [parametrosTasa, setParametrosTasa] = useState({ tasa: 33, "48hs": 2, "24hs": 1 });
  const [tasaCanje, setTasaCanje] = useState(1);
  const [diccStock, setDiccStock] = useState({});

  async function handleSaveUserConfig() {
    const widgetResumenEspMon = { tableDate, selectedCuenta: selectedCuenta || cuentas[0], diccStock, diccEspecies };

    const newConfig = { ...userConfig, widgetResumenEspMon };
    const res = await setUserConfig(newConfig);
    if (res.error) return;
    alert("Configuracion guardada");
  }

  useEffect(() => {
    if (userConfig.widgetResumenEspMon) {
      if (userConfig.widgetResumenEspMon.tableDate) {
        setTableDate(userConfig.widgetResumenEspMon.tableDate);
      }
      if (userConfig.widgetResumenEspMon.selectedCuenta) {
        setSelectedCuenta(userConfig.widgetResumenEspMon.selectedCuenta);
      }
      if (userConfig.widgetResumenEspMon.diccStock) {
        setDiccStock(userConfig.widgetResumenEspMon.diccStock);
      }
      if (userConfig.widgetResumenEspMon.diccEspecies) {
        setDiccEspeciesConfig(userConfig.widgetResumenEspMon.diccEspecies);
      }
    }
  }, [userConfig]);

  function generaListaTabla(data1, data2, diccEspeciesConfig = {}) {
    //console.log({data1, data2})
    const infoProcesadaTabla = combineAndSumObjects(data1, data2);
    let lista = [];
    let diccEspecies = {...diccEspeciesConfig};
    let cuentas = new Set();
    for (const plazo in infoProcesadaTabla) {
      for (const moneda in infoProcesadaTabla[plazo]) {
        for (const idx in infoProcesadaTabla[plazo][moneda]) {
          const { codespecie, nroCajval, cuenta } = infoProcesadaTabla[plazo][moneda][idx];
          if (diccEspecies[`${codespecie} - ${plazo}`] == undefined) {
            diccEspecies[`${codespecie} - ${plazo}`] = { codespecie, plazo, moneda, nroCajval, idNem: `${codespecie} - ${plazo}` };
          }
          cuentas.add(cuenta);
          lista.push({ ...infoProcesadaTabla[plazo][moneda][idx], plazo, moneda });
        }
      }
    }
    let especies = Object.entries(diccEspecies).map(([key, value]) => value);
    especies.sort(function (str1, str2) {
      return str1.idNem.localeCompare(str2.idNem);
    });

    return { lista, especies, diccEspecies, cuentas: [...cuentas] };
  }
  //console.log(lista);
  //console.log(especies);
  function addRow() {
    setTableDate([...tableDate, { especieSelected: especies?.[0]?.idNem || "", id: shortid.generate() }]);
  }
  function delRow(id) {
    const newTable = [...tableDate.filter((item) => item.id != id)];
    setTableDate(newTable);
  }

  function handleSetEspcie(value, id) {
    //console.log({ value, id });
    let newTable = [...tableDate];
    let found = tableDate.find((item) => item.id == id);
    found.especieSelected = value;
    setTableDate(newTable);
  }

  function getCantidadTotal(selectedCuenta, especieSelected) {
    let suma = 0;
    if (!selectedCuenta) {
      selectedCuenta = cuentas[0];
    }
    if (diccEspecies[especieSelected]) {
      const { nroCajval, plazo } = diccEspecies[especieSelected];
      lista
        .filter((item) => item.cuenta == selectedCuenta && item.nroCajval == nroCajval && item.plazo == plazo)
        .forEach((item) => {
          suma += item.nc * 1;
        });
    }
    return suma;
  }

  function getPrecioMontoCantidadPorMoneda(selectedCuenta, especieSelected, moneda) {
    let precio = 0;
    let cantidad = 0;
    let monto = 0;
    if (!selectedCuenta) {
      selectedCuenta = cuentas[0];
    }
    //console.log({ selectedCuenta });
    if (diccEspecies[especieSelected]) {
      const { nroCajval, plazo } = diccEspecies[especieSelected];

      const item = lista.find(
        (item) => item.cuenta == selectedCuenta && item.nroCajval == nroCajval && item.plazo == plazo && item.moneda == moneda
      );
      if (item) {
        precio = Math.abs(item.ppp);
        if (item?.categoria == "Titulos Publicos") {
          precio *= 100;
        }
        cantidad = item.nc;
        monto = item.nm;
      }
    }
    return [cantidad, precio, monto];
  }
  const formatNumero = (num = 0, maximumFractionDigits = 2) => {
    if (!num) return "";
    return num.toLocaleString("de", { maximumFractionDigits, minimumFractionDigits: 2 });
  };

  function cargaTodasLasEspecies() {
    //let newTable = [];
    let unicos = {};
    especies.forEach((especie) => {
      const { nroCajval, idNem, plazo } = especie;
      if (unicos[`${nroCajval}-${plazo}`] == undefined) {
        unicos[`${nroCajval}-${plazo}`] = idNem;
      }
    });
    const newTable = Object.entries(unicos).map(([key, value]) => {
      return { especieSelected: value, id: shortid.generate() };
    });
    setTableDate(newTable);
  }
  function borrarTodasLasEspecies() {
    setTableDate([]);
  }
  //console.log(tableDate);
  function tabletoClipboard(comaSeparatedValues = false) {
    const thead = `<table className="table table-sm table-bordered"> <thead> <tr> <th colspan="4"> </th> <th className="text-center" colspan="3"> Pesos </th> <th className="text-center" colspan="3"> Cable </th> <th className="text-center" colspan="3"> Dolar </th> <th className="text-center"> </th> </tr> <tr> <th>Especie</th> <th>Cantidad</th> <th>Ajuste</th> <th>Neto</th> <th>Cantidad</th> <th>Precio</th> <th>Monto</th> <th>Cantidad</th> <th>Precio</th> <th>Monto</th> <th>Cantidad</th> <th>Precio</th> <th>Monto</th></tr> </thead>`;
    let tbody = document.createElement("tbody");
    let tabla = [];
    tableDate.forEach((item) => {
      if (diccEspecies[item.especieSelected]) {
        // const {nroCajval, plazo} = diccEspecies[item.especieSelected];
        const row = [
          item.especieSelected,
          getCantidadTotal(selectedCuenta, item.especieSelected, diccEspecies),
          "",
          "",
          ...getPrecioMontoCantidadPorMoneda(selectedCuenta, item.especieSelected, "P"),
          ...getPrecioMontoCantidadPorMoneda(selectedCuenta, item.especieSelected, "C"),
          ...getPrecioMontoCantidadPorMoneda(selectedCuenta, item.especieSelected, "D"),
        ];
        tabla.push(row);
      }
    });
    //console.log(tabla)
    tabla.forEach((row) => {
      let tr = document.createElement("tr");
      row.forEach((elem) => {
        let td = document.createElement("td");
        td.innerText = elem;
        tr.append(td);
      });
      tbody.append(tr);
    });

    let table = `${thead}${tbody.outerHTML}</table>`;
    if (comaSeparatedValues) {
      table = table.replace(/\./g, ",");
    }
    //console.log(table)
    navigator.clipboard
      .writeText(table)
      .then(() => {
        console.log("Texto copiado al portapapeles");
      })
      .catch((err) => {
        console.error("Error al copiar al portapapeles:", err);
        alert("No se pudo copiar");
      });
  }
  function handleSetSeparadorDecimales(value) {
    if (value == "coma") {
      setComaValuesEnabled(true);
    }
    if (value == "punto") {
      setComaValuesEnabled(false);
    }
  }

  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none";
  const leyBtn = bodyVisible ? "-" : "+";

  const showhideBody = () => {
    setBodyVisible(!bodyVisible);
  };

  function handleSetParametrosTasa(prop, value) {
    let newParametros = { ...parametrosTasa };
    newParametros[prop] = value * 1;
    setParametrosTasa(newParametros);
  }

  function handleSetTasaCanje(value) {
    setTasaCanje((prev) => value * 1);
  }

  const tableRender = tableDate.map((item) => {
    const cantidadNetaEspecie = getCantidadTotal(selectedCuenta, item.especieSelected, diccEspecies);
    const precioMontoCantidadPesos = getPrecioMontoCantidadPorMoneda(selectedCuenta, item.especieSelected, "P");
    const precioMontoCantidadCable = getPrecioMontoCantidadPorMoneda(selectedCuenta, item.especieSelected, "C");
    const precioMontoCantidadDolar = getPrecioMontoCantidadPorMoneda(selectedCuenta, item.especieSelected, "D");
    return { ...item, cantidadNetaEspecie, precioMontoCantidadPesos, precioMontoCantidadCable, precioMontoCantidadDolar };
  });
  //console.log({ tableRender });

  function generaSumatoriaRender(tableRender = []) {
    let sumCantidadNetaEspecie = 0;
    let sumPrecioMontoCantidadPesos = { cantidad: 0, monto: 0 };
    let sumPrecioMontoCantidadCable = { cantidad: 0, monto: 0 };
    let sumPrecioMontoCantidadDolar = { cantidad: 0, monto: 0 };
    tableRender.forEach((item) => {
      sumCantidadNetaEspecie += item.cantidadNetaEspecie;
      sumPrecioMontoCantidadPesos.cantidad += item.precioMontoCantidadPesos[0] * 1;
      sumPrecioMontoCantidadPesos.monto += item.precioMontoCantidadPesos[2] * 1;
      sumPrecioMontoCantidadCable.cantidad += item.precioMontoCantidadCable[0] * 1;
      sumPrecioMontoCantidadCable.monto += item.precioMontoCantidadCable[2] * 1;
      sumPrecioMontoCantidadDolar.cantidad += item.precioMontoCantidadDolar[0] * 1;
      sumPrecioMontoCantidadDolar.monto += item.precioMontoCantidadDolar[2] * 1;
    });
    return { sumCantidadNetaEspecie, sumPrecioMontoCantidadPesos, sumPrecioMontoCantidadCable, sumPrecioMontoCantidadDolar };
  }

  const sumatoria = generaSumatoriaRender(tableRender);

  let agrupadosPorEspecie = {};

  tableRender.forEach((item) => {
    if (diccEspecies[item.especieSelected]) {
      const { nroCajval, plazo, codespecie } = diccEspecies[item.especieSelected];
      if (agrupadosPorEspecie[nroCajval] == undefined) {
        agrupadosPorEspecie[nroCajval] = {
          cantidadNetaEspecie: 0,
          codespecie,
          nroCajval,
          cantidadARS: 0,
          pppARS: 0,
          montoARS: 0,
          cantidadCable: 0,
          pppCable: 0,
          montoCable: 0,
          cantidadUSD: 0,
          pppUSD: 0,
          montoUSD: 0,
        };
      }
      agrupadosPorEspecie[nroCajval].cantidadNetaEspecie += item.cantidadNetaEspecie;
      agrupadosPorEspecie[nroCajval].cantidadARS += item.precioMontoCantidadPesos[0] * 1;
      agrupadosPorEspecie[nroCajval].cantidadCable += item.precioMontoCantidadCable[0] * 1;
      agrupadosPorEspecie[nroCajval].cantidadUSD += item.precioMontoCantidadDolar[0] * 1;
      agrupadosPorEspecie[nroCajval].montoUSD += item.precioMontoCantidadDolar[2] * 1;
      agrupadosPorEspecie[nroCajval].montoCable += item.precioMontoCantidadCable[2] * 1;

      if (plazo == "48hs") {
        agrupadosPorEspecie[nroCajval].montoARS += item.precioMontoCantidadPesos[2];
      }
      if (plazo == "24hs") {
        agrupadosPorEspecie[nroCajval].montoARS +=
          item.precioMontoCantidadPesos[2] * (1 + (parametrosTasa.tasa / (100 * 365)) * (parametrosTasa["48hs"] - parametrosTasa["24hs"]));
      }
      if (plazo == "Inmediato") {
        agrupadosPorEspecie[nroCajval].montoARS +=
          item.precioMontoCantidadPesos[2] * (1 + (parametrosTasa.tasa / (100 * 365)) * parametrosTasa["48hs"]);
      }
    }
  });
  //console.log({ agrupadosPorEspecie });
  const tablaAgrupados48hs = Object.entries(agrupadosPorEspecie).map(([key, item]) => {
    const found = lista.find((elem) => elem.codespecie == item.codespecie);
    const mutiplicador = found?.categoria == "Titulos Publicos" ? 100 : 1;
    item.pppARS = Math.abs((item.montoARS / item.cantidadARS) * mutiplicador);
    item.pppCable = Math.abs((item.montoCable / item.cantidadCable) * mutiplicador);
    item.pppUSD = Math.abs((item.montoUSD / item.cantidadUSD) * mutiplicador);
    item.mutiplicador = mutiplicador;

    return item;
  });

  //console.log({ tablaAgrupados48hs });
  const tablaCCL = tablaAgrupados48hs.map((item) => {
    const montoUSDConverido = item.montoUSD / (1 + tasaCanje / 100);
    const cantidadStock = item.cantidadARS + item.cantidadCable + item.cantidadUSD;
    const precioStock = diccStock?.[item.codespecie]?.precio || 0;
    const montoStock = precioStock * cantidadStock * (1 / item.mutiplicador);
    item.montoUSDCable = item.montoCable + montoUSDConverido + montoStock;
    item.cantidadUSDCable = item.cantidadCable + item.cantidadUSD - cantidadStock;
    item.pppUSDCable = Math.abs((item.montoUSDCable / item.cantidadUSDCable) * item.mutiplicador);
    item.netoTitulos = item.cantidadUSDCable * 1 + item.cantidadARS * 1;
    item.cantidadStockFijo = cantidadStock || 0;
    item.CCL = item.pppARS / item.pppUSDCable;

    return item;
  });

  function handleChangeStock(codespecie, col, value) {
    let newStock = { ...diccStock };
    if (newStock[codespecie] == undefined) {
      newStock[codespecie] = {};
    }
    newStock[codespecie][col] = Number(value);
    setDiccStock(newStock);
  }

function calculaSumatoriaTablaAgrupados (tablaAgrupados48hs = [] ) {
let sumatoriaTablaAgrupados =  {montoARS: 0, montoCable: 0, montoDolar: 0};
tablaAgrupados48hs.forEach(item => {
   sumatoriaTablaAgrupados.montoARS += item.montoARS*1;
   sumatoriaTablaAgrupados.montoCable +=  item.montoCable*1;
   sumatoriaTablaAgrupados.montoDolar +=  item.montoUSD*1;
})
return sumatoriaTablaAgrupados
}
const sumatoriaTablaAgrupados = calculaSumatoriaTablaAgrupados(tablaAgrupados48hs);
function calculaSumatoriaTablaCCL (tablaCCL = [] ) {
  let sumatoriaTablaCCL =  {montoARS: 0, montoUSDCable: 0};
  tablaCCL.forEach(item => {
     sumatoriaTablaCCL.montoARS += item.montoARS*1;
     sumatoriaTablaCCL.montoUSDCable +=  item.montoUSDCable*1;

  })
  return sumatoriaTablaCCL
  }
  const sumatoriaTablaCCL = calculaSumatoriaTablaCCL(tablaCCL)
//console.log(sumatoriaTablaAgrupados)
  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-8">
            <span>
              <b>Widgets</b>
            </span>
          </div>
          <div className="col-4">
            {" "}
            <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              {leyBtn}
            </button>{" "}
          </div>
        </div>
      </div>
      <div className={cardBodyClass}>
        <div className="row no-gutters">
          <div className="col-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th colSpan={4}>
                    <span>Cuenta: </span>
                    <select
                      value={selectedCuenta}
                      style={{ maxWidth: "100px" }}
                      className="custom-select custom-select-sm ml-2"
                      onChange={(e) => {
                        setSelectedCuenta(e.target.value);
                      }}
                    >
                      {cuentas.map((cuenta) => (
                        <option key={cuenta} value={cuenta}>
                          {`${cuenta}`}
                        </option>
                      ))}
                    </select>

                    <button className="btn btn-sm btn-secondary ml-2" title="Cargar todas las especies" onClick={cargaTodasLasEspecies}>
                      Cargar especies
                    </button>
                    <button className="btn btn-sm btn-danger ml-2" title="Borrar todas las especies" onClick={borrarTodasLasEspecies}>
                      Borrar especies
                    </button>
                    <select
                      title="Separador decimal"
                      value={comaValuesEnabled ? "coma" : "punto"}
                      style={{ width: "40px" }}
                      onChange={(e) => {
                        handleSetSeparadorDecimales(e.target.value);
                      }}
                      class="form-control-sm float-right ml-1 float-right"
                    >
                      <option value="coma">,</option>
                      <option value="punto">.</option>
                    </select>
                    <button
                      title="Copiar tabla"
                      className="btn btn-sm btn-success float-right"
                      onClick={() => tabletoClipboard(comaValuesEnabled)}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faCopy} />{" "}
                    </button>
                  </th>
                  <th className="text-center" colSpan={3}>
                    Pesos
                  </th>
                  <th className="text-center" colSpan={3}>
                    Cable
                  </th>
                  <th className="text-center" colSpan={3}>
                    Dolar
                  </th>
                  <th className="text-center">
                    <button className="btn btn-sm btn-success" onClick={handleSaveUserConfig}>
                      {" "}
                      <FontAwesomeIcon icon={faSave} />{" "}
                    </button>
                  </th>
                </tr>
                <tr>
                  <th>Especie</th>
                  <th>Cantidad</th>
                  <th>Ajuste</th>
                  <th>Neto</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Monto</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Monto</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Monto</th>
                  <th className="text-center">
                    {" "}
                    <button className="btn btn-sm btn-success" onClick={addRow}>
                      +
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableRender.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <select
                        value={item.especieSelected}
                        className="custom-select custom-select-sm"
                        onChange={(e) => handleSetEspcie(e.target.value, item.id)}
                      >
                        {especies.map((espcie) => (
                          <option key={espcie.idNem} value={espcie.idNem}>
                            {`${espcie.codespecie} (${espcie.nroCajval}) - ${espcie.plazo}`}{" "}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="text-right"> {formatNumero(item.cantidadNetaEspecie)} </td>
                    <td> </td>
                    <td style={{ borderRightColor: "black" }}></td>
                    {item.precioMontoCantidadPesos.map((rdo, index) => (
                      <td
                        style={index == item.precioMontoCantidadPesos.length - 1 ? { borderRightColor: "black" } : null}
                        key={`pesos-${index}`}
                        className="text-right"
                      >
                        {formatNumero(rdo, 3)}
                      </td>
                    ))}
                    {item.precioMontoCantidadCable.map((rdo, index) => (
                      <td
                        style={index == item.precioMontoCantidadCable.length - 1 ? { borderRightColor: "black" } : null}
                        key={`cable-${index}`}
                        className="text-right"
                      >
                        {formatNumero(rdo, 3)}
                      </td>
                    ))}
                    {item.precioMontoCantidadDolar.map((rdo, index) => (
                      <td
                        style={index == item.precioMontoCantidadDolar.length - 1 ? { borderRightColor: "black" } : null}
                        key={`dolar-${index}`}
                        className="text-right"
                      >
                        {formatNumero(rdo, 3)}
                      </td>
                    ))}

                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          delRow(item.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                {tableDate.length > 0 && (
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="text-right">{formatNumero(sumatoria.sumPrecioMontoCantidadPesos.cantidad)}</th>
                    <th></th>
                    <th className="text-right">{formatNumero(sumatoria.sumPrecioMontoCantidadPesos.monto)}</th>
                    <th className="text-right">{formatNumero(sumatoria.sumPrecioMontoCantidadCable.cantidad)}</th>
                    <th></th>
                    <th className="text-right">{formatNumero(sumatoria.sumPrecioMontoCantidadCable.monto)}</th>
                    <th className="text-right">{formatNumero(sumatoria.sumPrecioMontoCantidadDolar.cantidad)}</th>
                    <th></th>
                    <th className="text-right">{formatNumero(sumatoria.sumPrecioMontoCantidadDolar.monto)}</th>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>
          <div className="col-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th colSpan={2}>
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <div class="input-group-text input-group-text-sm" id="btnTasa">
                          Tasa
                        </div>
                      </div>
                      <input
                        style={{ maxWidth: "60px" }}
                        type="number"
                        class="form-control form-control-sm"
                        aria-describedby="btnTasa"
                        value={parametrosTasa.tasa}
                        onChange={(e) => handleSetParametrosTasa("tasa", e.target.value)}
                      />
                      <div class="input-group-prepend">
                        <div class="input-group-text input-group-text-sm" id="btn24hs">
                          Dias 24hs
                        </div>
                      </div>
                      <input
                        style={{ maxWidth: "40px" }}
                        type="number"
                        class="form-control form-control-sm"
                        aria-describedby="btn24hs"
                        value={parametrosTasa["24hs"]}
                        onChange={(e) => handleSetParametrosTasa("24hs", e.target.value)}
                      />
                      <div class="input-group-prepend">
                        <div class="input-group-text input-group-text-sm" id="btn48hs">
                          Dias 48hs
                        </div>
                      </div>
                      <input
                        style={{ maxWidth: "40px" }}
                        type="number"
                        class="form-control form-control-sm"
                        aria-describedby="btn48hs"
                        value={parametrosTasa["48hs"]}
                        onChange={(e) => handleSetParametrosTasa("48hs", e.target.value)}
                      />
                    </div>
                  </th>
                  <th className="text-center" colSpan={3}>
                    Pesos
                  </th>
                  <th className="text-center" colSpan={3}>
                    Cable
                  </th>
                  <th className="text-center" colSpan={3}>
                    Dolar
                  </th>
                </tr>
                <tr>
                  <th>Especie</th>
                  <th>Cantidad Neta</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Monto</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Monto</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Monto</th>
                </tr>
              </thead>
              <tbody>
                {tablaAgrupados48hs.map((item) => (
                  <tr key={item.nroCajval}>
                    <td>{item.codespecie}</td>
                    <td style={{ borderRightColor: "black" }} className="text-right">
                      {formatNumero(item.cantidadNetaEspecie)}
                    </td>
                    <td className="text-right">{formatNumero(item.cantidadARS)}</td>
                    <td className="text-right">{formatNumero(item.pppARS, 3)}</td>
                    <td style={{ borderRightColor: "black" }} className="text-right">
                      {formatNumero(item.montoARS)}
                    </td>
                    <td className="text-right">{formatNumero(item.cantidadCable)}</td>
                    <td className="text-right">{formatNumero(item.pppCable, 3)}</td>
                    <td style={{ borderRightColor: "black" }} className="text-right">
                      {formatNumero(item.montoCable)}
                    </td>
                    <td className="text-right">{formatNumero(item.cantidadUSD)}</td>
                    <td className="text-right">{formatNumero(item.pppUSD, 3)}</td>
                    <td className="text-right">{formatNumero(item.montoUSD)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
               { tablaAgrupados48hs.length > 0 && <tr> 
               <th></th>
               <th></th>
               <th></th>
               <th></th>
               <th className="text-right"> {formatNumero(sumatoriaTablaAgrupados.montoARS)} </th>
               <th></th>
               <th></th>
               <th className="text-right"> {formatNumero(sumatoriaTablaAgrupados.montoCable)} </th>
               <th></th>
               <th></th>
               <th className="text-right"> {formatNumero(sumatoriaTablaAgrupados.montoDolar)} </th>
               </tr>}
              </tfoot>
            </table>
          </div>
          <div className="col-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th colSpan={4}></th>
                  <th colSpan={3}>Pesos</th>
                  <th colSpan={3}>
                    <div className="input-group input-group-sm" style={{ justifyContent: "space-between" }}>
                      Cable + Dolar + Stock
                      <div class="input-group-prepend input-group-prepend-sm">
                        <div class="input-group-text input-group-text-sm" id="btnTasaCanje">
                          Canje
                        </div>
                        <input
                          style={{ maxWidth: "60px" }}
                          type="number"
                          class="form-control form-control-sm"
                          aria-describedby="btnTasaCanje"
                          value={tasaCanje}
                          onChange={(e) => handleSetTasaCanje(e.target.value)}
                        />
                      </div>
                    </div>
                  </th>
                  <th colSpan={2}>Stock Dolar</th>
                </tr>
                <tr>
                  <th>Especie</th>
                  <th>Monto (D+C+S)</th>
                  <th>CCL</th>
                  <th>Neto Titulos</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Monto</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Monto</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                </tr>
              </thead>
              <tbody>
                {tablaCCL.map((item) => (
                  <tr key={item.codespecie}>
                    <td>{item.codespecie}</td>
                    <td className="text-right">{formatNumero(item.montoUSDCable)}</td>
                    <td className="text-right">{formatNumero(item.CCL)}</td>
                    <td style={{ borderRightColor: "black" }} className="text-right">
                      {formatNumero(item.netoTitulos)}
                    </td>
                    <td className="text-right">{formatNumero(item.cantidadARS)}</td>
                    <td className="text-right">{formatNumero(item.pppARS, 3)}</td>
                    <td style={{ borderRightColor: "black" }} className="text-right">
                      {formatNumero(item.montoARS)}
                    </td>
                    <td className="text-right">{formatNumero(item.cantidadUSDCable)}</td>
                    <td className="text-right">{formatNumero(item.pppUSDCable, 3)}</td>

                    <td style={{ borderRightColor: "black" }} className="text-right">
                      {formatNumero(item.montoUSDCable)}
                    </td>

                    <td className="text-right" style={{ maxWidth: "80px" }}>
                     {formatNumero(item.cantidadStockFijo)}
                    </td>
                    <td style={{ maxWidth: "80px" }}>
                      <input
                        value={diccStock?.[item.codespecie]?.precio || null}
                        onChange={(e) => handleChangeStock(item.codespecie, "precio", e.target.value)}
                        type="number"
                        className="form-control form-control-sm"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
               { tablaCCL.length > 0 && <tr> 
               <th></th>
               <th></th>
               <th></th>
               <th></th>
               <th></th>
               <th></th>
               <th className="text-right"> {formatNumero(sumatoriaTablaCCL.montoARS)} </th>
               <th></th>
               <th></th>
               <th className="text-right"> {formatNumero(sumatoriaTablaCCL.montoUSDCable)} </th>
               <th></th>
               <th></th>

               </tr>}
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetResumenEspMon;
