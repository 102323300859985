import React, { useState, useEffect } from "react";

import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
import CaucionTableMAV from "../components/CaucionTableMAV";
import OpersGralTableMAV from "../components/OpersGralTableMAV";
import PanelHeaderMAV from "../components/PanelHeaderMAV";
import CPDsTableMAV from "../components/CPDsTableMAV";


const PanelMAVAN = () => {
  useDocumentTitle("Panel de Operaciones MAV");

  const [isLoading, setIsLoading] = useState(false);
  const [dataCauciones, setDataCauciones] = useState([]);
  const [dataOpersGral, setDataOpersGral] = useState([])
  const [datosCPD, setDatosCPD] = useState([])
  const [moneda, setMoneda] = useState("$")
  const [marketSesions, setMarketSesions] = useState({})
  const { getInfCaucionesAN, getInfOpersGeneralMAVAN, getCPDConcertacionMAVAN,  getMarketSesions } = useServices();

  useEffect(() => {
    initPanel();
  }, []);

  async function cargaCauciones() {
    const res = await getInfCaucionesAN();
    if(res.error) return
    setDataCauciones(res)
  }

  async function cargaCPDsMAV () {
    const res = await getCPDConcertacionMAVAN();
    if(res.error) return
    setDatosCPD(res)
  }

  async function cargaOpersGralMAV () {
    const res = await getInfOpersGeneralMAVAN();
    if(res.error) return
    setDataOpersGral(res)

  }



  async function handleSetMarketSesions (){
    const res = await getMarketSesions();
    if(res.error) return
    setMarketSesions(res)
  }


  async function initPanel() {
    await handleSetMarketSesions()
    setIsLoading(true)

    await cargaCauciones();
    await cargaOpersGralMAV();
    await cargaCPDsMAV();

   setIsLoading(false)
  }


  return (
    <div className="container-fluid">
<div className="row no-gutters">
  <div className="col-12">
    <div className="card-header d-flex justify-content-between">

      <div></div>
 
      <div className="float-right">
        <span className="mr-2">MAV Automatico</span> 
        {marketSesions.loginMAVAutomatico ? <span className="badge badge-pill badge-success">Cargado</span> : <span className="badge badge-pill badge-danger">Sin credenciales</span>}
      </div>
    </div>
  </div>
</div>
   

  
      <PanelHeaderMAV isLoading={isLoading} setMoneda={setMoneda} moneda={moneda} cargaCauciones={cargaCauciones} cargaOpersGral={cargaOpersGralMAV} cargaCPDsMAV={cargaCPDsMAV}/>

 

      <div className="row no-gutters">    
      <CaucionTableMAV moneda={moneda} datos={dataCauciones}  />  
      <OpersGralTableMAV moneda={moneda} datos={dataOpersGral} />  
      <CPDsTableMAV  moneda={moneda} datos={datosCPD} />
      
      </div>
    </div>
  );
};

export default PanelMAVAN;
