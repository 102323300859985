const baseURL = process.env.REACT_APP_API_URL;
const getPositionMaintenanceReportBetweenDatesTrader = async (options, params = {}) => {
  let json =  []
  try {
    const res = await fetch(`${baseURL}AP5/getPositionMaintenanceReportBetweenDatesTrader?startDate=${params.startDate}&endDate=${params.endDate}&account=${params.account}`, options);
    json = await res.json();
 
  } catch (e) {
    return { error: e };
  }

  return json;


};

export default getPositionMaintenanceReportBetweenDatesTrader;
