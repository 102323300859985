import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import '../css/customTable.css'


const DayTradingTable = ({ titulo, datos, moneda, initBodyVisb = false, realoadBtn = false, isLoading = false}) => {
 
  const [filtro, setFiltro] = useState("");
  let tabla = [];
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
  const tablaRef = useRef(null);

  //console.log("datos", datos);


    tabla = datos;
  

  const formatNumero = (numero) => {
    return numero.toLocaleString('es');
  };
  const sumaMontoNeto = (datos) => {
    let suma = 0;
    datos.map((item) => {
      suma += item.nm;
    });
    return formatNumero(suma);
  };
  const showhideBody = () => {
    setBodyVisible(!bodyVisible)
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
  const leyBtn = bodyVisible ? "-" : "+";



  function exportTableToExcel(tableSelect, filename = "") {
    
    const dataType = "application/vnd.ms-excel;charset=utf-8";
    //var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

    // Specify file name
    //const fechaStr = new Date().toISOString().split("T")[0];
    const fechaStr =  new Date().toISOString().split("T")[0]
    //fechaStr = fechaStr.replace(/\//g, '-');
    filename = filename ? filename + ".xls" : `daytrading_${fechaStr}.xls`;

    // Create download link element
    const downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = "data:" + dataType + ", " + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }
  function handleDownloadExcel () {
    exportTableToExcel(tablaRef.current)
  }

  return (
      <div className="card">
        <div className="card-header ">
          <div className="row">
          <div className="col-8">
            <span>
              <b>Operaciones - {titulo}</b> 
            </span>
       
        
          </div>
          <div className="col-4">
          <button className="btn btn-sm btn-success ml-1  float-right" onClick={() => handleDownloadExcel()}>
                  Descargar archivo
                </button>
        
            <span>
     {realoadBtn &&    <button
                type="button"
                onClick={() => realoadBtn()}
                name=""
              
                className={
                 isLoading ? "btn btn-secondary float-right btn-sm ml-1 disabled" : "btn btn-secondary float-right btn-sm ml-1"
                }
              >
                <FontAwesomeIcon icon={faSync} />
              </button>}
    <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              <b>{leyBtn}</b>
            </button>
            <input
              placeholder="Cuenta"
              onChange={(e) => {
                setFiltro(e.target.value);
              }} 
              className="form-control-sm form-control  float-right ml-1"
              type="text"
              style={{ "maxWidth": "80px" }}
            />
            </span>
        
          </div>
          </div>
        </div>
        <div  className={cardBodyClass}>
        <div style={{'maxHeight': '60vh', minHeight: '10vh'}} className=" tableFixHead">
          <table ref={tablaRef} className="table table-sm table-bordered table-hover">
            <thead>
              <tr>
              <th scope="col">Fecha Concert.</th>
              <th scope="col">Fecha Liq.</th>
              <th scope="col">Plazo</th>
              <th scope="col">Moneda</th>
              <th scope="col">Categoria</th>
                <th scope="col">Cuenta</th>
                <th scope="col">Especie</th>
                <th scope="col">M.Com</th>
                <th scope="col">C.Com</th>
                <th scope="col">M.Ven</th>
                <th scope="col">C.Ven</th>
                <th scope="col">Neto C.</th>
                <th scope="col">Neto M.</th>
                <th scope="col">PPP</th>
                <th scope="col">Monto Suj. Bonif.</th>
                <th scope="col">Dh. Mdo.</th>
                <th scope="col">Bonificacion</th>
              </tr>
            </thead>
            <tbody>
              {tabla
                .filter((item) => item.cuenta.includes(filtro))
                .map((item, index) => (
                  <tr key={index}>
                     <td >{item.fechaConcertacion}</td>
                     <td >{item.fechaLiquidacion}</td>
                     <td >{item.plazo}</td>
                     <td >{item.codmon}</td>
                     <td >{item.categoria}</td>
                    <td >{item.cuenta}</td>
                    <td>{item.codespecie}</td>
                    <td>{formatNumero(item.mc)}</td>
                    <td>{formatNumero(item.cc)}</td>
                    <td>{formatNumero(item.mv)}</td>
                    <td>{formatNumero(item.cv)}</td>
                    <td className="text-right">{formatNumero(item.nc)}</td>
                    <td className="text-right">{formatNumero(item.nm)}</td>
                    <td>{formatNumero(item.ppp)}</td>
                    <td>{formatNumero(item.montoSujBonif)}</td>
                    <td>{formatNumero(item.dhMdoPreBonif)}</td>
                    <td>{formatNumero(item.bonifCalculada)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
  );
};

export default DayTradingTable;
