import React, { useState, useEffect } from "react";

import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
import CaucionTableMAV from "../components/CaucionTableMAV";
import OpersGralTableMAV from "../components/OpersGralTableMAV";
import PanelHeaderMAV from "../components/PanelHeaderMAV";
import CPDsTableMAV from "../components/CPDsTableMAV";
import SaldosDerivacionesMAV from "../components/SaldosDerivacionesMAV";
import { formatoFecha } from "../lib/utils";
const REACT_APP_MENU_SALDOS_MAV = process.env.REACT_APP_MENU_SALDOS_MAV === "false" ? false : true;

const PanelDerivacionesMAV = () => {
  useDocumentTitle("Panel de Derivaciones MAV");

  const [isLoading, setIsLoading] = useState(false);
  const [dataCauciones, setDataCauciones] = useState([]);
  const [saldosDiaAnt, setSaldosDiaAnt] = useState({})
  
  const [dataOpersGral, setDataOpersGral] = useState([])
  const [datosCPD, setDatosCPD] = useState([])
  const [moneda, setMoneda] = useState("$")
  const { getInfCaucionesDerivacionesMAV, getInfOpersGeneralDerivacionesMAV, getCPDConcertacionDerivacionesMAV, getSaldosDerivaMAVDiaAnt} = useServices();
  useEffect(() => {
    initPanel();
  }, []);


  const fechaHoy = formatoFecha(new Date())

  async function cargaCauciones() {
    const res = await getInfCaucionesDerivacionesMAV();
    if(res.error) return
    setDataCauciones(res)
  }

  async function cargaCPDsMAV () {
    const res = await getCPDConcertacionDerivacionesMAV();
    if(res.error) return
    setDatosCPD(res)
  }

  async function cargaOpersGralMAV () {
    const res = await getInfOpersGeneralDerivacionesMAV();
    if(res.error) return
    setDataOpersGral(res)

  }
  async function cargaSaldosDerivaMAVDiaAnt () {
    const res = await getSaldosDerivaMAVDiaAnt();
    if(res.error) return;
    setSaldosDiaAnt(res)

  }


  async function initPanel() {
    setIsLoading(true)
  //  await cargaSaldosMAV();
    await cargaCauciones();
    await cargaOpersGralMAV();
    await cargaCPDsMAV();
   if(REACT_APP_MENU_SALDOS_MAV) { await cargaSaldosDerivaMAVDiaAnt();}
    setIsLoading(false)
  }


  return (
    <div className="container-fluid">
   { REACT_APP_MENU_SALDOS_MAV &&   <div className="row no-gutters">
        <div className="col">  </div>
       <SaldosDerivacionesMAV moneda="$" isLoading={isLoading} fechaHoy={fechaHoy} dataCauciones={dataCauciones} dataOpersGral={dataOpersGral} datosCPD={datosCPD} saldosDiaAnt={saldosDiaAnt} reloadFunc={initPanel} />
       <SaldosDerivacionesMAV moneda="U$S" isLoading={isLoading} fechaHoy={fechaHoy}  dataCauciones={dataCauciones} dataOpersGral={dataOpersGral} datosCPD={datosCPD} saldosDiaAnt={saldosDiaAnt} reloadFunc={initPanel} />
        <div className="col"></div>
      </div>}
      {/*       <Paneldheader
        showhidePanel={showhidePanel}
        setMoneda={setMoneda}
        moneda={moneda}
        cuentaOper={cuentaOper}
        cargaContado={reloadContado}
        cargaCauciones={cargaResumenCaucGara}
        cargaOpciones={cargaResumenOpc}
        hayEjercicios={hayEjercicios}
        hayVC={hayVC}
        panelShow = {{showCI, show24hs, show48hs, showPanelSENEBI}}
      /> */}

  
      <PanelHeaderMAV isLoading={isLoading} setMoneda={setMoneda} moneda={moneda} cargaCauciones={cargaCauciones} cargaOpersGral={getInfOpersGeneralDerivacionesMAV} cargaCPDsMAV={getCPDConcertacionDerivacionesMAV}/>

 

      <div className="row no-gutters">    
      <CaucionTableMAV moneda={moneda} datos={dataCauciones}  />  
      <OpersGralTableMAV moneda={moneda} datos={dataOpersGral} />  
      <CPDsTableMAV  moneda={moneda} datos={datosCPD} />
      
      </div>
    </div>
  );
};

export default PanelDerivacionesMAV;
