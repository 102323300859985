import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const PanelHeaderTrader = ({ cargaContado, cuentaOper = 0, setMoneda, showhidePanel, hayEjercicios = false,  hayVC = false, panelShow, cargaCauciones = false, cargaOpciones = false, moneda, horaUltimaOperacion = "", cargaContadoSB =  false, isLoadingContado = false, isLoadingCauciones = false, isLoadingOpciones = false, isLoadingContadoSB = true, showPanelesNoContado = true, opersOrigin = "N/D" }) => {
  //console.log({panelShow})
  //console.log({moneda})
  //const [isLoadingContado, setisLoadingContado] = useState(false);
 // const [isLoadingContadoSB, setisLoadingContadoSB] = useState(false);
 // const [isLoadingCauciones, setisLoadingCauciones] = useState(false);
 // const [isLoadingOpciones, setisLoadingOpciones] = useState(false);
  const handleCargaContado = async () => {
   // setisLoadingContado(true)
     await cargaContado();
   //  setisLoadingContado(false)
  }
  const handleCargaCauciones = async () => {
   // setisLoadingCauciones(true)
     await cargaCauciones();
    // setisLoadingCauciones(false)
  }
  const handleCargaOpciones = async () => {
   // setisLoadingOpciones(true)
     await cargaOpciones();
    // setisLoadingOpciones(false)
  }

  const handleCargaContadoSB = async () => {
   // setisLoadingContadoSB(true)
     await cargaContadoSB();
    // setisLoadingContadoSB(false)
  }

  
  return (
    <div className="row">
      <div className="col-sm">
        <div className="card-header ">
          <button
            disabled={moneda == 'P'}
            type="button"
            onClick={() => {
              setMoneda("P");
            }}
            className="btn btn-secondary btn-sm"
          >
            Pesos
          </button>{" "}
          <button
           disabled={moneda == 'D'}
            type="button"
            onClick={() => {
              setMoneda("D");
            }}
            className="btn btn-secondary btn-sm ml-1"
          >
            Dolares
          </button>{" "}
          <button
           disabled={moneda == 'C'}
            type="button"
            onClick={() => {
              setMoneda("C");
            }}
            className="btn btn-secondary btn-sm ml-1"
          >
            Cable
          </button>{" "}
          <button disabled={isLoadingContado} type="button" onClick={handleCargaContado} className="btn btn-secondary">
            <FontAwesomeIcon className={isLoadingContado ? "loading-icon" : "" } icon={faSync} />
          </button>
          <span  title="Cantidad Operaciones Cdo. Gdo. - Hora Última Operación" className="ml-1">{cuentaOper} - {horaUltimaOperacion}</span>
     {  cargaCauciones &&   <button disabled={isLoadingCauciones} onClick={handleCargaCauciones} type="button" name="" className="btn btn-secondary ml-2 btn-sm">
            Cauciones   <FontAwesomeIcon className={isLoadingCauciones ? "loading-icon" : "" }  icon={faSync} />  
          </button>}
          <span> </span>
     { cargaOpciones &&    <button disabled={isLoadingOpciones} onClick={handleCargaOpciones} type="button" name="" className="btn btn-secondary btn-sm ">
            Opciones <FontAwesomeIcon className={isLoadingOpciones ? "loading-icon" : "" }  icon={faSync} />
          </button>}


          { cargaContadoSB &&    <button disabled={isLoadingContadoSB} onClick={handleCargaContadoSB} type="button" name="" className="btn btn-secondary btn-sm ml-1 ">
            SENEBI <FontAwesomeIcon className={isLoadingContadoSB ? "loading-icon" : "" }  icon={faSync} />
          </button>}
       

          <span> </span>
        
          <div role="group" className="btn-group  btn-group-toggle float-right">
       
          
            <button
              onClick={() => {
                showhidePanel("CI");
              }}
              type="checkbox"
              aria-pressed="false"
              autocomplete="off"
              className={panelShow.showCI ? "btn btn-secondary btn-sm active" : "btn btn-secondary btn-sm"}
              
            >
              CI
            </button>
      
            <button
              onClick={() => {
                showhidePanel("24hs");
              }}
              type="checkbox"
              aria-pressed="false"
              autocomplete="off"
              className={panelShow.show24hs ? "btn btn-secondary btn-sm active" : "btn btn-secondary btn-sm"}
            >
              24hs
            </button>
     

           {showPanelesNoContado && <><button
              onClick={() => {
                showhidePanel("caucion");
              }}
              type="checkbox"
              aria-pressed="true"
              autocomplete="off"
              className={panelShow.showCauc ? "btn btn-secondary btn-sm active ml-2" : "btn btn-secondary btn-sm ml-2"}
            >
              Caución
            </button>
            <button
              onClick={() => {
                showhidePanel("opciones");
              }}
              type="checkbox"
              aria-pressed="true"
              autocomplete="off"
              className={panelShow.showOpc ? "btn btn-secondary btn-sm active ml-2" : "btn btn-secondary btn-sm ml-2"}
            >
              Opciones
            </button>

            <button
              onClick={() => {
                showhidePanel("SENEBI");
              }}
              type="checkbox"
              aria-pressed="true"
              autocomplete="off"
              className={panelShow.showPanelSENEBI ? "btn btn-secondary btn-sm active ml-2" : "btn btn-secondary btn-sm ml-2"}
            >
              SENEBI
            </button>

            <button
              onClick={() => {
                showhidePanel("panelTotPorEsp");
              }}
              type="checkbox"
              aria-pressed="true"
              autocomplete="off"
              className={panelShow.showTotPorEsp ? "btn btn-secondary btn-sm active ml-2" : "btn btn-secondary btn-sm ml-2"}
            >
              Tot. Por. Especie
            </button>

          <span title="Fuente Operaciones" className="float-right ml-2 input-group-text form-control-sm"> <b>Origen: {opersOrigin == "SDIB" ? "SDIB+GARA" : opersOrigin}</b></span>

            </>
            }
            
          </div>
          { hayEjercicios &&
          <Link to="/ejerciciosTrader">
          <button className="btn btn-sm btn-warning float-right mr-1">Hay ejercicios</button>
          </Link>
          }
                    { hayVC &&
          <Link to="/vcTrader">
          <button className="btn btn-sm btn-danger float-right mr-1">Hay venta en corto</button>
          </Link>
          }

 

        </div>
      </div>
    </div>
  );
};

export default PanelHeaderTrader;
