import React, { useEffect, useState } from "react";
import CardTemplate from "../components/CardTemplate";

//import getPosiOpcionesTrader from "../services/getPosiOpcionesTrader";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
import getTKN from "../services/getTKN";
const baseURL = process.env.REACT_APP_API_URL;

const PosiOpcAN = () => {
  useDocumentTitle('Posición Opciones BYMA')
 // const [tablaOpc, setTablaOpc] = useState([]);
  const {getPosiOpcionesAN, getResumenOperdiaOpcionesAN} = useServices();
  const [filtroCuenta, setfiltroCuenta] = useState('')
  const [filtroSerie, setfiltroSerie] = useState('')
  const [isLoadingDia, setIsLoadingDia] = useState(false);
  const [isLoadingOpc, setIsLoadingOpc] = useState(false);
  const [posiDia, setPosiDia] = useState({});
  const [posiOpc, setPosiOpc] = useState([]);

 async function cargaPosiDia() {
   setIsLoadingDia(true);
   const res = await getResumenOperdiaOpcionesAN();
   setIsLoadingDia(false);
   if(res.error) return
   setPosiDia(res);
 }

 async function  cargaPosiOpc() {
   setIsLoadingOpc(true);
   const res = await getPosiOpcionesAN();
   setIsLoadingOpc(false);
   if(res.error) return
   setPosiOpc(res);
  
 }

  async function cargaDatos() {
    await cargaPosiDia();
    await cargaPosiOpc();
  }
  async function downloadListadoSeries() {
     const url = `${baseURL}getListadoSeriesHabilitadas?tkn=${getTKN()}`
     window.open(url)
  }

  useEffect(() => {
    cargaDatos();
  }, []);

  function mathTablas(posiOpc = [], posiDia = {}) { 
    let contenido = [...posiOpc];
    posiOpc.forEach(item => {
      const {cuenta, serie} = item;
      item.posiDia = 0;
      let found = posiDia?.tabla?.Pesos?.find(item => item.cuenta == cuenta && item.serie == serie);
      if(found) { item.posiDia = found.nc; found.matched = true; }

    })

    posiDia?.tabla?.Pesos?.filter(item => !item.matched).forEach(item => {
      const {cuenta, serie, nc} = item;
      contenido.push({cuenta, serie, netoc: 0, netol_des: 0, netol_cub: 0, posiDia: nc})
    })

    return contenido;

  }

  //const btnDownload =   <button type="button" onClick={ () => downloadListadoSeries() } className="btn btn-primary btn-sm float-right mr-1"> Descargar listado Series Habilitadas </button>
  const tablaOpc = mathTablas(posiOpc, posiDia);

  const btnReload =   <button type="button" onClick={ () => cargaDatos() } disabled = { isLoadingDia || isLoadingOpc } className="btn btn-primary btn-sm float-right mr-1"> {isLoadingOpc ? "Cargando Posiciones..." : isLoadingDia ? "Cargando Posicion Dia..." : "Actualizar Posiciones"} </button>
   
  return (
    <div className="container-fluid">
    <div className="row">
       <div className="col-sm-0 col-lg-3 col-md-2"></div>
      <div className="col-sm-12 col-lg-6 col-md-8">
        <CardTemplate title={"OPCIONES BYMA"} description={"Posicion por Comitente"} customBtn={btnReload}>
        
          <table className="table table-sm table-bordered table-hover">
            <thead className="thead-light">
              <tr>
                <th> <input type="text" value={filtroCuenta} onChange= {(e) => setfiltroCuenta(e.target.value) } placeholder="Cuenta" className="form-control" /> </th>
                <th> <input type="text" value={filtroSerie} onChange= {(e) => setfiltroSerie(e.target.value) } placeholder="Serie" className="form-control" /> </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th scope="col">Cuenta</th>
                <th scope="col">Serie</th>
                <th scope="col">Neto Comprado</th>
                <th scope="col">Neto Lanzado Descubierto</th>
                <th scope="col">Neto Lanzado Cubierto</th>
                <th scope="col">Posicion Dia</th>
                <th scope="col">Posicion Proyectada</th>
              </tr>
            </thead>
            <tbody>
              {tablaOpc.filter(item => item.cuenta.includes(filtroCuenta) && item.serie.includes(filtroSerie)).map((item) => (
                <tr key={item.cuenta + item.serie}>
                  <td className="text-right">{item.cuenta}</td>
                  <td className="text-right">{item.serie}</td>
                  <td className="text-right">{item.netoc}</td>
                  <td className="text-right">{item.netol_des}</td>
                  <td className="text-right">{item.netol_cub}</td>
                  <td className="text-right">{item.posiDia}</td>
                  <td className="text-right">{item.netoc - item.netol_des - item.netol_cub + item.posiDia}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardTemplate>
      </div>
      <div className="col-sm-0 col-lg-6 col-md-2"></div>
    </div>
    </div>
  );
};

export default PosiOpcAN;
