import React, { useContext } from "react";
import SessionContext from "../context/SessionContext";
import { useHistory } from "react-router-dom";

export default function useSession() {
  let history = useHistory();
  const { tkn, userCategory, username, setTkn, setUserName, setUserCategory } = useContext(SessionContext);
  const setSession = () => {};

  const getSession = () => {
    return window.localStorage.getItem("tkn");
  };

  const logout = () => {
    window.localStorage.removeItem("tkn");
    window.localStorage.removeItem("userCategory");
    history.push("/login");
  };

  return { logout, getSession };
}
