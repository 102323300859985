import React, {useState} from 'react'

const CPDsTableMAV = ({ datos = [], moneda = '$'}) => {

    const [filtro, setFiltro] = useState("");
    const [filtroFecha, setFiltroFecha] = useState("");
    let tabla = [];
    const [bodyVisible, setBodyVisible] = useState(false);
    const diccCodMon = {'$': 'Pesos', 'U$S': 'Dólar', 'C': 'Dólar Transferencia'}

    tabla = datos.filter(item => item.moneda == moneda)
    let montosNetos = {};
    tabla.filter(item => item.comitente.includes(filtro)).forEach( ({montoNetoTotal, fechaLiq}) => {
         if(montosNetos[fechaLiq] == undefined) { montosNetos[fechaLiq] = 0}
         montosNetos[fechaLiq] += montoNetoTotal;
    } )

   
   


  const formatNumero = (numero) => {
    return numero.toLocaleString();
  };
  const sumaMontoNeto = (datos) => {
    let suma = 0;
    datos.map((item) => {
      suma += item.mContado;
    });
    return formatNumero(suma);
  };
  const showhideBody = () => {
    setBodyVisible(!bodyVisible)
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
  const leyBtn = bodyVisible ? "-" : "+";

    return (
     <div className="col col-sm-12 col-md-12">
      <div className="card">
        <div className="card-header ">
          <div className="row">
          <div className="col-8 ">
            <span>
              <b>Operaciones CPD</b> {tabla.length > 0 && "-"} {Object.entries(montosNetos).map( ([fecha, monto]) =><button onClick={() => setFiltroFecha(fecha) } className='btn btn-sm active'><b>{fecha}:</b> {formatNumero(monto)} </button>)} {filtroFecha != '' && <button onClick={() => setFiltroFecha('') } className='btn btn-sm btn-secondary'>quitar filtro</button> }
             
            </span>
       
        
          </div>
          <div className="col-4">
            <span>
    <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              <b>{leyBtn}</b>
            </button>
          <input
              placeholder="Cuenta"
              onChange={(e) => {
                setFiltro(e.target.value);
              }}
              className="form-control-sm form-control   float-right ml-1"
              type="text"
              style={{ "maxWidth": "80px" }}
            /> 
             
            </span>
          </div>
          </div>
        </div>
        <div className={cardBodyClass}>
        <div  style={{'maxHeight': '60vh', minHeight: '10vh'}} className="tableFixHead table-responsive-md" >
          <table className="table table-sm table-bordered table-hover texto-small">
            <thead>
              <tr>
               <th scope="col">Segmento</th>
                <th scope="col">Tipo Instrumento</th>
                <th scope="col">Comitente</th>
                <th scope="col">Fecha Liq.</th>
                <th scope="col">Cantidad CPDs</th>
                <th scope="col">Monto Bruto</th>
                 <th scope="col">Descuento</th>
                 <th scope="col">Der. Mdo.</th>
                <th scope="col">Monto Neto</th> 
              </tr>
            </thead>
            <tbody>
              {tabla
                .filter((item) =>  item.fechaLiq.includes(filtroFecha) && item.comitente.includes(filtro)  ) 
                .map((item, index) => (
                  <tr key={index}>
                    <th scope="col">{item.segmento}</th>
                    <th scope="col">{item.tipoInstrumento}</th>
                    <th scope="col">{item.comitente}</th>
                    <td>{item.fechaLiq}</td>
                    <td className='text-right'>{formatNumero(item.cantidadCPDs)}</td>
                    <td className='text-right'>{formatNumero(item.montoBrutoTotal)}</td>
                    <td className='text-right'>{formatNumero(item.descuentoTotal)}</td>
                    <td className='text-right'>{formatNumero(item.derMdoTotal)}</td>
                    <td className='text-right' >{formatNumero(item.montoNetoTotal)}</td>

                  </tr>
                ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    )
}

export default CPDsTableMAV
