import React, { useState, useEffect } from 'react'
import useDocumentTitle from '../hooks/useDocumentTitle';
import FileUploadButton from '../components/FileUploadButton';
import useServices from '../hooks/useServices';
import NominalesControlAUNEAN from '../components/NominalesControlAUNEAN';
import ModalALYCSP from '../components/ModalALYCSP';
const baseURL = process.env.REACT_APP_API_URL;
const ALYC_INTEGRAL = process.env.REACT_APP_ALYC_INTEGRAL === "true";


const ControlNominalesPerfilAN = () => {

    useDocumentTitle('Control Nominales - AN')
  
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [sumaCOMAFI, setSumaCOMAFI] = useState(true);

    const [ultimoContado, setUltimoContado] = useState("");


    const {getNominalesProyectadoAUNEAN} = useServices();

 


    async function cargaDatos () {
        setIsLoading(true)
        const res = await getNominalesProyectadoAUNEAN(sumaCOMAFI);
        setIsLoading(false)
        if(res.error) return;
        const {tablaNominalesProyectados, ultimaOperacionInmediato, ultimaOperacionInmediatoSB} = res;
        setData(tablaNominalesProyectados || []);
        setUltimoContado(ultimaOperacionInmediato);


    }

    useEffect(() => {
    
    cargaDatos()
    }, [sumaCOMAFI])
    
   function handleSumaComafi () {
    setSumaCOMAFI(prev => !prev)
   } 

 


  return (
    <div className="container-fluid">
    <div className="row">
        <div className="col-12">
            <div className="card-header">
                <div className="row">
             
                     
                     <div className="col">
                     <span className="float-right ml-2 input-group-text form-control-sm"> <b>Últ. Op. CI: {ultimoContado ? ultimoContado : "N/D"}</b></span>
                     { ALYC_INTEGRAL && <>
                     <span className="float-right ml-2 input-group-text form-control-sm">Suma COMAFI <input onChange={handleSumaComafi} checked={sumaCOMAFI} className="ml-1" type="checkbox"  /> </span>
                     </>
                      }
                     
                     </div>
                 
                </div>
              
         
            </div>
        </div>
    </div>
    <div className="row no-gutters">

               <NominalesControlAUNEAN  data={data} isLoading={isLoading} reloadPanel={cargaDatos}/>
     

    


        </div>

        
        
    </div>
  )
}

export default ControlNominalesPerfilAN