import React, { useMemo, useState } from "react";
import Table from "./Table";

const NomPorEspecie = ({ fecha, datos = [], initBodyVisb = false }) => {
  //console.log(datos);
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
  const formatDigit = (number) => {
  
    return number.toLocaleString();
  };
  const showhideBody = () => {
    setBodyVisible(!bodyVisible)
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
  const leyBtn = bodyVisible ? "-" : "+";

  const columns = useMemo(
    () => [
      {
        Header: "Nro. Esp.",
        accessor: "nroEspecie",
        Cell: (props) => <span style={{fontWeight: "bold" }}>{String(props.value)}</span>,
      },
      {
        Header: "Denom",
        accessor: "denom",
      },
      {
        Header: "Cantidad Comp.",
        accessor: "cantComp",
        Cell: (props) => formatDigit(props.value),
      },
      {
        Header: "Cantiad Vent.",
        accessor: "cantVent",
        Cell: (props) => formatDigit(props.value),
      },
      {
        Header: "Neto",
        accessor: "saldoNeto",
        Cell: (props) => formatDigit(props.value),
      },
    ],
    []
  );

  return (
    <div className="card">
      <div className="card-header grey">
       <b>Nominales por especie</b> - Contado Garantizado
       <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              <b>{leyBtn}</b>
            </button>
        <button type="button" name="bt_fecha" className="btn btn-secondary btn-sm float-right">
          {fecha}
        </button>
      </div>
      <div className={cardBodyClass}>
      <div className="tableFixHead" style={{"max-height": "50vh", "min-height": "10vh"}}>
        <Table data={datos} columns={columns} />
        </div>
     
      </div>
    </div>
  );
};

export default NomPorEspecie;
