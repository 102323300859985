import {conceptos} from "../vars/conceptosLiq"


export const procesaPosiNom = ({ posiNom, fechaHoy = null, cuentaPuente = "PUENTE" }) => {
  const extraeNumEspecie = (str) => {
    return str.split("(")[1].split(")")[0];
  };

  posiNom.pop();
  let listFechas = [];
  let objFechas = {};
  let posiConLiq = posiNom.filter((linea) => linea[7] !== "Cerrada");

  posiConLiq.forEach((item) => {
    if (item[2].indexOf("/") !== -1) {
      const vencimiento = item[2];
      const vencSplit = vencimiento.split("/");
      const timest = new Date(vencSplit[2] * 1, vencSplit[1] * 1 - 1, vencSplit[0] * 1).getTime();
      objFechas[vencimiento] = timest;
      //listFechas.push({ vencimiento, timest });
    }
    item[8] *= 1;
    item[5] *= 1;
    item[6] *= 1;
  });

  Object.entries(objFechas).forEach((item) => {
    const [vencimiento, timest] = item;
    listFechas.push({ vencimiento, timest });
  });

  listFechas.sort((a, b) => a.timest - b.timest);

  const fechaFiltro = fechaHoy !== null ? fechaHoy : listFechas[0].vencimiento;

  //console.log({ fechaFiltro });
  const posiConLiqHoy = posiConLiq.filter((linea) => linea[2] == fechaFiltro);
  let tablaPosiConLiqHoy = [];
  posiConLiqHoy.forEach((item) => {
    const agente = item[0];
    const denom = item[1];
    const nroEspecie = extraeNumEspecie(denom);
    const codComit = item[4];
    const tipoSaldo = item[7];
    const cantComp = item[6];
    const cantVent = item[5];
    const saldoNeto = tipoSaldo == "Comprador" ? item[8] : item[8] * -1;

    let found = tablaPosiConLiqHoy.find((posi) => posi.nroEspecie == nroEspecie && posi.codComit == codComit);
    if (found) {
      found.cantComp += cantComp;
      found.cantVent += cantVent;
      found.saldoNeto += saldoNeto;
    } else {
      tablaPosiConLiqHoy.push({ denom, nroEspecie, codComit, cantComp, cantVent, saldoNeto, agente });
    }
  });

  tablaPosiConLiqHoy = tablaPosiConLiqHoy.filter((posi) => posi.saldoNeto !== 0);
  let tablaTranf = [];
  let objTablaEspecies = {};
  let tablaEspecies = [];
  tablaPosiConLiqHoy.sort((a, b) => a.saldoNeto - b.saldoNeto);
  tablaPosiConLiqHoy.forEach((item) => {
    const tipo = item.saldoNeto < 0 ? "(I/E)" : "(I/E)";

    const esEmisora = item.saldoNeto < 0;

    const cuentaComit = `${item.agente}/${item.codComit}`;
    const ctaPuente = `${cuentaPuente}/10000`;
    const subcuenta = esEmisora ? cuentaComit : ctaPuente;
    const contraparte = esEmisora ? ctaPuente : cuentaComit;
    const cantidad = Math.abs(item.saldoNeto);

    if (objTablaEspecies[item.nroEspecie] == undefined) {
      objTablaEspecies[item.nroEspecie] = { denom: item.denom, cantComp: 0, cantVent: 0, saldoNeto: 0 };
    }
    objTablaEspecies[item.nroEspecie].cantComp += item.cantComp;
    objTablaEspecies[item.nroEspecie].cantVent += item.cantVent;
    objTablaEspecies[item.nroEspecie].saldoNeto += item.saldoNeto;

    tablaTranf.push({
      tipo,
      subcuenta,
      contraparte,
      cantidad,
      nroEspecie: item.nroEspecie,
      saldoNeto: item.saldoNeto,
      match: "N",
    });
  });

  // tablaTranf.sort((a,b) => a.saldoNeto - b.saldoNeto )

  Object.entries(objTablaEspecies).forEach((item) => {
    const [nroEspecie, resto] = item;
    tablaEspecies.push({ nroEspecie, ...resto });
  });

  //console.log({ tablaEspecies });

  return { tablaPosiConLiqHoy, tablaTranf, listFechas, tablaEspecies};
};

export const procesaSaldosBYMA = ({ saldosMoneda, saldoInicio = 0, movGtias = 0, saldosAntesCI = 0, saldosDant = [], saldoOtros = 0, movBancos = null  }) => {
  if (saldosMoneda != undefined) {
    saldoInicio = saldosMoneda.estadoLiq.SdoantI * 1 + saldosMoneda.estadoLiq.MovacreI * 1 - saldosMoneda.estadoLiq.SdoantE * 1;
    movGtias = saldosMoneda.estadoLiq.MovgarI * 1 - saldosMoneda.estadoLiq.MovgarE * 1;

    if (saldosMoneda.diaAnt.EtDetalle != undefined) {
      if (saldosMoneda.diaAnt.EtDetalle.item != undefined) {
        saldosMoneda.diaAnt.EtDetalle.item.forEach((item) => {
          if (item.Segmento == "G") {
            if (conceptos[item.Concepto] != undefined) {
              saldosDant.push({ concepto: conceptos[item.Concepto], monto: item.NetoALiquidar * 1 });
            }
          }
          if (item.Segmento == "CP") {
            if (conceptos[item.Concepto] != undefined) {
              saldosDant.push({ concepto: conceptos[item.Concepto], monto: item.NetoALiquidar * 1 });
            }
          }
          if (item.Segmento == "NG") {
            if (conceptos[item.Concepto] != undefined) {
              saldosDant.push({ concepto: conceptos[item.Concepto], monto: item.NetoALiquidar * 1 });
            }
          }
        });
      }
    }
    saldosAntesCI += saldoInicio;
    saldosDant.forEach((item) => (saldosAntesCI += item.monto));
    saldosAntesCI += saldoOtros;

    movBancos = saldosMoneda.estadoLiq.MovbcosI * 1 - saldosMoneda.estadoLiq.MovbcosE * 1;
  
  }
  return { saldosMoneda, saldoInicio, movGtias, saldosAntesCI, saldosDant, saldoOtros, movBancos  }
};


export const procesaSaldosDerivacionesSLYC = ({ saldosMoneda}) => { 
  let contadoDiaAntDerivado = 0;
  let contadoInmediatoDerivado = 0;
  let caucionesDerivado = 0;
  if(saldosMoneda) {

    if(saldosMoneda.diaAnt) {
      contadoDiaAntDerivado += saldosMoneda.diaAnt.EtDetalle?.item?.find( item => item.Segmento == "G" && item.Concepto == "DC" )?.NetoALiquidar*1 || 0;
      contadoInmediatoDerivado += saldosMoneda.diaAnt.EtDetalle?.item?.find( item => item.Segmento == "G" && item.Concepto == "DI" )?.NetoALiquidar*1 || 0;
      caucionesDerivado += saldosMoneda.diaAnt.EtDetalle?.item?.find( item => item.Segmento == "G" && item.Concepto == "D0" )?.NetoALiquidar*1 || 0;
    }

  }

   return {contadoDiaAntDerivado, contadoInmediatoDerivado, caucionesDerivado}

}

export const formatoFecha = (dato) => {

  if (dato.getDate().toString().length == 1) { var dia = "0" + dato.getDate().toString(); } else { var dia = dato.getDate().toString(); }

  if ((dato.getMonth() + 1).toString().length == 1) { var mes = "0" + (dato.getMonth() + 1).toString(); } else { var mes = (dato.getMonth() + 1).toString(); }

  var fechat = dia + "/" + mes + "/" + dato.getFullYear().toString();

  return fechat;

}