import React, { useState, useEffect } from "react";



import useDocumentTitle from "../hooks/useDocumentTitle";
import DistribucionesTable from "../components/DistribucionesTable";




const Distribuciones = () => {
  useDocumentTitle("Distribuciones BYMA - PPT");



  
  



  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
        <div className="row">
            <div className="col">
              <div className="card-header">
                <div className="row">
                  <div className="col">

                  </div>
                </div>
      
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
          <DistribucionesTable  titulo={`Distribuciones Generadas y Anuladas`} initBodyVisb={true} />
          </div>
          </div>


        </div>

      </div>
    </div>
  );
};

export default Distribuciones;
