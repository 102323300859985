import React from 'react'
import TablaComitentes from '../components/TablaComitentes'
import useDocumentTitle from "../hooks/useDocumentTitle";

const ListaComitentes = () => {
    useDocumentTitle('Listado Comitentes')
    return (
        <div className="container-fluid">
        <div className="row">
            <div className="col">       
             <TablaComitentes />
             </div>

        </div>
        </div>
    )
}

export default ListaComitentes
