const baseURL = process.env.REACT_APP_API_URL;

export async function getTableCrud(options, tableName) {
  let json = [];
  try {
    const res = await fetch(`${baseURL}tables/${tableName}`, options);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
}

export async function addTableCrud(options, { tableName, row }) {
  let json = [];
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...options.headers },
    body: JSON.stringify({ row }),
  };
  try {
    const res = await fetch(`${baseURL}tables/${tableName}`, requestOptions);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
}
export async function delTableCrud(options, { tableName, findParams }) {
  let json = [];
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", ...options.headers },
    body: JSON.stringify({ findParams }),
  };
  try {
    const res = await fetch(`${baseURL}tables/${tableName}`, requestOptions);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
}
