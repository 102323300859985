export const conceptos = {
    CN: "Contado Garantizado",
    FV: "Cauciones",
    DM: "Derechos de Mercado",
    OP: "Opciones",
    OC: "OPER.CONTINUA",
    CP: "Licitaciones",
    DK: "Market Data",
    FA: "Factura",
    VV: "Alq. Venta en corto",
    SS: "Suscripcion FCI",
    NC: "Nota de crédito",
    CR: "Créditos ACDYS",
    TP: "TRANSF. AUD. RES/816",
    VS: "Varios",
    RN: "RETRIBUCIÓN M.MAKERS",
    GB: "Gastos Bancarios",
    PV: "PREST.TIT.VTO.",
    LP: "LICIT.PESOS",
    RS: "RESCATE"
  };