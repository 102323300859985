import React, { useState } from "react";

const TradeCaptureTable = ({SettlCurrency = '', tableData = []}) => {
    const [filtroCuenta, setFiltroCuenta] = useState('');
    const [filtroEspecie, setFiltroEspecie] = useState('');
    
    const formatNumberMoneda = (num) => {
  if(!num) return '';
    return num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    }
    const formatNumberCantidad = (num) => {
        if(!num) return '';
          return num.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
          }

          const ppp = (netoM, netoC) => {
              const num = netoM / netoC;
              return isFinite(num) ? num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) :  '-'
              }
  return (
    <div className="card">
      <div className="card-header ">
        <span>
          <b>Futuros y Opciones</b> - Liquidacion {SettlCurrency}
        </span>
        <input placeholder="Especie" value={filtroEspecie} onChange={(e) => setFiltroEspecie(e.target.value) } className="form-control-sm form-control  float-right mr-1" type="text" style={{"max-width": "110px"}} />
        <input placeholder="Cuenta" value={filtroCuenta} onChange={(e) => setFiltroCuenta(e.target.value) } className="form-control-sm form-control  float-right mr-1" type="text" style={{"max-width": "70px"}} />
      </div>
      <div className="card-body">
        <table  className="table table-sm table-bordered table-hover texto-small">
          <thead>
            <tr>
              <th scope="col">Cuenta</th>
              <th scope="col">Especie</th>
              <th scope="col">M.Com</th>
              <th scope="col">C.Com</th>
              <th scope="col">M.Ven</th>
              <th scope="col">C.Ven</th>
              <th scope="col">Neto C.</th>
              <th scope="col">Neto M.</th>
              <th scope="col">PPP</th>
            </tr>
          </thead>
          <tbody>
          {tableData.filter( item => item.Account.toLowerCase().includes(filtroCuenta.toLowerCase()) && item.SecurityID.toLowerCase().includes(filtroEspecie.toLowerCase()) ).map( item => <tr key={item.Account+item.SecurityID}>
            <th scope="col">{item.Account}</th>
              <td >{item.SecurityID}</td>
              <td className="text-right"  >{formatNumberMoneda(item.buyAmount)}</td>
              <td className="text-right"  >{formatNumberCantidad(item.buyQty)}</td>
              <td className="text-right"  >{formatNumberMoneda(item.sellAmount)}</td>
              <td className="text-right"  >{formatNumberCantidad(item.sellQty)}</td>
              <td className="text-right"  >{formatNumberCantidad(item.netQty)}</td>
              <td className="text-right"  >{formatNumberMoneda(item.netAmount)}</td>
              <td className="text-right"  >{ppp(item.netAmount, item.netQty)}</td>
          </tr> ) }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TradeCaptureTable;
