import React, { useState } from "react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableGarantiasyCoberturasBYMA = ({ objData = {}, cargaData = () => {}, isLoading = false }) => {
  const [filtroCuenta, setFiltroCuenta] = useState("");
  const [filtroCodInstr, setFiltroCodInstr] = useState("");

  const formatNumberMoneda = (num) => {
    if (!num) return "";
    return num.toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  };
  const formatNumberCantidad = (num) => {
    if (!num) return "";
    return num.toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const borderStyle = { borderRightColor: "black" };
  const borderBottom = { borderBottomColor: "black", borderBottomStyle: "solid" };

  const sum = (a, b) => {
    a = a ? a : 0;
    b = b ? b : 0;
    return a + b;
  };

  let data = [];
  Object.entries(objData).forEach(([idCuenta, rows]) => {
    rows.forEach((item) => {
      data.push({ idCuenta, ...item });
    });
  });
  // console.log(objDataTable)
  //console.log(data);

  return (
    <div className="card">
      <div className="card-header ">
        <span>
          <b>Garantias y Coberturas</b>
          <button
            disabled={isLoading}
            className="btn btn-secondary btn-sm ml-1"
            onClick={() => {
              cargaData();
            }}
          >
            <FontAwesomeIcon icon={faSync} />
          </button>
        </span>
        <input
          placeholder="Cod. Instrumento"
          value={filtroCodInstr}
          onChange={(e) => setFiltroCodInstr(e.target.value)}
          className="form-control-sm form-control  float-right mr-1"
          type="text"
          style={{ "max-width": "130px" }}
        />
        <input
          placeholder="Cuenta"
          value={filtroCuenta}
          onChange={(e) => setFiltroCuenta(e.target.value)}
          className="form-control-sm form-control  float-right mr-1"
          type="text"
          style={{ "max-width": "70px" }}
        />
      </div>
      <div className="card-body table-responsive">
        <div className="row">
          <div className="col-12">
          <div style={{ maxHeight: "85vh", minHeight: "10vh" }} className=" tableFixHead">
            <table style={{ fontSize: "11px" }} className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th style={{ visibility: "hidden" }}></th>
                  <th style={borderStyle} colSpan={4} className="text-center" scope="col">
                    Activos en Gtia.
                  </th>
                  <th colSpan={4} className="text-center" scope="col">
                    Coberturas por activo
                  </th>
                </tr>
                <tr>
                  <th style={borderStyle}>Cuenta</th> <th>Codigo Instrumento</th> <th>Instrumento</th> <th>Total</th> <th style={borderStyle}>Disponible</th>
                  <th>Forma Operativa</th> <th>Cant. Gtia.</th> <th>Vencimiento</th>
                  <th>Total Utilizado</th>
                </tr>
              </thead>
              <tbody>
                {data.filter(item => item.idCuenta.includes(filtroCuenta) && item.codInstr.toLowerCase().includes(filtroCodInstr.toLowerCase()) ).map((row) => (
                  <>
          
                    <tr key={row.idCuenta}>
                      <td style={{...borderStyle, ...borderBottom} } rowSpan={row.coberturas.length != 0 ? row.coberturas.length : 1}>{row.idCuenta}</td>
                      <td style={{ ...borderBottom} } rowSpan={row.coberturas.length != 0 ? row.coberturas.length : 1}>{row.codInstr}</td>
                      <td style={{ ...borderBottom} } rowSpan={row.coberturas.length != 0 ? row.coberturas.length : 1}>{row.instrumento}</td>
                      
                      <td className="text-right"  style={{ ...borderBottom} } rowSpan={row.coberturas.length != 0 ? row.coberturas.length : 1}>{formatNumberMoneda(row.disponible+row.sumaCantCobertura)}</td>
                      <td className="text-right"  style={{...borderStyle, ...borderBottom} } rowSpan={row.coberturas.length != 0 ? row.coberturas.length : 1}>{formatNumberMoneda(row.disponible)}</td>
                      
                      {row.coberturas.length != 0 ? (
                        <>
                          <td style={row.coberturas.length == 1 ? borderBottom : undefined}>{row.coberturas[0].formaOperativa}</td> <td className="text-right" style={row.coberturas.length == 1 ? borderBottom : undefined}>{formatNumberMoneda(row.coberturas[0].cantGtia)}</td>{" "}
                          <td style={row.coberturas.length == 1 ? borderBottom : undefined}>{row.coberturas[0].vencimiento}</td>{" "}
                        </>
                      ) : (
                        <>
                          <td style={borderBottom} ></td>
                          <td style={borderBottom}></td>
                          <td style={borderBottom}></td>
                        </>
                      )}
                      <td className="text-right"  style={{...borderStyle, ...borderBottom} } rowSpan={row.coberturas.length != 0 ? row.coberturas.length : 1}>{formatNumberMoneda(row.sumaCantCobertura)}</td>
                    </tr>
                    {row.coberturas.slice(1).map((item, index) => (
                      <tr>
                        <td style={index == row.coberturas.length-2 ? borderBottom : undefined} >{item.formaOperativa}</td>
                        <td style={index == row.coberturas.length-2 ? borderBottom : undefined}  className="text-right">{formatNumberMoneda(item.cantGtia)}</td>
                        <td style={index == row.coberturas.length-2 ? borderBottom : undefined} >{item.vencimiento}</td>
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableGarantiasyCoberturasBYMA;
