import React from 'react'
import TablaUsers from '../components/TablaUsers';


import useDocumentTitle from "../hooks/useDocumentTitle";

const UsersPanel = () => {
    useDocumentTitle('Gestión de Usuarios')
    return (
        <div className="container-fluid">
        <div className="row">
            <div className="col">       
            <TablaUsers />
             </div>

        </div>
        </div>
    )
}

export default UsersPanel
