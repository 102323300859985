import React, { useState, useRef } from "react";
import getTKN from "../services/getTKN";

function FileUploadButton({ endPoint = "", className = "", btnClassName = "btn btn-primary", btnText = "Subir archivo", icon = null, callBack =  () => {}, fileAccept = ".xls, .xlsx" }) {
  const [uploadStatus, setUploadStatus] = useState("");
  const fileInputRef = useRef(null);

  // Función para manejar el upload del archivo
  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setUploadStatus("Por favor selecciona un archivo primero.");
      return;
    }

    const formData = new FormData();
    formData.append("File", file, "filename.xlsx");

    try {
      const response = await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: { Authorization: `Bearer ${getTKN()}` },
      });

      if (response.ok) {
        setUploadStatus("Archivo subido exitosamente.");
        callBack();
      } else {
        setUploadStatus("Error al subir el archivo.");
      }
    } catch (error) {
      setUploadStatus("Error al subir el archivo.");
    }
  };

  // Función para disparar el click en el input file
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <span className={className}>
      <input accept={fileAccept} type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleUpload} />
      <button className={btnClassName} onClick={handleButtonClick}>
        {btnText} {icon}
      </button>
      {uploadStatus && (
        <span class="alert alert-warning alert-dismissible fade show ml-1" role="alert">
          {uploadStatus}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </span>
      )}
    </span>
  );
}

export default FileUploadButton;
