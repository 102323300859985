const baseURL = process.env.REACT_APP_API_URL;

export const setUserConfig = async (options, newUser) => {
    let json =  []
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...options.headers },
        body: JSON.stringify(newUser)
    };
    try {
      const res = await fetch(`${baseURL}user/config`, requestOptions);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };