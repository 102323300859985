import React, { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import Tablev2 from "./Tablev2";
const ALYC_INTEGRAL = process.env.REACT_APP_ALYC_INTEGRAL === "true";


const SaldosControlAN = ({data = [], moneda = "", reloadPanel, initBodyVisb = true, isLoading = false}) => {

  const [filtroAN, setFiltroAN] = useState("")
  const [filtroCuenta, setFiltroCuenta] = useState("")
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
  const [agrupaAN, setAgrupaAN] = useState(false)

  const showhideBody = () => {
    setBodyVisible(!bodyVisible);
  };

  const formatDigit = (value) => {
    if(!value) return ""
    return value.toLocaleString("de", {minimumFractionDigits: 0, maximumFractionDigits: 0})
   }
   const sumColumn = (table, col) => {
    let sum = 0;
    table.forEach((item) => {
      if (item[col]) {
        sum += item[col];
      }
    });
    return sum;
  };

  const colAN = ALYC_INTEGRAL ? [      {
    Header: 'Cod. AN',
    accessor: 'codAN', 
    Cell: (props) => (
      <span style={{fontWeight: 'bold'}}>{String(props.value)}</span>
    ),

  }] : [];
  const colFCI = ALYC_INTEGRAL ? [{
    Header: "FCI Pend.",
    accessor: "fcisaldoPendiente",
    className: "text-right",
    Cell: (props) => formatDigit(props.value),
    sortType: "basic",
    Footer: ({ data }) => {
      return <b>{formatDigit(sumColumn(data, "fcisaldoPendiente"))} </b>;
    },
  },
  {Header: "FCI Proc.",
    accessor: "fcisaldoProcesado",
    className: "text-right",
    Cell: (props) => formatDigit(props.value),
    sortType: "basic",
    Footer: ({ data }) => {
      return <b>{formatDigit(sumColumn(data, "fcisaldoProcesado"))} </b>;
    },
    
   } 
] : [];     

  const colsDepExt = ALYC_INTEGRAL ? [
    {
      Header: 'Dep/Ext Pend.',
      accessor: 'depExtPendiente',
      className: "text-right",
      Cell: (props) => formatDigit(props.value),
      sortType: 'basic',
      Footer:  ({data}) => {return <b >{formatDigit(sumColumn(data, "depExtPendiente"))} </b> },
    },
    {
      Header: 'Dep/Ext Proc.',
      accessor: 'depExtProcesado',
      className: "text-right",
      Cell: (props) => formatDigit(props.value),
      sortType: 'basic',
      Footer:  ({data}) => {return <b >{formatDigit(sumColumn(data, "depExtProcesado"))} </b> },
    },
  ] : [];
  
   const columns = useMemo(
    () => [
       ...colAN,
      {
        Header: 'Cuenta',
        accessor: 'codCuenta', 
        Cell: (props) => (
          <span style={{fontWeight: 'bold'}}>{String(props.value)}</span>
        ),
  
      },
      {
        Header: 'Denom.',
        accessor: 'desComitente',
        disableSortBy: true,
        Footer:  () =>     <span style={{fontWeight: 'bold'}}>Totales:</span>
      },
      {
        Header: 'Inicio',
        accessor: 'inicio',
        className: "text-right",
        Cell: (props) => formatDigit(props.value),
        sortType: 'basic',
        Footer:  ({data}) => {return <b >{formatDigit(sumColumn(data, "inicio"))} </b> },
      },
      {
        Header: 'Codo. Gdo.',
        accessor: 'contadoGarantizado',
        className: "text-right",
        Cell: (props) => formatDigit(props.value),
        sortType: 'basic',
        Footer:  ({data}) => {return <b >{formatDigit(sumColumn(data, "contadoGarantizado"))} </b> },
      },
      {
        Header: 'SENEBI',
        accessor: 'senebi',
        className: "text-right",
        Cell: (props) => formatDigit(props.value),
        sortType: 'basic',
        Footer:  ({data}) => {return <b >{formatDigit(sumColumn(data, "senebi"))} </b> },
      },
      {
        Header: 'Caución BYMA',
        accessor: 'caucion',
        className: "text-right",
        Cell: (props) => formatDigit(props.value),
        sortType: 'basic',
        Footer:  ({data}) => {return <b >{formatDigit(sumColumn(data, "caucion"))} </b> },
      },
      {
        Header: 'Caución MAV',
        accessor: 'caucionMAV',
        className: "text-right",
        Cell: (props) => formatDigit(props.value),
        sortType: 'basic',
        Footer:  ({data}) => {return <b >{formatDigit(sumColumn(data, "caucionMAV"))} </b> },
      },
      {
        Header: 'CPD MAV',
        accessor: 'saldoCPD',
        className: "text-right",
        Cell: (props) => formatDigit(props.value),
        sortType: 'basic',
        Footer:  ({data}) => {return <b >{formatDigit(sumColumn(data, "saldoCPD"))} </b> },
      },
      {
        Header: 'PNG MAV',
        accessor: 'paseNoGAR',
        className: "text-right",
        Cell: (props) => formatDigit(props.value),
        sortType: 'basic',
        Footer:  ({data}) => {return <b >{formatDigit(sumColumn(data, "paseNoGAR"))} </b> },
      },
      ...colsDepExt,
      ...colFCI,
      // {
      //   Header: 'Deriv. MAV',
      //   accessor: 'saldoDerivMAV',
      //   className: "text-right",
      //   Cell: (props) => formatDigit(props.value),
      //   sortType: 'basic',
      // },
      
      {
        Header: 'Cierre',
        accessor: 'cierre',
        className: "text-right",
        Cell: (props) =>           <div className={props.value < 0 ? "alert-danger" : ""}>  {formatDigit(props.value)} </div>,
        sortType: 'basic',
        Footer:  ({data}) => {return <b >{formatDigit(sumColumn(data, "cierre"))} </b> },
  
      }
    ],
    []
  )

  const cardBodyClass = bodyVisible ? "card-body " : "card-body d-none";
  const leyBtn = bodyVisible ? "-" : "+";

  let dataRender = [];
  if(!agrupaAN) {
     dataRender = data.filter( item => item?.codAN.includes(filtroAN) && item?.codCuenta.includes(filtroCuenta) )
  } else {
    let agrupados = {};
    data.forEach(item => {
      const {codAN} = item;
      if (agrupados[codAN] == undefined) {
        agrupados[codAN] = {
          inicio: 0,
          caucion: 0,
          contadoGarantizado: 0,
          senebi: 0,
          cierre: 0,
          codAN,
          codCuenta: "",
          caucionMAV: 0,
          paseNoGAR: 0,
          saldoCPD: 0,
        };
      }
      agrupados[codAN].inicio += item.inicio || 0;
      agrupados[codAN].caucion += item.caucion || 0;
      agrupados[codAN].contadoGarantizado += item.contadoGarantizado || 0;
      agrupados[codAN].senebi += item.senebi || 0;
      agrupados[codAN].caucionMAV += item.caucionMAV || 0;
      agrupados[codAN].paseNoGAR += item.paseNoGAR || 0;
      agrupados[codAN].saldoCPD += item.saldoCPD || 0;
      agrupados[codAN].cierre += item.cierre || 0;
    })
     dataRender = Object.entries(agrupados).map(([key, value]) => value ).filter( item => item?.codAN.includes(filtroAN) )
  }
  
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <strong>Saldos {moneda} </strong> - {new Date().toLocaleDateString()}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
            <span className="input-group" style={{justifyContent: "right"}}>
            
            
        {ALYC_INTEGRAL &&  <input
                placeholder="AN"
                onChange={(e) => {
                  setFiltroAN(e.target.value);
                }}
                className="form-control-sm form-control  mr-2"
                type="text"
                style={{ maxWidth: "40px"}}
              />}

              <input
                placeholder="Cuenta"
                onChange={(e) => {
                  setFiltroCuenta(e.target.value);
                }}
                className="form-control-sm form-control  float-right mr-2"
                type="text"
                style={{ maxWidth: "100px", minWidth: "70px" }}
              />
{ALYC_INTEGRAL &&
          <div role="group" className="  btn-group-toggle float-right mr-2">
          <button
            onClick={() => {
              setAgrupaAN(prev => !prev);
            }}
            type="checkbox"
            aria-pressed="false"
            autocomplete="off"
            className={agrupaAN ? "btn btn-secondary btn-sm active" : "btn btn-secondary  btn-sm"}
            title="Agrupa AN"
          >
            Agrupa ANs
          </button>
        </div>}
 

              <button
                type="button"
                onClick={() => reloadPanel()}
                name=""
                disabled={isLoading}
                className={
                  !isLoading
                    ? "btn btn-secondary float-right btn-sm mr-1"
                    : "btn btn-secondary float-right btn-sm mr-1 disabled"
                }
              >
                <FontAwesomeIcon className={isLoading ? "loading-icon" : "" } icon={faSync} />
              </button>
    
 
             <button
                type="button"
                onClick={() => showhideBody()}
                className="btn btn-secondary btn-sm float-right ml-1"
              >
                <b>{leyBtn}</b>
              </button>
              </span>
            </div>
          </div>
        </div>
       <div className={cardBodyClass}>
       <div style={{ maxHeight: "82vh", minHeight: "10vh" }} className="tableFixHead">
              <Tablev2  data={dataRender} columns={columns}/>
          </div>
       </div>

      </div>
    </div>
  );
};

export default SaldosControlAN;
