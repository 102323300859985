import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const PasswordInput = ({ containerClassName = "input-group mt-2", ...props }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={containerClassName}>
      <input

        type={passwordVisible ? 'text' : 'password'}
        className="form-control"
        placeholder="Contraseña"
        aria-label="Password"
        {...props}
      />
      <div className="input-group-append">
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={togglePasswordVisibility}
        >
          <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
        </button>
      </div>
    </div>
  );
};

export  {PasswordInput};
