import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrashAlt, faCopy, faSave, faEdit } from "@fortawesome/free-solid-svg-icons";
//import {  getFondos } from "../services/listaFondos";
import useServices from "../hooks/useServices";

const TablaFondos = () => {
  const [datos, setDatos] = useState([]);
  const [nroComitenteESCO, setNroComitenteESCO] = useState("");
  const [denominacion, setDenominacion] = useState("");
  const [CUIT, setCUIT] = useState("");
  const [nroBCRA, setNroBCRA] = useState("");
  const [depositante, setDepositante] = useState("");
  const [comitenteDep, setComitente] = useState("");
  const [denomSocDepositaria, sedenomSocDepositaria] = useState("");
  const [aliasFondo, setAliasFondo] = useState("");
  const [ctaBancaria, setCtaBancaria] = useState("");
  const [CBU, setCBU] = useState("");
  const { delFondo, addFondo, getFondos, setFondo } = useServices();

  async function cargaDatos() {
    const listaFondos = await getFondos();
    //console.log({ listaFondos });
    setDatos(listaFondos);
  }

  function selectElementContents(el) {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
  }
  async function handleDelFondo(id) {
    //console.log(id);
    const proceed = window.confirm(`Esta seguro de borrar el fondos?`);
    if(!proceed) return
    const respuesta = await delFondo(id);

    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }

  async function handleAddFondo() {

    if(nroComitenteESCO == '') return alert('Numero Comitente ESCO no definido');
    if(denominacion == '') return alert('Denominacion no definido');
 
    
    const postData = { nroComitenteESCO, denominacion, CUIT, nroBCRA, depositante, denomSocDepositaria, aliasFondo, ctaBancaria, CBU, comitenteDep };
    //console.log(postData)
    const proceed = window.confirm("Estas por agregar un fondo, confirmas?");
    if(!proceed) return

 
    const respuesta = await addFondo(postData);

    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }
  async function handleSetFondo (Fondo) {
    Fondo.editable = false;
    //console.log(Fondo)
    const proceed = window.confirm("Esta seguro de sobreescribir los datos?");
    if(!proceed) return
    const respuesta = await setFondo(Fondo);
    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }
  function setEditable(id) {
    datos.find(item => item.id == id).editable = true;
    setDatos([...datos])
  }
  function unSetEditable(id) {
    datos.find(item => item.id == id).editable = false;
    setDatos([...datos])
  }

  useEffect(() => {
    cargaDatos();
  }, []);
  function editDato(valor, columna, id) {
    //   console.log(valor, columna, id)
       let newDatos = [...datos];

       newDatos.find(item => item.id == id)[columna] = valor;
       setDatos([...newDatos])
  } 

  return (
    <div className="card">
      <div className="card-header ">
        <span className="ml-1">
          <b>Fondos</b>
        </span>{" "}
        <span className="badge badge-secondary"></span>
        <button
          type="button"
          name=""
          data-toggle="tooltip"
          data-placement="bottom"
          title="Copiar"
          onClick={() => {
            selectElementContents(document.getElementById("tb_comit"));
          }}
          className="btn btn-secondary float-right ml-1"
        >
          <FontAwesomeIcon icon={faCopy} />
        </button>

      </div>

      <div style={{ maxHeight: "95vh", overflowY: "auto" }} className="card-body">
        <table id={"tb_comit"} className="table table-sm table-bordered table-hover ">
          <thead>
            <tr>
              <th scope="col">Número Comitente ESCO</th>
              <th scope="col">Denominación FCI</th>
              <th scope="col">CUIT Soc. Dep.</th>
              <th scope="col">Número BCRA</th>
              <th scope="col">Depositante</th>
              <th scope="col">Comitente</th>
              <th scope="col">Denominacion Soc. Dep.</th>
              <th scope="col">Cuenta Bancaria</th>
              <th scope="col">CBU</th>
              <th scope="col">Alias Fondo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input onChange={(e) => setNroComitenteESCO(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => setDenominacion(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => setCUIT(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => setNroBCRA(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => setDepositante(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => setComitente(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => sedenomSocDepositaria(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => setCtaBancaria(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => setCBU(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => setAliasFondo(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                {" "}
                <button onClick={() => handleAddFondo()} className="btn btn-success btn-sm">
                  <b>+</b>
                </button>
              </td>
            </tr>

            {datos.map((linea) => (
              <tr key={linea.id}>
                {!linea.editable && <>
                <td> {linea.nroComitenteESCO} </td>
                <td> {linea.denominacion} </td>
                <td> {linea.CUIT} </td>
                <td> {linea.nroBCRA} </td>
                <td> {linea.depositante} </td>
                <td> {linea.comitenteDep} </td>
                <td> {linea.denomSocDepositaria} </td>
                <td> {linea.ctaBancaria} </td>
                <td> {linea.CBU} </td>
                <td> {linea.aliasFondo} </td>
        
                </>
              } { linea.editable &&
                <>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'nroComitenteESCO', linea.id)} value = {linea.nroComitenteESCO} className="form-control" type="text" />  </td>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'denominacion', linea.id)} value = {linea.denominacion} className="form-control" type="text" />  </td>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'CUIT', linea.id)} value = {linea.CUIT} className="form-control" type="text" />  </td>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'nroBCRA', linea.id)} value = {linea.nroBCRA} className="form-control" type="text" />  </td>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'depositante', linea.id)} value = {linea.depositante} className="form-control" type="text" />  </td>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'comitenteDep', linea.id)} value = {linea.comitenteDep} className="form-control" type="text" />  </td>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'denomSocDepositaria', linea.id)} value = {linea.denomSocDepositaria} className="form-control" type="text" />  </td>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'ctaBancaria', linea.id)} value = {linea.ctaBancaria} className="form-control" type="text" />  </td>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'CBU', linea.id)} value = {linea.CBU} className="form-control" type="text" />  </td>
                 <td>  <input onChange={(e) => editDato(e.target.value, 'aliasFondo', linea.id)} value = {linea.aliasFondo} className="form-control" type="text" />  </td>

                </>

              }
                <td>
                  <button
                    onClick={() => {
                      handleDelFondo(linea.id);
                    }}
                    className="btn btn-danger btn-sm"
                    title="Eliminar registro"
                  >
                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                  </button>
                </td>
            {!linea.editable &&   <td>
                  <button
                    onClick={() => {
                      setEditable(linea.id);
                    }}
                    className="btn btn-warning btn-sm"
                    title="Editar registro"
                  >
                     <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                  </button>
                </td>}
                {linea.editable &&
                <td>
                  <button
                    onClick={() => {
                      handleSetFondo(linea);
                    }}
                    className="btn btn-success btn-sm"
                    title="Guardar cambios"
                  >
                     <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                  </button>
                  <button
                    onClick={() => {
                      unSetEditable(linea.id);
                    }}
                    className="btn btn-danger btn-sm"
                    title="Cancelar"
                  >
                     <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                  </button>
                </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablaFondos;
