import React, {useState} from 'react'

const Context = React.createContext({
    username: '',
    tkn: '',
    userCategory: ''
});
export function SessionContextProvider({children}) {
    const [tkn, setTkn] = useState('');
    const [userCategory, setUserCategory] = useState('')
    const [username, setUserName] = useState('')

    return <Context.Provider value={{tkn, userCategory, username, setTkn, setUserName, setUserCategory}}>
        {children}
    </Context.Provider>

}

export default Context

