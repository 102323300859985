
const baseURL = process.env.REACT_APP_API_URL;

const getSaldosBOPTrader = async (options) => {
  let json =  []
  try {
    const res = await fetch(`${baseURL}bop/saldosTrader`, options);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export default getSaldosBOPTrader;