import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import "../css/customTable.css";
import FileUploadButton from "./FileUploadButton";
import useServices from "../hooks/useServices";
const baseURL = process.env.REACT_APP_API_URL;


const DistribucionesTable = ({ titulo, initBodyVisb = false, realoadBtn = true }) => {
  const [filtro, setFiltro] = useState("");
  const [tabla, setTabla] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  //let tabla = [];
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
  const tablaRef = useRef(null);
  const { getDerivacionesPPTLiqDiaBoletos, distribucionMasiva } = useServices();

  useEffect(() => {
    cargaDerivacionesPPT();
  }, []);

  async function cargaDerivacionesPPT() {
    setisLoading(true);
    const derivaciones = await getDerivacionesPPTLiqDiaBoletos();
    setisLoading(false);
    if (derivaciones.error) return;
    setTabla(derivaciones.tabla);
  }

  //console.log("datos", datos);

  //setTabla(datos)
  //tabla = datos;

  const formatNumero = (numero) => {
    if (isNaN(numero)) return "";
    if (numero == undefined) return numero;
    if (numero == " ") return numero;
    return numero.toLocaleString("es");
  };

  const showhideBody = () => {
    setBodyVisible(!bodyVisible);
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none";
  const leyBtn = bodyVisible ? "-" : "+";

  function exportTableToExcel(tableSelect, filename = "") {
    const dataType = "application/vnd.ms-excel;charset=utf-8";
    //var tableSelect = document.getElementById(tableID);
    let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");
        tableHTML = tableHTML.replace(/<input.*?type="checkbox">/gm, "");

    // Specify file name
    //const fechaStr = new Date().toISOString().split("T")[0];
    const fechaStr = new Date().toISOString().split("T")[0];
    //fechaStr = fechaStr.replace(/\//g, '-');
    filename = filename ? filename + ".xls" : `distribuciones_${fechaStr}.xls`;

    // Create download link element
    const downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = "data:" + dataType + ", " + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }
  function handleDownloadExcel() {
    exportTableToExcel(tablaRef.current);
  }
  function matchBoletos(boletosvb = []) {
    let newArr = [...tabla];
    boletosvb.forEach((boletovb) => {
      let found = newArr.find(
        (item) =>
          item.comitente == boletovb.comitente &&
          item.cantidad == boletovb.cantidad &&
          item.fechaConcertacion == boletovb.fechaConcertacion &&
          item.fechaLiquidacion == boletovb.fechaLiquidacion &&
          item.moneda == boletovb.moneda &&
          item.nroCajval == boletovb.nroCajval &&
          item.lado == boletovb.lado
      );
      if(found) {found.montoDistribucion = boletovb.montoNeto }
    
    });
    console.log(newArr)
    setTabla(newArr)
  }
  function handleUploadResult(result) {
    console.log(result)
    if (result.error) return;
    matchBoletos(result.data);
  }
  function editMonto (itemId, value) {
    let newArr = [...tabla];
    let found = newArr.find( item => item.id == itemId);
    found.montoDistribucion = value;
    if(!value) found.selected = false;
    setTabla(newArr)
  }
  function handleCheck (itemId) {
    let newArr = [...tabla];
    let found = newArr.find( item => item.id == itemId);
    found.selected = !found.selected
    setTabla(newArr)
  }
  function handleCheckAll (checked) {
    
    const newArr = tabla.map( item => {
      if(item.montoDistribucion) item.selected = checked;
      return item;
    })

    setTabla(newArr)
  }
  async function distribuirSaldos () {

  const selecteds =  tabla.filter( item => item.selected );
  for (const item in selecteds) {
    console.log(selecteds[item])
    let newArr = [...tabla];
    let found = newArr.find( itm => itm.id ==  selecteds[item].id);
    found.isSending = true;
    setTabla(newArr)
    const res = await distribucionMasiva(selecteds[item])
    if(res.error) return

    found.isSending = false;
    found.status = res.status;
    setTabla([...newArr])
  }

  }

  return (
    <div className="card">
      <div className="card-header ">
        <div className="row">
          <div className="col">
            <span>
              <b>Derivaciones - {titulo}</b>
            </span>
          </div>
          <div className="col">
            <FileUploadButton
              endPoint={`${baseURL}upload/uploadBoletosVB`}
              fileName={"boletosvb.xlsx"}
              label={"Cargar Boletos"}
              callback={handleUploadResult}
            />
             </div>
        <div className="col">

        <button  className="btn btn-sm btn-primary ml-1  float-right" onClick={() => distribuirSaldos()}>
            Distribuir saldos
            </button>

            <button title="Exportar tabla" className="btn btn-sm btn-success ml-1  float-right" onClick={() => handleDownloadExcel()}>
             <FontAwesomeIcon icon={faFileAlt} />
            </button>

            <span>
              {realoadBtn && (
                <button
                  type="button"
                  onClick={() => cargaDerivacionesPPT()}
                  name=""
                  className={
                    isLoading
                      ? "btn btn-secondary float-right btn-sm ml-1 disabled"
                      : "btn btn-secondary float-right btn-sm ml-1"
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </button>
              )}
 
              <input
                placeholder="Cuenta"
                onChange={(e) => {
                  setFiltro(e.target.value);
                }}
                className="form-control-sm form-control  float-right ml-1"
                type="text"
                style={{ maxWidth: "80px" }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className={cardBodyClass}>
        <div style={{ maxHeight: "80vh", minHeight: "10vh" }} className=" tableFixHead">
          <table ref={tablaRef} className="table table-sm table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col">Fecha Concert.</th>
                <th scope="col">Fecha Liq.</th>
                <th scope="col">Plazo</th>
                <th scope="col">Moneda</th>
                <th scope="col">Comitente</th>
                <th scope="col">Especie</th>
                <th scope="col">Nro. Caj. Val.</th>
                <th scope="col">Lado</th>
                <th scope="col">Tasa</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Monto Bruto</th>
                <th scope="col">Monto a Derivar</th>
                <th scope="col" className="text-right" style={{maxWidth: "20px"}} ><input onChange={ (e) => {handleCheckAll(e.target.checked)} }   className="form-control-sm form-control" type="checkbox"  /></th>
              </tr>
            </thead>
            <tbody>
              {tabla
                .filter((item) => item.comitente.includes(filtro))
                .map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.fechaConcertacion}</td>
                    <td>{item.fechaLiquidacion}</td>
                    <td>{item.plazo}</td>
                    <td>{item.moneda}</td>
                    <td>{item.comitente}</td>

                    <td>{item.codTitVal}</td>
                    <td>{item.nroCajval}</td>
                    <td>{item.lado}</td>
                    <td>{item.tasa}</td>

                    <td className="text-right">{formatNumero(item.cantidad)}</td>
                    <td className="text-right">{formatNumero(item.montoBruto)}</td>
                    <td className="text-right" style={{maxWidth: "100px"}} ><span hidden>{formatNumero(item.montoDistribucion*1)}</span> <input onChange={ (e) => {editMonto(item.id, e.target.value)} } className="form-control-sm form-control text-right" type="number" value={item.montoDistribucion} /></td>
                    <td  className="text-center" style={{maxWidth: "35px"}} >
                      {!item.isSending && !item.status ?
                        <input onChange={ (e) => {handleCheck(item.id)} } disabled={!item.montoDistribucion || item.montoDistribucion == 0 } checked={item.selected} className="form-control-sm form-control" type="checkbox"  /> 
                          :
                         (!item.status && <button className="btn btn-sm btn-warning">Enviando...</button>)
                       }
                      {item.status == "OK" && <button className="btn btn-sm btn-success">OK</button> }
                      {item.status == "ERROR" && <button className="btn btn-sm btn-danger">{item.status}</button> }
                      </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DistribucionesTable;
