import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { procesaSaldosDerivacionesSLYC } from "../lib/utils";



const SaldosDerivaciones = ({
  moneda,
  saldos,
  saldosCI,
  reloadPanelSaldos,
  saldosDerivaciones = false,
  saldosDerivacionesSB = false, 
  isLoading = false
}) => {
  const formatNumero = (numero) => {
    if (!numero) return "";
    return (numero * 1).toLocaleString();
  };

  const mon_dicc = { P: "Pesos", D: "Dólares", C: "Dólar Transferencia" };
  const diccCodMon = { P: "Pesos", D: "Dólar", C: "Dólar Transferencia" };

  const saldosMoneda = saldos[moneda];
  let saldosAntesCI = 0;

  const [saldosCIMoneda, setSaldosCIMoneda] = useState(0);


  //console.log("saldos", saldos);

/*   useEffect(() => {
    //console.log({ saldosCI });

    if (saldosCI !== undefined) {
      //console.log({ saldosCI });
      //setSaldosCIMoneda(moneda == "P" ? saldosCI["Pesos"] : saldosCI["Dólar"]);
      setSaldosCIMoneda(saldosCI[diccCodMon[moneda]] || 0);
      //  console.log(saldosCIMoneda);
    } else {
      setSaldosCIMoneda(0);
    }

    //console.log({ saldosCI }, { saldosCIMoneda });
  }, [saldosCI]); */

  let saldosCaucionDerivable = 0;
  let caucionesDerivado = 0;
  if(saldosDerivaciones) {
  if (saldosDerivaciones.saldosCaucion[diccCodMon[moneda]]) {
    saldosCaucionDerivable = saldosDerivaciones.saldosCaucion[diccCodMon[moneda]];
    saldosCaucionDerivable *= -1;
  }
  if (saldosDerivaciones.saldosCaucionDerivado[diccCodMon[moneda]]) {
    caucionesDerivado = saldosDerivaciones.saldosCaucionDerivado[diccCodMon[moneda]];
    caucionesDerivado *= -1;
  }
}
//console.log({saldosCaucionDerivable, caucionesDerivado})

  //const saldoCIDerivable = saldosCIMoneda * -1;

  const procesados = procesaSaldosDerivacionesSLYC({ saldosMoneda });
  //const {  contadoInmediatoDerivado } = procesados;




  const handleReload = async () => {

    await reloadPanelSaldos();
 
  };
  let saldoDerivableTotalDiaAnt = 0;
  let contadoDiaAntDerivado = 0;
  let  contadoInmediatoDerivado = 0;
  let saldoCIDerivable = 0;
  let saldoSBDerivable = 0;
  let saldoSBDerivado = 0;
  if (saldosDerivaciones) {
    if (saldosDerivaciones.saldos["48hs"]) {
      saldoDerivableTotalDiaAnt += saldosDerivaciones.saldos["48hs"][diccCodMon[moneda]] || 0;
    }
    if (saldosDerivaciones.saldos["24hs"]) {
      saldoDerivableTotalDiaAnt += saldosDerivaciones.saldos["24hs"][diccCodMon[moneda]] || 0;
    }
    if (saldosDerivaciones.saldos["Inmediato"]) {
      saldoCIDerivable += saldosDerivaciones.saldos["Inmediato"][diccCodMon[moneda]] || 0;
    }
    if (saldosDerivaciones.saldosDerivados["Inmediato"]) {
      contadoInmediatoDerivado += saldosDerivaciones.saldosDerivados["Inmediato"][diccCodMon[moneda]] || 0;
    }
    contadoInmediatoDerivado *=-1;
    saldoDerivableTotalDiaAnt *= -1;
    saldoCIDerivable *=  -1;
    saldosAntesCI += saldoDerivableTotalDiaAnt;
    if (saldosDerivaciones.saldosDerivados["48hs"]) {
      contadoDiaAntDerivado += saldosDerivaciones.saldosDerivados["48hs"][diccCodMon[moneda]] || 0;
    }
    if (saldosDerivaciones.saldosDerivados["24hs"]) {
      contadoDiaAntDerivado += saldosDerivaciones.saldosDerivados["24hs"][diccCodMon[moneda]] || 0;
    }
    contadoDiaAntDerivado *= -1;


  }

if(saldosDerivacionesSB) {

  if (saldosDerivacionesSB.saldos["48hs"]) {
    saldoSBDerivable += saldosDerivacionesSB.saldos["48hs"][diccCodMon[moneda]] || 0;
  }
  if (saldosDerivacionesSB.saldos["24hs"]) {
    saldoSBDerivable += saldosDerivacionesSB.saldos["24hs"][diccCodMon[moneda]] || 0;
  }
  if (saldosDerivacionesSB.saldos["Inmediato"]) {
    saldoSBDerivable += saldosDerivacionesSB.saldos["Inmediato"][diccCodMon[moneda]] || 0;
  }
  saldoSBDerivable *= -1; 
  if (saldosDerivacionesSB.saldosDerivados["48hs"]) {
    saldoSBDerivado += saldosDerivacionesSB.saldosDerivados["48hs"][diccCodMon[moneda]] || 0;
  }
  if (saldosDerivacionesSB.saldosDerivados["24hs"]) {
    saldoSBDerivado += saldosDerivacionesSB.saldosDerivados["24hs"][diccCodMon[moneda]] || 0;
  }
  if (saldosDerivacionesSB.saldosDerivados["Inmediato"]) {
    saldoSBDerivado += saldosDerivacionesSB.saldosDerivados["Inmediato"][diccCodMon[moneda]] || 0;
  }

  saldoSBDerivado *= -1;

}


  const borderStyle = { borderTopWidth: "2px", borderTopColor: "black" };
  //console.log({ saldosAntesCI, saldosCIMoneda, movBancos, movGtias });
  return (
    <div className="col-lg-4 col-sm-6 col-md-6">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12">
              <strong>Saldos Derivaciones en {mon_dicc[moneda]} </strong> - {new Date().toLocaleDateString()}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 d-sm-none d-md-block ">
              <button
                type="button"
                onClick={() => handleReload()}
                name=""
                disabled={isLoading}
                className={
                  !isLoading
                    ? "btn btn-secondary float-right btn-sm mr-1"
                    : "btn btn-secondary float-right btn-sm mr-1 disabled"
                }
              >
                <FontAwesomeIcon icon={faSync} />
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-sm table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" class="text-right">
                  Saldo Derivable
                </th>
                <th scope="col" class="text-right">
                  Saldo Derivado
                </th>
                <th scope="col" class="text-right">
                  Diferencia
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Contando Gdo. Dia Ant.</td> <td class="text-right">{formatNumero(saldoDerivableTotalDiaAnt)}</td>{" "}
                <td class="text-right">{formatNumero(contadoDiaAntDerivado)}</td>{" "}
                <td class="text-right">{formatNumero(saldoDerivableTotalDiaAnt - contadoDiaAntDerivado)}</td>
              </tr>
              <tr>
                <td>Cauciones</td> <td class="text-right">{formatNumero(saldosCaucionDerivable)}</td>{" "}
                <td class="text-right">{formatNumero(caucionesDerivado)}</td>{" "}
                <td class="text-right">{formatNumero(saldosCaucionDerivable - caucionesDerivado)}</td>
              </tr>
              <tr>
                <td>Contado Inmediato</td> <td class="text-right">{formatNumero(saldoCIDerivable)}</td>{" "}
                <td class="text-right">{formatNumero(contadoInmediatoDerivado)}</td>{" "}
                <td class="text-right">{formatNumero(saldoCIDerivable - contadoInmediatoDerivado)}</td>
              </tr>
              <tr>
              <td>SENEBI</td> <td class="text-right">{formatNumero(saldoSBDerivable)}</td>{" "}
                <td class="text-right">{formatNumero(saldoSBDerivado)}</td>{" "}
                <td class="text-right">{formatNumero(saldoSBDerivable - saldoSBDerivado)}</td>
              </tr>
              <tr>
                
                {" "}
                <td style={borderStyle} class="text-right">
                  <b>Totales</b>
                </td>{" "}
                <td style={borderStyle} class="text-right">
                  {" "}
                  {formatNumero(saldoDerivableTotalDiaAnt + saldosCaucionDerivable + saldoCIDerivable + saldoSBDerivable)}{" "}
                </td>{" "}
                <td style={borderStyle} class="text-right">
                  {" "}
                  {formatNumero(contadoDiaAntDerivado + caucionesDerivado + contadoInmediatoDerivado + saldoSBDerivado)}{" "}
                </td>
                <td class="text-right" style={borderStyle}>
                  {" "}
                  {formatNumero(
                    saldoDerivableTotalDiaAnt -
                      contadoDiaAntDerivado +
                      saldosCaucionDerivable -
                      caucionesDerivado +
                      saldoCIDerivable -
                      contadoInmediatoDerivado + saldoSBDerivable - saldoSBDerivado
                  )}{" "}
                </td>{" "}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SaldosDerivaciones;
