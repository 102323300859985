import TableCrud from '../components/TableCrud'
import useDocumentTitle from '../hooks/useDocumentTitle'


const TablaSizesMTR = () => {
  useDocumentTitle('Tabla Sizes - MTR')
 
    const columns = [{title: "Referencia", dataIndex: 'identifier'}, {title: "Size", dataIndex: 'size', inputType: "number"}]


  return (
    <div className="container">
      <div className="row">
       <div className="col-12">
        <div className="card">
          <div className="card-header">
          <span className="ml-1">
            <b>Sizes Contratos MTR</b>
          </span>
          </div>
          <div className="card-body">
          <TableCrud tableName={'sizesContratosMTR'} columns={columns} />
          </div>
        </div>

       </div>
 
      </div>

    </div>
  )
}

export default TablaSizesMTR