import React, { useState, useEffect } from "react";
import Plazotable from "../components/Plazotable";
import VCAlqTable from "../components/VCAlqTable";
import useDocumentTitle from "../hooks/useDocumentTitle";
//import getResumenOperdiaVC from "../services/getResumenOperdiaVC";
import useServices from "../hooks/useServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";


const VcTrader = () => {
  useDocumentTitle('Ventas en corto')
  const moneda = "P";
  const [info48hs, setInfo48hs] = useState({});
  const [tablaAlqVC, settablaAlqVC] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {getResumenOperdiaVCTrader, getResumenOperdiaAlquilerVCTrader} = useServices();

 async function cargaDatos () {
  setIsLoading(true)
  await cargaOperdia();
  await cargaAlquilerVC();
  setIsLoading(false)
 }

  useEffect(() => {
    cargaDatos()
  }, []);

  async function cargaOperdia() {
    let operContado = await getResumenOperdiaVCTrader();
    if(operContado.error) return
    if (operContado.tabla["48hs"] != undefined) {
     
      const operContadoIntrad = operContado.tabla["48hs"][moneda];
      setInfo48hs({ P: operContadoIntrad });
    }
  }
  async function cargaAlquilerVC () {
    const res = await getResumenOperdiaAlquilerVCTrader();
    if(res.error) return;
    if(res.tabla) { settablaAlqVC(res.tabla)}
  }
 async function handleRecarga () {
  cargaDatos()
 }


  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-12">
        <div className="card">
          <div className="card-header">
          <button disabled={isLoading} type="button" onClick={handleRecarga} className="btn btn-secondary">
            <FontAwesomeIcon icon={faSync} />
          </button>
          </div>
        </div>
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col-lg-6 col-sm-12 col-md-12">
          <Plazotable moneda={moneda} titulo="48hs" datos={info48hs} initBodyVisb={true} />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12">
            <VCAlqTable datos={tablaAlqVC} titulo="Alquiler"  initBodyVisb={true} />
        
            </div>
   
      </div>
    </div>
  );
};

export default VcTrader;
