import React, { useState } from "react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableGarantiasYMargenesMTR = ({ objData = {}, cargaData = () => {}, isLoading = false }) => {
  const [filtroCuenta, setFiltroCuenta] = useState("");
  const [filtroEspecie, setFiltroEspecie] = useState("");

  const formatNumberMoneda = (num) => {
    if (!num) return "";
    return num.toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };
  const formatNumberCantidad = (num) => {
    if (!num) return "";
    return num.toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const ppp = (netoM, netoC) => {
    const num = netoM / netoC;
    return isFinite(num) ? num.toLocaleString("de", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-";
  };

  const borderStyle = { borderRightColor: "black" };
  const borderBottom = {borderBottomColor: "black"};

  const sum = (a, b) => {
    a = a ? a : 0;
    b = b ? b : 0;
    return a + b;
  };
  let objDataTable = {}
  
  Object.entries(objData).map(([idCuenta, row])  => {
    const iterador = row.requerimientosMargenes.length > row.activos.length ? row.requerimientosMargenes : row.activos;
    for(const i in iterador) {
      if(objDataTable[idCuenta] == undefined ) { objDataTable[idCuenta] = [] }
      objDataTable[idCuenta].push({...row?.requerimientosMargenes?.[i], ...row?.activos?.[i], rowspan: iterador.length, idCuenta, totalValorizadoAG: row.totalValorizadoAG, totalReqMargenes: row.totalReqMargenes, neto: sum(row.totalReqMargenes, row.totalValorizadoAG)})
    }

  })
 // console.log(objDataTable)
 const dataRender = Object.entries(objDataTable).sort((a, b) => { return a[1][0].neto - b[1][0].neto} ).filter(([idCuenta, val]) =>  `${idCuenta} ${val[0]?.NettingAccount}`.toLocaleLowerCase().includes(filtroCuenta.toLocaleLowerCase()) );
console.log(dataRender)
function sumaCol(colKey, data = []) {
  let sum = 0;
  data.forEach(item => {
    const rows = item[1];
   
      sum += rows?.[0]?.[colKey] || 0;
   
  })
  return sum
} 
 
return (
    <div className="card">
      <div className="card-header ">
        <span>
          <b>Garantias y Margenes</b>
          <button
            disabled={isLoading}
            className="btn btn-secondary btn-sm ml-1"
            onClick={() => {
              cargaData();
            }}
          >
            <FontAwesomeIcon icon={faSync} />
          </button>
        </span>
        <input
          placeholder="Cuenta"
          value={filtroCuenta}
          onChange={(e) => setFiltroCuenta(e.target.value)}
          className="form-control-sm form-control  float-right mr-1"
          type="text"
          style={{ "max-width": "70px" }}
        />
      </div>
      <div className="card-body table-responsive">
        <table style={{fontSize: "11px"}} className="table table-sm table-bordered">
          <thead>
            <tr>
              <th style={{ visibility: "hidden"}}></th>
              <th style={borderStyle}  colSpan={8} className="text-center" scope="col">
                Activos en Gtia.
              </th>
              <th  colSpan={5} className="text-center" scope="col">
                Req. Margenes
              </th>
            
            </tr>
            <tr>
              {" "}
              <th style={borderStyle}>Cuenta</th> <th>Asset</th> <th>CurrencyCode</th>  <th>Reference</th> <th>InstrumentCode</th> <th>SHAI</th> <th>SECV</th>
              <th>Amount</th> 
              <th style={borderStyle}>Total Valoriz.</th>  
              <th>ProductGroup</th><th>ProductGroupCurrency</th><th>Reference</th>
              <th>Margin</th>
              <th style={borderStyle}>Total Valoriz.</th>
              <th className="text-center" scope="col">{`Neto`}</th>
            </tr>
          </thead>
          <tbody>
           {dataRender.map(([idCuenta, rows]) => rows.map( (row, index) => <tr key={`${idCuenta}-${index}`}>
            {index == 0 && <td style={ {...borderBottom, ...borderStyle} } rowSpan={row.rowspan} >{row.idCuenta} {row.NettingAccount && `- ${row.NettingAccount}`} </td>}
           <td style={index == rows.length -1 ? borderBottom : undefined}>{row.Asset}</td>
           <td style={index == rows.length -1 ? borderBottom : undefined}>{row.CurrencyCode}</td>
           <td style={index == rows.length -1 ? borderBottom : undefined}>{row.ReferenceAct}</td>
           <td  style={index == rows.length -1 ? borderBottom : undefined}>{row.InstrumentCode}</td>
           <td  style={index == rows.length -1 ? borderBottom : undefined} className="text-right">{row.SHAI}</td>
           <td style={index == rows.length -1 ? borderBottom : undefined}  className="text-right">{formatNumberCantidad(row.SECV)}</td>
           <td style={index == rows.length -1 ? borderBottom : undefined}  className="text-right">{formatNumberMoneda(row.Amount)}</td>
           {index == 0 && <td style={{...borderBottom, ...borderStyle}} className="text-right" rowSpan={row.rowspan} >{formatNumberMoneda(row.totalValorizadoAG)}</td>}
          <td style={index == rows.length -1 ? borderBottom : undefined}>{row.ProductGroup} </td>
          <td style={index == rows.length -1 ? borderBottom : undefined}>{row.ProductGroupCurrency} </td>
          <td style={index == rows.length -1 ? borderBottom : undefined}>{row.Reference} </td>
          <td style={index == rows.length -1 ? borderBottom : undefined} className="text-right">{formatNumberMoneda(row.Margin)}</td>
          {index == 0 && <td style={{...borderBottom, ...borderStyle}} className="text-right" rowSpan={row.rowspan} >{formatNumberMoneda(row.totalReqMargenes)}</td>}
           
          { index == 0 && <td className="text-right" style={{...borderBottom, ...borderStyle}} rowSpan={row.rowspan} >{formatNumberMoneda(row.neto)}</td>}
           </tr>   ) )  }
         
         </tbody>
         <tfoot>
          <tr>
            <th>Totales</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th className="text-right">{formatNumberMoneda(sumaCol("totalValorizadoAG", dataRender))}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th className="text-right">{formatNumberMoneda(sumaCol("totalReqMargenes", dataRender))} </th>
            <th className="text-right">{formatNumberMoneda(sumaCol("neto", dataRender))}</th>
          </tr>
         </tfoot>
        </table>
      </div>
    </div>
  );
};

export default TableGarantiasYMargenesMTR;
