import React, { useState, useEffect } from "react";
//import getReqNoCubiertos from "../services/getReqNoCubiertos";
import shortid from "shortid"
import useServices from "../hooks/useServices";
const REACT_APP_CAJVAL_GTIAS_MAV_DEPSUB = process.env.REACT_APP_CAJVAL_GTIAS_MAV_DEPSUB;
const REACT_APP_CAJVAL_DEPOSITANTE = process.env.REACT_APP_CAJVAL_DEPOSITANTE;

const MovGtiasCaucMAV = ({addTransfWidgets}) => {
  const [datos, setDatos] = useState([]);
  const [showDerivadas, setShowDerivadas] = useState(false);
  const {getNominalesCaucMAV} = useServices();




  async function cargaDatos() {
    let movGtias = await getNominalesCaucMAV();
    //console.log({ reqNoc });
    let movimientos = [];
    if (!movGtias.error) {
    
      movGtias.forEach( item => {
        
        if(item.saldoCompensado != 0) {
          const tipoSaldo = item.saldoCompensado > 0 ? "Libre" : "Deudor";
          item.saldoCompensado = Math.abs(item.saldoCompensado)
          movimientos.push({...item, tipoSaldo, id: shortid()})
        }

        });
       // console.log({reqNocTransf})
        setDatos(movimientos)
    }
  }

 
  const handleInputChange = (valor, index) => {

    let newDatos = [...datos];
    newDatos[index].enviamos = valor;
    setDatos(newDatos)

  }

  const handleAddItem =  (id) => {
      //console.log(index)
      const index = datos.findIndex( item => item.id === id)
      const dato = datos[index];
      //console.log(dato)
      let transf = {};
      if(dato.tipoSaldo == "Deudor") {
      transf = {tipo: "(I/E)", subcuenta: `${REACT_APP_CAJVAL_DEPOSITANTE}/${dato.comitente}`, contraparte: `${REACT_APP_CAJVAL_GTIAS_MAV_DEPSUB}`, cantidad: dato.saldoCompensado, nroEspecie: dato.codEspecie, match: "N", classString: 'table-warning'}
      }
      if(dato.tipoSaldo == "Libre") {
        transf = {tipo: "(I/R)", subcuenta: `${REACT_APP_CAJVAL_DEPOSITANTE}/${dato.comitente}`, contraparte: `${REACT_APP_CAJVAL_GTIAS_MAV_DEPSUB}`, cantidad: dato.saldoCompensado, nroEspecie: dato.codEspecie, match: "N", classString: 'table-warning'}
        }
      let newDatos = [...datos];
      newDatos.splice(index, 1);
      setDatos(newDatos)
      addTransfWidgets([transf]);
  }



  const handleAddAllItems = () => {
    //console.log(index)
    let transfers = [];
    //console.log(dato)
    datos.filter(filterDeriv).forEach( dato => {
      let transf = {};
      if (dato.tipoSaldo == "Deudor") {
        transf = {
          tipo: "(I/E)",
          subcuenta: `${REACT_APP_CAJVAL_DEPOSITANTE}/${dato.comitente}`,
          contraparte: `${REACT_APP_CAJVAL_GTIAS_MAV_DEPSUB}`,
          cantidad: dato.saldoCompensado,
          nroEspecie: dato.codEspecie,
          match: "N",
          classString: "table-warning",
        };
      }
      if (dato.tipoSaldo == "Libre") {
        transf = {
          tipo: "(I/R)",
          subcuenta: `${REACT_APP_CAJVAL_DEPOSITANTE}/${dato.comitente}`,
          contraparte: `${REACT_APP_CAJVAL_GTIAS_MAV_DEPSUB}`,
          cantidad: dato.saldoCompensado,
          nroEspecie: dato.codEspecie,
          match: "N",
          classString: "table-warning",
        };
      }
      transfers.push(transf);
    });

    setDatos([]);
    addTransfWidgets([...transfers]);
  };


  useEffect(() => {
    cargaDatos();
  }, []);
  function filterDeriv (item) {
     
    if(showDerivadas) {
      if(item.deriva === true) {return true} else return false;
    }
    if(!showDerivadas) {
      if(item.deriva === true) {return false} else return true;
    }
    
  }

  return (
    <div className="card">
      <div className="card-header grey">
        <b>Movimientos de Garantias - MAV</b>
       
   
        <button type="button" className="btn btn-secondary btn-sm float-right">
          {new Date().toLocaleDateString()}
        </button>
        <button onClick={() => handleAddAllItems() } class="btn btn-sm btn-warning float-right mr-1">Agregar todas</button>
        {showDerivadas ? <button onClick={() => setShowDerivadas(false) } class="btn btn-sm btn-secondary float-right mr-1">Mostrar sin derivación</button>
        :
        <button onClick={() => setShowDerivadas(true) } class="btn btn-sm btn-secondary float-right mr-1">Mostrar derivadas</button>
        }

      </div>
      <div className="card-body">
        <table className="table table-sm table-bordered table-hover">
          <thead>
            <tr>
              <th>Comitente</th>
              <th>Cod. Especie</th>
              <th>Especie</th>
              <th>Tipo saldo</th>
              <th>Cantidad</th>
              <th>Transferimos</th>
            </tr>
          </thead>
          <tbody>
            {datos.filter(filterDeriv).map((item, index) => (
              <tr key={item.id}>
                <td>{item.comitente}</td>
                <td>{item.codEspecie}</td>
                <td>{item.especie}</td>
                <td>{item.tipoSaldo}</td>
                <td>{item.saldoCompensado}</td>
              
                <td>
                  {" "}
                  <input type="number" onChange= {(e) => handleInputChange(e.target.value, index) } value={item.saldoCompensado} className="form-control-sm form-control" />{" "}
                </td>
                <td>
                <button type="button" onClick={() => handleAddItem(item.id) } className="btn btn-warning btn-sm">+</button>
                </td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MovGtiasCaucMAV;
