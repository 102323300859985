
const baseURL = process.env.REACT_APP_API_URL;

const getSaldosProyectadosAUNEComitente = async (options,  {sumaPendientes = false, comitente}) => {
  let json =  []
  console.log({sumaPendientes})
  try {
    const res = await fetch(`${baseURL}AUNE/saldosProyectados/${comitente}?sumaPendientes=${sumaPendientes}`, options);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export default getSaldosProyectadosAUNEComitente;
