import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const CaucionTable = ({ datos = {}, moneda = "P", initialshowDetalle = false }) => {
  const [filtro, setFiltro] = useState("");
  let tabla = [];
  const [bodyVisible, setBodyVisible] = useState(false);
  const [showDetalle, setShowDetalle] = useState(initialshowDetalle);
  const diccCodMon = { P: "Pesos", D: "Dólar", C: "Dólar Transferencia" };

  function handleShowDetalle() {
    setShowDetalle(!showDetalle);
  }

  if (datos[diccCodMon[moneda]] !== undefined) {
    tabla = datos[diccCodMon[moneda]];
    tabla.sort((a, b) => a.dtv - b.dtv);
  }

  const formatNumero = (numero) => {
    return numero.toLocaleString();
  };
  const sumaMontoNeto = (datos) => {
    let suma = 0;
    datos.map((item) => {
      suma += item.mc;
    });
    return formatNumero(suma);
  };
  const showhideBody = () => {
    setBodyVisible(!bodyVisible);
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none";
  const leyBtn = bodyVisible ? "-" : "+";

  return (
    <div className="col">
      <div className="card">
        <div className="card-header ">
          <div className="row">
            <div className="col-8 ">
              <span>
                <b>Operaciones Caucion</b> - Monto: ${" "}
                {sumaMontoNeto(tabla.filter((item) => item.cuenta.includes(filtro)))}
              </span>
            </div>
            <div className="col-4">
              <span>
                <button
                  type="button"
                  onClick={() => showhideBody()}
                  className="btn btn-secondary btn-sm float-right ml-1"
                >
                  <b>{leyBtn}</b>
                </button>
                <input
                  placeholder="Cuenta"
                  onChange={(e) => {
                    setFiltro(e.target.value);
                  }}
                  className="form-control-sm form-control   float-right ml-1"
                  type="text"
                  style={{ maxWidth: "80px" }}
                />

                <button
                  onClick={handleShowDetalle}
                  type="checkbox"
                  aria-pressed="true"
                  autocomplete="off"
                  className={
                    showDetalle
                      ? "btn btn-secondary btn-sm active ml-2  float-right"
                      : "btn btn-secondary btn-sm ml-2  float-right"
                  }
                >
                  Detalle
                  <FontAwesomeIcon className="ml-2" icon={faPlusCircle} />
                </button>
              </span>
            </div>
          </div>
        </div>
        <div className={cardBodyClass}>
          <div style={{ maxHeight: "60vh", minHeight: "10vh" }} className="tableFixHead table-responsive-md">
            <table className="table table-sm table-bordered table-hover texto-small">
              <thead>
                <tr>
                  <th scope="col">Cuenta</th>
                  <th scope="col">Venc.</th>
                  <th scope="col">DTV</th>

                  {showDetalle && (
                    <>
                      <th scope="col" title="Monto a compensar" >A compensar</th>
                      <th scope="col">Monto Tom. Cdo</th>
                      <th scope="col">Monto Col. Cdo</th>
                      <th scope="col">Monto Tom. Fut</th>
                      <th scope="col">Monto Col. Fut</th>
                    </>
                  )}
                  <th scope="col">Monto Contado</th>
                  <th scope="col">Monto Futuro</th>
                  <th scope="col">Tasa</th>
                </tr>
              </thead>
              <tbody>
                {tabla
                  .filter((item) => item.cuenta.includes(filtro))
                  .map((item, index) => (
                    <tr key={index}>
                      <th scope="col">{item.cuenta}</th>
                      <td>{item.fVencimien}</td>
                      <td>{item.dtv}</td>
                      {showDetalle && (
                        <>
                          <td className="text-right">{formatNumero(Math.min(item.montoTomFut,item.montoColFut))}</td>
                          <td className="text-right">{formatNumero(item.montoTomCdo)}</td>
                          <td className="text-right">{formatNumero(item.montoColCdo)}</td>
                          <td className="text-right">{formatNumero(item.montoTomFut)}</td>
                          <td className="text-right">{formatNumero(item.montoColFut)}</td>
                        </>
                      )}
                      <td className="text-right">{formatNumero(item.mc)}</td>
                      <td className="text-right">{formatNumero(item.mf)}</td>
                      <td className="text-right">{ Math.round(item.mc) != 0 ? formatNumero(item.tasa * 100) : "-" }  </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaucionTable;
