import React, { useEffect, useState } from "react";
import PosCaucComit from "../components/PosCaucComit";
import useDocumentTitle from "../hooks/useDocumentTitle";

//import getResumenPosiCauc from "../services/getResumenPosiCauc";
import useServices from "../hooks/useServices";


const VencCaucAN = () => {
  useDocumentTitle('Vencimientos Cauciones')
  const [tablaPosiciones, setTablaPosiciones] = useState({ P: [], D: [] });
  const [listaFechas, setListaFechas] = useState({ P: [], D: [] });
  const {getResumenPosiCaucAN} = useServices();
  const [isLoading, setIsLoading] = useState(false);

  async function cargaDatos() {
    setIsLoading(true);
    const datos = await getResumenPosiCaucAN();
    setIsLoading(false);
    if(datos.error) return
    setTablaPosiciones(datos.tablasPosiciones);
    setListaFechas(datos.listaFechas);
  }

  useEffect(() => {
    cargaDatos();
  }, []);

  return (
    <div className="container-fluid">
    <div className="row no-gutters">
      <div className="col-12">
        <div className="card-header">
          <div className="row">
          <div className="col-12">
          <button disabled={isLoading} type="button" className="btn btn-secondary btn-sm" onClick={() => cargaDatos()}>
            {isLoading ? "Cargando..." : "Actualizar"}
          </button>
          </div>
  
          </div>
     
        </div>
      </div>
    </div>
    <div className="row no-gutters">
      <div className="col-sm">
        <PosCaucComit monedaLabel="PESOS" tablaPosiciones={tablaPosiciones.P} listaFechas={listaFechas.P} trader={true} showLeyenda={false} />
      </div>
      <div className="col-sm"> 
       <PosCaucComit monedaLabel="DÓLAR" tablaPosiciones={tablaPosiciones.D} listaFechas={listaFechas.D}  trader={true} showLeyenda={false} />
      </div>
    </div>
    </div>
  );
};

export default VencCaucAN;
