import React from "react";

import logo from "../img/logo.svg";
const appVer = process.env.REACT_APP_VERSION;

const About = () => {
  return (
    <div className="container ">
     < div   style={{"height": "100vh" }} className="row d-flex justify-content-center align-items-center">
     <div className="card" style={{"width": "20rem", "border-width" : "0" }}>
  <img src={logo} className="card-img-top" alt="..." />
  <hr></hr>
  <div className="card-body">
  <h1 class="card-title text-center">v{appVer}</h1>

  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item"><span className="btn btn-secondary btn-sm">Autor</span>
            <span className="badge badge-pill badge-light">Guillermo Missan</span></li>
            <li class="list-group-item"><span className="btn btn-secondary btn-sm">Contacto</span>
            <span className="badge badge-pill badge-light">gmissan@gmail.com</span></li>


  </ul>
</div>
   

      </div>
    </div>
  );
};

export default About;
