import React from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";
import TranfFCI from "../components/TranfFCI";

const NominalesFCI = () => {
  useDocumentTitle("Nominales FCI");
  return <div className="row">
    <div className="col-12">
        <TranfFCI />
    </div>
  </div>;
};

export default NominalesFCI;
