
const baseURL = process.env.REACT_APP_API_URL;

const getResumenOperdiaVC = async (options) => {
  let json =  []
  try {
    const res = await fetch(`${baseURL}getResumenOperdiaVC`, options);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export default getResumenOperdiaVC;
