
const baseURL = process.env.REACT_APP_API_URL;

const getSaldosConsolidados = async (options, dropCopyFIX = false) => {
  let json =  []
  try {
    const res = await fetch(`${baseURL}saldosConsolidados?dropCopyFIX=${dropCopyFIX}`, options);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export default getSaldosConsolidados;
