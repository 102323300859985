import React, { useState } from "react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TablePosicionesMTR = ({tableData = [], cargaData = () => {}, isLoading = false }) => {
  const [filtroCuenta, setFiltroCuenta] = useState("");
  const [filtroEspecie, setFiltroEspecie] = useState("");

  const formatNumberMoneda = (num) => {
    if(!num) return '';
      return num.toLocaleString("de", {minimumFractionDigits: 2, maximumFractionDigits: 2})
      }
      const formatNumberCantidad = (num) => {
          if(!num) return '';
            return num.toLocaleString("de", {minimumFractionDigits: 0, maximumFractionDigits: 0})
            }
  
            const ppp = (netoM, netoC) => {
                const num = netoM / netoC;
                return isFinite(num) ? num.toLocaleString("de", {minimumFractionDigits: 2, maximumFractionDigits: 2}) :  '-'
                }

   const borderStyle = { borderRightColor: "black"}   
   
   const sum = (a, b) => {
    a = a ? a : 0;
    b = b ? b : 0;
    return a + b
   }

  return <div className="card">
  <div className="card-header ">
    <span>
      <b>Posiciones</b>
      <button
              disabled={isLoading}
              className="btn btn-secondary btn-sm ml-1"
              onClick={() => {
                cargaData();
              }}
            >
              <FontAwesomeIcon icon={faSync} />
            </button>
    </span>

    <input placeholder="Especie" value={filtroEspecie} onChange={(e) => setFiltroEspecie(e.target.value) } className="form-control-sm form-control  float-right mr-1" type="text" style={{"max-width": "110px"}} />
    <input placeholder="Cuenta" value={filtroCuenta} onChange={(e) => setFiltroCuenta(e.target.value) } className="form-control-sm form-control  float-right mr-1" type="text" style={{"max-width": "70px"}} />
  </div>
  <div className="card-body table-responsive">
    <table  className="table table-sm table-bordered table-hover texto-small">
      <thead>
        <tr>
        <th style={borderStyle} colSpan={3} ></th>
        <th style={borderStyle} colSpan={2} className="text-center" scope="col">{`Inicio (t-1)`}</th>
        <th style={borderStyle} colSpan={2} className="text-center" scope="col">{`Rueda`}</th>
        <th  colSpan={2} className="text-center" scope="col">{`Final`}</th>
        </tr>
        <tr>
          <th scope="col">Cuenta</th>
          <th scope="col">Symbol</th>
          <th style={borderStyle} scope="col">Size Contrato</th>
          <th scope="col">Cantidad Contratos</th>
          <th style={borderStyle} scope="col">Cantidad</th>
          <th scope="col">Cantidad Contratos</th>
          <th style={borderStyle} scope="col">Cantidad</th>
          <th scope="col">Cantidad Contratos</th>
          <th  scope="col">Cantidad</th>
        </tr>
      </thead>
      <tbody>
      {tableData.filter( item => item.Account.toLowerCase().includes(filtroCuenta.toLowerCase()) && item.Symbol.toLowerCase().includes(filtroEspecie.toLowerCase()) ).map( item => <tr key={item.Account+item.Symbol}>
        <th className="text-right" scope="col">{item.Account}</th>
          <td >{item.Symbol}</td>
          <td style={borderStyle} className="text-right"  >{item.contractSize === null ? "ERROR_SIZE" : formatNumberCantidad(item.contractSize)  }</td>
          <td className="text-right"  >{item.posContractsQty === null ? "ERROR_SIZE" : formatNumberCantidad(item.posContractsQty)  }</td>
          <td style={borderStyle} className="text-right"  >{item.posQty === null ? "ERROR_SIZE" : formatNumberCantidad(item.posQty)   }</td>
          <td className="text-right"  >{item.posContractsQty_t0 === null ? "ERROR_SIZE" : formatNumberCantidad(item.posContractsQty_t0)  }</td>
          <td style={borderStyle}  className="text-right"  >{item.posQty_t0 === null ? "ERROR_SIZE" : formatNumberCantidad(item.posQty_t0)  }</td>
          <td className="text-right"  >{formatNumberCantidad(sum(item.posContractsQty_t0, item.posContractsQty ) )}</td>
          <td className="text-right"  >{formatNumberCantidad(sum(item.posQty_t0, item.posQty)  )}</td>
       

      </tr> ) }
      </tbody>
    </table>
  </div>
</div>;
};

export default TablePosicionesMTR;
