import React, { useState, useEffect } from "react";

import Plazotable from "../components/Plazotable";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";
import PanelHeaderTrader from "../components/PanelHeaderTrader";
const hasDerivaciones = process.env.REACT_APP_BYMA_DERIVACIONES  === "true";

const PanelSB = () => {
  useDocumentTitle("Panel SENEBI");
  const [infoCI, setInfoCI] = useState([]);
  const [info24hs, setInfo24hs] = useState([]);
  const [info48hs, setInfo48hs] = useState([]);

  const [infoCISB, setInfoCISB] = useState([]);
  const [info24hsSB, setInfo24hsSB] = useState([]);
  const [info48hsSB, setInfo48hsSB] = useState([]);
  const [moneda, setMoneda] = useState("P");
  const [cuentaOper, setCuentaOper] = useState(0);
  const [showCI, setShowCI] = useState(true);
  const [show24hs, setShow24hs] = useState(true);
  const [show48hs, setShow48hs] = useState(true);
  const [isLoadingContado, setisLoadingContado] = useState(false);
  
  const [totalOpersNoderiv, setTotalOpersNoderiv] = useState(0);
  const [totalOperDeriv, setTotalOpersDeriv] = useState(0);
  const [hayVC, setHayVC] = useState(false);
  //const [info_procesada, setInfoprocesada] = useState({})

  let info_procesada;

  const { getResumenOperdiaContadoSB, getResumenOperdiaContadoDerivacionesSB} =
    useServices();

  useEffect(() => {
    initPanel();
  }, []);

async function reloadPanel () {
  //setCuentaOper(0);

  await carga_oper_gara();

  if(hasDerivaciones) {   await carga_oper_gara_SBDerivaciones(); }
}

  async function carga_oper_gara() {
    setisLoadingContado(true)
    let operdia = await getResumenOperdiaContadoSB();
    setisLoadingContado(false);
    if (operdia.error) return;

    info_procesada = operdia;

    setTotalOpersNoderiv(prev => info_procesada.cantOper);
    //console.log({ info_procesada });
   // setHayEjercicios(info_procesada.hayEjercicios);
    setHayVC(info_procesada.hayVC);

    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCI(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hs(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hs(info_procesada.tabla["48hs"]);
      }
    }

    return true;
  }

  async function carga_oper_gara_SBDerivaciones() {
    let operdia = await getResumenOperdiaContadoDerivacionesSB();
    if (operdia.error) return;

    const info_procesada = operdia;

    setTotalOpersDeriv(info_procesada.cantOper);
    //setCuentaOper(info_procesada.cantOper);
    //console.log({ info_procesada });


    if (info_procesada.tabla != undefined) {
      if (info_procesada.tabla["Inmediato"] != undefined) {
        setInfoCISB(info_procesada.tabla["Inmediato"]);
      }
      if (info_procesada.tabla["24hs"] != undefined) {
        setInfo24hsSB(info_procesada.tabla["24hs"]);
      }
      if (info_procesada.tabla["48hs"] != undefined) {
        setInfo48hsSB(info_procesada.tabla["48hs"]);
      }
    }

    return true;
  }



  async function initPanel() {
    await carga_oper_gara();
    if(hasDerivaciones) {   await carga_oper_gara_SBDerivaciones(); }

  }





  const showhidePanel = (idpanel) => {
    switch (idpanel) {
      case "CI":
        setShowCI(!showCI);
        break;
      case "24hs":
        setShow24hs(!show24hs);
        break;
      case "48hs":
        setShow48hs(!show48hs);
        break;
    }
  };

  return (
    <div className="container-fluid">
      <PanelHeaderTrader
        showhidePanel={showhidePanel}
        setMoneda={setMoneda}
        moneda={moneda}
        cuentaOper={totalOperDeriv+totalOpersNoderiv}
        cargaContado={reloadPanel}
        showPanelesNoContado = {false}
        isLoadingContado={isLoadingContado}

        hayEjercicios={false}
        hayVC={hayVC}
        panelShow={{ showCI, show24hs, show48hs }}
      />

      <div className="row no-gutters">
        {showCI && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="CI - SENEBI" datos={infoCI} />
          </div>
        )}
        {show24hs && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="24hs - SENEBI" datos={info24hs} />
          </div>
        )}

      </div>
      
{  hasDerivaciones &&    <div className="row no-gutters">
        {showCI && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="CI - SENEBI - Derivaciones" datos={infoCISB} />
          </div>
        )}
        {show24hs && (
          <div className="col-lg col-sm-12 col-md-12">
            <Plazotable moneda={moneda} titulo="24hs - SENEBI - Derivaciones" datos={info24hsSB} />
          </div>
        )}
  
      </div>}

    </div>
  );
};

export default PanelSB;
