import React, { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import Tablev2 from "./Tablev2";
const ALYC_INTEGRAL = process.env.REACT_APP_ALYC_INTEGRAL === "true";

const NominalesControlAUNEAN = ({data = [], isLoading = false, reloadPanel = () => {} }) => {
    const [conLiq, setConLiq] = useState(false)
    const [filtroAN, setFiltroAN] = useState("")
    const [filtroCuenta, setFiltroCuenta] = useState("")
    const [filtroNroCaja, setFiltroNroCaja] = useState("")


    const formatDigit = (value) => {
        if(!value) return ""
        return value.toLocaleString("de", {minimumFractionDigits: 0, maximumFractionDigits: 4})
       }
       const sumColumn = (table, col) => {
        let sum = 0;
        table.forEach((item) => {
          if (item[col]) {
            sum += item[col];
          }
        });
        return sum;
      };

      const colAN = ALYC_INTEGRAL ? [      {
        Header: 'Cod. AN',
        accessor: 'codAN', 
        Cell: (props) => (
          <span style={{fontWeight: 'bold'}}>{String(props.value)}</span>
        ),
    
      }] : [];
      
       const columns = useMemo(
        () => [
         ...colAN,
          {
            Header: 'Cuenta',
            accessor: 'codCuenta', 
            Cell: (props) => (
              <span style={{fontWeight: 'bold'}}>{String(props.value)}</span>
            ),
      
          },
          {
            Header: 'Denom. Cuenta',
            accessor: 'desComitente',
            disableSortBy: true,

          },
          {
            Header: 'Nro. Caj. Val.',
            accessor: 'nroCajval',

          },
          {
            Header: 'Des. Espcie',
            accessor: 'descripcion',
            disableSortBy: true,
          },
          
          {
            Header: 'Inicio',
            accessor: 'tenenciaInicial',
            className: "text-right",
            Cell: (props) => formatDigit(props.value),
            sortType: 'basic',

          },
          {
            Header: 'Codo. Gdo.',
            accessor: 'contadoGarantizado',
            className: "text-right",
            Cell: (props) => formatDigit(props.value),
            sortType: 'basic',

          },
          {
            Header: 'SENEBI',
            accessor: 'contadoGarantizadoSB',
            className: "text-right",
            Cell: (props) => formatDigit(props.value),
            sortType: 'basic',

          },
          {
            Header: 'D.C. BYMA',
            accessor: 'distribCaucBYMA',
            className: "text-right",
            Cell: (props) => formatDigit(props.value),
            sortType: 'basic',

          },
          
          {
            Header: 'PNG MAV',
            accessor: 'paseNoGAR',
            className: "text-right",
            Cell: (props) => formatDigit(props.value),
            sortType: 'basic',

          },
          {
            Header: 'Cauc. MAV',
            accessor: 'movGtiasCaucMAV',
            className: "text-right",
            Cell: (props) => formatDigit(props.value),
            sortType: 'basic',

          },
/*           {
            Header: "Res. FCI Proc.",
            accessor: "fciNomRescateProcesado",
            className: "text-right",
            Cell: (props) => formatDigit(props.value),
            sortType: "basic",
          },
          {
          Header: "Res. FCI Pend.",
          accessor: "fciNomRescatePendiente",
          className: "text-right",
          Cell: (props) => formatDigit(props.value),
          sortType: "basic",
          }, */
          {
            Header: 'Posicion Cierre',
            accessor: 'cierre',
            className: "text-right",
            Cell: (props) =>           <div className={props.value < 0 ? "alert-danger" : ""}>  {formatDigit(props.value)} </div>,
            sortType: 'basic',
      
          }
        ],
        []
      )

    let dataRender = data.filter(item => item.codCuenta.includes(filtroCuenta) && item.codAN.includes(filtroAN) && item.nroCajval.includes(filtroNroCaja));
    if(conLiq) { dataRender = data.filter(item => item.contadoGarantizadoSB != 0 || item.contadoGarantizado != 0  ); }

  return (
    <div className="col-12">
    <div className="card">
      <div className="card-header">
      <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <strong>Control de Nominales</strong> 
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
          <span className="input-group" style={{justifyContent: "right"}}>
          
    
          {ALYC_INTEGRAL && <input
              placeholder="AN"
              onChange={(e) => {
                setFiltroAN(e.target.value);
              }}
              className="form-control-sm form-control  mr-2"
              type="text"
              style={{ maxWidth: "40px"}}
            />}

            <input
              placeholder="Cuenta"
              onChange={(e) => {
                setFiltroCuenta(e.target.value);
              }}
              className="form-control-sm form-control  float-right mr-2"
              type="text"
              style={{ maxWidth: "100px", minWidth: "70px" }}
            />

             <input
              placeholder="Nro. Caja"
              onChange={(e) => {
                setFiltroNroCaja(e.target.value);
              }}
              className="form-control-sm form-control  mr-2"
              type="text"
              style={{ maxWidth: "80px"}}
            />

        <div role="group" className="  btn-group-toggle float-right mr-2">
        <button
          onClick={() => {
            setConLiq(prev => !prev);
          }}
          type="checkbox"
          aria-pressed="false"
          autocomplete="off"
          className={conLiq ? "btn btn-secondary btn-sm active" : "btn btn-secondary  btn-sm"}
          title="Agrupa AN"
        >
          Con liquidacion
        </button>
      </div>


            <button
              type="button"
              onClick={() => reloadPanel()}
              name=""
              disabled={isLoading}
              className={
                !isLoading
                  ? "btn btn-secondary float-right btn-sm mr-1"
                  : "btn btn-secondary float-right btn-sm mr-1 disabled"
              }
            >
              <FontAwesomeIcon className={isLoading ? "loading-icon" : "" } icon={faSync} />
            </button>


            </span>
          </div>
        </div>
      </div>
     <div className="card-body">
     <div style={{ maxHeight: "82vh", minHeight: "10vh" }} className="tableFixHead">
            <Tablev2 hasFooter={false}  data={dataRender} columns={columns}/>
        </div>
     </div>

    </div>
  </div>
  )
}

export default NominalesControlAUNEAN