
const baseURL = process.env.REACT_APP_API_URL;

const getPosiNominales = async (options) => {
  let json =  []
  try {
    const res = await fetch( `${baseURL}getPosiNominales`, options);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export default getPosiNominales;
