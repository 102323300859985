import React, { useState, useEffect } from "react";
import CardTemplate from "../components/CardTemplate";
//import getReporteIdSdib from "../services/getReporteIdSdib";
import getTimestReporteSDIB from "../services/getTimestReporteSDIB";
import useServices from "../hooks/useServices";
import useDocumentTitle from "../hooks/useDocumentTitle";

const Sdib = () => {
  useDocumentTitle('SDIB')
  const [timest, setTimest] = useState({});
  const {getReporteIdSdib} = useServices();

  function downloadFile(file) {
    let filename, link;
    filename = file.fileName;
    let data = "data:text/txt;charset=utf-8," + file.data;
    data = encodeURI(data);
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  }

  async function des(id) {
    handleDownloading(id, true)
    const reporte = await getReporteIdSdib(id);
    downloadFile(reporte);
    handleDownloading(id, false)
    actualizaTimest();
  }
  async function actualizaTimest() {
    const res = await getTimestReporteSDIB();
    //console.log(res)
    const timest = res;
    setTimest(timest)
 
  }

  function handleDownloading(id, state) {
    let newTimest = {...timest};
    if(newTimest[id] == undefined) { newTimest[id] = {}}
    newTimest[id].isDownloading = true;
    setTimest(newTimest)
  }

  useEffect(() => {
  actualizaTimest()
  }, [])
  const timestToString = (timest) => {
    if(!timest) return '';
    return new Date(timest).toLocaleString()

  }
  const btnOpen = <button type="button" onClick={() => window.open('/api/files') } className="btn btn-secondary btn-sm float-right mr-1">Backup de archivos</button>

const data = [
  {
    nombre: "RUEDA-OPER CONTADO INM. C/OPER+OFTS",
    archivo: "RUEDANON.DAT",
    tiempo: "Tiempo convertido",
    parametroDes: "706"
  },
  {
    nombre: "RUEDA – OPER CDO. NORM. C/OP. + OFTS",
    archivo: "SINACNON.DAT",
    tiempo: "Tiempo convertido",
    parametroDes: "699"
  },
  {
    nombre: "RUEDA-OPCIONES C/OPERAD.+OFTS",
    archivo: "OPCIONEN.DAT",
    tiempo: "Tiempo convertido",
    parametroDes: "684"
  },
  {
    nombre: "RUEDA - PASE Y CAUCION C/OPER+OFTS",
    archivo: "CAUCIRNN.DAT",
    tiempo: "Tiempo convertido",
    parametroDes: "691"
  },
  {
    nombre: "OPERACIONES CON SECUENCIA",
    archivo: "OEPRSEC.DAT",
    tiempo: "Tiempo convertido",
    parametroDes: "701"
  }
];

  return (
    <div className="container-fluid">
    <div className="row">
      <div className="col-sm-0 col-lg-3 col-md-2"></div>
      <div className="col-sm-12 col-lg-6 col-md-8">
        <CardTemplate title={"SDIB"} description={"Descarga de archivos para importación"} customBtn={btnOpen}>
          <table  className="table table-sm table-bordered table-hover">
            <thead>

              <tr>
                <th scope="col">Reporte</th>
                <th scope="col">Nombre archivo</th>
                <th scope="col">Ultima Descarga</th>
              </tr>
            </thead>
            <tbody>
            {data.map(item => 
                 <tr key={item.archivo}>
                <td>{item.nombre}</td>
                <td>{item.archivo}</td>
                <td> <span> {timestToString(timest?.[item.parametroDes]?.timest)}</span></td>
                <td>
                  <button disabled={timest?.[item.parametroDes]?.isDownloading || false} type="button" className="btn btn-secondary btn-sm" onClick={() => {des(item.parametroDes)}}>
                    { timest?.[item.parametroDes]?.isDownloading ? "Descargando..." : "Descargar"}
                  </button>
                </td>
              </tr>
            )}
             
            </tbody>
          </table>
        </CardTemplate>
      </div>
      <div className="col-sm-0 col-lg-3 col-md-2"></div>
    </div>
    </div>
  );
};

export default Sdib;
