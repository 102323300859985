const baseURL = process.env.REACT_APP_API_URL;
const getPositionMaintenanceReportBetweenDatesExcel = async (options, params = {}) => {

  try {
    const res = await fetch(`${baseURL}AP5/getPositionMaintenanceReportBetweenDates/Excel?startDate=${params.startDate}&endDate=${params.endDate}&account=${params.account}`, options);
    const json = await res.blob();
    return { data: json };
  } catch (e) {
    return { error: e };
  }

}



export default getPositionMaintenanceReportBetweenDatesExcel;
