import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

const ConciliaCaucionesVBTable = ({isLoading = false, tableData = [], reloadDatos = () => {} }) => {

    const formatNumero = (num, maximumFractionDigits = 99) => {
        return num?.toLocaleString("de", {maximumFractionDigits});
      };

//console.log({tableData})

  return (
    <div className="card">
    <div className="card-header">
        <div className="row">
            <div className="col-12"> <b>Diferencias</b> - Cauciones
            <span class="badge badge-secondary ml-1">{tableData.length}</span>
            <button
                  type="button"
                  onClick={() => reloadDatos()}
                  name=""
                  className={isLoading ? "btn btn-secondary float-right btn-sm ml-2 disabled" : "btn btn-secondary float-right btn-sm ml-2"}
                >
                  <FontAwesomeIcon className={isLoading ? "loading-icon" : "" } icon={faSync} />
                </button>
            </div>
        </div>
    </div>
    <div className="card-body">
      {isLoading ? <h3 className="text-center">Cargando...</h3>  :  <div style={{ maxHeight: "35vh", minHeight: "10vh" }}  className="table-responsive tableFixHead">
        <table className="table table-sm table-hover table-bordered">
          <thead>
            <tr>
              <th>Origen</th>
              <th>Código Comitente</th>
              <th>Denomin. Comitente</th>
              <th>Vencimiento</th>
              <th>Monto Cdo. GARA</th>
              <th>Monto Cdo. VB</th>
              <th>Monto Fut. GARA</th>
              <th>Monto Fut. VB</th>
              <th>Volumen Operado GARA</th>
              <th>Volumen Operado VB</th>
              <th>Dif. Vol Operado</th>


            </tr>
          </thead>
          <tbody>
            {tableData

              .map((item) => (
                <tr key={`${item.origen}-${item.comitente}-${item.vencimiento}`}>
                  <td>{item.origen}</td>
                  <td>{item.comitente}</td>
                  <td>{item.comitenteDenom}</td>
                  <td>{item.vencimiento}</td>
                  <td className='text-right' >{formatNumero(item.montoContado)}</td>
                  <td className='text-right' >{formatNumero(item.montoContadoESCO)}</td>
                  <td className='text-right' >{formatNumero(item.montoFuturo)}</td>
                  <td className='text-right' >{formatNumero(item.montoFuturoESCO)}</td>
                  <td className='text-right' >{formatNumero(item.volumenOperado)}</td>
                  <td className='text-right' >{formatNumero(item.volumenOperadoESCO)}</td>
                  <td className='text-right' >{formatNumero(item.diferenciaVolumenOperado, 2)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>}
    </div>
  </div>
  )
}

export default ConciliaCaucionesVBTable