const baseURL = process.env.REACT_APP_API_URL;


const login = async (params) => {
  let json =  []
  if(!params.user) return;
  if(!params.password) return;
  try {
    const res = await fetch(`${baseURL}login`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    
        body: JSON.stringify(params)
    });
     
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export default login;
