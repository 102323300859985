const baseURL = process.env.REACT_APP_API_URL;
const getPositionMaintenanceReportBetweenDatesTraderExcel = async (options, params = {}) => {

  try {
    const res = await fetch(`${baseURL}AP5/getPositionMaintenanceReportBetweenDatesTrader/Excel?startDate=${params.startDate}&endDate=${params.endDate}&account=${params.account}`, options);
   
    const contentType = res.headers.get("Content-Type");

    if (contentType && contentType.includes("application/json")) {
      // Si el servidor responde con JSON, procesar como JSON
      const json = await res.json();
      return json;
    }
    const json = await res.blob();
    return { data: json };
  } catch (e) {
    return { error: e };
  }


};

export default getPositionMaintenanceReportBetweenDatesTraderExcel;
