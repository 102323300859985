import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import '../css/customTable.css'

const VCAlqTable = ({ titulo, datos,  initBodyVisb = false, realoadBtn = false, isLoading = false}) => {
 
  const [filtro, setFiltro] = useState("");
  let tabla = [];
  const [bodyVisible, setBodyVisible] = useState(initBodyVisb);
 

  //console.log("datos", datos);



  const formatNumero = (numero) => {
    return numero.toLocaleString('es');
  };

  const showhideBody = () => {
    setBodyVisible(!bodyVisible)
  };
  const cardBodyClass = bodyVisible ? "card-body" : "card-body d-none"
  const leyBtn = bodyVisible ? "-" : "+";


  return (
      <div className="card">
        <div className="card-header ">
          <div className="row">
          <div className="col-8">
            <span>
              <b>Operaciones {titulo}</b> - Monto: ${" "}
              
            </span>
       
        
          </div>
          <div className="col-4">
            <span>
     {realoadBtn &&    <button
                type="button"
                onClick={() => realoadBtn()}
                name=""
              
                className={
                 isLoading ? "btn btn-secondary float-right btn-sm ml-1 disabled" : "btn btn-secondary float-right btn-sm ml-1"
                }
              >
                <FontAwesomeIcon icon={faSync} />
              </button>}
    <button type="button" onClick={() => showhideBody()} className="btn btn-secondary btn-sm float-right ml-1">
              <b>{leyBtn}</b>
            </button>
            <input
              placeholder="Cuenta"
              onChange={(e) => {
                setFiltro(e.target.value);
              }} 
              className="form-control-sm form-control  float-right ml-1"
              type="text"
              style={{ "maxWidth": "80px" }}
            />
            </span>
          </div>
          </div>
        </div>
        <div  className={cardBodyClass}>
        <div style={{'maxHeight': '60vh', minHeight: '10vh'}} className=" tableFixHead">
          <table className="table table-sm table-bordered table-hover">
            <thead>
              <tr>
              
                <th scope="col">Cuenta</th>
                <th scope="col">Especie</th>
                <th scope="col">Vencimiento</th>
                <th scope="col">M.Contado</th>
                <th scope="col">M.Futuro</th>
                <th scope="col">Costo</th>
                <th scope="col">Tasa</th>
                <th scope="col">C.Alquilda</th>
                <th scope="col">C.Colocada</th>
              </tr>
            </thead>
            <tbody>
              {datos
                .filter((item) => item.cuenta.includes(filtro))
                .map((item, index) => (
                  <tr key={index}>
                    <th scope="col">{item.cuenta}</th>
                    <td>{item.especie}</td>
                    <td>{item.vencimiento}</td>
                    <td>{formatNumero(item.montoContado)}</td>
                    <td>{formatNumero(item.montoFuturo)}</td>
                    <td>{formatNumero(item.montoContado + item.montoFuturo)}</td>
                    <td>{formatNumero(item.tasa)}</td>
                    <td>{formatNumero(item.sumCantidadCompra)}</td>
                    <td>{formatNumero(item.sumCantidadVenta)}</td>
       
                 
                  </tr>
                ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
  );
};

export default VCAlqTable;
