
const baseURL = process.env.REACT_APP_API_URL;

export const delUser = async (options, id) => {
  let json =  []
  try {
    const res = await fetch(`${baseURL}listaUsers/${id}`, { method: 'DELETE', headers: options.headers });
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export const addUser = async (options, newUser) => {
    let json =  []
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...options.headers },
        body: JSON.stringify(newUser)
    };
    try {
      const res = await fetch(`${baseURL}listaUsers`, requestOptions);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };

  export const setUser = async (options, User) => {
    let json =  []
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...options.headers },
        body: JSON.stringify(User)
    };
    try {
      const res = await fetch(`${baseURL}listaUsers`, requestOptions);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };


 export const getUsers = async (options) => {
    let json =  []
    try {
      const res = await fetch(`${baseURL}listaUsers`, options);
      json = await res.json();
    } catch (e) {
      return { error: e };
    }
  
    return json;
  };
  

