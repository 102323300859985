import React, { useEffect } from 'react'
import TableCanceladasMTR from '../components/TableCanceladasMTR'
import useServices from '../hooks/useServices'
import useDocumentTitle from '../hooks/useDocumentTitle'

import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CanceladasMTR = () => {
   
  useDocumentTitle('Canceladas MTR')

    const [tableData, setTableData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [isLoadingExcel, setIsLoadingExcel] = React.useState(false);
    const initDate = new Date().toISOString().split('T')[0];
    const [startDate, setStartDate] = React.useState(initDate)
    const [endDate, setEndDate] = React.useState(initDate)
    const [account, setAccount] = React.useState('')
    const {getPositionMaintenanceReportBetweenDates, getPositionMaintenanceReportBetweenDatesExcel} = useServices();
    async function cargaData() {
      if(account == "") { return alert("Cuenta no puede estar vacia") }
      if(startDate == "" || endDate == "" || account == "") return;
     //check if endDate is greater than startDate
     if(new Date(endDate) < new Date(startDate)){
       alert("La fecha de fin debe ser mayor a la de inicio")
       return;
     }
     //check if endDate is max 60 days from startDate 
     const diffDays = Math.abs(new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24);
     if(diffDays > 60){
       alert(`La fecha de fin no puede ser mas de 60 dias de la de inicio, actualmente tiene ${diffDays} dias`)
       return;
     }
      setIsLoading(true);
      const res = await getPositionMaintenanceReportBetweenDates({startDate, endDate, account});
      setIsLoading(false);
      if(res.error) return;
      setTableData(res)
    }
    async function exportarExcel() {
      if(startDate == "" || endDate == "" || account == "") return;
     //check if endDate is greater than startDate
     if(new Date(endDate) < new Date(startDate)){
       alert("La fecha de fin debe ser mayor a la de inicio")
       return;
     }
     //check if endDate is max 60 days from startDate 
     const diffDays = Math.abs(new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24);
     if(diffDays > 60){
       alert(`La fecha de fin no puede ser mas de 60 dias de la de inicio, actualmente tiene ${diffDays} dias`)
       return;
     }
      setIsLoadingExcel(true);
      const res = await getPositionMaintenanceReportBetweenDatesExcel({startDate, endDate, account});
      setIsLoadingExcel(false);
      if(res.error) return;
      
      const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Canceladas_MTR-${account}_${startDate}_${endDate}.xlsx`;
      link.click();
      link.remove();
    }

  return (
    <div className="container-fluid">
   <div className="row">
    <div className="col-12">
        <div className="card-header">
            <div className="row">
                <div className="col">
          <h5 >Canceladas MTR</h5>
          </div>
      <div className="col-7">
          

           <div className="input-group"  >
           <div class="input-group-prepend">
           <span class="input-group-text"> Fecha Inicio </span>
        </div>
          <input type="date" style={{width:'100px'}} value={startDate} className="form-control  mr-2 " id="startDate" placeholder="Fecha Inicio" onChange={(e) => setStartDate(e.target.value)} />
  
          <div class="input-group-prepend">
           <span class="input-group-text"> Fecha Fin </span>
        </div>
          <input type="date" style={{width:'100px'}} value={endDate} className="form-control  mr-2 " id="endDate" placeholder="Fecha Fin" onChange={(e) => setEndDate(e.target.value)} />
      
           <div class="input-group-prepend">
           <span class="input-group-text"> Cuenta </span>
        </div>
          <input type="text" style={{width:'100px'}} value={account} className="form-control  mr-2 " id="account" placeholder="Cuenta" onChange={(e) => setAccount(e.target.value)} />
      
          <button className="btn btn-secondary btn-sm" onClick={cargaData}>{isLoading ? "Cargando..." : "Cargar"}</button>
          <button className="btn btn-success btn-sm ml-1" disabled={tableData.length == 0} onClick={exportarExcel}>{isLoadingExcel ? "Exportando..." : "Exportar"} <FontAwesomeIcon icon={faFileExcel} className="ml-2" /></button>
          </div>
         
        </div>
        </div>
      </div>
      </div>
   </div>
    <div className="row">
      <div className="col-12">
    <TableCanceladasMTR  data={tableData} isLoading={isLoading} />
      </div>
    </div>

  </div>
  )
}

export default CanceladasMTR