import React, { useEffect, useState } from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useServices from "../hooks/useServices";
import TablePosicionesMTR from "../components/TablePosicionesMTR";


const PosicionesMTRTrader = () => {
  useDocumentTitle("Posiciones - MTR");
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const {getPositionReportT0MTRTrader} = useServices();

  
  const tradeCaptureSample = {
    "tablaPosiciones": {
        "Pesos": [
            {
                "SecurityID": "DLR022024",
                "SettlCurrency": "Pesos",
                "Account": "146642",
                "buyQty": 0,
                "sellQty": 30000,
                "buyAmount": 0,
                "sellAmount": 28838000,
                "netQty": -30000,
                "netAmount": 28838000
            },
            {
                "SecurityID": "DLR012024",
                "SettlCurrency": "Pesos",
                "Account": "161943",
                "buyQty": 50000,
                "sellQty": 50000,
                "buyAmount": 44080000,
                "sellAmount": 44050000,
                "netQty": 0,
                "netAmount": -30000
            },
            {
                "SecurityID": "DLR122023",
                "SettlCurrency": "Pesos",
                "Account": "161949",
                "buyQty": 15000000,
                "sellQty": 15000000,
                "buyAmount": 12265000000,
                "sellAmount": 12269500000,
                "netQty": 0,
                "netAmount": 4500000
            },
            {
                "SecurityID": "DLR022024",
                "SettlCurrency": "Pesos",
                "Account": "161949",
                "buyQty": 41000000,
                "sellQty": 41000000,
                "buyAmount": 39446500000,
                "sellAmount": 39467000000,
                "netQty": 0,
                "netAmount": 20500000
            },
            {
                "SecurityID": "DLR012024",
                "SettlCurrency": "Pesos",
                "Account": "162036",
                "buyQty": 15000,
                "sellQty": 15000,
                "buyAmount": 13215000,
                "sellAmount": 13234500,
                "netQty": 0,
                "netAmount": 19500
            },
            {
                "SecurityID": "DLR022024",
                "SettlCurrency": "Pesos",
                "Account": "162036",
                "buyQty": 0,
                "sellQty": 30000,
                "buyAmount": 0,
                "sellAmount": 28850000,
                "netQty": -30000,
                "netAmount": 28850000
            }
        ]
    },
    "tradesCount": 38
}
 
 function combinaPosi (posTmin1 = [] , posDia = [] ) {
    let combinados = [];
    


 }

  async function cargaData() {
    setIsLoading(true);
    const res = await getPositionReportT0MTRTrader();
    if(res.error) return;
    //console.log(res)
    setTableData(res)

    setIsLoading(false);

  }


  useEffect(() => {
    cargaData()
  }, []);

  

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
         <TablePosicionesMTR isLoading={isLoading} cargaData={cargaData} tableData={tableData} />
        </div>
      </div>

    </div>
  );
};

export default PosicionesMTRTrader;
