import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faEdit } from "@fortawesome/free-solid-svg-icons";
import {procesaSaldosBYMA} from "../lib/utils"
const hasSENEBI = process.env.REACT_APP_BYMA_SENEBI  === "true";

const Li = ({ type = "strong", leyenda, valor = 0 }) => {
  const formatNumero = (numero) => {
    if (!numero) return "";
    return (numero * 1).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2});
  };

  return (
    <>
      {type == "strong" && (
        <li className="list-group-item d-flex justify-content-between bg-light">
          <div>
            <h6 className="my-0">{leyenda}</h6>
          </div>
          <strong>{formatNumero(valor)}</strong>
        </li>
      )}
      {type == "light" && (
        <li className="list-group-item d-flex justify-content-between lh-condensed">
          <div>
            <small className="text-muted">{leyenda}</small>
          </div>
          <span id="sdos_inicio" className="text-muted ">
            {formatNumero(valor)}
          </span>
        </li>
      )}
    </>
  );
};

const SaldosPanel = ({ moneda, saldos, saldosCI = false, saldosCISB = false, saldosCaucion, reloadPanelSaldos, saldosDerivaciones = false, isLoading = false }) => {
  const formatNumero = (numero) => {
    if (!numero) return "";
    return (numero * 1).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2});
  };

  const mon_dicc = { P: "Pesos", D: "Dólares", C: "Dólar Transferencia" };
  const diccCodMon = {'P': 'Pesos', 'D': 'Dólar', 'C': 'Dólar Transferencia'}

  const saldosMoneda = saldos[moneda];
  let saldoInicio = 0;
  let saldosDant = [];
  let saldosAntesCI = 0;
  let movBancos = null;
  let movGtias = 0;
  //const [saldosCIMoneda, setSaldosCIMoneda] = useState(0);
  const [saldoCaucion, setSaldoCaucion] = useState(0);
  const [editarSaldoOtros, setEditarSaldoOtros] = useState(false);
  const [saldoOtros, setSaldoOtros] = useState(0);
  const [showDatallado, setShoDetallado] = useState(false)
  //const [isLoading, setIsLoading]  = useState(false);

  //console.log("saldosCaucion", saldosCaucion);

  let saldosCIMoneda = 0;
  let saldosCIMonedaSB = 0;
  if(saldosCI) { saldosCIMoneda = saldosCI[diccCodMon[moneda]] || 0 }
  if(saldosCISB) { saldosCIMonedaSB = saldosCISB[diccCodMon[moneda]] || 0 }

/*   useEffect(() => {
    //console.log({ saldosCI });

    if (saldosCI !== undefined) {
      //console.log({ saldosCI });
      //setSaldosCIMoneda(moneda == "P" ? saldosCI["Pesos"] : saldosCI["Dólar"]);
      setSaldosCIMoneda(saldosCI[diccCodMon[moneda]] || 0);
     //  console.log(saldosCIMoneda);
    } else {
      setSaldosCIMoneda(0);
    }

    //console.log({ saldosCI }, { saldosCIMoneda });
  }, [saldosCI]); */

  useEffect(() => {
    if (Object.keys(saldosCaucion).length > 0) {
      if (saldosCaucion["Pesos"] && moneda == "P") {
        setSaldoCaucion(saldosCaucion["Pesos"].montoContado);
      }
      if (saldosCaucion["Dólar"] && moneda == "D") {
        setSaldoCaucion(saldosCaucion["Dólar"].montoContado);
      }
    }
  }, [saldosCaucion]);

  const procesados = procesaSaldosBYMA({ saldosMoneda, saldoInicio, movGtias, saldosAntesCI, saldosDant, saldoOtros, movBancos  })
  saldoInicio = procesados.saldoInicio
  movGtias = procesados.movGtias
  saldosAntesCI = procesados.saldosAntesCI
  saldosDant = procesados.saldosDant
  //setSaldoOtros(procesados.saldoOtros)
  movBancos = procesados.movBancos


  const handleChange = (e) => {
    //console.log(e.target.value*1)
    setSaldoOtros(e.target.value * 1);
  };
  const leyBtn = showDatallado ? "-" : "+";

  const handleReload = async () => {
  
    await reloadPanelSaldos()
  
  }
  let saldoDerivableTotal = 0;
  if(saldosDerivaciones) {
    if(saldosDerivaciones.saldosNetos['48hs']) {
      saldoDerivableTotal += saldosDerivaciones.saldosNetos['48hs'][diccCodMon[moneda]] || 0;
    }
    if(saldosDerivaciones.saldosNetos['24hs']) {
      saldoDerivableTotal += saldosDerivaciones.saldosNetos['24hs'][diccCodMon[moneda]] || 0;
    }
    saldoDerivableTotal *= -1;
    saldosAntesCI+= saldoDerivableTotal;
    if(saldosDerivaciones.saldosNetos['Inmediato']) {
      let rdoDerivaciones = saldosDerivaciones.saldosNetos['Inmediato'][diccCodMon[moneda]]  - saldosDerivaciones.saldos['Inmediato'][diccCodMon[moneda]];
      rdoDerivaciones = isNaN(rdoDerivaciones) ? 0 : rdoDerivaciones *-1;
      saldosCIMoneda += rdoDerivaciones;
    }
  }
  
  //console.log({saldosAntesCI , saldosCIMoneda ,movBancos, saldoCaucion,movGtias})
  return (
    <div className="col-lg-4 col-sm-6 col-md-6">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <strong>Saldos en {mon_dicc[moneda]} </strong> - {new Date().toLocaleDateString()}
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 d-sm-none d-md-block ">

              <button
                type="button"
                onClick={() => setShoDetallado(!showDatallado)}
                className="btn btn-secondary btn-sm float-right ml-1"
              >
                <b>{leyBtn}</b>
              </button>
              <button type="button" onClick={() => handleReload() } name="" disabled={isLoading} className={!isLoading ? "btn btn-secondary float-right btn-sm mr-1" : "btn btn-secondary float-right btn-sm mr-1 disabled"}>
                <FontAwesomeIcon className={isLoading ? "loading-icon" : "" }  icon={faSync} /> 
              </button>
              <button
                onClick={() => {
                  setEditarSaldoOtros(!editarSaldoOtros);
                }}
                type="button"
                id="bt_edit"
                name=""
                className="btn btn-secondary float-right btn-sm mr-1"
                disabled={!showDatallado}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>

            </div>
          </div>
        </div>
        <div className="card-body">
          {showDatallado && (
            <ul className="list-group ">
              <Li type="strong" leyenda={"Saldos al inicio"} />
              <Li type="light" leyenda={"CCyL BYMA"} valor={saldoInicio} />
              <Li type="strong" leyenda={"Dias anterior liquida hoy"} />

              {saldosDant.map((item) => (
                <Li type="light" leyenda={item.concepto} valor={item.monto} />
              ))}
              {saldosDerivaciones &&   <Li type="light" leyenda={"Contado Gdo. Derivable"} valor={saldoDerivableTotal} />
              }

              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <small className="text-muted">Otros</small>
                </div>
                <span id="sdos_otros" className="text-muted ">
                  {editarSaldoOtros ? (
                    <input
                      onChange={handleChange}
                      value={saldoOtros}
                      className="form-control-sm "
                      type="number"
                    ></input>
                  ) : (
                    formatNumero(saldoOtros)
                  )}
                </span>
              </li>
              <Li type="strong" leyenda={"Saldo antes de CI"} valor={saldosAntesCI} />
              <Li type="strong" leyenda={"Movimientos hoy"} />
              <Li type="light" leyenda={"CI"} valor={saldosCIMoneda} />

              {movBancos != 0 && <Li type="light" leyenda={"Bancos"} valor={movBancos} />}

              <Li type="light" leyenda={"Caucion"} valor={saldoCaucion} />
              {movGtias != 0 && <Li type="light" leyenda={"Mov. Gtias"} valor={movGtias} />}
              {hasSENEBI && <Li type="light" leyenda={"SENEBI"} valor={saldosCIMonedaSB} />}

              <Li
                type="strong"
                leyenda={"Final"}
                valor={saldosAntesCI + saldosCIMoneda + saldosCIMonedaSB + movBancos + saldoCaucion + movGtias}
              />
            </ul>
          )}

          {!showDatallado && (
            <ul className="list-group">
              <Li type="strong" leyenda={"Saldos al inicio"} valor={saldoInicio} />
              <Li type="strong" leyenda={"Saldo antes de CI"} valor={saldosAntesCI} />
              <Li
                type="strong"
                leyenda={"Final"}
                valor={saldosAntesCI + saldosCIMoneda + saldosCIMonedaSB + movBancos + saldoCaucion + movGtias}
              />
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SaldosPanel;
