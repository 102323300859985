import React, { useEffect, useState } from "react";
//import getReporteIdSdib from "../services/getReporteIdSdib";
import "../css/cssMovCus.css"
import useServices from "../hooks/useServices";

const ControlMovCustodia = ({ tenenciaBOP = [] }) => {
  const [data, setData] = useState([])
  const {getReporteIdSdib} = useServices();
  const cargaMovimientosCustodia = async () => {
    const reporte = await getReporteIdSdib(639);
    const { data } = reporte;
    let movcus = data.split("\r\n");
    movcus.pop();
    for (var i = 0; i < movcus.length; i++) {
      movcus[i] = movcus[i].split('"');
    }
    //console.log({ tenenciaBOP });
    const tabla = generaTablaMovcus(procesaMovcus(movcus), tenenciaBOP);
    setData(tabla);
  };

  const formatNumero = (valor) => { 
    if(!valor) return ''
    return valor.toLocaleString() 
  }
  const getEspcieComit = (nroespecie, nrocaja, tbval) => {
    var found = false;
    for (var i = 0; i < tbval.length; i++) {
      if (tbval[i].nroCajaValores * 1 == nroespecie * 1 && tbval[i].nroComitente * 1 == nrocaja * 1 &&  tbval[i].estado == "Disponible") {
        found = tbval[i];
      }
    }
    return found;
  };

  const suma_especie = (nroespecie, tbval) => {
    var cantidad = 0;
    for (var i = 0; i < tbval.length; i++) {
      if (tbval[i].nroCajaValores * 1 == nroespecie * 1 && tbval[i].estado == "Disponible") {
        cantidad += tbval[i].cantidad;
      }
    }
    return cantidad;
  };

  const procesaMovcus = (movcus) => {
    var dic_esp = {};
    var tenencias = {};
    movcus.forEach((element) => {
      var indice = element[0];
      var tipo = element[4];
      var nroesp = element[1];
      var especie = element[2].trim();
      var nrocomitente = element[3];
      var cantidad = element[5];

      if (indice == "0") {
        dic_esp[nroesp] = especie;
        tenencias[nroesp] = { total: 0, codespecie: especie };
      }
      if (tipo == "SA") {
        if (tenencias[nroesp][nrocomitente] == undefined) {
          tenencias[nroesp][nrocomitente] = {};
        }
        tenencias[nroesp][nrocomitente].saldo = cantidad * 1;
      }
      if (tipo == "LQ") {
        tenencias[nroesp][nrocomitente].saldo += cantidad * 1;
      }
      if (tipo == "TF") {
        tenencias[nroesp][nrocomitente].saldo += cantidad * 1;
      }
      if (tipo == "AC") {
        tenencias[nroesp][nrocomitente].saldo += cantidad * 1;
      }
      if (tipo == "EX") {
        tenencias[nroesp][nrocomitente].saldo += cantidad * 1;
      }
      if (tipo == "BQ") {
        tenencias[nroesp][nrocomitente].saldo += cantidad * 1;
      }
      if (tipo == "CL") {
        tenencias[nroesp][nrocomitente].saldo += cantidad * 1;
      }
      if (tipo == "GT") {
        tenencias[nroesp][nrocomitente].saldo += cantidad * 1;
      }
      if (tipo == "CU") {
        tenencias[nroesp][nrocomitente].saldo += cantidad * 1;
      }
      if (tipo == "FD") {
        tenencias[nroesp][nrocomitente].saldo += cantidad * 1;
      }

      if (tipo == "SD") {
        tenencias[nroesp].total = cantidad * 1;
      }
    });

    return tenencias;
  };

  const generaTablaMovcus = (tenencias, tbval) => {
    var tb = [];
    for (var especie in tenencias) {
      tb.push({
        nroesp: especie,
        codespecie: tenencias[especie].codespecie,
        nrocomitente: "",
        saldo: "",
        class: "encabezado",
      });
      for (var nrocomit in tenencias[especie]) {
        if (!isNaN(nrocomit * 1)) {
          var posibop = getEspcieComit(especie, nrocomit, tbval);
          //console.log(posibop);
          if (posibop) {
            var cantidadbop = posibop.cantidad;
          } else {
            var cantidadbop = 0;
          }

          tb.push({
            nroesp: especie,
            codespecie: tenencias[especie].codespecie,
            nrocomitente: nrocomit,
            saldo: tenencias[especie][nrocomit].saldo,
            cantidadbop: cantidadbop,
          });
        }
      }

      var cant_total_bop = suma_especie(especie, tbval);
      tb.push({
        class: "encabezado2",
        nroesp: especie,
        codespecie: tenencias[especie].codespecie,
        nrocomitente: "TOTAL",
        saldo: tenencias[especie].total,
        cantidadbop: cant_total_bop,
      });
    }
    return tb;
  };

  useEffect(() => {}, [tenenciaBOP]);

  return (
    <div className="card">
      <div className="card-header grey">
        <span>
          {" "}
          <b>MOVIMIENTOS DE CUSTODIA</b> - Control{" "}
        </span>
        <button
          onClick={() => {
            cargaMovimientosCustodia();
          }}
          type="button"
          className="btn btn-secondary btn-sm float-right ml-1"
        >
          CARGAR
        </button>
      </div>
      <div className="card-body">
        <table className="table table-sm table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">Nro. Especie</th>
              <th scope="col">Cod. Especie</th>
              <th scope="col">Nro Comit</th>
              <th scope="col">Saldo Cus.</th>
              <th scope="col">Saldo BOP</th>
              <th scope="col">Diferencia</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => <tr className={item.class}>
              <td>{item.nroesp}</td>
              <td>{item.codespecie}</td>
              <td>{item.nrocomitente}</td>
              <td>{formatNumero(item.saldo)}</td>
              <td>{formatNumero(item.cantidadbop)}</td>
              <td>{formatNumero(item.cantidadbop-item.saldo)}</td>
            </tr>)}

          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ControlMovCustodia;
