
const baseURL = process.env.REACT_APP_API_URL;

const distribFilesMAV = async (options, data) => {
  let blob = '';
  const body = JSON.stringify(data);


  try {
    const res = await fetch(`${baseURL}MAV/distribFile`, {
      method: "POST",
      headers: {
   
        "Content-Type": "application/json",
        ...options.headers
      },

      body: body,
    });
    if(res.ok) {
    //console.log({res})

    const filename = res.headers.get('content-disposition');
    blob = await res.blob();
    
    var url = window.URL.createObjectURL(blob);
    //console.log(url)
    var a = document.createElement('a');
    a.download =  filename.split('filename="')[1].split('"')[0];
    a.href = url;
    document.body.appendChild(a); 
    a.click();    
    a.remove();  
    } else {
      return await res.json()
    }

  } catch (e) {
    return { error: e };
  }

  return blob;
};

export default distribFilesMAV;
