import React from "react";
import TablaRG624 from "../components/TablaRG624"
import useDocumentTitle from "../hooks/useDocumentTitle";


const RG624 = () => {
  useDocumentTitle('RG624')
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm">
            <TablaRG624 />


        </div>
      </div>
    </div>
  );
};

export default RG624;
